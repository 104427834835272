<a role="button" (click)="onCardClick()">
    <div class="tag cursor-pointer d-flex align-items-center">
        <svg
            width="7"
            height="55"
            viewBox="0 0 7 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.70392 56L3 56C1.34315 56 4.45483e-06 54.6569 4.65628e-06 53L1.07353e-05 3C1.09367e-05 1.34314 1.34316 -3.36987e-07 3.00001 -2.32834e-07L6.70393 0L6.70392 56Z"
                [attr.fill]="Tag.color" />
        </svg>

        <div class="d-flex flex-column mw-100">
            <span class="title pl-3">{{ Tag.name }}</span>
            <span class="digital-assets pl-3">{{ getDigitalAssetsCount }}</span>
        </div>

        <i *ngIf="showRemoveIcon" class="fas fa-times action-btn" (click)="onClickRemoveSVG()" [tooltip]="'Remove'" [delay]=500></i>
    </div>
</a>
