<ng-template #template>
  <div
    class="modal-header d-flex align-items-center"
    [ngStyle]="{'background-color': settings.isDelete ? '#ff5a5a' : '#0014DC'}">
    <h5 class="modal-title pull-left">{{ settings.title }}</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="noCLick()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-4">
    <div class="row no-gutters py-4 d-flex align-items-center">
      <svg
        *ngIf="settings.isDelete"
        class="mr-3"
        width="38"
        height="46"
        viewBox="0 0 38 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M38 8.625H35.0769V41.6875C35.0769 42.2865 34.9627 42.848 34.7344 43.3721C34.506 43.8962 34.1939 44.3529 33.7981 44.7422C33.4022 45.1315 32.9379 45.4385 32.405 45.6631C31.8722 45.8877 31.3013 46 30.6923 46H7.30769C6.69872 46 6.1278 45.8877 5.59495 45.6631C5.0621 45.4385 4.59776 45.1315 4.20192 44.7422C3.80609 44.3529 3.49399 43.8962 3.26562 43.3721C3.03726 42.848 2.92308 42.2865 2.92308 41.6875V8.625H0V5.75H11.6923V2.875C11.6923 2.4707 11.7684 2.09635 11.9207 1.75195C12.0729 1.40755 12.2784 1.10807 12.5373 0.853516C12.8113 0.583984 13.1234 0.374349 13.4736 0.224609C13.8237 0.0748698 14.2043 0 14.6154 0H23.3846C23.7957 0 24.1763 0.0748698 24.5264 0.224609C24.8766 0.374349 25.1811 0.583984 25.4399 0.853516C25.7139 1.10807 25.9271 1.40755 26.0793 1.75195C26.2316 2.09635 26.3077 2.4707 26.3077 2.875V5.75H38V8.625ZM14.6154 5.75H23.3846V2.875H14.6154V5.75ZM32.1538 8.625H5.84615V41.6875C5.84615 42.0768 5.99079 42.4137 6.28005 42.6982C6.56931 42.9827 6.91186 43.125 7.30769 43.125H30.6923C31.0881 43.125 31.4307 42.9827 31.72 42.6982C32.0092 42.4137 32.1538 42.0768 32.1538 41.6875V8.625ZM14.6154 37.375H11.6923V14.375H14.6154V37.375ZM20.4615 37.375H17.5385V14.375H20.4615V37.375ZM26.3077 37.375H23.3846V14.375H26.3077V37.375Z"
          fill="#FF5A5A" />
      </svg>

      <span>{{ settings.description }}</span>
    </div>

    <div class="d-flex justify-content-center pt-4">
      <button
        type="submit"
        (click)="noCLick()"
        [ngClass]="
                    settings.isDelete
                        ? 'button-bordered-red'
                        : 'button-bordered-blue'
                "
        class="col-md-5">
        CANCEL
      </button>

      <button
        type="submit"
        (click)="yesClick()"
        [ngClass]="settings.isDelete ? 'button-red' : 'button-blue'"
        class="button-blue col-md-7">
        {{ settings.confirmButtonText }}
      </button>
    </div>
  </div>
</ng-template>