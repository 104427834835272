import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Tag } from '~/shared/models/tag.model';
import { FilterService } from '~/shared/services/filter.service';

@Component({
    selector: 'app-card-tag',
    templateUrl: './card-tag.component.html',
    styleUrls: ['./card-tag.component.scss']
})
export class CardTagComponent implements OnInit {
    @Input()
    public Tag: Tag;

    @Input()
    public showRemoveIcon: boolean;

    @Output()
    public onRemoveTag = new EventEmitter<Tag>();

    @Input()
    public openFilterPageOnClick = true;

    constructor(private filterService: FilterService, private router: Router) { }

    public onCardClick() {
        if (this.openFilterPageOnClick)
            this.filterService.ShowAssetsRelatedToTag(this.Tag);
        else
            return event;
    }

    public get getDigitalAssetsCount() {
        return this.Tag.usage + ' BI Assets';
    }

    public ngOnInit(): void { }

    public onClickRemoveSVG() {
        this.onRemoveTag.emit(this.Tag);
    }
}
