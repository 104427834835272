import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { Tag } from '~/shared/models/tag.model';
import { LateralMenuService } from '~/shared/services/lateral-menu.service';

@Component({
    selector: 'app-assets-without-category',
    templateUrl: './assets-without-category.component.html',
    styleUrls: ['./assets-without-category.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({ height: 0, opacity: 0 }),
                        animate('250ms ease-out',
                            style({ height: '*', opacity: 1 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ height: '*', opacity: 1 }),
                        animate('250ms ease-in',
                            style({ height: 0, opacity: 0 }))
                    ]
                )
            ]
        )
    ]
})
export class AssetsWithoutCategoryComponent implements OnInit {

    @Input()
    public color = '#1c74d6';

    @Input()
    public assetsWithoutCategory: Array<Asset> = [];

    @Input()
    public tagsToFilter: Array<Tag> = [];

    public MaxAssetsToShow = 3;
    public showAll = false;

    constructor(
        private lateralMenuService: LateralMenuService
    ) { }

    public ngOnInit(): void {
        this.assetsWithoutCategory = UtilsService.sortArrayByTitleAndPriority(this.assetsWithoutCategory);

        const totalPriorityItens = this.assetsWithoutCategory.filter(a => a.isPriority === true).length;

        if (totalPriorityItens > 0) {
            this.MaxAssetsToShow = totalPriorityItens;
        }

    }

    public assetClicked(asset: Asset) {
        this.lateralMenuService.setAsset(asset);
    }

    public openAssetAddress(url: string, event) {
        event.stopPropagation();
        window.open(url, '_blank');
    }

    public get assetsToShow(): Array<Asset> {

        if (this.tagsToFilter?.length > 0) {

            const filteredAssets = [];
            this.assetsWithoutCategory.forEach((asset) => {
                this.tagsToFilter.forEach((selectedTag) => {
                    if (asset.tags.some((tag) => tag.id === selectedTag.id)) {
                        filteredAssets.push(asset);
                    }
                });
            });

            const assetsNotDuplicated = [];

            filteredAssets.forEach((asset) => {
                const i = assetsNotDuplicated.findIndex((x) => x.id === asset.id);
                if (i <= -1) {
                    assetsNotDuplicated.push(asset);
                }
            });

            return assetsNotDuplicated;
        }
        else {
            return this.assetsWithoutCategory;
        }
    }

    public getAssetTagsColors(asset: Asset) {

        return asset.tags.map((tag) => tag.color)

    }

}
