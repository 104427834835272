<div *ngIf="isVisible"
  class="board-title w-100 {{ marginTop }}"
  [ngClass]="{ borderless: !showBorderBottom }">

  <div class="d-flex mt-3">

    <div class="d-flex flex-column">

      <div class="d-flex align-items-center">
        <span class="capitalize" [ngClass]="{ 'title-small': FontSmall, 'title-big': !FontSmall }">
          {{ Title }}
        </span>

        <div *ngIf="showAllType" class="cursor-pointer view-all d-flex justify-content-center align-items-center" (click)="redirect()">

          <span class="see-more">See More</span>

        </div>

      </div>

      <span class="description" *ngIf="Description">
        {{ Description }}
      </span>

    </div>

  </div>

</div>