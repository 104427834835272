import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { AssetService } from '~/shared/services/asset.service';
import { LateralMenuService } from '~/shared/services/lateral-menu.service';
import { RequestAccessDetailsService } from '~/shared/services/request-access-description.service';

@Component({
  selector: 'app-card-asset',
  templateUrl: './card-asset.component.html',
  styleUrls: ['./card-asset.component.scss'],

  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class CardAssetComponent implements OnInit {
  @Input()
  public Asset: Asset;

  // Used in the user page.
  @Input()
  public isEdit = false;

  // Used in category and Storefront page creation.
  @Input()
  public isRemove = false;

  // Used to enable the option to change asset priority
  @Input()
  public isPriority = false;

  @Input()
  public logoAreaColor = '';

  @Output()
  public clickSvg = new EventEmitter<Asset>();

  @Output()
  public clickLike = new EventEmitter();

  public targetElement: HTMLElement;

  constructor(
    private lateralMenuService: LateralMenuService,
    private router: Router,
    private requestAccessDetailsService: RequestAccessDetailsService,
    private assetService: AssetService
  ) {}

  public ngOnInit(): void {
    if (!this.logoAreaColor) {
      this.logoAreaColor = this.getInitialLogoColor();
    }
  }

  public OpenLateralModal(asset) {
    event.stopPropagation();
    this.lateralMenuService.setAsset(this.Asset);
  }

  public onClick(event) {
    event?.stopPropagation();

    if (this.isRemove) this.clickSvg.emit(this.Asset);
    else if (this.isEdit)
      this.router.navigate(['/asset', this.Asset.id, 'edit']);
  }

  public openAddress(url: any, event) {
    window.open(url, '_blank');
  }

  public getInitialLetters(): string {
    return UtilsService.getInitialLetters(this.Asset.name);
  }

  public get showShortcuts(): boolean {
    return (
      this.Asset.url != null ||
      this.Asset.requestAccessLink != null ||
      this.Asset.documentationLink != null ||
      this.Asset.askAndLearn != null ||
      this.Asset.videoUrl != null ||
      this.Asset.usageStats != null
    );
  }

  public openRequestAccessDetails(event): void {
    event.stopPropagation();
    this.requestAccessDetailsService.setRequestAccess(
      this.Asset.requestAccessLink,
      true
    );
  }

  // public get getColorWithAlpha() {
  //     return UtilsService.addAlpha(this.logoAreaColor, .75);
  // }

  public emitLike() {
    this.clickLike.emit(this.Asset);
  }

  private getInitialLogoColor(): string {
    const initialLetter = this.getInitialLetters()[0].toLocaleLowerCase();

    if (['1', 'a', 'g', 'm', 's', 'y'].some((l) => l === initialLetter)) {
      return '#e5d48b'; // yellow
    } else if (
      ['2', 'b', 'h', 'n', 't', 'z'].some((l) => l === initialLetter)
    ) {
      return '#feb7b1'; // orange
    } else if (
      ['3', '7', 'c', 'i', 'o', 'u'].some((l) => l === initialLetter)
    ) {
      return '#ffb3c6'; // pink
    } else if (
      ['4', '8', 'd', 'j', 'p', 'v'].some((l) => l === initialLetter)
    ) {
      return '#b5ead6'; // green
    } else if (
      ['5', '9', 'e', 'k', 'q', 'w'].some((l) => l === initialLetter)
    ) {
      return '#9bdbe8'; // blue
    } else if (
      ['6', '10', 'f', 'l', 'r', 'x'].some((l) => l === initialLetter)
    ) {
      return '#bfa2db'; // purple
    } else {
      return '#957fef'; // purple 2
    }
  }

  public getCountViewNumber() {
    return UtilsService.getShortViewNumber(this.Asset.numberOfViews);
  }

  public openEmail() {
    event.stopPropagation();

    this.assetService
      .getAssetById(this.Asset.id)
      .pipe(take(1))
      .subscribe((a) => {
        const mailText = `mailto:${a.coOwners.map((u) => {
          return u.email + ';';
        })}${a.productOwner.email}?subject=${a.name}&body=`;
        window.location.href = mailText;
      });
  }
}
