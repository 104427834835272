<div class="container">

  <div class="row mt-3 px-3">
    <div class="d-flex flex-column w-100">

      <div class="d-flex w-100 align-items-center">

        <mat-form-field appearance="standard" class="w-25">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applySearchFilter($event)" placeholder="Name" #input>
        </mat-form-field>

        <div class="filter ml-auto pt-2">

          <span class="mr-4"><b>Filter:</b></span>

          <span class="cursor-pointer mr-3 p-1"
            (click)="applyFilter('update')"
            [ngClass]="{'filter-selected': currentFilter == 'update'}">
            <i [class]="'fas fa-circle status icon-yellow mr-1'"></i>
            Update needed
          </span>

          <span class="cursor-pointer mr-3 p-1"
            (click)="applyFilter('duplicated')"
            [ngClass]="{'filter-selected': currentFilter == 'duplicated'}">
            <i [class]="'fas fa-circle status icon-red mr-1'"></i>
            Duplicated
          </span>

          <span class="cursor-pointer p-1"
            (click)="applyFilter('assign')"
            [ngClass]="{'filter-selected': currentFilter == 'assign'}">
            <i [class]="'fas fa-circle status icon-orange mr-1'"></i>
            BI Report link not validated
          </span>
        </div>

      </div>

      <table mat-table [dataSource]="assetsDataSource" matSort #assetsSort="matSort">

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="6%">Status</th>
          <td mat-cell *matCellDef="let element">
            <i [class]="'fas fa-circle status ' + getStatusClass(element)"
              tooltip=" {{ getStatusName(element) | titlecase }}" placement="top"></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef width="10%" class="align-right"></th>
          <td mat-cell *matCellDef="let element" class="align-right">
            <span class="take-action" align="center" (click)="takeAction()">{{getbtnTitle(element)}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"
          (click)="selecAssetRow(row)" [ngClass]="{ 'selected': row === selectedAsset }"></tr>
      </table>

      <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: white; border: 1px solid #c7d0d8; border-top: none;"
        class="py-5">
        <app-loader [height]="32"></app-loader>
      </div>

      <mat-paginator #assetsPaginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>

  </div>

</div>

<app-fix-asset-maintenance-modal
  *ngIf="showModal && selectedAsset"
  [asset]="selectedAsset"
  (close)="showModal = false"
  (saved)="getAssets()">

</app-fix-asset-maintenance-modal>

<ng-template #loading>

</ng-template>