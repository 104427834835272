import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IImage } from '~/shared/models/image.model';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
    @Input() public fileType = '.jpg,.jpeg,.png';

    @Output() public filesSelected = new EventEmitter<Array<IImage>>();

    public uniqId = '';

    private numberSelectedItems = 0;

    @Input()
    public multiple = false;

    public get isMultiple() {
        if (this.multiple) return 'multiple';

        return '';
    }

    constructor() {}

    public ngOnInit(): void {
        this.uniqId = Math.random().toString(36).substring(2);
    }

    private selectedImgs = [];

    public onFileSelected(event) {
        this.numberSelectedItems = event.target.files.length;
        this.selectedImgs = [];

        this.processFiles(event.target);
    }

    private processFiles(target) {
        for (const file of target.files) {
            this.getLocalPath(file);
        }
    }

    private getLocalPath(file: File) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            const selectImg: IImage = {
                image: file,
                localPath: event.target.result as string,
                isNew: true
            };
            this.storeAndSaveAttachment(selectImg);
        };
        reader.readAsDataURL(file);
    }

    private storeAndSaveAttachment(image: IImage) {
        this.selectedImgs.push(image);

        if (this.numberSelectedItems === this.selectedImgs.length) {
            this.filesSelected.emit(this.selectedImgs);
        }
    }
}
