import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { ValueStream } from '../models/value-stream.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({ providedIn: 'root' })
export class ValueStreamsService extends ApiServiceBase<ValueStream> {
  constructor(protected http: HttpClient) {
    super(http, 'value-streams');
  }

  public getAll(): Observable<Array<ValueStream>> {
    return this.get();
  }
}
