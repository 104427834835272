import { Component, Input, OnInit } from '@angular/core';
import { Review } from '~/shared/models/review.model';

@Component({
    selector: 'app-card-review',
    templateUrl: './card-review.component.html',
    styleUrls: ['./card-review.component.scss']
})
export class CardReviewComponent implements OnInit {
    @Input()
    public Review: Review;

    constructor() {}

    ngOnInit(): void {}
}
