<div class="card text-left" *ngIf="filters && !showFilterComponent">
  <div class="title card-header">FILTER</div>

  <div class="card-body p-2 pb-3">
    <ng-container>
      <div
        class="p-2"
        *ngIf="
                    this.filters?.storesRawCount > 0 &&
                    (this.filters.filterType == 'showAllGlobal' ||
                        this.filters.filterType == 'allStoreFronts' ||
                        this.filters.filterType == 'showAllFromStore' ||
                        this.filters.filterType == 'mostVisitedStoreFronts' ||
                        this.filters.filterType == 'lastAddedStoreFronts')
                ">
        <span class="category-title">VERIFICATION</span>

        <div class="d-flex align-items-start flex-column pr-0">
          <label class="check-box-container">Verified
            <input
              type="checkbox"
              [checked]="filters.showStoresVerified"
              (click)="filters.changeStoresVerifiedVisibility()" />
            <span class="checkmark"></span>
          </label>

          <label class="check-box-container">Unverified
            <input
              type="checkbox"
              [checked]="filters.showStoresUnVerified"
              (click)="filters.changeStoresUnVerifiedVisibility()" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div
        *ngIf="
                    this.filters.filterType == 'showAllGlobal' ||
                    this.filters.filterType == 'showAllFromStore'
                ">
        <span
          class="category-title pl-2"
          *ngIf="
                        filters.storesRawCount > 0 ||
                        filters.assetsRawCount > 0 ||
                        filters.tagsRawCount > 0
                    ">TYPE</span>

        <div
          class="d-flex align-items-start flex-column pr-0 pl-2"
          *ngIf="filters.storesRawCount > 0">
          <label class="check-box-container">Stores
            <input
              type="checkbox"
              [checked]="filters.showStoresList"
              [disabled]="filters.storesToShow.length == 0"
              (click)="filters.changeStoresVisibility()" />
            <span class="checkmark"></span>
          </label>
        </div>

        <div
          class="d-flex align-items-start flex-column pr-0 pl-2"
          *ngIf="filters.assetsRawCount > 0">
          <label class="check-box-container">BI Assets
            <input
              type="checkbox"
              [checked]="filters.showAssetsList"
              (click)="filters.changeAssetsVisibility()" />
            <span class="checkmark"></span>
          </label>
        </div>

        <div
          class="d-flex align-items-start flex-column pr-0 pl-2"
          *ngIf="filters.tagsRawCount > 0">
          <label class="check-box-container">Tags
            <input
              type="checkbox"
              [checked]="filters.showTagsList"
              (click)="filters.changeTagsVisibility()" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div style="margin-top: 10px;"
        *ngIf="
                    this.filters.filterType == 'showAllGlobal' ||
                    this.filters.filterType == 'showAllFromStore' &&
                    this.selectedStore  == null
                ">
        <span
          class="category-title pl-2"
          *ngIf="filters.assetsRawCount > 0">ASSETS BY STOREFRONT</span>

        <div class="options">
          <div
            class="d-flex align-items-start flex-column pr-0 pl-2"
            *ngFor="let item of filters.showAssetsByStoreList">
            <label class="check-box-container">{{item.name}}
              <input
                type="checkbox"
                [checked]="item.selected"
                (click)="filters.selectStoreToFilter(item)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <div style="margin-top: 10px;"
        *ngIf="
                    this.filters.filterType == 'showAllGlobal' ||
                    this.filters.filterType == 'showAllFromStore'
                ">
        <span
          class="category-title pl-2"
          *ngIf="filters.assetsRawCount > 0">ASSETS BY TAG</span>

        <div class="options">
          <div
            class="d-flex align-items-start flex-column pr-0 pl-2"
            *ngFor="let item of filters.showAssetsByTagList">
            <label class="check-box-container">{{item.name}}
              <input
                type="checkbox"
                [checked]="item.selected"
                (click)="filters.selectTagToFilter(item)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <!--<div class="pt-3" *ngIf="this.filters.assetsCategoryToShow.length > 0">

        <span class="category-title pl-2">BI ASSETS CATEGORIES</span>

        <div class="d-flex align-items-start flex-column pr-0 pl-2" *ngFor="let category of this.filters.assetsCategoryToShow">
          <label class="check-box-container">{{category.name}}
            <input type="checkbox" [checked]="false" (click)="filters.filterByCategory(category)">
            <span class="checkmark"></span>
          </label>
        </div>

      </div> -->

    </ng-container>
  </div>
</div>