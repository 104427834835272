import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '@env';
import { Observable } from 'rxjs/internal/Observable';
import { RoleTypes } from '../enums/role-type';
import { Category } from '../models/category.model';
import { IImage } from '../models/image.model';
import { Storefront } from '../models/storefront.model';
import { Tag } from '../models/tag.model';
import { User } from '../models/user.model';
import { ApiServiceBase } from './api-service-base.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class StorefrontService extends ApiServiceBase<Storefront> {
  constructor(
    protected http: HttpClient,
    private router: Router,
    private usersService: UserService
  ) {
    super(http, 'storefronts');
  }

  public getNames(): Observable<Array<string>> {
    return this.getAs<string>('names');
  }

  public getVerified(limit: number = 0): Observable<Array<Storefront>> {
    return this.get('verified/' + (limit > 0 ? limit : ''));
  }

  public getStoreById(Id: number): Observable<Storefront> {
    return this.getSingle(Id.toString());
  }

  public getAll(): Observable<Array<Storefront>> {
    return this.get();
  }

  public getLastAdded(limit: number = 0): Observable<Array<Storefront>> {
    return this.get('last-added/' + (limit > 0 ? limit : ''));
  }

  public getStoresByOrderIndex(
    limit: number = 0
  ): Observable<Array<Storefront>> {
    return this.get('sort-by-order-index/' + limit);
  }

  public getMostVisited(limit: number = 0): Observable<Array<Storefront>> {
    return this.get('most-visited/' + (limit > 0 ? limit : ''));
  }

  public getStoresByAsset(asset: number): Observable<Array<Storefront>> {
    return this.get('get-by-asset/' + asset);
  }

  public getSimpleStoreById(id: number): Observable<Storefront> {
    return this.getSingle('tiny-card/' + id);
  }

  public getStoreByFilter(filter: string): Observable<Storefront[]> {
    return filter
      ? this.http.post<Storefront[]>(
          `${environment.apiUrl}/storefronts/filter`,
          { filter },
          this.HEADERS
        )
      : this.getAll();
  }

  public getStoreByOwner(userId: number): Observable<Array<Storefront>> {
    return this.get('owner/' + userId.toString());
  }

  public getAllRetired(): Observable<Array<Storefront>> {
    return this.get('retired');
  }

  public getAllFullCards(): Observable<Array<Storefront>> {
    return this.get('all-full-cards');
  }

  public getRecommended(limit: number = 0): Observable<Array<Storefront>> {
    return this.get(`recommended/${limit}`);
  }

  public remove(storefrontId: number): Observable<void> {
    return this.delete<void>(storefrontId.toString());
  }

  public async canActivate(route: ActivatedRouteSnapshot) {
    const id: number = +route.params.id;

    let storefront: Storefront = new Storefront();

    if (id) storefront = await this.getSimpleStoreById(id).toPromise();

    let user: User;

    await this.usersService.getCurrentUser().then((obj) => {
      user = obj.currentUser;
    });

    if (user.roles.some((r) => r.name == RoleTypes.Owner)) return true;

    if (
      (storefront.id &&
        !storefront.active &&
        !storefront.currentUserCanManage) || // The selected storefront exists and it's active and the user cannot edit it.
      (route.url.some((p) => p.path == 'new') &&
        !user.roles.some((r) => r.name == RoleTypes.Member)) || // The current user has permission to create new storefronts.
      (route.url.some((p) => p.path == 'edit') &&
        !storefront.currentUserCanManage)
    ) {
      // The current user has permission to edit it.
      this.router.navigate(['home']);

      return false;
    }

    return true;
  }

  public add(
    item: Storefront,
    cats: Array<Category>,
    tagsIds: number[],
    logo?: IImage,
    headerImage?: IImage,
    tagsFromAssets?: Array<Tag>
  ): Observable<number> {
    return this.post({
      Name: item.name,
      PageTitle: item.pageTitle,
      Description: item.description,
      CustomMessage: item.customMessage,
      DefaultPageView: item.defaultPageView,
      DefaultTab: item.defaultTab,
      Categories: cats,
      Assets: item.assets,
      color: item.color,
      active: item.active,
      retired: item.retired,
      TagsIds: tagsIds,
      Owner: item.owner,
      CoOwners: item.coOwners,
      HelpLinks: item.helpLinks,
      TagsFromAssetsOrder: tagsFromAssets,
      userGroups: item.userGroups,
      // SlDivisionIds: item.slDivisionIds,
      // JobIds: item.jobIds,
      // SlBusinessLineIds: item.slBusinessLineIds,
      // BfIds: item.bfIds,
      LogoFile: logo
        ? {
            Base64: logo.localPath,
            Name: logo.image.name,
            ImageType: logo.image.type
          }
        : null,
      HeaderFile: headerImage
        ? {
            Base64: headerImage.localPath,
            Name: headerImage.image.name,
            ImageType: headerImage.image.type
          }
        : null
    });
  }

  public update(
    store: Storefront,
    cats: Array<Category>,
    tagsIds: number[],
    logo?: IImage,
    headerImage?: IImage,
    tagsFromAssets?: Array<Tag>
  ): any {
    return this.put({
      Id: store.id,
      Name: store.name,
      PageTitle: store.pageTitle,
      Description: store.description,
      CustomMessage: store.customMessage,
      DefaultPageView: store.defaultPageView,
      DefaultTab: store.defaultTab,
      TagsIds: tagsIds,
      categories: cats,
      Assets: store.assets,
      color: store.color,
      active: store.active,
      retired: store.retired,
      Owner: store.owner,
      CoOwners: store.coOwners,
      EditAssetsUsers: store.editAssetsUsers,
      HelpLinks: store.helpLinks,
      TagsFromAssetsOrder: tagsFromAssets,
      userGroups: store.userGroups,
      // SlDivisionIds: store.slDivisionIds,
      // JobIds: store.jobIds,
      // SlBusinessLineIds: store.slBusinessLineIds,
      // BfIds: store.bfIds,
      LogoFile: logo
        ? {
            Base64: logo.localPath,
            Name: logo.image.name,
            ImageType: logo.image.type
          }
        : null,

      HeaderFile: headerImage
        ? {
            Base64: headerImage.localPath,
            Name: headerImage.image.name,
            ImageType: headerImage.image.type
          }
        : null
    });
  }

  public canUserEdit(store: Storefront): boolean {
    return store?.currentUserCanManage;
  }

  public changeVerifiedStatus(storefrontId: number, status: boolean) {
    return this.get('change-verification/' + storefrontId + '/' + status);
  }
}
