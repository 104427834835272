import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { User } from '~/shared/models/user.model';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UserPickerComponent)
    }
  ]
})
export class UserPickerComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() public required = true;
  @Input() public placeholder = '';
  @Input() public multiple = false;
  @Input() public editableSearchTerm = true;
  @Input() public type: 'user' | 'group' = 'user';
  @Input() public appendToBody = true;

  @Output()
  public userResolved = new EventEmitter<User[]>();

  public people$: Observable<User[]>;
  public peopleInput$ = new Subject<string>();

  public loading = false;
  public selectedPeople = [];
  public form: FormControl;

  public onChange: (value) => any;
  public onTouch: (value) => any;

  private formChangedSubscription: Subscription;

  constructor(
    private usersService: UserService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.control(
      null,
      this.required ? Validators.required : Validators.nullValidator
    );
    this.form.markAsTouched();

    this.people$ = concat(
      of([]),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => (this.loading = true)),
        switchMap((term) =>
          term ? this.fetchItems(term).pipe(catchError(() => of([]))) : of([])
        ),
        tap(() => (this.loading = false))
      )
    );

    this.formChangedSubscription = this.form.valueChanges.subscribe((value) => {
      if (typeof this.onChange === 'function') {
        this.onChange(value);
        this.changeDetector.detectChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    this.formChangedSubscription.unsubscribe();
  }

  public writeValue(obj: any): void {
    if (this.form) {
      this.form.patchValue(obj);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  private fetchItems(term: string) {
    return this.type === 'user'
      ? this.usersService.searchUsers(term)
      : this.usersService.searchGroups(term).pipe(
          map((items) => {
            return items.map(
              (group) =>
                new User({
                  email: group,
                  userName: group,
                  name: group
                })
            );
          })
        );
  }
}
