import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UtilsService } from './core/services/utils.service';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isUserLoggedIn: boolean = false;

  constructor(
    public readonly router: Router,
    public readonly applicationRef: ApplicationRef,
    public readonly ngZone: NgZone,
    public readonly msalService: MsalService,
    private readonly usersService: UserService
  ) {
    router.events.subscribe(() => {
      ngZone.run(() => applicationRef.tick());
    });
  }

  public ngOnInit() {
    this.usersService.getCurrentUser().then( (obj)=> {
      localStorage.setItem(UtilsService.SESSION_TOKEN_FIELD_NAME, obj.sessionToken);
      localStorage.setItem(UtilsService.SESSION_USER_FIELD_NAME, JSON.stringify(obj.currentUser));
      console.log(obj)
      if(localStorage.getItem('token') == null){
        localStorage.setItem('token', 'true');
        location.reload();
      }
    });
    if (this.msalService.getAccount() == null) {
      this.msalService.loginRedirect();
    } else {
      this.isUserLoggedIn = true;
    }
  }
}
