<ng-template #assetsListModal>
  <div class="lateral-menu">
    <header>
      <app-modal-header [title]="modalTitle" [heightpx]="70" (onClickClose)="closeModal()">
      </app-modal-header>

      <div class="serach-area d-flex py-2">
        <input
          maxlength="1000"
          type="text"
          class="search m-1"
          [(ngModel)]="textToSearch"
          autocomplete="off"
          (keydown.enter)="onSearch()"
          [placeholder]="'Search'" />

        <button type="submit" class="search-button btn btn-primary text-uppercase m-1" (click)="onSearch()"> Search </button>
      </div>

    </header>

    <div *ngIf="!isLoading; else loading">
      <div *ngIf="!searchBtnClicked" class="d-flex justify-content-center w-100 pt-4 mt-4">
        The search results will appear here.
      </div>

      <div *ngIf="searchBtnClicked && !assets.length && !tagsList.length" class="d-flex justify-content-center w-100 pt-4 mt-4">
        There is no related data.
      </div>

      <div class="lits-container col-md-12 container"
        [ngStyle]="!isAssetList && {'min-height': 'calc(100vh - 220px)'}">
        <div *ngIf="isAssetList">
          <app-small-item-list *ngFor="let asset of assets"
            [height]="15"
            [asset]="asset"
            [selected]="isAssetSelected(asset.id)"
            (addAsset)="addAssetToSelectedList($event)"
            (removeAsset)="removeAssetFromSelectedList($event)">
          </app-small-item-list>
        </div>

        <div *ngIf="!isAssetList">
          <app-small-item-list *ngFor="let tag of tagsList"
            [height]="15"
            [tag]="tag"
            (addTag)="addTagToSelectedList($event)"
            (removeTag)="removeTagFromSelectedList($event)">
          </app-small-item-list>
        </div>
      </div>
    </div>

    <ng-template #loading>
      <div class="d-flex justify-content-center w-100 pt-4 mt-4">
        <app-loader [height]="30"></app-loader>
      </div>
    </ng-template>

    <footer class="container d-flex align-items-center justify-content-between footer-container col-md-12">
      <p class="sleceted-amount col-md-6">{{ currentAddedValuesAmount }} selected
        {{ currentAddedValuesAmountLabel }} </p>
      <button (click)="onClickAdd()" type="button" class="button-blue col-md-6">
        ADD
      </button>
    </footer>
  </div>
</ng-template>