<app-board-header *ngIf="showHeader"
    [Title]="Title"
    [Description]="Description"
    [showAllType]="showAllType"
    [FontSmall]="FontSmall"
>
</app-board-header>

<div class="container">
    <div class="row pt-2">
        <ng-container *ngIf="Tags?.length; else loading">
            <app-card-tag
                class="d-block col-sm-12 col-md-6 col-lg-4 col-xl-3 p-1"
                *ngFor="let tag of Tags"
                [Tag]="tag"
            ></app-card-tag>
        </ng-container>

        <ng-template #loading>
            <div class="d-flex justify-content-center w-100">
                <app-loader [height]="32"></app-loader>
            </div>
        </ng-template>
    </div>
</div>
