<div *ngIf="helpLinks?.length > 0"
  (click)="showHelp = !showHelp"
  class="btn-help"
  [attr.title]="'Useful Links'"
  [ngClass]="{'showContent': showHelp}"
  [ngStyle]="{'bottom.px': footerMargin }">

  <div class="title-container cursor-pointer d-flex align-items-center h-100">
    <span class="title h-100 d-flex align-items-center">Useful Links</span>
    <i class="fas fa-chevron-down ml-auto"></i>
  </div>

  <div *ngIf="showHelp" [@inOutAnimation] class="opened">

    <div class="d-flex align-items-start p-2"
      *ngFor="let link of helpLinks">
      <div class="pipe"></div>

      <a href="{{ link.linkAddress }}" (click)="$event.stopPropagation()"
        class="btn btn-link p-0 pl-1 text-left"
        target="_blank">{{ link.title }}</a>
    </div>

    <span *ngIf="helpLinks.length === 0"
      class="no-help-links p-0 pl-1 text-left"
      target="_blank">Quick Links</span>
  </div>

</div>