<app-board-header *ngIf="Title"
    [Title]="Title"
    [marginTop]="'mt-0'">
</app-board-header>

<div class="container px-0 pt-3 position-relative" style="padding-top: 0 !important;">
    <div #scrollableDiv class="d-flex overflow-hidden w-100">
        <ng-container *ngIf="!isLoading">
            <button
                *ngIf="showLeftButton"
                class="position-absolute left btn-arrow"
                (click)="scrollLeft()">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
            </button>

            <ng-container *ngIf="!showFullCards">
                <app-card-storefront-simple
                    class="d-block col-sm-12 col-md-6 col-lg-4 col-xl-3 p-1"
                    *ngFor="let store of Stores"
                    [Store]="store">
                </app-card-storefront-simple>
            </ng-container>

            <ng-container *ngIf="showFullCards">
                <app-card-storefront-detailed
                    *ngFor="let store of Stores"
                    [Store]="store"
                    (clickLike)="emitLike($event)">
                </app-card-storefront-detailed>
            </ng-container>

            <button
                *ngIf="showRightButton"
                class="right btn-arrow"
                (click)="scrollRight()">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
        </ng-container>

        <ng-container *ngIf="isLoading; else noData">
            <div class="d-flex justify-content-center align-items-center w-100" style="height: 140px;">
                <app-loader [height]="32"> </app-loader>
            </div>
        </ng-container>

        <ng-template #noData>
            <div *ngIf="Stores.length == 0"
                class="d-flex justify-content-center align-items-center w-100"
                style="height: 140px">
                There is no related data.
            </div>
        </ng-template>
    </div>
</div>
