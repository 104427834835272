import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router
} from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { StorefrontDefaultPageViewType } from '~/shared/enums/storefront-page-view-type';
import { Storefront } from '~/shared/models/storefront.model';
import { User } from '~/shared/models/user.model';
import { HeaderService } from '~/shared/services/header.service';
import { NotificationService } from '~/shared/services/notification.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { UserService } from '~/shared/services/user.service';

declare let gtag: Function;

@Component({
  selector: 'app-store-home',
  templateUrl: './store-home.component.html',
  styleUrls: ['./store-home.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('250ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('250ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class StoreHomeComponent implements OnInit {
  public storefront: Storefront;

  public user: User = new User();

  public defaultView: StorefrontDefaultPageViewType;

  public StorefrontPageViewType: typeof StorefrontDefaultPageViewType =
    StorefrontDefaultPageViewType;

  public showCustomMessage = true;

  public isCurrentUserOwnerOrMember = false;
  public isCurrentUserOwner = false;

  constructor(
    private headerService: HeaderService,
    private activatedRoute: ActivatedRoute,
    public storeService: StorefrontService,
    private usersService: UserService,
    public readonly router: Router,
    private toastr: NotificationService
  ) {
    // Google Analytics.
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-159565378-2', {
          page_path: '/#' + event.urlAfterRedirects
        });
      }
    });
  }

  public ngOnInit() {
    if (localStorage.getItem('viewType')) {
      this.setViewType(JSON.parse(localStorage.getItem('viewType')));
    } else {
      this.setViewType(2);
    }

    this.usersService.getCurrentUser().then((obj) => {
      this.user = obj.currentUser;
      this.isCurrentUserOwnerOrMember = UtilsService.isCurrentUserOwnerOrMember(
        this.user
      );
      this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(this.user);
    });

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.storeService.getStoreById(Number(params.get('id'))).subscribe(
        (store) => {
          this.storefront = store;
          this.headerService.SetStore(this.storefront);
        },
        () => {
          this.router.navigate(['/']);
        }
      );
    });
  }

  public setViewType(viewType: number): void {
    localStorage.setItem('viewType', viewType.toString());
    this.defaultView = viewType;
  }

  public changeStorefrontVerifiedStatus(verified) {
    this.storeService
      .changeVerifiedStatus(this.storefront.id, verified)
      .subscribe((r) => {
        this.toastr.success('Saved', 'Successfully Saved');
        this.storefront.verified = verified;
        // this.headerService.SetStore(this.storefront);
      });
  }
}
