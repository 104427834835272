import { Component, Input, OnInit } from '@angular/core';
import { User } from '~/shared/models/user.model';

@Component({
    selector: 'app-responsible-card',
    templateUrl: './responsible-card.component.html',
    styleUrls: ['./responsible-card.component.scss']
})
export class ResponsibleCardComponent implements OnInit {
    @Input()
    public people: User;

    @Input()
    public description: string;

    @Input()
    public sendEmail = false;

    constructor() {}

    public ngOnInit(): void {}
}
