import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Tag } from '~/shared/models/tag.model';
import { FilterService } from '~/shared/services/filter.service';
import { HeaderService } from '~/shared/services/header.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { TagService } from '~/shared/services/tag.service';

@Component({
    selector: 'app-our-sections',
    templateUrl: './our-sections.component.html',
    styleUrls: ['./our-sections.component.scss'],

    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('300ms ease-out', style({ height: '*', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                animate('300ms ease-in', style({ height: 0, opacity: 0 }))
            ])
        ])
    ]
})
export class OurSectionsComponent implements OnInit {
    @Input()
    public Stores;

    public ourSectionsIsVisible: BehaviorSubject<boolean>;

    @Input()
    public Tags;

    constructor(
        private storeService: StorefrontService,
        private tagService: TagService,
        private filterService: FilterService,
        private heraderService: HeaderService
    ) {}

    public OpenAllAssets() {
        this.filterService.ShowAllAssets();
    }

    public openAllVerifiedStores() {
        this.filterService.ShowAllStores();
    }

    public openAllTags() {
        this.filterService.ShowAllTags();
    }

    private fetchStores(): void {
        this.storeService
            .getVerified(9)
            .pipe(take(1))
            .subscribe((stores) => {
                this.Stores = stores;
                this.Stores.loaded = true;
            });
    }

    private fetchTags(): void {
        this.tagService
            .getTags(9)
            .pipe(take(1))
            .subscribe((tags) => {
                this.Tags = tags;
                this.Tags.loaded = true;
            });
    }

    public ngOnInit(): void {
        this.ourSectionsIsVisible = this.heraderService.ourSectionsVisibility$;
        this.fetchStores();
        this.fetchTags();
    }

    public onTagClick(tag: Tag) {
        this.filterService.ShowAssetsRelatedToTag(tag);
    }
}
