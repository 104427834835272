import { Asset } from './asset.model';
import { Category } from './category.model';
import { KeyProcess } from './keyProcess.model';
import { ModelBase } from './mode-base.model';
import { ProductLine } from './productLine';
import { Storefront } from './storefront.model';
import { Tag } from './tag.model';
import { User } from './user.model';

export class Internal extends ModelBase {
    constructor() {
        super();
    }

    public created: Date;

    public modified: Date;

    public brd: string;

    public assetName: string;

    public assetDescription: string;

    public assetAddress: string;

    public assetDocumentationLink: string;

    public assetRequestAccessLink: string;

    public assetUsageStatsLink: string;

    public assetVideoLink: string;

    public assetAskAndLearnLink: string;

    public validated: boolean;

    // Relationships
    public storefront: Storefront;

    public processedAsset: Asset;

    public createdBy: User;

    public modifiedBy: User;

    public categoryResponsible: User;

    public scrumMaster: User;

    public assetProductOwner: User;

    public assetProductLine: ProductLine;

    public keyProcesses: KeyProcess[];

    public tags: Tag[];

    public category: Category;

    public images: string[];
}