export enum FilterType {
    GLOBAL = 'showAllGlobal',
    ALL_FROM_STORE = 'showAllFromStore',
    ALL_STOREFRONTS = 'allStoreFronts',
    VERIFIED_STOREFRONTS = 'verifiedStoreFronts',
    MOST_VISITED_STOREFRONTS = 'mostVisitedStoreFronts',
    LAST_ADDED_STOREFRONTS = 'lastAddedStoreFronts',
    ALL_TAGS = 'allTags',
    ALL_ASSETS = 'allAssets',
    ASSETS_RELATED_TO_TAG = 'assetsRelatedToTag'
}
