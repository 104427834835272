import { FilterType } from '../enums/filter-type';
import { Asset } from './asset.model';
import { Category } from './category.model';
import { Storefront } from './storefront.model';
import { Tag } from './tag.model';

class Option {
  public name: string;
  public selected = false;
}
export class FilterOptions {
  constructor(init?: Partial<FilterOptions>) {
    Object.assign(this, init);
  }

  public title: string;
  public boardStoresTitle: string;
  public boardAssetsTitle: string;
  public boardTagsTitle: string;

  public boardStoresTitleSmall = true;
  public boardAssetsTitleSmall = true;
  public boardTagsTitleSmall = true;

  public textToSearch: string;
  public filterType: FilterType;

  // The raw one is used to keep a original copy of the array.
  public storesRaw: Storefront[];
  public assetsRaw: Asset[];

  // The last modified by each filter
  private assetsStoreLastModified: Asset[];
  private assetsTagLastModified: Asset[];

  // this one are the arrays that can have filter and order applied.
  public storesToShow: Storefront[];
  public assetsToShow: Asset[];
  public tagsToShow: Tag[];

  public assetsCategoryToShow: Category[] = [];

  // the count is used to show or hide components based in the original array length.
  public storesRawCount = 0;
  public assetsRawCount = 0;
  public tagsRawCount = 0;

  public showStoresVerified = true;
  public showStoresUnVerified = true;

  public showStoresList = true;
  public showAssetsList = true;
  public showTagsList = true;
  public showAssetsByStoreList: Option[] = [];
  public showAssetsByTagList: Option[] = [];

  public selectedCategoriesFilter: Category[] = [];

  public setStores(stores: Storefront[]) {
    this.storesRaw = this.storesToShow = stores;
    this.storesRawCount = stores.length;
  }

  public setAssets(assets: Asset[]) {
    this.assetsRaw = this.assetsToShow = assets;
    this.assetsRawCount = assets.length;
  }

  public setTags(tags: Tag[]) {
    this.tagsToShow = tags;
    this.tagsRawCount = tags.length;
  }

  protected changeStoresVisibility() {
    this.showStoresList = !this.showStoresList;
  }

  protected changeAssetsVisibility() {
    this.showAssetsList = !this.showAssetsList;
  }

  protected changeTagsVisibility() {
    this.showTagsList = !this.showTagsList;
  }

  protected changeStoresVerifiedVisibility() {
    this.showStoresVerified = !this.showStoresVerified;
    this.applyVerifiedFilterVisibility();
  }

  protected changeStoresUnVerifiedVisibility() {
    this.showStoresUnVerified = !this.showStoresUnVerified;
    this.applyVerifiedFilterVisibility();
  }

  protected selectStoreToFilter(store: Storefront) {
    this.showAssetsByStoreList.forEach((s) => {
      if (s.name.toLowerCase() === store.name.toLowerCase()) {
        s.selected = !s.selected;
      }
    });

    this.updatedAssetsToShow();
  }

  protected selectTagToFilter(tag: Tag) {
    this.showAssetsByTagList.forEach((t) => {
      if (t.name.toLowerCase() === tag.name.toLowerCase()) {
        t.selected = !t.selected;
      }
    });

    this.updatedAssetsToShow();
  }

  protected applyVerifiedFilterVisibility() {
    if (this.showStoresVerified && !this.showStoresUnVerified) {
      this.storesToShow = this.storesRaw.filter((s) => s.verified === true);
    } else if (!this.showStoresVerified && this.showStoresUnVerified) {
      this.storesToShow = this.storesRaw.filter((s) => s.verified === false);
    } else if (!this.showStoresVerified && !this.showStoresUnVerified) {
      this.storesToShow = [];
    } else {
      this.storesToShow = this.storesRaw;
    }
  }

  public filterByCategory(category: Category): void {
    if (this.selectedCategoriesFilter.some((c) => c.id === category.id)) {
      this.selectedCategoriesFilter = this.selectedCategoriesFilter.filter(
        (c) => c.id !== category.id
      );
    } else {
      this.selectedCategoriesFilter.push(category);
    }

    if (this.selectedCategoriesFilter.length === 0) {
      this.assetsToShow = this.assetsRaw;
    } else {
      this.assetsToShow = [];
      let filteredAssets = [];

      this.selectedCategoriesFilter.forEach((category) => {
        const result = this.assetsRaw.filter((a1) =>
          category.assets.some((a2) => a1.id === a2.id)
        );
        filteredAssets = [...filteredAssets, ...result];
      });

      // remove duplicated
      filteredAssets = filteredAssets.filter(
        (v, i) => filteredAssets.findIndex((item) => item.id === v.id) === i
      );

      this.assetsToShow = filteredAssets;
    }
  }

  public sortByDate() {
    if (this.storesToShow) {
      this.storesToShow = this.sortArrayByDate(this.storesToShow);
    }
    if (this.assetsToShow) {
      this.assetsToShow = this.sortArrayByDate(this.assetsToShow);
    }
    if (this.tagsToShow) {
      this.tagsToShow = this.sortArrayByDate(this.tagsToShow);
    }
  }

  public sortByName() {
    if (this.storesToShow) {
      this.storesToShow = this.sortArrayByName(this.storesToShow);
    }
    if (this.assetsToShow) {
      this.assetsToShow = this.sortArrayByName(this.assetsToShow);
    }
    if (this.tagsToShow) {
      this.tagsToShow = this.sortArrayByName(this.tagsToShow);
    }
  }

  private sortArrayByDate(array) {
    let property = 'created';

    if (!array.some((e) => e[property])) {
      property = 'id';
    }

    return array.sort((a, b) => {
      return (new Date(b[property]) as any) - (new Date(a[property]) as any);
    });
  }

  private sortArrayByName(array) {
    return array.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }

      return 0;
    });
  }

  private compareAssetNames(firstAsset: Asset, secondAsset: Asset) {
    if (firstAsset.name < secondAsset.name) {
      return -1;
    }
    if (firstAsset.name > secondAsset.name) {
      return 1;
    }
    return 0;
  }

  private checkAssetsByTag(): Asset[] {
    const assets: Asset[] = [];
    this.showAssetsByTagList.forEach((t) => {
      this.assetsRaw.map((asset) => {
        if (
          asset.tags.some(
            (tag) =>
              tag.name.toLowerCase() === t.name.toLowerCase() && t.selected
          )
        ) {
          if (!assets.includes(asset)) {
            assets.push(asset);
          }
        }
      });
    });

    return assets;
  }

  private checkAssetsByStore(): Asset[] {
    const assets: Asset[] = [];
    this.showAssetsByStoreList.forEach((s) => {
      this.assetsRaw.map((asset) => {
        if (
          asset.storefront.name?.toLowerCase() === s.name.toLowerCase() &&
          s.selected === true
        ) {
          assets.push(asset);
        }
      });
    });

    return assets;
  }

  private updatedAssetsToShow() {
    const byTag = this.checkAssetsByTag();

    const merged = this.checkAssetsByStore();
    if (byTag.length > 0) {
      byTag.map((asset) => {
        if (!merged.some((a) => a.id === asset.id)) {
          merged.push(asset);
        }
      });
    }

    merged.sort((first, second) =>
      first.name > second.name ? 1 : second.name > first.name ? -1 : 0
    );

    this.assetsToShow = merged;
  }
}
