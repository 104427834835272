import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { SearchComponent } from './pages/search/search.component';
import { FilterComponent } from './components/filter/filter.component';

const MODULE_COMPONENTS = [FilterComponent, SearchComponent];

const MODULE_MODULES = [CommonModule, SharedModule];

@NgModule({
    declarations: [...MODULE_COMPONENTS],
    imports: [...MODULE_MODULES]
})
export class SearchModule {}
