import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '~/shared/models/asset.model';
import { Tag } from '~/shared/models/tag.model';

const DEFAULT_LISTED_CARD_HEIGHT = 15;

@Component({
  selector: 'app-small-item-list',
  templateUrl: './small-item-list.component.html',
  styleUrls: ['./small-item-list.component.scss']
})
export class SmallItemListComponent implements OnInit {
  @Input()
  public asset: Asset;

  @Input()
  public height = DEFAULT_LISTED_CARD_HEIGHT;

  @Input()
  public tag: Tag;

  @Output()
  public addAsset = new EventEmitter<Asset>();

  @Output()
  public removeAsset = new EventEmitter<Asset>();

  @Output()
  public addTag = new EventEmitter<Tag>();

  @Output()
  public removeTag = new EventEmitter<Tag>();

  public titleToShow = '';

  public description = '';

  @Input()
  public selected = false;

  constructor() {}

  public ngOnInit() {
    if (this.tag) {
      this.titleToShow = this.tag.name;
      this.description = this.tag.name;
    }

    if (this.asset) {
      this.titleToShow = this.asset.name;
      this.description = this.asset.description;
    }
  }

  public itemClicked() {
    this.selected = !this.selected;

    if (this.tag) {
      this.selected
        ? this.addTag.emit(this.tag)
        : this.removeTag.emit(this.tag);
    } else {
      this.selected
        ? this.addAsset.emit(this.asset)
        : this.removeAsset.emit(this.asset);
    }
  }
}
