<ng-template #template>
  <div
    class="modal-header d-flex align-items-center"
    style="color:white; background-color: #0014DC;">
    <h5 class="modal-title pull-left">Take Action</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="noCLick()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div *ngIf="this.asset.status == 'assign'"
      class="row w-100 no-gutters d-flex flex-column">

      <span style="font-size: 14px;"> Your asset is not assigned to a BI Report, please select one bellow and save it.</span>

      <app-bi-reports-selector
        class="w-100 my-4"
        [asset]="asset"
        [showIgnoreBiReportOption]="false"
        (selectBiReport)="biReportSelected($event)">
      </app-bi-reports-selector>

    </div>

    <div *ngIf="showAssetDiff" class="row w-100 no-gutters">

      <div class="col-5 info-area green d-flex flex-column">

        <div class="title w-100 d-flex align-items-center justify-content-center">
          <span>BI Report</span>
        </div>

        <div class="content ">
          <label class="custom-label mt-1">Name</label>
          <input type="text" [(ngModel)]="biReport.name" maxlength="50" class="custom-input" readonly />

          <label class="custom-label mt-4">URL</label>
          <input type="text" [(ngModel)]="biReport.url" maxlength="50" class="custom-input" readonly />
        </div>

      </div>

      <div class="col-2 action-area d-flex flex-column align-items-center justify-content-center">

        <div class="title">
          <span>Fix:</span>
        </div>

        <div class="btn-title-area d-flex align-items-center">
          <span *ngIf="biReport.name != asset.name" class="btn" (click)="fixName()" tooltip="Fix Name" delay="500"> <i class=" fas fa-angle-double-right"></i></span>
          <span *ngIf="biReport.name == asset.name" class="btn btn-ok"> <i class="fas fa-check"></i></span>
        </div>

        <div class="btn-url-area d-flex align-items-center ">
          <span *ngIf="biReport.url != asset.url" class="btn" (click)="fixUrl()" tooltip="Fix URL" delay="500"> <i class="fas fa-angle-double-right"></i></span>
          <span *ngIf="biReport.url == asset.url" class="btn btn-ok"> <i class="fas fa-check"></i></span>
        </div>

      </div>

      <div [class]="'col-5 info-area d-flex flex-column ' + getStatusClass()">

        <div class="title w-100 d-flex align-items-center justify-content-center">
          <span>Your Asset</span>
        </div>

        <div class="content ">
          <label class="custom-label mt-1">Name</label>
          <input type="text" [(ngModel)]="asset.name" maxlength="50" class="custom-input" readonly />

          <label class="custom-label mt-4">URL</label>
          <input type="text" [(ngModel)]="asset.url" maxlength="50" class="custom-input" readonly />
        </div>

      </div>

    </div>

    <div *ngIf="this.asset.status == 'duplicated'" style="display: grid;">

      <span style="font-size: 14px;">You have multiple BI Assets linked to the same BI Report.
        To fix BI Assets that are linked to the same BI Report, you must contact the owner of the BI Asset.
        You can use the "Notify BI Asset Owner" button to sent an email.
      </span>

      <div class="tree-item d-flex flex-column my-1 p-2 w-100 mt-2" style=" z-index: 2;">
        <span>BI Report</span>
        <span style="font-weight: bold;" class="mt-1">{{biReport?.name}}</span>
        <span class="mt-2">Id: <b>{{biReport?.code}}</b> </span>
        <span>Workspace: <b>{{biReport?.biWorkspace.name}}</b> </span>
        <span><a class="ml-auto mt-2" target="_blank" [href]="biReport?.url">Visit BI Report</a></span>
      </div>

      <div *ngIf="duplicatedAssets; else loading" class="tree-nested-items mt-2">

        <div *ngFor="let a of duplicatedAssets" class="tree-item tree-item-nested d-flex flex-column my-1 p-2 w-100">

          <div class="d-flex w-100">
            <span>BI Asset</span>
            <span class="ml-auto" style="font-size: 12px; color:#0014DC" *ngIf="asset.id == a.id"><b> This is your BI Asset <i class="fas fa-asterisk"></i></b></span>
          </div>

          <span style="font-weight: bold;" class="mt-1">{{ a.name }}</span>
          <span class="mt-2">Product Owner: <b>{{ a.productOwner.name }}</b></span>
          <span>Storefront: <b>{{ a.storefront?.name }}</b></span>
          <span><a class="ml-auto mt-2" target="_blank" [href]="a.url">Visit BI Asset</a></span>

          <div class="d-flex">
            <span *ngIf="asset.id != a.id" class="notify-owner cursor-pointer mt-2" (click)="openEmail(a)"><i class="far fa-envelope"></i> Notify BI Asset Owner </span>
            <a class="ml-auto mt-2" target="_blank" [routerLink]="['/asset', a.id, 'details']">More Details</a>
          </div>

        </div>

      </div>
    </div>

    <div class="d-flex  pt-4">
      <button
        type="submit"
        (click)="noCLick()"
        class="w-50 button-bordered-blue">
        CANCEL
      </button>

      <button
        *ngIf="this.asset.status == 'duplicated'"
        type="submit"
        (click)="noCLick()"
        class="w-50 button-blue ">
        CLOSE
      </button>

      <button
        *ngIf="this.asset.status != 'duplicated'"
        type="submit"
        (click)="save()"
        class="button-blue w-50"
        [disabled]="ckeckDisabled">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="d-flex justify-content-center w-100 py-3">
    <app-loader [height]="30"></app-loader>
  </div>
</ng-template>