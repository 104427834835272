import { Component } from '@angular/core';

@Component({
    selector: 'app-sandbox-warning',
    templateUrl: './sandbox-warning.component.html',
    styleUrls: ['./sandbox-warning.component.scss']
})
export class SandboxWarningComponent {
    private page = location.href;
    public hideNotification = false;

    public dismiss(): void {
        this.hideNotification = true;
    }

    public get isSandBox(): boolean {
        return this.page.toLowerCase().includes('sandbox');
    }
}
