import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Asset } from '../models/asset.model';

@Injectable({ providedIn: 'root' })
export class LateralMenuService {
    private asset: Asset;

    public selectedAsset$ = new BehaviorSubject<Asset>(this.asset);

    public setAsset(asset: Asset): void {
        this.asset = asset;
        this.selectedAsset$.next(this.asset);
    }
}
