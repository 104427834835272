import { NotificationComponent } from '@/components/notification/notification.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AssetDetailsComponent } from './modules/asset/pages/asset-details/asset-details.component';
import { NewAssetComponent } from './modules/asset/pages/new-asset/new-asset.component';
import { CategoryComponent } from './modules/category/category/pages/category.component';
import { NotFoundComponent } from './modules/error/pages/not-found/not-found.component';
import { FAQComponent } from './modules/faq/pages/faq/faq.component';
import { HomeComponent } from './modules/home/pages/home/home.component';
import { MyBiDashboardComponent } from './modules/home/pages/my-bi-dashboard/my-bi-dashboard.component';
import { InternalComponent } from './modules/internal/pages/internal/internal.component';
import { LogoutComponent } from './modules/logout/logout/logout.component';
import { SearchComponent } from './modules/search/pages/search/search.component';
import { SimpleDataManagerComponent } from './modules/simple-data-manager/pages/simple-data-manager/simple-data-manager.component';
import { NewStoreComponent } from './modules/store/pages/new-store/new-store.component';
import { StoreAboutUsComponent } from './modules/store/pages/store-about-us/store-about-us.component';
import { StoreHomeComponent } from './modules/store/pages/store-home/store-home.component';
import { UserManagementComponent } from './modules/user/pages/user-management-component/user-management/user-management.component';
import { UserProfileComponent } from './modules/user/pages/user-profile-component/user-profile/user-profile.component';
import { AssetService } from './shared/services/asset.service';
import { InternalService } from './shared/services/internal.service';
import { StorefrontService } from './shared/services/storefront.service';
import { UserService } from './shared/services/user.service';
import { AssetsMaintenanceComponent } from './modules/asset/pages/assets-maintenance/assets-maintenance.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },

  { path: 'my-bi-dashboard', component: MyBiDashboardComponent, canActivate: [MsalGuard]  },

  { path: 'user-management', component: UserManagementComponent },
  { path: 'user-profile', component: UserProfileComponent },

  { path: 'simple-data-manager', component: SimpleDataManagerComponent, canActivate: [UserService] },

  { path: 'store/new', component: NewStoreComponent, canActivate: [StorefrontService] },
  { path: 'store/:id/edit', component: NewStoreComponent, canActivate: [StorefrontService] },
  { path: 'store/:id', component: StoreHomeComponent, canActivate: [StorefrontService] },
  { path: 'store/:id/aboutus', component: StoreAboutUsComponent, canActivate: [StorefrontService] },

  { path: 'asset/new', component: NewAssetComponent, canActivate: [AssetService] },
  { path: 'asset/:id/edit', component: NewAssetComponent, canActivate: [AssetService] },
  { path: 'asset/:id/details', component: AssetDetailsComponent },
  { path: 'asset/:id/details/:storefrontid', component: AssetDetailsComponent },
  { path: 'assets-maintenance', component: AssetsMaintenanceComponent },

  { path: 'faq', component: FAQComponent },

  { path: 'internal', component: InternalComponent, canActivate: [InternalService] },

  { path: 'category/:id', component: CategoryComponent },

  { path: 'search', component: SearchComponent },

  { path: 'logout', component: LogoutComponent },

  { path: 'notification', component: NotificationComponent },

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      initialNavigation: isInIframe() ? 'disabled' : undefined // <-THIS
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
