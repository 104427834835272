import { ModelBase } from '@/models/mode-base.model';
import { Notification } from './notification.model';

export class NotificationCategory extends ModelBase {
  constructor() {
    super();
  }

  public notifications: Array<Notification> = [];

  public expand = false;

  public get notReadCount(): number {
    return this.notifications.filter((n) => !n.viewed).length;
  }
}
