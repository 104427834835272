import { Storefront } from './storefront.model';

export class HelpLink {
    public id: number;

    public title: string;

    public linkAddress: string;

    public storefront: Storefront;
}
