<div class="card card-category">

    <div class="header p-2" [ngStyle]="{'background-color': category?.color ? category.color : color}">

        <div class="row no-gutters d-flex align-items-center">

            <i class="icon fas fa-{{category.iconName}} d-flex align-items-center mr-1 "></i>

            <span [attr.title]="category.name" class="name">{{category.name}}</span>

            <span class="assets-count ml-2">({{assetsToShow.length}})</span>

        </div>

        <div class="row no-gutters pt-2">

            <div class="responsible col-md-12 new d-flex flex-column pr-2">

                <div class="user d-flex">

                    <img alt="Category Responsible"
                        [src]="category.responsible?.image || '/assets/default_user.jpg'"
                        class="avatar img-thumbnail rounded-circle shadow-sm p-0 mr-1"
                        [attr.title]="'Category Responsible'">

                    <a
                        [attr.title]="'Send Message'"
                        class="title cursor-pointer d-flex align-items-center"
                        href="mailto:{{category.responsible.email}}?subject=BI Portfolio">
                        {{ category.responsible.name }}
                    </a>

                </div>

            </div>

        </div>

    </div>

    <div class="body p-2 row no-gutters">

        <div *ngIf="loading" class="d-flex justify-content-center w-100">

            <app-loader [height]="32"></app-loader>

        </div>

        <span class="no-info px-1" *ngIf="assetsToShow.length === 0 && loading == false"
            style="margin:auto; display:table;">No Assets related</span>    

        <app-asset-category-label class="w-100"
            *ngFor="let asset of assetsToShow | slice:0:(showAll ? assetsToShow.length : MaxAssetsToShow)"
            [asset]="asset">
        </app-asset-category-label>

    </div>

    <div class="show-more-div d-flex justify-content-center">

        <span class="cursor-pointer show-more p-1 px-3 mb-2"
            *ngIf="!showAll && MaxAssetsToShow < assetsToShow?.length" (click)="showAll = true">
            + {{assetsToShow?.length - MaxAssetsToShow}} More
        </span>
    </div>

</div>
