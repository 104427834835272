import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { RoleTypes } from '~/shared/enums/role-type';
import { Category } from '~/shared/models/category.model';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-simple-category-card',
    templateUrl: './simple-category-card.component.html',
    styleUrls: ['./simple-category-card.component.scss']
})
export class SimpleCategoryCardComponent implements OnInit {
    @Input()
    public categories: Array<Category> = [];

    @Input()
    public isEditable: boolean = true;

    @Input()
    public isVisible: boolean = true;

    constructor(
        private router: Router,
        private usersService: UserService) {}

    public ngOnInit(): void {
        this.usersService.getCurrentUser().then((obj) => {
            let user = obj.currentUser;

            this.categories.forEach((category) => {
                category['canCurrentUserEdit'] =
                    user.roles.some((role) => role.name == RoleTypes.Owner || role.name == RoleTypes.Member);
            });
        });
    }

    public onEdit(category: Category) {
        if (category['canCurrentUserEdit'])
            this.router.navigateByUrl(`/category/${category.id}`);
    }

    public getInitialLetters(category: Category) {
        return UtilsService.getInitialLetters(category.name);
    }
}
