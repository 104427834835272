import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RatingStats } from '../models/ratingStats';
import { Review } from '../models/review.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends ApiServiceBase<Review> {
  constructor(protected http: HttpClient) {
    super(http, 'assets-reviews');
  }

  public reviewClosed$ = new Subject<boolean>();

  public getReviewsByAssetId(
    id: number,
    max: number
  ): Observable<Array<Review>> {
    return this.get(id.toString() + '/limit/' + max.toString());
  }

  public getReviewByUserAndId(assetId: number): Observable<Review> {
    return this.getSingle(assetId.toString());
  }

  public getReviewStats(assetId: number): Observable<RatingStats> {
    return this.getReviewsByAssetId(assetId, 0).pipe(
      map((reviews) => {
        if (reviews === undefined || reviews === null || reviews.length === 0) {
          return new RatingStats();
        }

        const reviewsWithText: Array<Review> = [];
        const rate = [0, 0, 0, 0, 0];
        let total = 0;

        reviews.forEach((r) => {
          rate[r.rating - 1] += 1;
          total += r.rating;

          if (r.review) {
            reviewsWithText.push(r);
          }
        });

        const rtStatus: RatingStats = new RatingStats();

        rtStatus.TotalRatings = reviews.length;
        rtStatus.RatingAverage = total / reviews.length;
        rtStatus.Ratings[0] = Math.round((rate[0] / reviews.length) * 100);
        rtStatus.Ratings[1] = Math.round((rate[1] / reviews.length) * 100);
        rtStatus.Ratings[2] = Math.round((rate[2] / reviews.length) * 100);
        rtStatus.Ratings[3] = Math.round((rate[3] / reviews.length) * 100);
        rtStatus.Ratings[4] = Math.round((rate[4] / reviews.length) * 100);

        rtStatus.ReviewsWithText = reviewsWithText;

        return rtStatus;
      })
    );
  }

  public saveReview(review: Review): Observable<number> {
    return this.post({
      rating: review.rating,
      review: review.review,
      assetId: review.asset.id
    });
  }

  public editReview(review: Review): Observable<number> {
    return this.put({
      id: review.id,
      rating: review.rating,
      review: review.review,
      assetId: review.asset.id,
      userId: review.user.id,
      created: review.created
    });
  }

  public getReviewClosedObservable(): Observable<any> {
    return this.reviewClosed$.asObservable();
  }

  public reviewClosed(): void {
    this.reviewClosed$.next();
  }
}
