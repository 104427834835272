import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { KeyProcess } from '../models/keyProcess.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({ providedIn: 'root' })
export class KeyProcessService extends ApiServiceBase<KeyProcess> {
    constructor(protected http: HttpClient) {
        super(http, 'key-processes');
    }

    public getAll(): Observable<Array<KeyProcess>> {
        return this.get().pipe(
            tap(results => {
                results = UtilsService.sortArrayByTitle(results);
            }))
    }

    public add(kp): Observable<number> {
        return this.post(kp);
    }

    public update(kp): Observable<number> {
        return this.put(kp);
    }
}
