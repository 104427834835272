<div [attr.title]="'Ask BIA'"
  class="virtual-agent-btn d-flex align-items-center justify-content-center cursor-pointer"
  (click)="open()">
  <img src="../assets/bia-button-icon.svg">

</div>

<div class="virtual-agent-panel d-flex" [ngStyle]="{'right':  !visible ? '-500px' : '5px' }">

  <div class="virtual-agent d-flex flex-column">

    <div class="buttons d-flex align-items-center w-100">

      <div class="img-background d-flex align-items-center justify-content-center">

        <img class="bia" src="../assets/bia-header-icon.svg">

      </div>

      <span class=" title">BIA - Business Intelligence Assistant</span>

      <img src="../assets/close.svg" class="p-2 cursor-pointer ml-auto" (click)="close()">

    </div>

    <div class="chat d-flex flex-column w-100">

      <div *ngIf="loading" class="h-100">
        <div class="d-flex justify-content-end align-items-center w-100 h-100 flex-column">
          <span class="mb-3" style="font-weight: 300;">Please wait a moment</span>
          <app-loader [height]="32"></app-loader>
        </div>
      </div>

      <div class="webchat-container w-100 h-100" #botWindow></div>

    </div>

  </div>

</div>

<ng-template #loadingAnimation>

</ng-template>