import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent {
  @Input()
  public fill = '#051464';

  @Input()
  public width = 135;

  @Input()
  public height = 140;
}
