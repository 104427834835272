import { Component, Input, OnInit } from '@angular/core';
import Bowser from 'bowser';

// A port of: https://github.com/ChinW/happy-browser

@Component({
    selector: 'app-outdated-browser',
    templateUrl: './outdated-browser.component.html',
    styleUrls: ['./outdated-browser.component.scss']
})
export class OutdatedBrowserComponent implements OnInit {
    @Input()
    public supportedBrowsers = {
        Chrome: 70,
        Firefox: 60,
        Safari: 11,
        'Microsoft Edge': 78,
        'Internet Explorer': 11
    };

    public hideNotification = false;
    public browser: { name: string; version: number };

    get isSupported(): boolean {
        return this.supportedBrowsers[this.browser.name];
    }

    get isOutdated(): boolean {
        return this.browser.version < this.supportedBrowsers[this.browser.name];
    }

    public dismiss(): void {
        this.hideNotification = true;
    }

    public ngOnInit(): void {
        const { name, version } = Bowser.getParser(
            navigator.userAgent
        ).getBrowser();
        this.browser = { name, version: Number(version.split('.')[0]) };
    }
}
