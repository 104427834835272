import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  public scrollChanged$ = new BehaviorSubject<number>(0);
  public footerMargin$ = new BehaviorSubject<number>(0);

  public setScrollChanged(offsetHeight, scrollTop, scrollHeight) {
    const percentage = Math.floor(
      (scrollTop / (scrollHeight - offsetHeight)) * 100
    );
    this.scrollChanged$.next(percentage);
    this.setFooterMargin(offsetHeight, scrollTop, scrollHeight);
  }

  public setFooterMargin(offsetHeight, scrollTop, scrollHeight) {
    const border = offsetHeight + scrollTop - (scrollHeight - 63);
    this.footerMargin$.next(border > 0 ? border : 0);
  }
}
