import { Component, Input, OnInit } from '@angular/core';
import { Email } from '~/shared/models/email.model';
import { EmailService } from '~/shared/services/email.service';
import { NotificationService } from '~/shared/services/notification.service';

@Component({
    selector: 'app-feedback-window',
    templateUrl: './feedback-window.component.html',
    styleUrls: ['./feedback-window.component.scss']
})
export class FeedbackWindowComponent implements OnInit {

    public visible = false;

    public message: string;

    public rating = -1;

    @Input()
    public email: string;

    @Input()
    public storeName: string;

    constructor(
        private emailService: EmailService,
        private toastr: NotificationService
    ) { }

    public ngOnInit(): void { }

    public sendMessage(): void {

        if (!this.message) return;

        let userRating = '😠 (Hate it)';

        switch (this.rating) {
            case 2:
                userRating = '🙁 (Didn\'t like it)';
                break;

            case 3:
                userRating = '😐 (It\'s ok)';
                break;

            case 4:
                userRating = '🙂 (Like it)';
                break;

            case 5:
                userRating = '😃 (Love it)';
                break;

        }

        const message =
            '[' + this.storeName + ' Feedback] <br /> <br /> ' +
            'User experience rating: <br />' + userRating + ' <br /> <br /> ' +
            'User message: <br />' + this.message;

        const title = '[' + this.storeName + ' Feedback] ';

        this.emailService
            .sendEmail(
                new Email({
                    to: [this.email],
                    subject: title,
                    body: message
                })
            )
            .subscribe({
                next: () => {
                    this.toastr.success(
                        'Your message has been successfully delivered to: ' +
                        this.email,
                        'Message sent Successfully',
                        3000
                    );
                    this.clearForm();
                },
                error: (err) => {
                    this.toastr.error(JSON.stringify(err), 'Error', 5000);
                }
            });
    }

    private clearForm(): void {
        this.visible = false;
        this.message = '';
        this.rating = -1;
    }

    public get disableSendBtn(): boolean {
        return this.rating === -1 || this.message === '';
    }

}
