<div class="container p-0">

  <mat-tab-group [@.disabled]="true" disableRipple class=" mt-2">

    <mat-tab label="Tags">

      <div class="container ">

        <div class="digital-asset-title d-flex align-items-center justify-content-between pb-2 h-15 mt-4">

          <span class="title mr-2 mr-auto">Tags</span>

          <mat-form-field appearance="standard" class="mr-3">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyTagFilter($event)" placeholder="Tag Name" #input>
          </mat-form-field>

          <button type="button" class="button-blue px-3" (click)="createNewTag()">
            CREATE NEW
          </button>
        </div>

        <div class="row mt-3 px-3">

          <div class=" d-flex flex-column w-100">

            <table mat-table [dataSource]="tagsDataSource" matSort #tagSort="matSort">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="60%">Name</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="color">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="30%">Color</th>
                <td mat-cell *matCellDef="let element">
                  <div style="width: 20px; height: 20px; border-radius: 50px; border: 1px solid white" [ngStyle]="{'background-color': element.color}"></div>
                </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef width="10%" class="align-right">
                </th>
                <td mat-cell *matCellDef="let element" class="align-right">
                  <i class="cursor-pointer fas fa-pen" *ngIf="element.id == selectedTag?.id" (click)="editTag()"></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedTagsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedTagsColumns;"
                (click)="selectTagRow(row)" [ngClass]="{ 'selected': row === selectedTag }"> <button>View Details</button></tr>
            </table>

            <mat-paginator #tagsPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

          </div>

        </div>

      </div>

    </mat-tab>

    <mat-tab label="Key Processes/Sub Categories">

      <div class="container ">

        <div class="digital-asset-title d-flex align-items-center justify-content-between pb-2 h-15 mt-4">

          <span class="title mr-2 mr-auto">Key Processes/Sub Categories</span>

          <mat-form-field appearance="standard" class="mr-3">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyKPFilter($event)" placeholder="Key Processes/Sub Categories Name" #input>
          </mat-form-field>

          <button type="button" class="button-blue  px-3" (click)="createNewKeyProcess()">
            CREATE NEW
          </button>
        </div>

        <div class="row mt-3 px-3">

          <div class=" d-flex flex-column w-100">

            <table mat-table [dataSource]="keyProcessesDataSource" matSort #kpSort="matSort">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef width="10%" class="align-right">
                </th>
                <td mat-cell *matCellDef="let element" class="align-right">
                  <i class="cursor-pointer fas fa-pen" *ngIf="element.id == selectedKeyProcess?.id" (click)="editKeyProcess()"></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedKpColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedKpColumns;"
                (click)="selectKpRow(row)" [ngClass]="{ 'selected': row === selectedKeyProcess }"></tr>
            </table>

            <mat-paginator #keyProcessesPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

          </div>
        </div>

      </div>

    </mat-tab>

    <mat-tab label="PL/BL">

      <div class="container ">

        <div class="digital-asset-title d-flex align-items-center justify-content-between pb-2 h-15 mt-4">

          <span class="title mr-2 mr-auto">PL/BL</span>

          <mat-form-field appearance="standard" class="mr-3">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyPLBLfilter($event)" placeholder="PL/BL Name" #input>
          </mat-form-field>

          <button type="button" class="button-blue  px-3" (click)="createNewPlBl()">
            CREATE NEW
          </button>
        </div>

        <div class="row mt-3 px-3">

          <div class=" d-flex flex-column w-100">

            <table mat-table [dataSource]="plBlDataSource" matSort #plBlSort="matSort">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef width="10%" class="align-right">
                </th>
                <td mat-cell *matCellDef="let element" class="align-right">
                  <i class="cursor-pointer fas fa-pen" *ngIf="element.id == selectedPlBl?.id" (click)="editPlBl()"></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedKpColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedKpColumns;"
                (click)="selectPlBlRow(row)" [ngClass]="{ 'selected': row === selectedPlBl }"></tr>
            </table>

            <mat-paginator #plBlPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

          </div>
        </div>

      </div>

    </mat-tab>

    <mat-tab label="Storefronts Order">

      <app-storefronts-order> </app-storefronts-order>

    </mat-tab>

    <mat-tab label="User Permissions">

      <app-user-permissions></app-user-permissions>

    </mat-tab>

  </mat-tab-group>

</div>

<app-create-edit-tag
  [tag]="selectedTag"
  [createdTags]="tags"
  (tagSaved)="tagSaved($event)"
  (tagDeleted)="tagDeleted($event)"
  *ngIf="showTagModal"
  (close)="modalClosed()">
</app-create-edit-tag>

<app-create-edit-key-process
  [keyProcess]="selectedKeyProcess"
  (keyProcessSaved)="keyProcessSaved($event)"
  *ngIf="showKeyProcessModal"
  (close)="modalClosed()">
</app-create-edit-key-process>

<app-create-edit-pl-bl
  [plBl]="selectedPlBl"
  (plBlSaved)="plBlSaved($event)"
  *ngIf="showPlBlModal"
  (close)="modalClosed()">
</app-create-edit-pl-bl>