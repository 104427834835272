import { AssetTabType } from '@/enums/asset-tab-type';
import { StoreFrontTabType } from '@/enums/store-front-tab-type';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { RoleTypes } from '~/shared/enums/role-type';
import { StorefrontDefaultPageViewType } from '~/shared/enums/storefront-page-view-type';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { Storefront } from '~/shared/models/storefront.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { HeaderService } from '~/shared/services/header.service';
import { LikedAssetService } from '~/shared/services/liked-asset.service';
import { LikedStorefrontService } from '~/shared/services/liked-storefront.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { TagService } from '~/shared/services/tag.service';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        trigger(
            'inAnimation',
            [transition(':enter', [style({ opacity: 0 }), animate('.3s ease-out', style({ opacity: 1 }))])]
        )
    ]
})
export class HomeComponent implements OnInit, OnDestroy {
    @ViewChild('asset', { static: false }) public tabGroupAsset: MatTabGroup;
    @ViewChild('storeFront', { static: false }) public tabGroupStoreFront: MatTabGroup;

    public storefronts: Array<Storefront> = [];
    public filteredStorefronts: Array<Storefront> = [];
    public categories: Array<Category> = [];
    public assets: Array<Asset> = [];
    public user: User;

    public retiredStorefronts: Array<Storefront> = [];
    public retiredAssets: Array<Asset> = [];
    public lastVisitedAssets: Array<Asset> = [];
    public favouriteStorefronts: Array<Storefront> = [];
    public recommendedStorefronts: Array<Storefront> = [];
    public favouriteAssets: Array<Asset> = [];
    public recommendedAssets: Array<Asset> = [];
    public mostVisitedAssets: Array<Asset> = [];

    public isCurrentUserOwner = false;
    public isCurrentUserOwnerOrMember = false;

    public storefrontTabSelectedIndex = 3;
    public assetTabSelectedIndex = 3;

    public defaultView = 1;

    public homeSelectedTopTab = '';

    public StorefrontPageViewType: typeof StorefrontDefaultPageViewType =
        StorefrontDefaultPageViewType;

    public isLoadingRequest = {
        assets: true,
        favouriteAssets: true,
        recommendedAssets: true,
        lastVisitedAssets: true,
        mostVisitedAssets: true,

        stores: true,
        favouriteStorefronts: true,
        recommendedStorefronts: true
    };

    public assetTabType = AssetTabType;
    public storeFrontTabType = StoreFrontTabType;

    constructor(
        private storeService: StorefrontService,
        private assetsService: AssetService,
        private headerService: HeaderService,
        private router: Router,
        private usersService: UserService,
        private likedAssetsService: LikedAssetService,
        private likedStorefrontsService: LikedStorefrontService,
        private tagService: TagService
    ) { }

    public ngOnInit(): void {
      this.usersService.getCurrentUser().then( (obj)=>{
        localStorage.setItem(UtilsService.SESSION_TOKEN_FIELD_NAME, obj.sessionToken);
        localStorage.setItem(UtilsService.SESSION_USER_FIELD_NAME, JSON.stringify(obj.currentUser));
        this.headerService.SetDefaultHome();
        this.headerService.showHomeMenuTabs(true);
        this.headerService.showOrHideBackButton(false);

        this.headerService.homeMenuTabChanged$.subscribe((tab) => {
          this.homeSelectedTopTab = tab;
        })

        this.usersService.getCurrentUser().then((obj) => {
          this.user = obj.currentUser;

          this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(
            this.user
          );
          this.isCurrentUserOwnerOrMember = UtilsService.isCurrentUserOwnerOrMember(this.user);

          this.getProfilePhoto();

          if (this.isCurrentUserOwner) {
            this.getRetiredAssetsAndStores();
          }
        });

        this.getAssets();
        this.getStores();
        this.getRecommendedStorefronts();
        this.getRecommendedAssets();
        this.getFavouriteStorefronts();
        this.getFavouriteAssets();
        this.getLastVisited();
        this.getMostVisitedAssets()
      })
    }

    public ngOnDestroy(): void {
        this.headerService.showHomeMenuTabs(false);
        this.headerService.showOrHideBackButton(true);
    }

    private getStores(): void {
        this.isLoadingRequest.stores = true;
        this.storeService.getStoresByOrderIndex().subscribe(
            (stores) => {
                this.storefronts.push(...stores);
                this.filteredStorefronts = this.storefronts;
                this.isLoadingRequest.stores = false;
            },
            () => (this.isLoadingRequest.stores = false)
        );
    }

    private getAssets(): void {
        this.isLoadingRequest.assets = true;
        this.assetsService.getLastAddede(10).subscribe(
            (assets) => {
                this.assets.push(...assets);
                this.isLoadingRequest.assets = false;
            },
            () => (this.isLoadingRequest.assets = false)
        );
    }

    private getMostVisitedAssets(): void {
        this.isLoadingRequest.mostVisitedAssets = true;
        this.assetsService.getMostVisited(10).subscribe(
            (assets) => {
                this.mostVisitedAssets.push(...assets);
                this.isLoadingRequest.mostVisitedAssets = false;
            },
            () => (this.isLoadingRequest.mostVisitedAssets = false)
        );
    }

    private getProfilePhoto(): void {
        this.usersService.getUserPhoto(this.user.email).subscribe((photo) => {
            if (photo)
                UtilsService.isValidImage((validImage) => {
                    this.user.image = validImage ? photo : UtilsService.USER_PHOTO_PLACEHOLDER;
                }, photo);
        });
    }

    private getRetiredAssetsAndStores(): void {
        this.isLoadingRequest.stores = true;
        this.storeService.getAllRetired().subscribe(
            (retiredStores) => {
                this.retiredStorefronts.push(...retiredStores);
                this.isLoadingRequest.stores = false;
            },
            () => (this.isLoadingRequest.stores = false)
        );

        this.isLoadingRequest.assets = true;
        this.assetsService.getAllRetired().subscribe(
            (retiredAssets) => {
                this.retiredAssets.push(...retiredAssets);
                this.isLoadingRequest.assets = false;
            },
            () => (this.isLoadingRequest.assets = false)
        );
    }

    public onClick(event: string) {
        if (event === 'asset') {
            this.router.navigateByUrl('/asset/new');
        }

        if (event === 'store') {
            this.router.navigateByUrl('/store/new');
        }

        if (event === 'data-manager') {
            this.router.navigateByUrl('/simple-data-manager');
        }

        if (event === 'internal') {
            this.router.navigateByUrl('/internal');
        }

        if (event === 'logout') {
            this.router.navigateByUrl('/logout');
        }
    }

    public isCurrentUserInternal(): boolean {
        return (
            this.user != null &&
            this.user.roles != null &&
            this.user.roles.some(
                (r) => r.name === RoleTypes.Internal || r.name === RoleTypes.Owner
            )
        );
    }

    public getLastVisited() {
        this.assetsService
            .getLastVisitedByCurentUser(10)
            .pipe(take(1))
            .subscribe((dr) => {
                this.lastVisitedAssets = dr;
                this.isLoadingRequest.lastVisitedAssets = false;
            });
    }

    public getFavouriteStorefronts() {
        this.isLoadingRequest.favouriteStorefronts = true;

        this.likedStorefrontsService
            .getLikedStorefrontsByUser()
            .subscribe((stores) => {
                this.favouriteStorefronts = stores;
                this.isLoadingRequest.favouriteStorefronts = false;
            });
    }

    public getRecommendedStorefronts() {
        this.isLoadingRequest.recommendedStorefronts = true;

        this.storeService
            .getRecommended()
            .pipe(take(1))
            .subscribe((stores) => {
                this.recommendedStorefronts = stores;
                this.isLoadingRequest.recommendedStorefronts = false;
            });
    }

    public getFavouriteAssets() {
        this.likedAssetsService.getLikedAssetsByUser().subscribe((assets) => {
            this.favouriteAssets = assets;
            this.isLoadingRequest.favouriteAssets = false;
        });
    }

    public getRecommendedAssets() {
        this.assetsService.getRecommended().subscribe((assets) => {
            this.recommendedAssets = assets;
            this.isLoadingRequest.recommendedAssets = false;
        });
    }

    public onClickStorefrontLikeButton(storefront: Storefront) {
        if (storefront.isLikedByCurrentUser) {
            this.favouriteStorefronts.push(storefront);
        } else {
            this.favouriteStorefronts = this.favouriteStorefronts.filter(
                (element) => element.id !== storefront.id
            );

            this.recommendedStorefronts = this.recommendedStorefronts.map(
                (element) => {
                    if (element.id === storefront.id)
                        element.isLikedByCurrentUser = storefront.isLikedByCurrentUser;

                    return element;
                }
            );

            this.storefronts = this.storefronts.map((element) => {
                if (element.id === storefront.id)
                    element.isLikedByCurrentUser =
                        storefront.isLikedByCurrentUser;

                return element;
            });
        }

        this.favouriteStorefronts = JSON.parse(
            JSON.stringify(this.favouriteStorefronts)
        );
        this.recommendedStorefronts = JSON.parse(
            JSON.stringify(this.recommendedStorefronts)
        );
        this.storefronts = JSON.parse(JSON.stringify(this.storefronts));
    }

    public onClickAssetLikeButton(asset: Asset) {
        if (asset.isLikedByCurrentUser) {
            this.favouriteAssets.push(asset)
        } else {
            this.favouriteAssets = this.favouriteAssets.filter(
                (element) => element.id !== asset.id
            );

            this.recommendedAssets = this.recommendedAssets.map(
                (element) => {
                    if (element.id === asset.id)
                        element.isLikedByCurrentUser =
                            asset.isLikedByCurrentUser;

                    return element;
                }
            );

            this.assets = this.assets.map((element) => {
                if (element.id === asset.id)
                    element.isLikedByCurrentUser =
                        asset.isLikedByCurrentUser;

                return element;
            });
        }

        this.favouriteAssets = JSON.parse(JSON.stringify(this.favouriteAssets))
        this.recommendedAssets = JSON.parse(JSON.stringify(this.recommendedAssets))
        this.assets = JSON.parse(JSON.stringify(this.assets))
    }

    public filterChanged(verified: boolean): void {
        if (!verified) {
            this.filteredStorefronts = this.storefronts;
        }
        else {
            this.filteredStorefronts = this.storefronts.filter(function(s) {
                return s.verified == true;
            });;
        }
    }
}
