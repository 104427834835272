<div class="container faq">

  <div class="d-flex">
    <span class="title">Frequently Asked Questions</span>

    <button *ngIf="!editMode && isCurrentUserOwner"
      type="button"
      class="ml-auto cursor-pointer button-blue mb-3 px-3"
      style="margin: 0px;" (click)="newFaq()">new item
    </button>
  </div>

  <div *ngIf="faqs; else loading" class="row no-gutters d-flex flex-column">

    <div *ngFor="let faq of faqs; let i = index" class="faq-info" (click)="openCloseDetails(i)">

      <div class="faq-info-title d-flex align-items-center" [ngClass]="{ selected: selectedItemIdxToShow == i }">

        <span *ngIf="!editMode || selectedItemIdxToShow != i" class="pr-4">{{ faq.name }}</span>

        <input *ngIf="editMode && selectedItemIdxToShow == i"
          type="text"
          maxlength="250"
          class="custom-input w-75"
          autocomplete="off"
          placeholder="Title"
          [(ngModel)]="faq.name" />

        <input *ngIf="editMode && selectedItemIdxToShow == i"
          type="number"
          min="1"
          maxlength="250"
          class="custom-input w-25 mx-4"
          autocomplete="off"
          placeholder="Order"
          [(ngModel)]="faq.orderId" />

        <img class="ml-auto" [src]="'../../assets/arrow-up.svg'" />

      </div>

      <div class="faq-info-description " (click)="$event.stopPropagation();"
        [ngClass]="{ show: selectedItemIdxToShow == i }">

        <ckeditor
          [disabled]="!editMode"
          [editor]="editor"
          [config]="config"
          (ready)="onReady($event)"
          [(ngModel)]="faq.description">
        </ckeditor>

        <div *ngIf="isCurrentUserOwner" class="controls w-100 d-flex" style="margin-top: 20px">
          <button *ngIf="!editMode" type="button" class="ml-auto cursor-pointer button-red" (click)="deleteFaq()">delete</button>
          <button *ngIf="!editMode" type="button" class="ml-3 cursor-pointer button-blue" (click)="editFaq()">edit</button>
          <button *ngIf="editMode" type="button" class="ml-auto cursor-pointer button-blue" (click)="saveFaq()">save</button>
        </div>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div class="d-flex justify-content-center w-100 pt-5 mt-5">
    <app-loader [height]="32"></app-loader>
  </div>
</ng-template>