import { FilterService } from '@/services/filter.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '~/shared/models/asset.model';

@Component({
  selector: 'app-board-assets',
  templateUrl: './board-assets.component.html',
  styleUrls: ['./board-assets.component.scss']
})
export class BoardAssetsComponent implements OnInit {
  @Input()
  public Assets: Array<Asset>;

  @Input()
  public Title = 'BI Assets';

  @Input()
  public logoAreaColor = '';

  @Input()
  public FontSmall = false;

  @Input()
  public isEdit = false;

  @Input()
  public isRemove = false;

  @Input()
  public showTitle = true;

  @Input()
  public isVisible = true;

  @Output()
  public clickLike = new EventEmitter();

  @Input()
  public isLoading = false;

  @Input()
  public assetCardSize = 'col-sm-12 col-md-6 col-lg-4 col-xl-3';

  @Input()
  public marginTop = 'mt-0';

  public assetsByStorefront = [];
  public panelOpenState = true;

  constructor(private filterService: FilterService) {}

  public ngOnInit(): void {
    // this.separateAssetsByStorefront();
  }

  public emitLike(asset: Asset) {
    this.clickLike.emit(asset);
  }

  public OpenAllAssets() {
    this.filterService.ShowAllAssets();
  }

  public separateAssetsByStorefront(): void {
    this.Assets.forEach((asset) => {
      if (
        this.assetsByStorefront.length >= 1 &&
        this.assetsByStorefront.some((a) => a.title === asset.storefront.name)
      ) {
        const index = this.assetsByStorefront.indexOf(
          this.assetsByStorefront.find((a) => a.title === asset.storefront.name)
        );
        this.assetsByStorefront[index].assets.push(asset);
      } else if (
        (!asset.storefront.name || asset.storefront.name === '') &&
        !this.assetsByStorefront.some(
          (a) => a.title === 'Not linked to Storefront'
        )
      ) {
        this.assetsByStorefront.push({
          title: 'Not linked to Storefront',
          assets: [asset]
        });
      } else if (
        (!asset.storefront.name || asset.storefront.name === '') &&
        this.assetsByStorefront.some(
          (a) => a.title === 'Not linked to Storefront'
        )
      ) {
        const index = this.assetsByStorefront.indexOf(
          this.assetsByStorefront.find(
            (a) => a.title === 'Not linked to Storefront'
          )
        );
        this.assetsByStorefront[index].assets.push(asset);
      } else {
        this.assetsByStorefront.push({
          title: asset.storefront.name,
          assets: [asset]
        });
      }
    });
  }
}
