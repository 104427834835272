<ng-template #template>
  <div
    class="modal-header d-flex align-items-center"
    style="color:white; background-color: #0014DC;">
    <h5 class="modal-title pull-left">{{ modalTitle }}</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="noCLick()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-4">

    <div class="col-md-12 pl-0">
      <label class="custom-label">Name*</label>
      <input type="text" [(ngModel)]="name"
        required maxlength="50"
        class="custom-input" />
    </div>

    <div class="d-flex justify-content-center pt-5">
      <button
        type="submit"
        (click)="noCLick()"
        class="col-md-6 button-bordered-blue">
        CANCEL
      </button>

      <button
        type="submit"
        (click)="save()"
        class="button-blue col-md-6"
        [disabled]="ckeckDisabled">
        Save
      </button>
    </div>
  </div>
</ng-template>