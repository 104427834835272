<app-board-header
  *ngIf="Title"
  [Title]="Title"
  [marginTop]="'mt-3'"
  [showBorderBottom]="false">

</app-board-header>

<div class="board">
  <mat-tab-group
    [selectedIndex]="checkTabToSetAsMain()"
    [@.disabled]="true" disableRipple
    *ngIf="!isLoadingRequest.all && !isLoadingRequest.recommended; else loadingAnimation"
    class="storefront-page">

    <mat-tab label="Favourite ({{ favouriteAssetsCount }})">

      <div *ngIf="favouriteAssetsCount == 0"
        class="d-flex justify-content-center align-items-center pt-5 mt-5">
        <span class="title">There is no related data.</span>
      </div>

      <div class="card-columns p-2 w-100">

        <div *ngIf="pageViewType === storefrontPageViewType.Subcategory">

          <app-card-category-and-subcategory-detailed
            *ngFor="let cat of favouriteAssetsCategories"
            [sortBy]="sortBy"
            [color]="storefront.color"
            [category]="cat"
            [tagsToFilter]="tagsToFilter">
          </app-card-category-and-subcategory-detailed>

        </div>

        <div *ngIf="pageViewType === storefrontPageViewType.Category">

          <app-card-category-detailed
            *ngFor="let cat of favouriteAssetsCategories"
            [color]="storefront.color"
            [category]="cat"
            [tagsToFilter]="tagsToFilter">
          </app-card-category-detailed>

        </div>

      </div>
    </mat-tab>

    <mat-tab label="Recommended ({{ recommendedAssetsCount }})">

      <div *ngIf="recommendedAssetsCount == 0"
        class="d-flex justify-content-center align-items-center pt-5 mt-5">
        <span class="title">There is no related data.</span>
      </div>

      <div class="card-columns p-2 w-100">

        <div *ngIf="pageViewType === storefrontPageViewType.Subcategory">

          <app-card-category-and-subcategory-detailed
            *ngFor="let cat of recommendedAssetsCategories"
            [sortBy]="sortBy"
            [color]="storefront.color"
            [category]="cat"
            [tagsToFilter]="tagsToFilter">
          </app-card-category-and-subcategory-detailed>

        </div>

        <div *ngIf="pageViewType === storefrontPageViewType.Category">

          <app-card-category-detailed
            *ngFor="let cat of recommendedAssetsCategories"
            [color]="storefront.color"
            [category]="cat"
            [tagsToFilter]="tagsToFilter">
          </app-card-category-detailed>

        </div>

      </div>
    </mat-tab>

    <mat-tab label="All ({{ categoryAssetsCount + storefront.assets.length }})">

      <div *ngIf="categories?.length == 0 && storefront.assets?.length == 0 &&
                       !isLoadingRequest.all && !isLoadingRequest.recommended"
        class="d-flex justify-content-center align-items-center pt-5 mt-5">
        <span>This Storefront does not have any Category yet</span>
      </div>

      <div class="card-columns p-2 w-100" *ngIf="!isLoadingRequest.all; else loadingAnimation">

        <div *ngIf="pageViewType === storefrontPageViewType.Subcategory">

          <app-card-category-and-subcategory-detailed
            *ngFor="let cat of categories"
            [sortBy]="sortBy"
            [color]="storefront.color"
            [category]="cat"
            [tagsToFilter]="tagsToFilter">
          </app-card-category-and-subcategory-detailed>

        </div>

        <div *ngIf="pageViewType === storefrontPageViewType.Category">

          <app-card-category-detailed
            *ngFor="let cat of categories"
            [color]="storefront.color"
            [category]="cat"
            [tagsToFilter]="tagsToFilter">
          </app-card-category-detailed>
        </div>

        <app-assets-without-category
          *ngIf="storefront.assets.length > 0"
          [color]="storefront.color"
          [assetsWithoutCategory]="storefront.assets"
          [tagsToFilter]="tagsToFilter">
        </app-assets-without-category>

      </div>

    </mat-tab>

    <mat-tab disabled>
      <ng-template mat-tab-label>
        <app-bar-sort-by (sortByChanged)="toggleSort($event)">
        </app-bar-sort-by>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</div>

<app-tags-filter-horizontal
  *ngIf="
        (categories?.length > 0 || storefront.assets?.length > 0) &&
        !isLoadingRequest.all &&
        !isLoadingRequest.recommended"
  [tags]="tagsToShow"
  [fullSize]="false"
  (tagsToFilter)="tagsToFilter = $event">
</app-tags-filter-horizontal>

<ng-template #loadingAnimation>

  <div class="d-flex justify-content-center w-100 p-5">
    <app-loader [height]="32"></app-loader>
  </div>

</ng-template>