import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '~/shared/shared.module';
import { CategoryComponent } from './pages/category.component';

const MODULE_COMPONENTS = [CategoryComponent];

const MODULE_MODULES = [CommonModule, SharedModule];

@NgModule({
    declarations: [...MODULE_COMPONENTS],
    imports: [...MODULE_MODULES]
})
export class CategoryModule {}
