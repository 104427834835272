<div class="d-flex align-items-center">
    <img
        [src]="people?.image || '/assets/default_user.jpg'"
        class="avatar img-thumbnail rounded-circle p-0 ml-0"
        [attr.title]="people.name"
        [attr.alt]="people.email"
    />

    <div class="d-flex flex-column pl-2">
        <span class="description">{{ description }} </span>

        <span *ngIf="!sendEmail" class="title">{{ people.name }}</span>

        <a
            *ngIf="sendEmail"
            [attr.title]="'Send Message'"
            class="title"
            href="mailto:{{ people.email }}?subject=BI Portfolio"
        >
            {{ people.name }}
            <i class="far fa-envelope pl-1"></i>
        </a>
    </div>
</div>
