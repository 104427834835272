<div class="row no-gutters pt-5">
    <div class="content-card w-100">
        <div class="profile">
            <img
                class="image mb-2"
                [src]="user.image || '/assets/default_user.jpg'"
            />
            <label class="name p-2 px-2 mt-1"> {{ user.name }} </label>
        </div>
    </div>
</div>
