import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { FilterOptions } from '~/shared/models/filter-options.model';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { HeaderService } from '~/shared/services/header.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { TagService } from '~/shared/services/tag.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input()
  public filters = new FilterOptions();

  public selectedStore: Storefront;

  public storefronts: Storefront[];
  public tags: Tag[];

  public sortOptions = [
    { id: 1, Title: 'Name' },
    { id: 2, Title: 'Created Date' }
  ];

  public onSortByChange($event) {
    switch ($event.Title) {
      case 'Created Date':
        this.filters.sortByDate();
        break;
      case 'Name':
        this.filters.sortByName();
        break;
    }
  }

  constructor(
    private storeService: StorefrontService,
    private tagService: TagService,
    private headerService: HeaderService
  ) {}

  public ngOnInit(): void {
    this.getStores();
    this.getTags();

    this.headerService.selectedStore$.subscribe((store) => {
      console.log(store);

      this.selectedStore = store;
    });
  }

  public get showFilterComponent() {
    return (
      !this.filters ||
      (this.filters.storesRawCount === 0 &&
        this.filters.assetsRawCount === 0 &&
        this.filters.tagsRawCount === 0)
    );
  }

  public getStores() {
    this.storeService
      .getAll()
      .pipe(take(1))
      .subscribe((stores) => {
        this.storefronts = stores;

        stores.forEach((store) => {
          this.filters.showAssetsByStoreList.push({
            name: store.name,
            selected: this.containsStorefront(store)
          });
        });
      });
  }

  public getTags() {
    this.tagService
      .getTags()
      .pipe(take(1))
      .subscribe((tags) => {
        this.tags = tags;

        tags.forEach((tag) => {
          this.filters.showAssetsByTagList.push({
            name: tag.name,
            selected: this.containsTag(tag)
          });
        });
      });
  }

  private containsStorefront(store: Storefront): boolean {
    let hasStore = false;
    this.filters.assetsToShow.forEach((asset) => {
      if (asset.storefront.id === store.id) {
        hasStore = true;
      }
    });

    return hasStore;
  }

  private containsTag(tag: Tag): boolean {
    let hasTag = false;
    this.filters.assetsToShow.forEach((asset) => {
      if (asset.tags.some((t) => t.id === tag.id)) {
        hasTag = true;
      }
    });

    return hasTag;
  }
}
