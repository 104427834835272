import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { KeyProcess } from '~/shared/models/keyProcess.model';
import { KeyProcessService } from '~/shared/services/key-process.service';

@Component({
    selector: 'app-create-edit-key-process',
    templateUrl: './create-edit-key-process.component.html',
    styleUrls: ['./create-edit-key-process.component.scss']
})
export class CreateEditKeyProcessComponent implements OnInit {
    @ViewChild('template', { static: true }) public elementView: ElementRef;

    @Output() public close = new EventEmitter();
    @Output() public keyProcessSaved = new EventEmitter<KeyProcess>();

    @Input() public keyProcess = new KeyProcess();

    public name: string;

    private config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'center-modal modal-dialog-centered'
    };

    @Input() public settings = [];

    public modalRef: BsModalRef;

    public modalTitle: string;

    constructor(private modalService: BsModalService,
        private keyProcessService: KeyProcessService) { }

    public ngOnInit(): void {
        this.modalRef = this.modalService.show(this.elementView, this.config);
        this.modalTitle = this.keyProcess?.id ? "Edit Key Process/Sub Category" : 'Create Key Process/Sub Category';

        if (this.keyProcess.id) {
            this.name = this.keyProcess.name
        }
    }

    public save() {
        this.keyProcess.name = this.name;

        if (this.keyProcess.id) {
            this.keyProcessService.update(this.keyProcess).pipe(take(1)).subscribe((keyProcessId) => {
                this.keyProcessSaved.emit(this.keyProcess);
                this.closeModal();
            })

        } else {
            this.keyProcessService.add(this.keyProcess).pipe(take(1)).subscribe((keyProcessId) => {
                this.keyProcess.id = keyProcessId;
                this.keyProcessSaved.emit(this.keyProcess);
                this.closeModal();
            })
        }

    }

    public noCLick() {
        this.closeModal();
    }

    private closeModal() {
        this.close.emit();
        this.modalRef.hide();
    }

    public get ckeckDisabled(): boolean {
        return !this.name;
    }
}
