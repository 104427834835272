import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '~/shared/services/notification.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private toastr: NotificationService) {
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let message = typeof (error.error) == 'string' ? error.error :
          error.error?.message ? error.error.message : `We had a problem getting the data: ${error.message}`;

        if (message.substr(message.length - 1, 1) != '.')
          message += '.';

        if (message === 'retired.') {
          this.toastr.warning('This item has been retired by the owner.', 'Retired');
        } else {
          // removed error based on missing token when loggin in
          // this.toastr.error(message + `<a href="mailto:fsethi@slb.com?subject=Bi Portfolio
          // Help&body=[Please describe the issue here]%0D%0A%0D%0A------%0D%0AError message:
          // '${message}'%0D%0A%0D%0A"> Get help</a>`, 'Error', 50000, true);
        }

        return throwError(error);
      })
    );
  }
}
