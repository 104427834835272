import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from '~/core/services/utils.service';
import { Storefront } from '~/shared/models/storefront.model';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-card-storefront-detailed',
    templateUrl: './card-storefront-detailed.component.html',
    styleUrls: ['./card-storefront-detailed.component.scss']
})
export class CardStorefrontDetailedComponent implements OnInit {
    constructor(private usersService: UserService) { }

    @Input()
    public Store: Storefront;

    @Output()
    public clickLike = new EventEmitter();

    public initialLetters = '';

    public initialColor = '';

    public ngOnInit(): void {
        if (this.Store) {
            if (this.Store.owner) {
                this.usersService.getUserPhoto(this.Store.owner.email).subscribe((photo) => {
                    if (photo)
                        UtilsService.isValidImage((validImage) => {
                            this.Store.owner.image = validImage ? photo : UtilsService.USER_PHOTO_PLACEHOLDER;
                        }, photo);

                });
            }

            if (this.Store.logo) {
                UtilsService.isValidImage((validImage) => {
                    if (!validImage)
                        this.Store.logo = null;
                }, this.Store.logo);
            }
            else {
                this.initialLetters = this.getInitialLetters()
                this.initialColor = this.getInitialLogoColor();
            }
        }
    }

    public emitLike() {
        this.clickLike.emit(this.Store);
    }

    private getInitialLetters(): string {
        return UtilsService.getInitialLetters(this.Store.name);
    }

    private getInitialLogoColor(): string {
        let initialLetter = this.getInitialLetters()[0].toLocaleLowerCase();


        if (['a', 'b', 'c', 'd'].some((l) => l === initialLetter)) {
            return '#e5d48b'; // yellow
        }
        else if (['e', 'f', 'g', 'h'].some((l) => l === initialLetter)) {
            return '#feb7b1'; // orange
        }
        else if (['i', 'j', 'k', 'l', 'o'].some((l) => l === initialLetter)) {
            return '#ffb3c6'; // pink
        }
        else if (['m', 'n', 'p', 'r',].some((l) => l === initialLetter)) {
            return '#b5ead6'; // green
        }
        else if (['q', 's', 't', 'u',].some((l) => l === initialLetter)) {
            return '#9bdbe8'; // blue
        }
        else if (['w', 'x', 'y', 'z',].some((l) => l === initialLetter)) {
            return '#bfa2db'; // purple
        }

    }
}
