import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '~/shared/models/asset.model';
import { LikedAssetService } from '~/shared/services/liked-asset.service';

@Component({
    selector: 'app-like-asset',
    templateUrl: './like-asset.component.html',
    styleUrls: ['./like-asset.component.scss']
})
export class LikeAssetComponent implements OnInit {
    public isLikedByCurrentUser = false;

    @Input()
    public fontSize = 18;

    @Input()
    public Asset: Asset;

    @Output()
    public clickLike = new EventEmitter();

    constructor(private likedAssetsService: LikedAssetService) { }

    public ngOnInit() {
        this.isLikedByCurrentUser = this.Asset.isLikedByCurrentUser;
    }

    public onClick(event) {
        event.stopPropagation();

        if (this.isLikedByCurrentUser)
            this.likedAssetsService.removeLiked(this.Asset).subscribe((la) => {
                return;
            });
        else
            this.likedAssetsService.addLiked(this.Asset).subscribe((la) => {
                return;
            });

        this.isLikedByCurrentUser = !this.isLikedByCurrentUser;
        this.Asset.isLikedByCurrentUser = this.isLikedByCurrentUser
        this.emitClick();
    }

    public getIcon(): string {
        return this.isLikedByCurrentUser ? 'fas fa-heart' : 'far fa-heart';
    }

    public emitClick() {
        this.clickLike.emit(this.Asset);
    }
}
