<div class="container pt-5">
    <div class="row" *ngIf="filter; else loading">
        <div class="col-md-2"
            *ngIf="!showNoResultsMessage && (
                this.filter.filterType == 'allStoreFronts' ||
                this.filter.filterType == 'showAllGlobal' ||
                this.filter.filterType == 'showAllFromStore')">
            <app-filter [filters]="filter"> </app-filter>
        </div>

        <div
            class="col-md-10 p-0"
            [ngClass]="{
                'col-md-12':
                showNoResultsMessage || (
                    this.filter.filterType != 'allStoreFronts' &&
                    this.filter.filterType != 'showAllGlobal' &&
                    this.filter.filterType != 'showAllFromStore')
            }">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex">
                    <span *ngIf="filter?.title" class="filter-title mr-3">{{
                        filter.title
                        }}</span>

                    <i
                        *ngIf="selectedStore"
                        class="fas fa-times cursor-pointer pr-2"
                        style="font-size: 20px"
                        (click)="goToStorefrontPage()"></i>
                </div>

                <div *ngIf="this.filter.filterType !== 'allStoreFronts'"
                    class="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex align-items-center">
                    <span class="sort-by ml-auto"> SORT BY: </span>

                    <button
                        type="button"
                        class="sort-by-btn btn ml-3"
                        [class.active]="sortBy == 'most-recent'"
                        (click)="toggleSort('most-recent')">
                        Most Recent
                    </button>
                    <button
                        type="button"
                        class="sort-by-btn btn ml-2"
                        [class.active]="sortBy == 'name'"
                        (click)="toggleSort('name')">
                        Name (A to Z)
                    </button>
                </div>
            </div>

            <div
                *ngIf="showNoResultsMessage"
                class="d-flex justify-content-center align-items-center h-100 w-100 py-5">
                <span class="filter-title">No Results Found <i class="fas fa-search pl-1"></i></span>
            </div>

            <div
                *ngIf="showNothingMessage"
                class="d-flex justify-content-center align-items-center h-100 w-100 py-5">
                <span class="filter-title">Nothing to show</span>
            </div>

            <app-board-storefronts
                *ngIf="
                    filter?.storesToShow?.length > 0 && filter?.showStoresList
                "
                [Stores]="filter?.storesToShow"
                [Title]="filter.boardStoresTitle"
                [FontSmall]="filter.boardStoresTitleSmall"
                [showFullCards]="true"></app-board-storefronts>

            <app-board-assets
                *ngIf="filter?.assetsToShow?.length > 0 && filter?.showAssetsList"
                [Assets]="filter?.assetsToShow"
                [Title]="filter.boardAssetsTitle"
                [FontSmall]="filter.boardAssetsTitleSmall"></app-board-assets>

            <app-board-tags
                *ngIf="filter?.tagsToShow?.length > 0 && filter?.showTagsList"
                [Tags]="filter?.tagsToShow"
                [Title]="filter.boardTagsTitle"
                [FontSmall]="filter.boardTagsTitleSmall">
            </app-board-tags>
        </div>
    </div>

    <ng-template #loading>
        <div class="row p-5">
            <div class="d-flex justify-content-center w-100">
                <app-loader [height]="32"></app-loader>
            </div>
        </div>
    </ng-template>
</div>
