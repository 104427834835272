import { Injectable } from '@angular/core';
import { RoleTypes } from '~/shared/enums/role-type';
import { User } from '~/shared/models/user.model';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  public static isNew(creationDate: Date): boolean {
    const DaysNew = 30;

    if (!creationDate) return false;

    const diff = Math.floor(
      (Date.parse(new Date().toString()) -
        Date.parse(creationDate.toString())) /
        86400000
    );

    return diff < DaysNew;
  }

  public static USER_PHOTO_PLACEHOLDER = '/assets/default_user.jpg';

  public static SESSION_TOKEN_FIELD_NAME = '1663d4aadda29a7d9252af85c69a6106';

  public static SESSION_USER_FIELD_NAME = 'ffac34139cd160bf41398fd8a2754a19';

  public static REGEX_URL_WITH_MAILTO =
    /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)|mailto:[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/;

  public static getUserPhotoUrlFromSlbNetwork(ginNumber: string): string {
    return `https://directory.slb.com/misc/pictures/${ginNumber}.jpg`;
  }

  public static isCurrentUserOwner(user: User): boolean {
    return (
      user != null &&
      user.roles != null &&
      user.roles.some((r) => r.name === RoleTypes.Owner)
    );
  }

  public static isCurrentUserOwnerOrMember(user: User): boolean {
    return (
      user != null &&
      user.roles != null &&
      user.roles.some(
        (r) => r.name === RoleTypes.Owner || r.name === RoleTypes.Member
      )
    );
  }

  public static isCurrentUserInternal(user: User): boolean {
    return (
      user != null &&
      user.roles != null &&
      user.roles.some((r) => r.name === RoleTypes.Internal)
    );
  }

  public static isValidImage(
    callback,
    url: string,
    placeholder?: string
  ): void {
    const img: HTMLImageElement = new Image();

    img.onload = () => {
      if (typeof callback == 'function') callback(img.naturalWidth > 0);
    };
    img.onerror = () => {
      if (typeof callback == 'function') callback(false);
    };

    if (!url && placeholder) url = placeholder;

    img.src = url;
  }

  public static getInitialLetters(text: string): string {
    text = text.replace(/[^a-z0-9]/gi, ' ');
    text = text.replace('  ', ' ');

    const textArray = text.split(' ');

    let initialLetters = '';

    textArray.forEach((element) => {
      // It will make the return have just 3 or less characteres
      if (initialLetters.length < 3) initialLetters += element.charAt(0);
    });

    return initialLetters.toUpperCase();
  }

  public static addAlpha(color: string, opacity: number): string {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  public static sortArrayByTitle(array) {
    if (!array) return [];

    return array.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public static sortArrayByMostRecent(array) {
    if (!array) return [];

    return array.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );
  }

  // If you return -1, it will place the first item before the second.
  // If you return 1, it will place the second item before the first.
  // If you return 0, it will leave them unchanged.
  public static sortArrayByTitleAndPriority(array) {
    if (!array) return [];

    return array.sort((x, y) => {
      if (x.isPriority !== y.isPriority) {
        if (x.isPriority && !y.isPriority) return -1;
        if (!x.isPriority && y.isPriority) return 1;
      } else {
        return x.name.toLowerCase().localeCompare(y.name.toLowerCase());
        // x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1;
      }
    });
  }

  public static sortArrayByMostRecentAndPriority(array) {
    if (!array) return [];

    return array.sort((x, y) => {
      if (x.isPriority !== y.isPriority) {
        if (x.isPriority && !y.isPriority) return -1;
        if (!x.isPriority && y.isPriority) return 1;
      } else {
        return new Date(y.created).getTime() - new Date(x.created).getTime();
      }
    });
  }

  public static sortArrayByOrderIndexAndPriority(array) {
    if (!array) return [];

    return array.sort((x, y) => {
      if (x.isPriority !== y.isPriority) {
        if (x.isPriority && !y.isPriority) return -1;
        if (!x.isPriority && y.isPriority) return 1;
      } else {
        if (x.orderIndex === y.orderIndex) return 0;
        if (x.orderIndex < y.orderIndex) return -1;
        if (x.orderIndex > y.orderIndex) return 1;
      }
    });
  }

  public static sortArrayByOrderIndex(array) {
    if (!array) return [];

    return array.sort((x, y) => {
      if (x.orderIndex === y.orderIndex) return 0;
      if (x.orderIndex < y.orderIndex) return -1;
      if (x.orderIndex > y.orderIndex) return 1;
    });
  }

  public static getFirstName(fullname: string) {
    if (!fullname) return '';

    return fullname.slice(0, fullname.search(' '));
  }

  public static arrayIsNullOrEmpty(array: any[]) {
    if (!array || array.length === 0) return true;

    return false;
  }

  public static getShortViewNumber(numberOfViews: number) {
    if (numberOfViews < 1000) {
      return numberOfViews;
    } else if (numberOfViews >= 1000 && numberOfViews <= 1000000) {
      return (numberOfViews / 1000).toFixed(1) + 'K';
    } else {
      return (numberOfViews / 1000000).toFixed(1) + 'M';
    }
  }
}
