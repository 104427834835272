<div
    class="container col-md-12 d-flex align-items-center"
    [style.height.px]="heightpx"
>
    <div class="d-flex align-items-center col-md-12 justify-content-between">
        <div class="title">{{ title }}</div>

        <button
            type="button"
            class="close-btn close pull-right"
            aria-label="Close"
            (click)="onClose()"
        >
            <span aria-hidden="true" style="color: white">&times;</span>
        </button>
    </div>
</div>
