import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorLoaderService } from './http-interceptor-loader.service';
import { ApiInterceptor } from './api-interceptor';
import { MsalInterceptor } from '@azure/msal-angular';
import { ErrorHandlerInterceptor } from './error_handler';

export const interceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorLoaderService,
        multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlerInterceptor,
        multi: true
    }
];
