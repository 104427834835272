import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { take, tap } from 'rxjs/operators';
import { Asset } from '~/shared/models/asset.model';
import { Tag } from '~/shared/models/tag.model';
import { AssetService } from '~/shared/services/asset.service';
import { TagService } from '~/shared/services/tag.service';

@Component({
  selector: 'app-lateral-modal-list',
  templateUrl: './lateral-modal-list.component.html',
  styleUrls: ['./lateral-modal-list.component.scss']
})
export class LateralModalListComponent implements OnInit, OnDestroy {
  @ViewChild('assetsListModal', { static: true }) public modal: ElementRef;

  @Input()
  public alreadyAddedAssets: Array<Asset> = [];

  @Input()
  public alreadyAddedTags: Array<Tag> = [];

  @Input()
  public isAssetList: boolean;

  @Output()
  public close = new EventEmitter<boolean>();

  @Output()
  public addAssetsList = new EventEmitter<Array<Asset>>();

  @Output()
  public addTagsList = new EventEmitter<Array<Tag>>();

  public modalRef: BsModalRef;

  private modalConfig: ModalOptions = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'lateral-menu col-md-9 p-0 modal-dialog'
  };

  public assets: Array<Asset> = [];

  public tagsList: Array<Tag> = [];

  public allSelectedAssets: Array<Asset> = [];

  public allSelectedTags: Array<Tag> = [];

  public modalTitle: string;

  public buttonLabel: string;

  public currentAddedValuesAmount = 0;

  public currentAddedValuesAmountLabel: string;

  public calledFrom: string;

  public isLoading: boolean;

  public textToSearch: string;

  public searchBtnClicked = false;

  constructor(
    private modalService: BsModalService,
    private assetService: AssetService,
    private tagService: TagService
  ) {}

  public ngOnInit(): void {
    this.openModal();
  }

  public ngOnDestroy() {
    this.resetAssetsListState();
    this.resetTagsListState();
  }

  public onClickNewAsset() {}

  public onClickAdd() {
    this.isAssetList
      ? this.addAssetsList.emit(this.allSelectedAssets)
      : this.addTagsList.emit(this.allSelectedTags);
    this.closeModal();
  }

  public openModal() {
    if (this.isAssetList) {
      this.modalTitle = 'Select BI Assets';
      this.buttonLabel = 'NEW BI ASSET';
      this.currentAddedValuesAmountLabel = 'BI Assets';
      // this.getAssets();
    }

    if (!this.isAssetList) {
      this.modalTitle = 'Select Tags';
      this.buttonLabel = '';
      this.currentAddedValuesAmountLabel = 'tag(s)';
      this.getTags();
    }
    this.modalRef = this.modalService.show(this.modal, this.modalConfig);
  }

  public closeModal() {
    this.isAssetList ? this.resetAssetsListState() : this.resetTagsListState();

    this.close.emit(true);
    this.modalRef.hide();
  }

  public onSearch() {
    // this.resetAssetsListState();
    this.isAssetList
      ? this.getAssets(this.textToSearch)
      : this.getTags(this.textToSearch);
  }

  public getAssets(textToFind?: string) {
    if (!textToFind) return;
    this.isLoading = true;
    this.searchBtnClicked = true;
    this.assetService
      .getSimpleData(textToFind)
      .pipe(
        take(1),
        tap(() => (this.assets = []))
      )
      .subscribe(
        (assets) => {
          this.checkExistsItemsList(
            assets,
            this.alreadyAddedAssets,
            this.assets
          );

          this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
  }

  public getTags(textToFind?: string) {
    this.isLoading = true;
    this.searchBtnClicked = true;
    this.tagService
      .getAllByUser(textToFind ? textToFind : '%20')
      .pipe(
        take(1),
        tap(() => (this.tagsList = []))
      )
      .subscribe(
        (tags) => {
          this.checkExistsItemsList(tags, this.alreadyAddedTags, this.tagsList);

          this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
  }

  private checkExistsItemsList(
    allPossibleValuesToAdd: Array<any>,
    valuesAlreadyAddedToList: Array<any>,
    fullList: Array<any>
  ) {
    if (!valuesAlreadyAddedToList || !valuesAlreadyAddedToList.length) {
      fullList.push(...allPossibleValuesToAdd);

      return;
    }

    allPossibleValuesToAdd.forEach((value) => {
      if (
        valuesAlreadyAddedToList &&
        !valuesAlreadyAddedToList.some((item) => item.id === value.id)
      )
        fullList.push(value);
    });
  }

  public addAssetToSelectedList(asset: Asset) {
    ++this.currentAddedValuesAmount;
    this.allSelectedAssets.push(asset);
  }

  public removeAssetFromSelectedList(asset: Asset) {
    --this.currentAddedValuesAmount;
    this.allSelectedAssets = this.allSelectedAssets.filter(
      (item) => item.id !== asset.id
    );
  }

  public resetAssetsListState() {
    this.currentAddedValuesAmount = 0;
    this.assets = [];
    this.allSelectedAssets = [];
  }

  public addTagToSelectedList(tag: Tag) {
    ++this.currentAddedValuesAmount;
    this.allSelectedTags.push(tag);
  }

  public removeTagFromSelectedList(tag: Tag) {
    --this.currentAddedValuesAmount;
    this.allSelectedTags = this.allSelectedTags.filter((item) => item !== tag);
  }

  public resetTagsListState() {
    this.currentAddedValuesAmount = 0;
    this.tagsList = [];
    this.allSelectedTags = [];
  }

  public isAssetSelected(assetId: number): boolean {
    let found = false;
    for (let i = 0; i < this.allSelectedAssets.length; i++) {
      if (this.allSelectedAssets[i].id === assetId) {
        found = true;
        break;
      }
    }

    return found;
  }
}
