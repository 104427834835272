<ng-container>
  <div class="container">
    <div class="row pt-2">
      <div class="col-md-4 pl-0" *ngIf="ReviewStats != null">
        <div class="rating-area">
          <div
            class="h-50 d-flex flex-column justify-content-center align-items-center">
            <span class="rating">{{
                            ReviewStats.RatingAverage | number: '1.1-1'
                        }}</span>

            <div
              class="d-flex justify-content-center align-items-center">
              <app-rating
                [Rating]="ReviewStats?.RatingAverage"></app-rating>

              <span class="pt-1 pl-2">{{ ReviewStats.TotalRatings }} votes</span>
            </div>
          </div>

          <div class="h-50 d-flex flex-column p-2">
            <div
              *ngFor="let number of [4, 3, 2, 1, 0]"
              class="row d-flex align-items-center justify-content-center m-0 d-flex h-100">
              <span>{{ number + 1 }}</span>
              <i class="fas fa-star"></i>

              <div class="progress flex-grow-1 mx-2">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngStyle]="{
                                        width: ReviewStats.Ratings[number] + '%'
                                    }"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"></div>
              </div>

              <span style="width: 10%">{{ ReviewStats.Ratings[number] }}%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8 p-0">
        <div
          class="border-b d-flex align-items-center mx-5 pb-1 h-15"
          style="height: 10%">
          <span class="title mr-2 mr-auto reviews-title">Reviews</span>

          <svg
            width="15"
            height="15"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.6777 2.625C10.7962 2.625 10.9375 2.61816 11.1016 2.60449C11.2702 2.59082 11.4434 2.58398 11.6211 2.58398C11.8034 2.57943 11.9834 2.58626 12.1611 2.60449C12.3434 2.62272 12.5052 2.66602 12.6465 2.73438C12.7878 2.79818 12.9017 2.8916 12.9883 3.01465C13.0794 3.1377 13.125 3.29948 13.125 3.5V9.625C13.125 9.74805 13.1022 9.86198 13.0566 9.9668C13.0111 10.0716 12.9473 10.165 12.8652 10.2471C12.7878 10.3245 12.6966 10.3861 12.5918 10.4316C12.487 10.4772 12.373 10.5 12.25 10.5H0.875C0.751953 10.5 0.638021 10.4772 0.533203 10.4316C0.428385 10.3861 0.334961 10.3245 0.25293 10.2471C0.175456 10.165 0.113932 10.0716 0.0683594 9.9668C0.0227865 9.86198 0 9.74805 0 9.625V3.5C0 3.37695 0.0227865 3.26302 0.0683594 3.1582C0.113932 3.05339 0.175456 2.96224 0.25293 2.88477C0.334961 2.80273 0.428385 2.73893 0.533203 2.69336C0.638021 2.64779 0.751953 2.625 0.875 2.625H6.82227L9.04395 0.40332C9.17155 0.275716 9.31738 0.177734 9.48145 0.109375C9.65007 0.0364583 9.82552 0 10.0078 0C10.1947 0 10.3701 0.0364583 10.5342 0.109375C10.6982 0.182292 10.8418 0.280273 10.9648 0.40332C11.0879 0.526367 11.1859 0.672201 11.2588 0.84082C11.3317 1.00488 11.3682 1.18034 11.3682 1.36719C11.3682 1.51758 11.3499 1.64974 11.3135 1.76367C11.2816 1.87305 11.2337 1.97559 11.1699 2.07129C11.1107 2.16699 11.0378 2.26042 10.9512 2.35156C10.8691 2.43815 10.778 2.5293 10.6777 2.625ZM10.0078 0.875C9.87565 0.875 9.76172 0.922852 9.66602 1.01855L4.06055 6.62402L3.82812 7.54688L4.75098 7.31445L9.40625 2.66602C9.39714 2.6569 9.39714 2.64551 9.40625 2.63184C9.41992 2.61816 9.43132 2.61589 9.44043 2.625C9.49512 2.57031 9.58171 2.49284 9.7002 2.39258C9.81868 2.29232 9.93717 2.18522 10.0557 2.07129C10.1742 1.9528 10.2767 1.83203 10.3633 1.70898C10.4544 1.58594 10.5 1.47201 10.5 1.36719C10.5 1.23047 10.4499 1.11426 10.3496 1.01855C10.2539 0.922852 10.14 0.875 10.0078 0.875ZM12.25 3.5H9.80273L5.20215 8.10742L2.625 8.75L3.26758 6.17285L5.94727 3.5H0.875V9.625H12.25V3.5Z"
              fill="#99A6B5" />
          </svg>

          <button
            class="write-review-title pl-1 btn btn-link"
            (click)="showReviewModal()">
            {{ checkReview ? 'Edit Review' : 'Write a Review' }}
          </button>

        </div>

        <div class="d-block pt-3" style="height: 90%">
          <swiper
            [config]="config"
            *ngIf="Reviews?.length > 0; else noreviews">
            <div
              *ngFor="let review of Reviews"
              class="swiper-slide">
              <app-card-review
                *ngIf="review.review !== ''"
                [Review]="review"></app-card-review>
            </div>
          </swiper>

          <ng-template #noreviews>
            <div
              class="no-reviews d-flex justify-content-center align-items-center h-100 mx-5">
              <span>No reviews yet</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="d-flex justify-content-center w-100">
    <app-loader [height]="32"></app-loader>
  </div>
</ng-template>