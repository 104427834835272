<div class="icon-picker">

    <div class="summary-info" (click)="toggleIconListView()">

        <div class="circle main-circle d-flex justify-content-center align-items-center" [attr.title]="'Icon Picker'">

            <i *ngIf="!selectedIcon" class="fas fa-plus" style="color: #1683FB;"></i>

            <i *ngIf="selectedIcon" class="cursor-pointer selected-icon fas fa-{{selectedIcon}}"></i>

        </div>

    </div>

    <div *ngIf="showIconsList" class="opened">

        <span class="w-100 remove-icon" (click)="setIconValue('')">Remove icon</span>

        <div class="icons">

            <i *ngFor="let icon of iconsToShow" class="fas fa-{{icon}} icon cursor-pointer d-flex align-items-center" (click)="setIconValue(icon)" [attr.title]="icon"></i>

        </div>

    </div>

</div>
