import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { RatingStats } from '~/shared/models/ratingStats';
import { AssetService } from '~/shared/services/asset.service';
import { HeaderService } from '~/shared/services/header.service';
import { RequestAccessDetailsService } from '~/shared/services/request-access-description.service';
import { ReviewService } from '~/shared/services/review.service';
import { TagService } from '~/shared/services/tag.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-assets-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit {
  public selectedAsset: Asset;

  public imageObject: Array<object> = [];

  public invalidImages = 0;

  public ReviewStats: RatingStats;

  public isReviewOpened = false;

  public showCIMApproval = false;

  public assetImage = '../../assets/placeholder.jpg';

  public isCurrentUserOwner = false;
  public isCurrentUserOwnerOrMember = false;

  public canUserApproveCIM = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public assetService: AssetService,
    public tagsService: TagService,
    private headerService: HeaderService,
    private reviewService: ReviewService,
    private router: Router,
    private usersService: UserService,
    private requestAccessDetailsService: RequestAccessDetailsService
  ) {}

  public getImages(asset: Asset): Array<object> {
    const imgs: Array<object> = [];
    let idx = 0;

    if (asset.images) {
      asset.images.forEach((element) => {
        imgs.push({
          image: element,
          index: idx,
          thumbImage: element
        });
        idx += 1;
      });
    }

    return imgs;
  }

  public get getTagsString(): Array<string> {
    const tags = [];

    this.selectedAsset.tags.map((tag) => {
      tags.push(tag.name);
    });

    return tags;
  }

  public ngOnInit() {
    this.headerService.SetDefaultHome();

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.assetService
        .getAssetById(Number(params.get('id')))
        .pipe(take(1))
        .subscribe(
          (asset) => {
            this.selectedAsset = asset;

            this.usersService.getCurrentUser().then((obj) => {
              this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(
                obj.currentUser
              );
              this.isCurrentUserOwnerOrMember =
                UtilsService.isCurrentUserOwnerOrMember(obj.currentUser);
              this.canUserApproveCIM =
                this.assetService.canUserApproveCIM(
                  this.selectedAsset,
                  obj.currentUser
                ) || UtilsService.isCurrentUserOwner(obj.currentUser);
            });

            // If the request came from a opened Storefront, it will filter the tag to show based on the Storefront that was opened.
            this.selectedAsset.tags = this.tagsService.filterTagsByStore(
              this.selectedAsset.tags,
              Number(params.get('storefrontid'))
            );

            if (this.selectedAsset.logo) {
              UtilsService.isValidImage((validImage) => {
                if (validImage) this.assetImage = this.selectedAsset.logo;
              }, this.selectedAsset.logo);
            }

            this.reviewService
              .getReviewStats(this.selectedAsset.id)
              .subscribe((rt) => {
                this.ReviewStats = rt;
              });

            const images = this.getImages(asset);

            images.forEach((v) => {
              UtilsService.isValidImage((validImage) => {
                if (validImage) {
                  this.imageObject.push(v);
                } else {
                  this.invalidImages++;
                }
                // tslint:disable-next-line: no-string-literal
              }, v['image']);
            });
          },
          () => {
            this.router.navigate(['/']);
          }
        );
    });
  }

  public showReviewModal() {
    this.isReviewOpened = true;
  }

  public updateAverageRating(event: number) {}

  public modalClosed(event) {
    this.isReviewOpened = false;
  }

  public get getKeyProcessesNames(): string {
    let kp = '';
    let count = 1;

    this.selectedAsset.keyProcesses.forEach((keyProcess) => {
      kp += keyProcess.name;

      if (count < this.selectedAsset.keyProcesses.length) {
        kp += ', ';
      }
      count += 1;
    });

    return kp;
  }

  public redirect() {
    this.router.navigate(['/asset', this.selectedAsset.id, 'edit']);
  }

  public openEmail() {
    event.stopPropagation();

    this.assetService
      .getAssetById(this.selectedAsset.id)
      .pipe(take(1))
      .subscribe((a) => {
        const mailText = `mailto:${a.coOwners.map((u) => {
          return u.email + ';';
        })}${a.productOwner.email}?subject=${a.name}&body=`;
        window.location.href = mailText;
      });
  }

  public openRequestAccessDetails(event): void {
    event.stopPropagation();
    this.requestAccessDetailsService.setRequestAccess(
      this.selectedAsset.requestAccessLink,
      true
    );
  }

  public getCountViewNumber() {
    return UtilsService.getShortViewNumber(this.selectedAsset.numberOfViews);
  }
}
