import { Asset } from './asset.model';
import { ModelBase } from './mode-base.model';
import { User } from './user.model';

export class Review extends ModelBase {
    constructor() {
        super();
    }

    public rating: number;

    public review: string;

    public user: User;

    public asset: Asset;

    public created: Date;

    public modified: Date;
}
