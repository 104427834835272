import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({ providedIn: 'root' })
export class RequestAccessDetailsService {

    private requestAccess = '';

    public selectedRequestAccess$ = new BehaviorSubject<string>(this.requestAccess);

    public showCenttralized = false;

    public setRequestAccess(requestAccess: string, showCenttralized = false): void {
        this.showCenttralized = showCenttralized;
        this.requestAccess = requestAccess;
        this.selectedRequestAccess$.next(this.requestAccess);
    }
}
