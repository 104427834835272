import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
    private starOptions = {
        InputGray: 'gray cursor-pointer',
        InputYellow: 'yellow cursor-pointer',
        Yellow: 'yellow',
        Gray: 'gray'
    };

    @Input()
    public Rating: number;

    @Input()
    public fontSize = 18;

    @Input()
    public isInput = false;

    @Output()
    public hoverChange = new EventEmitter();

    @Output()
    public rateChange = new EventEmitter();

    private hoverValue = -1;

    constructor() {}

    public ngOnInit(): void {}

    public OnClick(selectedRate: number) {
        if (this.isInput) {
            if (selectedRate === this.Rating) this.Rating = -1;
            else this.Rating = selectedRate;
        }

        this.rateChange.emit(this.Rating);
    }

    public mouseHover(value: number) {
        if (this.isInput) {
            this.hoverValue = value;
            this.hoverChange.emit(value);
        }
    }

    public enabled(index: number): string {
        if (this.isInput) {
            if (this.hoverValue > -1) {
                if (index > this.hoverValue) {
                    return this.starOptions.InputGray;
                }

                return this.starOptions.InputYellow;
            } else if (index <= this.Rating) {
                return this.starOptions.InputYellow;
            }

            return this.starOptions.InputGray;
        } else {
            if (index <= Math.round(this.Rating)) {
                return this.starOptions.Yellow;
            } else {
                return this.starOptions.Gray;
            }
        }
    }
}
