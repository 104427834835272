import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Storefront } from '~/shared/models/storefront.model';
import { LikedStorefrontService } from '~/shared/services/liked-storefront.service';

@Component({
    selector: 'app-like-storefront',
    templateUrl: './like-storefront.component.html',
    styleUrls: ['./like-storefront.component.scss']
})
export class LikeStorefrontComponent implements OnInit {
    public isLikedByCurrentUser = false;

    @Input()
    public fontSize = 18;

    @Input()
    public Storefront: Storefront;

    @Output()
    public clickLike = new EventEmitter();

    constructor(private likedStorefrontService: LikedStorefrontService) {}

    ngOnInit() {
        this.isLikedByCurrentUser = this.Storefront.isLikedByCurrentUser;
    }

    public onClick(event) {
        event.stopPropagation();

        if (this.isLikedByCurrentUser)
            this.likedStorefrontService
                .removeLiked(this.Storefront)
                .subscribe((ls) => {
                    return;
                });
        else
            this.likedStorefrontService
                .addLiked(this.Storefront)
                .subscribe((ls) => {
                    return;
                });

        this.isLikedByCurrentUser = !this.isLikedByCurrentUser;
        this.Storefront.isLikedByCurrentUser = this.isLikedByCurrentUser;
        this.emitClick();
    }

    public getIcon(): string {
        return this.isLikedByCurrentUser ? 'fas fa-heart' : 'far fa-heart';
    }

    public emitClick() {
        this.clickLike.emit(this.Storefront);
    }
}
