import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import { User } from '~/shared/models/user.model';
import { NotificationService } from '~/shared/services/notification.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnInit, AfterViewInit {
  public owners = new Array<User>();
  public members = new Array<User>();
  public internals = new Array<User>();

  @ViewChild('ownersSort', { static: false }) public ownersSort: MatSort;
  @ViewChild('ownersPaginator', { static: false })
  public ownersPaginator: MatPaginator;

  @ViewChild('membersSort') public membersSort: MatSort;
  @ViewChild('membersPaginator') public membersPaginator: MatPaginator;

  @ViewChild('internalsSort') public internalsSort: MatSort;
  @ViewChild('internalsPaginator') public internalsPaginator: MatPaginator;

  public ownersDataSource = new MatTableDataSource<User>([]);
  public membersDataSource = new MatTableDataSource<User>([]);
  public internalDataSource = new MatTableDataSource<User>([]);

  public displayColumns: string[] = ['name', 'delete'];

  public selectedOwner = new User();
  public selectedMember = new User();
  public selectedInternal = new User();

  private addPermissionType = '';

  public showSelectUser = false;

  public searchText = '';

  public members$: Observable<User[]>;

  constructor(
    private userService: UserService,
    private toastr: NotificationService
  ) {}

  public ngOnInit(): void {
    this.fetchAllGroups();
  }

  public ngAfterViewInit(): void {
    this.populateLists();
  }

  private fetchAllGroups(): void {
    this.userService
      .getUsersByPermission('Onwer')
      .pipe(take(1))
      .subscribe((owners) => {
        this.owners = owners;
        this.populateLists();
      });

    this.userService
      .getUsersByPermission('Member')
      .pipe(take(1))
      .subscribe((members) => {
        this.members = members;
        this.populateLists();
      });

    this.userService
      .getUsersByPermission('Internal')
      .pipe(take(1))
      .subscribe((internals) => {
        this.internals = internals;
        this.populateLists();
      });
  }

  private populateLists(): void {
    this.ownersDataSource.data = this.owners;
    this.ownersDataSource.sort = this.ownersSort;
    this.ownersDataSource.paginator = this.ownersPaginator;

    this.membersDataSource.data = this.members;
    this.membersDataSource.sort = this.membersSort;
    this.membersDataSource.paginator = this.membersPaginator;

    this.internalDataSource.data = this.internals;
    this.internalDataSource.sort = this.internalsSort;
    this.internalDataSource.paginator = this.internalsPaginator;
  }

  public openSearchUser(type: string) {
    this.addPermissionType = type;
    this.showSelectUser = true;
  }

  public userAdded(user: User): void {
    this.addnewUserToTheList(user);

    this.userService
      .addUserPermission(user, this.addPermissionType)
      .pipe(take(1))
      .subscribe(
        (userId) => {
          this.addnewUserToTheList(user);

          this.toastr.success(
            '',
            user.name + ' added as ' + this.addPermissionType,
            2500
          );
        },
        (error) => {
          this.toastr.error('', 'Error', 2500);
        }
      );
  }

  public addnewUserToTheList(user: User): void {
    let listToAdd = new Array<User>();

    switch (this.addPermissionType) {
      case 'Owner':
        listToAdd = this.owners;
        break;

      case 'Member':
        listToAdd = this.members;
        break;

      case 'Internal':
        listToAdd = this.internals;
        break;
    }

    const t = listToAdd.filter(
      (t) => t.id == user?.id || t.name == user.name
    )[0];

    if (!t) {
      listToAdd.unshift(user);
    }

    this.populateLists();
  }

  public removePermission(type: string): void {
    let listToRemove = new Array<User>();
    let selectedItem: User;

    switch (type) {
      case 'Owner':
        listToRemove = this.owners;
        selectedItem = this.selectedOwner;
        break;

      case 'Member':
        listToRemove = this.members;
        selectedItem = this.selectedMember;
        break;

      case 'Internal':
        listToRemove = this.internals;
        selectedItem = this.selectedInternal;
        break;
    }

    this.userService
      .removeUserPermission(selectedItem, type)
      .pipe(take(1))
      .subscribe(
        (userId) => {
          this.removeUserFromTheList(selectedItem, listToRemove);

          this.toastr.success(
            '',
            selectedItem.name + ' removed from ' + type + 's',
            2500
          );
        },
        (error) => {
          this.toastr.error('', 'Error', 2500);
        }
      );
  }

  private removeUserFromTheList(user: User, listToRemove: Array<User>) {
    const t = listToRemove.filter(
      (t) => t.id == user.id || t.name == user.name
    )[0];
    const index = listToRemove.indexOf(t);

    if (index !== -1) {
      listToRemove = listToRemove.splice(index, 1);
    }

    this.populateLists();
  }

  public selectOwnersRow(row): void {
    this.selectedOwner = row;
  }

  public selectMembersRow(row): void {
    this.selectedMember = row;
  }

  public selectInternalRow(row): void {
    this.selectedInternal = row;
  }

  public applyMemberFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.membersDataSource.filter = filterValue.trim().toLowerCase();
  }

  public applyInternalFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.internalDataSource.filter = filterValue.trim().toLowerCase();
  }
  public applyOwnerFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ownersDataSource.filter = filterValue.trim().toLowerCase();
  }
}
