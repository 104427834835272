import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '~/shared/shared.module';
import { CreateEditKeyProcessComponent } from './components/create-edit-key-process/create-edit-key-process.component';
import { CreateEditPlBlComponent } from './components/create-edit-pl-bl/create-edit-pl-bl.component';
import { CreateEditTagComponent } from './components/create-edit-tag/create-edit-tag.component';
import { SearchUserComponent } from './components/search-user/search-user.component';
import { StorefrontsOrderComponent } from './components/storefronts-order/storefronts-order.component';
import { UserPermissionsComponent } from './components/user-permissions/user-permissions.component';
import { SimpleDataManagerComponent } from './pages/simple-data-manager/simple-data-manager.component';

const MODULE_COMPONENTS = [
  SimpleDataManagerComponent,
  CreateEditTagComponent,
  CreateEditKeyProcessComponent,
  CreateEditPlBlComponent,
  UserPermissionsComponent,
  SearchUserComponent,
  StorefrontsOrderComponent
];

const MODULE_MODULES = [CommonModule, SharedModule];

@NgModule({
  declarations: [...MODULE_COMPONENTS],
  imports: [...MODULE_MODULES, MatFormFieldModule, MatInputModule],
  exports: [...MODULE_COMPONENTS]
})
export class SimpleDataManagerModule {
  public static WikiModule: any;
}
