<div class="text-white" *ngIf="!!currentUser">

  <span *ngIf="showName"> {{ currentUser.name }} </span>

  <div class="dropdown">

    <img mat-button [matMenuTriggerFor]="items"
      [src]="currentUser.image || '/assets/default_user.jpg'"
      class="img-thumbnail rounded-circle p-0 ml-0"
      [ngClass]="showAlternativeStyle ? 'alternative-avatar' : 'avatar'"
      [attr.title]="currentUser.name"
      [attr.alt]="currentUser.email" />

    <mat-menu #items="matMenu" xPosition="before">
      <div class="arrow"></div>
      <div class="new-button border-underline cursor-pointer" (click)="goToUserManagement()">
        <label class="icon-label cursor-pointer">
          User Access Management
        </label>
      </div>

      <div class="new-button border-underline cursor-pointer" (click)="goToUserProfile()">
        <label class="icon-label cursor-pointer">
          Storefront Management
        </label>
      </div>

      <button type="button" class="btn btn-primary button-logout" (click)="goToLogout()">Log out</button>

    </mat-menu>

  </div>

</div>