<ng-template #template>
  <div
    class="modal-header d-flex align-items-center"
    style="color:white; background-color: #0014DC;">
    <h5 class="modal-title pull-left">{{ modalTitle }}</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="noCLick()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-4">

    <div class="col-md-12 pl-0">
      <label class="custom-label">Tag Name*</label>
      <input type="text" [(ngModel)]="name"
        required maxlength="50"
        class="custom-input" />
    </div>

    <div class="col-md-12 pl-0 d-flex flex-column mt-4">
      <label class="custom-label" for="color">Tag Color*</label>
      <input type="color" id="color" name="color" value="#ffffff"
        [(ngModel)]="color" class=" mt-1" style="margin-left: 0.7rem;">
    </div>

    <div class="d-flex justify-content-center pt-5">
      <button
        type="submit"
        (click)="noCLick()"
        class="col-md-4 button-bordered-blue">
        CANCEL
      </button>

      <button
        type="submit"
        (click)="delete(tag)"
        class="col-md-4 button-bordered-red col-md-2"
        [disabled]="enabledDelete">
        DELETE
      </button>

      <button
        type="submit"
        (click)="save()"
        class="col-md-4 button-blue"
        [disabled]="ckeckDisabled">
        Save
      </button>
    </div>
  </div>
</ng-template>