<div
  class="card-asset cursor-pointer d-flex flex-column m-1"
  (click)="openAddress(Asset.url, $event)">
  <div class="row no-gutters">
    <div class="col-10 pt-2 pl-2">
      <div class="d-flex align-items-center">
        <div
          *ngIf="!isRemove"
          class="
                        logo
                        d-flex
                        align-items-center
                        justify-content-md-center
                        mr-1
                    "
          [ngStyle]="{ 'background-color': getColorWithAlpha }">
          <img
            class="logo"
            *ngIf="Asset.logo; else loading"
            [src]="Asset.logo" />

          <ng-template #loading>
            <span class="fist-letters">
              {{ getInitialLetters() }}
            </span>
          </ng-template>
        </div>

        <span
          class="title pt-1 pb-1"
          [tooltip]="Asset.description"
          [delay]="500">
          <svg
            *ngIf="Asset.isNew"
            width="29"
            height="10"
            viewBox="0 0 29 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="margin-bottom: 5px">
            <rect
              x="29"
              width="10"
              height="29"
              rx="5"
              transform="rotate(90 29 0)"
              fill="#FF9800" />
            <path
              d="M11.7031 8H10.5312L8.25 4.25781V8H7.07812V2.3125H8.25L10.5352 6.0625V2.3125H11.7031V8ZM14.5977 8.07812C13.9779 8.07812 13.4727 7.88802 13.082 7.50781C12.694 7.1276 12.5 6.62109 12.5 5.98828V5.87891C12.5 5.45443 12.582 5.07552 12.7461 4.74219C12.9102 4.40625 13.1419 4.14844 13.4414 3.96875C13.7435 3.78646 14.0872 3.69531 14.4727 3.69531C15.0508 3.69531 15.5052 3.8776 15.8359 4.24219C16.1693 4.60677 16.3359 5.1237 16.3359 5.79297V6.25391H13.6445C13.681 6.52995 13.7904 6.7513 13.9727 6.91797C14.1576 7.08464 14.3906 7.16797 14.6719 7.16797C15.1068 7.16797 15.4466 7.01042 15.6914 6.69531L16.2461 7.31641C16.0768 7.55599 15.8477 7.74349 15.5586 7.87891C15.2695 8.01172 14.9492 8.07812 14.5977 8.07812ZM14.4688 4.60938C14.2448 4.60938 14.0625 4.6849 13.9219 4.83594C13.7839 4.98698 13.6953 5.20312 13.6562 5.48438H15.2266V5.39453C15.2214 5.14453 15.1536 4.95182 15.0234 4.81641C14.8932 4.67839 14.7083 4.60938 14.4688 4.60938ZM20.6562 6.46484L21.2109 3.77344H22.3008L21.2227 8H20.2773L19.4766 5.33984L18.6758 8H17.7344L16.6562 3.77344H17.7461L18.2969 6.46094L19.0703 3.77344H19.8867L20.6562 6.46484Z"
              fill="white" />
          </svg>

          <i
            *ngIf="!Asset.active"
            class="fas fa-tools pl-1"
            tooltip="Under construction"
            containerClass="custom-tooltip"
            style="font-size: 12px; color: #e00000"></i>

          <i
            *ngIf="Asset.retired"
            class="fas fa-eye-slash pl-1"
            tooltip="Retired"
            containerClass="custom-tooltip"
            style="font-size: 12px; color: #e00000"></i>

          {{ Asset.name }}
        </span>
      </div>

      <span class="product-line text-truncate">{{
                Asset.productLine?.name
                }}</span>
    </div>

    <div
      class="
                col-2
                pt-2
                pr-2
                d-flex
                flex-column
                justify-content-start
                align-items-end
            ">
      <i
        *ngIf="isRemove"
        class="fas fa-times pb-2"
        (click)="onClick($event)"
        [tooltip]="'Remove'"
        [delay]="500"></i>

      <i
        *ngIf="isPriority"
        class="fas fa-thumbtack p-1 priority-icon"
        [ngClass]="{ 'priority-icon-active': this.Asset.isPriority }"
        (click)="
                    $event.stopPropagation();
                    this.Asset.isPriority = !this.Asset.isPriority
                "
        [attr.title]="
                    this.Asset.isPriority ? 'Remove Priority' : 'Add Priority'
                "></i>

      <i
        *ngIf="isEdit"
        class="fas fa-pen pb-2"
        (click)="onClick($event)"
        [tooltip]="'Edit'"
        [delay]="500"></i>

      <app-like-asset
        (clickLike)="emitLike()"
        *ngIf="!isRemove && !isEdit"
        [Asset]="Asset">
      </app-like-asset>

      <i
        *ngIf="Asset.isPriority && !isPriority && !isRemove && !isEdit"
        class="fas fa-thumbtack p-1 priority-icon"
        [ngClass]="{ 'priority-icon-active': Asset.isPriority }"
        style="color: #7a7a7a; font-size: 14px"
        [attr.title]="'This asset has priority'"></i>
    </div>

    <div
      *ngIf="Asset.tags?.length > 0"
      class="tags-area overflow-hidden pt-2 px-2">
      <div
        class="align-items-center d-inline-flex mr-1 mb-1"
        *ngFor="let tag of Asset.tags">
        <span
          class="tag"
          [ngStyle]="{ 'background-color': logoAreaColor }">
          {{ tag.name }}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="!isRemove && !isEdit" class="footer mt-auto d-flex px-2 pb-1">
    <div class="icons-area d-flex align-items-end justify-content-end">
      <a
        href="{{ Asset.usageStats }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.usageStats"
        (click)="$event.stopPropagation()">
        <i class="fas fa-chart-bar" [attr.title]="'Usage Stats'"></i>
      </a>

      <a
        href="{{ Asset.videoUrl }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.videoUrl"
        (click)="$event.stopPropagation()">
        <i class="fas fa-film ml-2" [attr.title]="'Video URL'"></i>
      </a>

      <a
        href="{{ Asset.askAndLearn }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.askAndLearn"
        (click)="$event.stopPropagation()">
        <i
          class="fas fa-chalkboard-teacher ml-2"
          [attr.title]="'Ask & Learn'"></i>
      </a>

      <a
        href="{{ Asset.documentationLink }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.documentationLink"
        (click)="$event.stopPropagation()">
        <i
          class="fas fa-file-alt ml-2"
          [attr.title]="'Documentation'"></i>
      </a>

      <a
        class="icon-block"
        *ngIf="Asset.requestAccessLink"
        (click)="openRequestAccessDetails($event)">
        <i
          class="fas fa-unlock ml-2"
          [attr.title]="'Request Access'"></i>
      </a>

      <a
        target="_blank"
        class="icon-block"
        *ngIf="Asset.url"
        (click)="OpenLateralModal(Asset)">
        <i
          class="fas fa-info-circle ml-2"
          [attr.title]="'More Details'"></i>
      </a>
    </div>
  </div>
</div>