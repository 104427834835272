<div class="search-bar">

  <div class="container p-0 d-flex h-100">

    <mat-tab-group
      *ngIf="showHomeMenuTabs"
      #storeFront
      [@.disabled]="true"
      disableRipple
      class="home-page-header"
      (selectedTabChange)="tabChanged($event)">

      <mat-tab label="Storefronts">
      </mat-tab>

      <mat-tab label="BI Assets">
      </mat-tab>

    </mat-tab-group>

    <div class="search-area d-flex align-items-center h-100 ml-auto">

      <div class="search d-flex align-items-center ml-auto">

        <img src="/assets/icon-search.svg" class="cursor-pointer" (click)="searchIconClicked()">

        <input
          #searchInput
          maxlength="1000"
          type="text"
          name="main-search"
          [(ngModel)]="searchText"
          autocomplete="off"
          [placeholder]="placeholder"
          (keydown.enter)="doSearch()"
          (keyup.backspace)="doSearchEmpty()"
          (keyup.delete)="doSearchEmpty()" />

      </div>

    </div>

    <div *ngIf="(isCurrentUserOwnerOrMember && showHomeMenuTabs) || (isCurrentUserOwnerOrMember && selectedFrontStore)" class="d-flex align-items-center">
      <div class="btn cursor-pointer d-flex align-items-center justify-content-center" [routerLink]="['/asset/new']">
        <span>Create a New BI Asset</span>
      </div>
    </div>

    <div *ngIf="selectedFrontStore && storefrontService.canUserEdit(selectedFrontStore)" class="d-flex align-items-center">
      <div class="btn cursor-pointer d-flex align-items-center justify-content-center" [routerLink]="['/store/' + selectedFrontStore.id + '/edit']">
        <span>Edit Storefront</span>
      </div>
    </div>

    <div *ngIf="selectedFrontStore && isCurrentUserOwner" class="d-flex align-items-center h-100">
      <span class="mr-2 text-dark ml-3">Verified:</span>
      <app-toggle-switch-button
        [checked]="selectedFrontStore.verified"
        [confirmationMessage]="'Are you sure you want to change the status of this Storefront?'"
        (changed)="changeStorefrontVerifiedStatus($event)">
      </app-toggle-switch-button>
    </div>

  </div>

</div>