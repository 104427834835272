import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RequestAccessDetailsService } from '~/shared/services/request-access-description.service';

@Component({
    selector: 'app-request-access-details',
    templateUrl: './request-access-details.component.html',
    styleUrls: ['./request-access-details.component.scss']
})
export class RequestAccessDetailsComponent implements OnInit {
    @ViewChild('requestAccess', { static: true }) public modalView: ElementRef;

    public requestAccessDetails: string;

    private config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: false,
        // class: 'request-access-details-modal'
        class: 'center-modal modal-dialog-centered'
    };

    public modalDetails: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private requestAccessDetailsService: RequestAccessDetailsService
    ) {}

    public ngOnInit(): void {
        this.requestAccessDetailsService.selectedRequestAccess$.subscribe(
            (desc) => {
                if (desc) {
                    this.requestAccessDetails = desc;

                    if (this.requestAccessDetailsService.showCenttralized)
                        this.config.class =
                            'center-modal modal-dialog-centered';
                    else this.config.class = 'request-access-details-modal';

                    this.modalDetails = this.modalService.show(
                        this.modalView,
                        this.config
                    );
                }
            }
        );
    }

    public okClick() {
        this.closeModal();
    }

    public closeModal() {
        this.modalDetails.hide();
    }

    public isHTML(): boolean {
        var a = document.createElement('div');
        a.innerHTML = this.requestAccessDetails;

        for (var c = a.childNodes, i = c.length; i--; ) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }
}
