import { ModelBase } from './mode-base.model';

export class Faq extends ModelBase {
  constructor() {
    super();
  }

  public description: string;

  public created: Date;

  public orderId = 99;
}
