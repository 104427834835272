<div class="list-container mt-2 pb-2 d-flex justify-content-between">
    <div class="category d-flex align-items-center">
        <div class="icon d-flex justify-content-center align-items-center">
            <i *ngIf="categoryIcon" class="p-1 fas fa-{{categoryIcon}}"></i>

            <span class="fist-letters" *ngIf="!categoryIcon">{{ getTextFirstLetters() }}</span>
        </div>

        <div class="ml-2">
            <div class="name">{{ categoryName }}</div>
            <div class="detail">
                <span>Category managed by </span>
                <span class="responsible">{{ categoryResponsible }}</span>
            </div>
        </div>
    </div>

    <div class="d-column ml-auto pt-1 pr-2">

        <div class="cursor-pointer delete-category d-flex align-items-center mt-2" (click)="onClickEdit()" [attr.title]="'Edit'">
            <i class="fas fa-pen" style="font-size:12px"></i>
        </div>

        <div class="cursor-pointer delete-category d-flex align-items-center mt-2" (click)="showDeleteModal = true" [attr.title]="'Delete'">
            <i class="fas fa-trash-alt" style="font-size:12px"></i>
        </div>
    </div>
</div>
<app-confirmation-box *ngIf="showDeleteModal" [settings]="deleteModalSettings" (close)="closeModalDelete($event)">
</app-confirmation-box>
