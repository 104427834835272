import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { Tag } from '~/shared/models/tag.model';
import { TagService } from '~/shared/services/tag.service';

@Component({
  selector: 'app-create-edit-tag',
  templateUrl: './create-edit-tag.component.html',
  styleUrls: ['./create-edit-tag.component.scss']
})
export class CreateEditTagComponent implements OnInit {
  @ViewChild('template', { static: true }) public elementView: ElementRef;

  @Output() public close = new EventEmitter();
  @Output() public tagSaved = new EventEmitter<Tag>();
  @Output() public tagDeleted = new EventEmitter<Tag>();

  @Input() public tag = new Tag();

  public name: string;
  public color: string;

  private config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'center-modal modal-dialog-centered'
  };

  @Input()
  public settings = [];

  @Input()
  private createdTags = [];

  public modalRef: BsModalRef;

  public modalTitle: string;

  public enabledDelete = false;

  constructor(
    private modalService: BsModalService,
    private tagService: TagService
  ) {}

  public ngOnInit(): void {
    this.modalRef = this.modalService.show(this.elementView, this.config);
    this.modalTitle = this.tag?.id ? 'Edit Tag' : 'Create Tag';
    this.enabledDelete = this.tag?.id ? false : true;

    if (this.tag.id) {
      this.color = this.tag.color;
      this.name = this.tag.name;
    }
  }

  public save() {
    let duplicatedName = false;

    this.createdTags.forEach((tag) => {
      if (
        tag.name.toLocaleLowerCase() === this.name.toLocaleLowerCase() &&
        (!this.tag || tag.id !== this.tag.id)
      ) {
        duplicatedName = true;

        return;
      }
    });

    if (duplicatedName) {
      window.alert(`you already have a Tag called ${this.name}`);

      return;
    }

    this.tag.active = true;
    this.tag.color = this.color;
    this.tag.name = this.name;

    if (this.tag.id) {
      this.tagService
        .update(this.tag)
        .pipe(take(1))
        .subscribe((tagId) => {
          this.tagSaved.emit(this.tag);
          this.closeModal();
        });
    } else {
      this.tagService
        .add(this.tag)
        .pipe(take(1))
        .subscribe((tagId) => {
          this.tag.id = tagId;
          this.tagSaved.emit(this.tag);
          this.closeModal();
        });
    }
  }

  public noCLick() {
    this.closeModal();
  }

  private closeModal() {
    this.close.emit();
    this.modalRef.hide();
  }

  public get ckeckDisabled(): boolean {
    return !this.name || !this.color ? true : false;
  }

  public delete(tagToDelete: Tag) {
    const ok = window.confirm(
      'Are you sure you want to delete the tag ' + tagToDelete.name + '?'
    );

    if (ok) {
      this.tagService
        .deleteTag(tagToDelete)
        .pipe(take(1))
        .subscribe((t) => {
          this.tagDeleted.emit(this.tag);
          this.closeModal();
        });
    }
  }
}
