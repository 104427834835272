import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env';
import {
  BotDirective,
  BotHelperDirective,
  BotService,
  ComService,
  DEFAULT_OPTIONS,
  IPayload,
  IWebChat
} from 'ngx-microsoft-bot-framework';
import { User } from '~/shared/models/user.model';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-virtual-agent',
  templateUrl: './virtual-agent.component.html',
  styleUrls: ['./virtual-agent.component.scss']
})
export class VirtualAgentComponent implements OnInit {
  @ViewChild('botWindow', { static: false })
  public botWindowElement: ElementRef;
  public passViewChild: ViewChild;
  public renderObject: IWebChat;

  public currentUser: User;

  public payload: IPayload;

  public stylesetPayload: DEFAULT_OPTIONS;

  public styleOptionsPayload: DEFAULT_OPTIONS;

  public visible = false;

  public loading = true;

  constructor(
    private usersService: UserService,
    private comService: ComService,
    private bot: BotDirective,
    private botHelper: BotHelperDirective,
    private botService: BotService
  ) {}

  public ngOnInit(): void {}

  public customBotDirective(): void {
    let token: string;
    this.passViewChild = this.botWindowElement.nativeElement;
    this.botService.getTokenObs().subscribe(async (response) => {
      this.payload.secretSetting
        ? (token = response.body)
        : (token = this.payload.secret);
      if (
        (response.status === 200 && response.statusText === 'OK') ||
        response === false
      ) {
        const directLine = window.WebChat.createDirectLine({
          secret: token,
          webSocket: true
        });

        const styleSet = window.WebChat.createStyleSet(this.stylesetPayload);

        this.renderObject = {
          directLine,
          userID: '',
          styleOptions: this.styleOptionsPayload,
          styleSet,
          disabled: false
        };

        this.botHelper.renderWebChat(this.passViewChild, this.renderObject);

        await directLine
          .postActivity({
            from: {
              id: '',
              name: this.currentUser.name
            },
            name: 'pvaSetContext',
            value: {
              UserEmail: this.currentUser.email
            },
            type: 'event'
          })
          .toPromise();

        await directLine
          .postActivity({
            from: {
              id: '',
              name: this.currentUser.name
            },
            name: 'startConversation',
            type: 'event',
            value: 'true'
          })
          .toPromise();

        this.loading = false;
      }
    });
  }

  public getUserInfo() {
    if (!this.currentUser)
      this.usersService.getCurrentUser().then((obj) => {
        this.currentUser = obj.currentUser;
        this.startBot();
      });
  }

  private startBot() {
    this.populateBotConfig();
    this.customBotDirective();
    this.obtainStylePayload();
    this.obtainLocalToken();
  }

  private populateBotConfig(): void {
    this.payload = {
      secret: environment.virtualAgentSecretKey,
      url: '',
      secretSetting: false,
      userId: '',
      webSocket: true
    };

    this.styleOptionsPayload = {
      botAvatarInitials: 'BIA',
      botAvatarImage: '../assets/bia-avatar-icon.svg',
      hideUploadButton: true
    };

    this.stylesetPayload = {
      backgroundColor: '#F1F1F1'
    };
  }

  public setBotDirective(): void {
    this.passViewChild = this.botWindowElement.nativeElement;
    this.bot.botDirective(this.passViewChild);
  }

  public obtainLocalToken() {
    this.comService.obtainToken(this.payload);
  }

  public obtainStylePayload() {
    this.comService.obtainStylePayload(
      this.stylesetPayload,
      this.styleOptionsPayload
    );
  }

  public open() {
    this.visible = true;
    this.getUserInfo();
  }

  public close() {
    this.visible = false;
  }
}
