<ng-template #createBulkAssets>
  <div class="lateral-menu">
    <header>
      <app-modal-header
        [title]="'Create Bulk Assets'"
        [heightpx]="70"
        (onClickClose)="closeModal()">
      </app-modal-header>
    </header>
    <div class="modal-body">
      <div class="pt-2" [@fadeInOut] *ngIf="!file">
        <div class="header-container d-flex flex-column">
          <span class="title mb-1">How To Use</span>
          <span class="sub-title mb-2"></span>
          <div class="mt-2">
            <a target="_blank"
              href="https://biportfolioprod.blob.core.windows.net/bi-portifolio/docs/template_bulk_assets_insert_new.csv">
              <span class=" btn button-blue col"><span class="fa fa-download"></span> Download CSV File</span>
            </a>
          </div>
          <span class="small-font pt-2">
            <ul class="dashed pl-2">
              <li>Open the file using a spreadsheet editor. e.g Microsoft Excel.</li>
              <li>Include one Asset per line, inserting the data in the respective column.</li>
              <li>Separate columns that have more than one value with semi-colon ";" (including search tags).</li>
              <li>Do not use comma in any field. Coma are used on CSV (Comma-separated values) to identify the next column.</li>
              <li><b>Active</b> and <b>Retired</b> values must be <b>true</b> or <b>false</b>.</li>
              <li>Required columns are Name, ProductOwner, Storefront, Description, Url and Active. If you don't need a column, let its value empty.</li>
              <li>The tags you select must be from the same storefront you selected.</li>
              <li>Save the file as CSV format.</li>
              <li>Upload the file using the "Browse" button under the Upload The .CSV File section.</li>
              <li>Click on the "CREATE ASSETS" button to finish.</li>
              <li>Images are not supported, but you can add it and update the Assets after creating them.</li>
              <li>Check the predefined values
                <a class="cursor-pointer" (click)="showPredefinedValues = !showPredefinedValues">here</a>.
              </li>
            </ul>
          </span>
          <div *ngIf="showPredefinedValues" [@fadeInOut] class="card card-body pb-1">
            <div class="header-container row">
              <div class="col-md-10">
                <span class="title mb-1">Select An Option</span>
              </div>
              <div class="col-md-2">
                <i class="fas fa-times text-secondary text-right" (click)="showPredefinedValues = false"></i>
              </div>
              <span class="sub-title mb-2"> </span>
            </div>
            <div class="row pb-1 pr-3 pl-3 pt-3">
              <ng-select
                class="col-md-11 mt-0"
                (change)="onOptionChange($event)"
                [items]="predefinedValuesOptions"
                placeholder="Predefined Options">
              </ng-select>
            </div>
            <div class="row no-gutters mt-3">
              <div class="mr-2 mb-3 ml-2 col-md-12">
                <span class="sub-title mb-2 right" *ngIf="selectedOptions.length > 0">Press <b>Ctrl</b> to select more than one</span>
                <div *ngIf="selectedOption == 'Key Processes'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let kp of keyProcesses" [ngValue]="kp.name">{{ kp.name }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'Product Lines'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let pl of productLines" [ngValue]="pl.name">{{ pl.name }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'Categories (Storefront - Category)'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let c of categories" [ngValue]="c.id">{{c.storefront.name}} - {{ c.name }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'Global Tags'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let t of tags" [ngValue]="t.name">{{ t.name }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'Storefront Tags'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let tag of storefrontTags" [ngValue]="tag.id">{{tag.storefront.name}} - {{ tag.name }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'Users'" class="pl-0 pb-0 col-md-11">
                  <app-user-picker
                    class="m-0 col-md-8"
                    [multiple]="false"
                    [(ngModel)]="selectedUser"
                    placeholder="Select a User">
                  </app-user-picker>
                </div>
                <div *ngIf="selectedOption == 'SL Divisions'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let sl of slDivisions" [ngValue]="sl.code">{{ sl.description }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'Jobs'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let j of jobs" [ngValue]="j.code">{{ j.description }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'SL Business Lines'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let slb of slBusinessLines" [ngValue]="slb.code">{{ slb.description }}</option>
                  </select>
                </div>
                <div *ngIf="selectedOption == 'Business Functions'">
                  <select class="form-select mt-2" multiple size="5" [(ngModel)]="selectedOptions">
                    <option *ngFor="let bf of businessFunctions" [ngValue]="bf.code">{{ bf.description }}</option>
                  </select>
                </div>
              </div>
              <div class="mr-2 mb-1 ml-2 col-md-11" style="text-align: right" *ngIf="selectedOption != '' && selectedOption != null">
                <div *ngIf="selectedOption != 'Users'">
                  <a
                    *ngIf="selectedOptions.length > 0"
                    class="sub-title mb-2 cursor-pointer"
                    [cdkCopyToClipboard]="concatenateAllOptionsName()">
                    Copy values
                  </a>
                  <input
                    *ngIf="selectedOptions.length > 0"
                    class="custom-input mb-2 input-code"
                    disabled
                    [value]="concatenateAllOptionsName()" />
                </div>
                <div *ngIf="selectedOption == 'Users' && selectedUser != null">
                  <label class="custom-label">Name</label>
                  <input type="text" class="custom-input" [value]="selectedUser.name" disabled />
                  <label class="custom-label pt-3">E-mail Address</label>
                  <input type="text" class="custom-input" [value]="selectedUser.email" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [class]="!file ? 'pt-4' : 'pt-2'">
        <div class="header-container d-flex flex-column">
          <span class="title mb-1">Upload The .CSV File</span>
          <span class="sub-title mb-2">Select a <b>.CSV</b> file to start the process.</span>
          <div class="custom-file">
            <input
              [disabled]="loading || this.createdAssets?.length > 0"
              name="inputUploadFile"
              class="custom-file-input"
              type="file"
              accept=".csv,text/plain"
              (change)="onFileChange($event.target.files)" />
            <label class="custom-file-label" for="customFile">
              {{ fileNameLabel ? fileNameLabel : fileNamePlaceholderLabel }}
            </label>
          </div>
          <div class="progress-bar-background" *ngIf="loading">
            <div [ngStyle]="{ 'width.%': width, 'background': progressBarBackgroundColor }" class="progress-bar"></div>
          </div>
        </div>
      </div>
      <div class="pt-4" *ngIf="this.createdAssets?.length > 0">
        <div class="header-container d-flex flex-column">
          <span class="title mb-1">Recently Added</span>
          <div class="row no-gutters mr-1">
            <app-card-asset-horizontal
              (click)="closeModal()"
              *ngFor="let asset of this.createdAssets"
              class="pl-1 col-md-6"
              [Asset]="asset">
            </app-card-asset-horizontal>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="button-blue col-md-6"
      (click)="add()"
      [disabled]="file == null || createdAssets?.length > 0 || loading">
      CREATE ASSETS
    </button>
  </div>
</ng-template>