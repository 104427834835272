import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { BIReport } from '~/shared/models/bi-report.model';
import { FitForRoleItem } from '~/shared/models/fit-for-role-item.model';
import { IImage } from '~/shared/models/image.model';
import { KeyProcess } from '~/shared/models/keyProcess.model';
import { ProductLine } from '~/shared/models/productLine';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { User } from '~/shared/models/user.model';
import { ValueStream } from '~/shared/models/value-stream.model';
import { AssetService } from '~/shared/services/asset.service';
import { FitForRoleService } from '~/shared/services/fit-for-role.service';
import { KeyProcessService } from '~/shared/services/key-process.service';
import { NotificationService } from '~/shared/services/notification.service';
import { ProductLineService } from '~/shared/services/product-line.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { TagService } from '~/shared/services/tag.service';
import { UserService } from '~/shared/services/user.service';
import { ValueStreamsService } from '~/shared/services/value-streams.service';

@Component({
  selector: 'app-create-asset-modal',
  templateUrl: './create-asset-modal.component.html',
  styleUrls: ['./create-asset-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100, style({ opacity: 1 }))
      ]),
      transition(':leave', [animate(100, style({ opacity: 0 }))])
    ])
  ]
})
export class CreateAssetModalComponent implements OnInit {
  public editor = InlineEditor;
  public config = {
    removePlugins: [
      'Image',
      'ImageCaption',
      'ImageStyle',
      'ImageToolbar',
      'ImageUpload',
      'MediaEmbed',
      'CKFinder',
      'EasyImage'
    ],
    link: {
      addTargetToExternalLinks: true
    }
  };

  @Input()
  public storefrontName = '';

  @Output()
  public closed = new EventEmitter();

  @Output()
  public saved = new EventEmitter<Asset>();

  public submitted = false;
  public saving = false;

  public asset: Asset = new Asset();

  public logo: IImage;
  public images: IImage[] = [];
  public selectUser: User;

  public productLines$: Observable<ProductLine[]>;
  public keyProcesses$: Observable<KeyProcess[]>;
  public globalTags$: Observable<Tag[]>;
  public storefrontTags$: Observable<Tag[]>;

  public slDivisions$: Observable<FitForRoleItem[]>;
  public jobs$: Observable<FitForRoleItem[]>;
  public slBusinessLines$: Observable<FitForRoleItem[]>;
  public bfs$: Observable<FitForRoleItem[]>;
  public storefronts$: Observable<Storefront[]>;
  public valueStreams$: Observable<ValueStream[]>;

  public isCurrentUserOwner = false;

  public showAllJobTags = false;

  public regex = UtilsService.REGEX_URL_WITH_MAILTO;

  public assetForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    storefront: new FormControl(null, [Validators.required]),
    description: new FormControl('', [Validators.required]),
    moreInfo: new FormControl(''),

    url: new FormControl('', [
      Validators.required,
      Validators.pattern(this.regex)
    ]),
    usageStats: new FormControl('', [Validators.pattern(this.regex)]),
    videoUrl: new FormControl('', [Validators.pattern(this.regex)]),
    askAndLearn: new FormControl('', [Validators.pattern(this.regex)]),
    documentationLink: new FormControl('', [Validators.pattern(this.regex)]),
    requestAccessLink: new FormControl(''),
    productOwner: new FormControl(null, [Validators.required]),
    coOwners: new FormControl([]),

    keyProcesses: new FormControl(null),
    productLine: new FormControl(null),
    searchTags: new FormControl(''),
    globalTags: new FormControl(null),
    storefrontTags: new FormControl(null),

    usingCIM: new FormControl(false),
    cimName: new FormControl({ value: null, disabled: true }),
    valueStreams: new FormControl({ value: null, disabled: true }),

    slDivisions: new FormControl(null),
    jobs: new FormControl(null),
    slBusinessLines: new FormControl(null),
    bfs: new FormControl(null),

    active: new FormControl(true),
    retired: new FormControl(false),

    biReport: new FormControl(null),
    ignoreBiReport: new FormControl(false)
  });

  constructor(
    private productLineService: ProductLineService,
    private keyProcessService: KeyProcessService,
    private tagService: TagService,
    private assetService: AssetService,
    private toastr: NotificationService,
    private router: Router,
    private fitForRoleService: FitForRoleService,
    private usersService: UserService,
    private storefrontService: StorefrontService,
    private valueStreamsService: ValueStreamsService
  ) {}

  public ngOnInit(): void {
    this.usersService.getCurrentUser().then((obj) => {
      this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(
        obj.currentUser
      );
    });

    this.keyProcesses$ = this.keyProcessService.getAll();

    this.productLines$ = this.productLineService.getAll();

    this.slDivisions$ = this.fitForRoleService.getSlDivisions();
    this.jobs$ = this.getAllJobs();
    this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines();
    this.bfs$ = this.fitForRoleService.getBFs();
    this.storefronts$ = this.storefrontService.getAll();
    this.valueStreams$ = this.valueStreamsService.getAll();

    this.getTags();
    this.enableDisableFields();
  }

  private enableDisableFields() {
    this.assetForm.get('usingCIM').valueChanges.subscribe((v) => {
      if (!v) {
        this.assetForm.get('cimName').disable();
        this.assetForm.get('valueStreams').disable();
      } else {
        this.assetForm.get('cimName').enable();
        this.assetForm.get('valueStreams').enable();
      }

      this.assetForm.get('cimName').setValue('');
      this.assetForm.get('valueStreams').setValue(null);
    });
  }

  private getTags(): void {
    this.globalTags$ = this.tagService.getTags();
    this.storefrontTags$ = this.tagService.getTagsFromCurrentUser();
  }

  public onSelectLogo(event) {
    this.logo = event[0];
  }

  public onSelectImages(event) {
    this.images = this.images.concat(event);
  }

  public biReportSelected(biReport: BIReport) {
    if (biReport) {
      if (!this.assetForm.controls.url.value) {
        this.assetForm.controls.title.setValue(biReport.name);
      }

      this.assetForm.controls.url.setValue(biReport.url);
    }

    this.assetForm.controls.biReport.setValue(biReport);
  }

  public setURLAddress(url: string) {
    this.assetForm.controls.url.setValue(url);
  }

  public setIgnoreBIReport(ignoreBiReport: boolean) {
    this.assetForm.controls.ignoreBiReport.setValue(ignoreBiReport);
  }

  public removeScreenshotImage(idx) {
    this.images.splice(idx, 1);
  }

  public submit() {
    this.submitted = true;

    if (
      this.assetForm.status === 'VALID' &&
      this.assetForm.controls.requestAccessLink.value.length < 6000
    ) {
      this.saving = true;

      this.saveAssetObject();
    } else {
      if (this.assetForm.controls.requestAccessLink.value.length > 6000) {
        this.toastr.warning(
          'The field exceeds the maximum number of characters allowed.',
          'Request Access '
        );
      } else {
        this.toastr.warning(
          'Please fill in all required fields.',
          'Required field'
        );
      }

      [
        'usageStats',
        'videoUrl',
        'askAndLearn',
        'documentationLink',
        'url'
      ].forEach((url) => {
        if (
          this.assetForm.controls[url].value &&
          !this.assetForm.controls[url].valid
        ) {
          this.toastr.warning(
            'The BI Asset URL Address format must be like: "http://www.example.com".',
            'Invalid URL address'
          );

          return;
        }
      });
    }
  }

  public trimText(event) {
    event.target.value = event.target.value.trim();
  }

  private saveAssetObject() {
    this.populateFieldsToSave();

    this.assetService
      .add(this.asset, this.logo, this.images)
      .subscribe((id) => {
        this.toastr.success(
          'Your BI Asset was successfully saved',
          'Successfully saved',
          2500
        );

        this.asset.id = id;
        this.saved.emit(this.asset);

        if (this.asset.retired) this.router.navigate(['home']);
      });
  }

  private populateFieldsToSave() {
    for (const key in this.assetForm.controls) {
      if (
        this.assetForm.controls[key] &&
        key != 'coOwners' &&
        key != 'productOwner'
      ) {
        this.asset[key] = this.assetForm.controls[key].value;
      }
    }

    this.asset.productOwner = this.assetForm.controls.productOwner.value.id;
    this.asset.name = this.assetForm.controls.title.value;
    this.asset.coOwners = this.assetForm.controls.coOwners.value?.map(
      (po) => po
    );
    this.asset.tags = [
      ...(this.assetForm.controls.globalTags.value || []),
      ...(this.assetForm.controls.storefrontTags.value || [])
    ];

    const slDivisionsFormValue = this.assetForm.controls.slDivisions.value;
    const jobsFormValue = this.assetForm.controls.jobs.value;
    const slBusinessLinesFormValue =
      this.assetForm.controls.slBusinessLines.value;
    const bfsFormValue = this.assetForm.controls.bfs.value;

    this.asset.slDivisionIds = UtilsService.arrayIsNullOrEmpty(
      slDivisionsFormValue
    )
      ? []
      : slDivisionsFormValue.map((element) => element.id);

    this.asset.jobIds = UtilsService.arrayIsNullOrEmpty(jobsFormValue)
      ? []
      : jobsFormValue.map((element) => element.id);

    this.asset.slBusinessLineIds = UtilsService.arrayIsNullOrEmpty(
      slBusinessLinesFormValue
    )
      ? []
      : slBusinessLinesFormValue.map((element) => element.id);

    this.asset.bfIds = UtilsService.arrayIsNullOrEmpty(bfsFormValue)
      ? []
      : bfsFormValue.map((element) => element.id);
  }

  public cancel() {
    this.closed.emit();
  }

  public async updatedSlDivisions() {
    this.jobs$ = this.getAllJobs(
      this.assetForm.controls.slDivisions.value.map((element) => element.id)
    );
    await this.jobs$.toPromise();
    this.updatedJobs();
  }

  public updatedJobs() {
    const slDivisionsFormValue = this.assetForm.controls.slDivisions.value;
    const jobsFormValue = this.assetForm.controls.jobs.value;

    if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue)
    ) {
      this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines();
      this.updatedSlBusinessLines();
    } else if (UtilsService.arrayIsNullOrEmpty(jobsFormValue)) {
      this.jobs$.subscribe(async (result) => {
        if (UtilsService.arrayIsNullOrEmpty(result)) {
          this.slBusinessLines$ = null;
          this.updatedSlBusinessLines();
        } else {
          this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines(
            result.map((element) => element.id)
          );
          await this.slBusinessLines$.toPromise();
          this.updatedSlBusinessLines();
        }
      });
    } else {
      this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines(
        jobsFormValue.map((element) => element.id)
      );
      this.updatedSlBusinessLines();
    }
  }

  public updatedSlBusinessLines() {
    const slDivisionsFormValue = this.assetForm.controls.slDivisions.value;
    const jobsFormValue = this.assetForm.controls.jobs.value;
    const slBusinessLinesFormValue =
      this.assetForm.controls.slBusinessLines.value;

    if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue)
    ) {
      this.bfs$ = this.fitForRoleService.getBFs();
    } else if (UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue)) {
      if (this.slBusinessLines$ == null) {
        this.bfs$ = null;
      } else {
        this.slBusinessLines$.subscribe(async (result) => {
          if (UtilsService.arrayIsNullOrEmpty(result)) {
            this.bfs$ = null;
          } else {
            this.bfs$ = this.fitForRoleService.getBFs(
              result.map((element) => element.id)
            );
          }
        });
      }
    } else {
      this.bfs$ = this.fitForRoleService.getBFs(
        slBusinessLinesFormValue.map((element) => element.id)
      );
    }

    this.updatedBfs();
  }

  public updatedBfs() {
    this.controlFitForRoleAppearence();
  }

  private controlFitForRoleAppearence() {
    const slDivisionsFormValue = this.assetForm.controls.slDivisions.value;
    const jobsFormValue = this.assetForm.controls.jobs.value;
    const slBusinessLinesFormValue =
      this.assetForm.controls.slBusinessLines.value;
    const bfsFormValue = this.assetForm.controls.bfs.value;

    if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue) &&
      !UtilsService.arrayIsNullOrEmpty(bfsFormValue)
    ) {
      this.assetForm.get('slDivisions').disable();
      this.assetForm.get('jobs').disable();
      this.assetForm.get('slBusinessLines').disable();
    } else if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue) &&
      !UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue)
    ) {
      this.assetForm.get('slDivisions').disable();
      this.assetForm.get('jobs').disable();
    } else if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      !UtilsService.arrayIsNullOrEmpty(jobsFormValue)
    ) {
      this.assetForm.get('slDivisions').disable();
    } else {
      this.assetForm.get('slDivisions').enable();
      this.assetForm.get('jobs').enable();
      this.assetForm.get('slBusinessLines').enable();
    }
  }

  public searchFitForRoleField(term: string, item: FitForRoleItem) {
    const bindLabel = `${item.code} - ${item.description}`;

    return (
      item.code.toUpperCase().includes(term.toUpperCase()) ||
      item.description.toUpperCase().includes(term.toUpperCase()) ||
      bindLabel.toUpperCase().includes(term.toUpperCase())
    );
  }

  public onSelectAllSlDivisions(): void {
    this.slDivisions$.subscribe((divisions) => {
      this.assetForm.get('slDivisions').setValue(divisions);
    });
  }

  public onSelectAllJobs(): void {
    this.jobs$.subscribe((jobs) => {
      this.assetForm.get('jobs').setValue(jobs);
    });
  }

  public onSelectAllSlBusinessLines(): void {
    this.slBusinessLines$.subscribe((lines) => {
      this.assetForm.get('slBusinessLines').setValue(lines);
    });
  }

  public onSelectAllBfs(): void {
    this.bfs$.subscribe((bfs) => {
      this.assetForm.get('bfs').setValue(bfs);
    });
  }

  public compareFn(a: any, b: any) {
    return a.id === b.id;
  }

  public showAllJobTagsChanged(event) {
    this.showAllJobTags = event;
    this.jobs$ = this.getAllJobs();
  }

  private getAllJobs(slDivisionIds: Array<number> = []) {
    return this.fitForRoleService.getJobs(slDivisionIds).pipe(
      map((items) => {
        return this.showAllJobTags
          ? items
          : items.filter(
              (i) =>
                i.bucketId === null ||
                i.bucketId >= 3 || // Bucket 3 and 4 must be displayed on the list
                this.asset.jobIds?.some((r) => r === i.id)
            );
      })
    );
  }
}
