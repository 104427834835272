import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { LateralMenuService } from '~/shared/services/lateral-menu.service';
import { RequestAccessDetailsService } from '~/shared/services/request-access-description.service';

@Component({
  selector: 'app-card-asset-horizontal',
  templateUrl: './card-asset-horizontal.component.html',
  styleUrls: ['./card-asset-horizontal.component.scss']
})
export class CardAssetHorizontalComponent {
  @Input()
  public Asset: Asset;

  // Used in the user page.
  @Input()
  public isEdit = false;

  // Used in category and Storefront page creation.
  @Input()
  public isRemove = false;

  // Used to enable the option to change asset priority
  @Input()
  public isPriority = false;

  @Input()
  public logoAreaColor = '#0014DC';

  @Output()
  public clickSvg = new EventEmitter<Asset>();

  @Output()
  public clickLike = new EventEmitter();

  public targetElement: HTMLElement;

  constructor(
    private lateralMenuService: LateralMenuService,
    private router: Router,
    private requestAccessDetailsService: RequestAccessDetailsService
  ) {}

  public OpenLateralModal(asset) {
    event.stopPropagation();
    this.lateralMenuService.setAsset(this.Asset);
  }

  public openAddress(url: any, event) {
    window.open(url, '_blank');
  }

  public onClick(event) {
    event?.stopPropagation();

    if (this.isRemove) this.clickSvg.emit(this.Asset);
    else if (this.isEdit)
      this.router.navigate(['/asset', this.Asset.id, 'edit']);
  }

  public getInitialLetters(): string {
    return UtilsService.getInitialLetters(this.Asset.name);
  }

  public get showShortcuts(): boolean {
    return (
      this.Asset.url != null ||
      this.Asset.requestAccessLink != null ||
      this.Asset.documentationLink != null ||
      this.Asset.askAndLearn != null ||
      this.Asset.videoUrl != null ||
      this.Asset.usageStats != null
    );
  }

  public openRequestAccessDetails(event): void {
    event.stopPropagation();
    this.requestAccessDetailsService.setRequestAccess(
      this.Asset.requestAccessLink
    );
  }

  public get getColorWithAlpha() {
    return UtilsService.addAlpha(this.logoAreaColor, 0.75);
  }

  public emitLike() {
    this.clickLike.emit(this.Asset);
  }
}
