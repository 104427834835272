<div class="row no-gutters">
  <div class="col-sm-12 col-md-5 col-lg-3 col-xl-2 pr-3">
    <div class="filter">
      <div
        class="
                    cursor-pointer cursor-pointer
                    d-flex
                    align-items-center
                    pr-3
                    pt-3
                    pb-2
                "
        (click)="showAllAssets()">
        <div
          class="show-selected-kp mr-4"
          [ngClass]="{
                        'selected-key-process': headerTitle == 'All BI Assets'
                    }"></div>

        <span style="font-weight: 500; font-size: 13px">Show All</span>
      </div>

      <span class="filter-title">CATEGORIES</span>

      <div class="tree">
        <span *ngIf="categoriesLoaded && categories?.length == 0; else loadingCategorries" class="no-related-items d-block p-3">No categories created</span>

        <div
          *ngFor="let category of categories"
          class="tree-area d-flex flex-column py-2 pr-3"
          [ngClass]="{ open: category.ShowKeyProcessXAssetsArea }">
          <div class="d-flex align-items-center">
            <div
              class="show-selected-kp mr-1"
              [ngClass]="{
                                'selected-key-process':
                                    selectedCategory === category
                            }"
              style="width: 5px"></div>

            <i
              class="fas fa-chevron-right py-1 px-2"
              [ngClass]="{
                                'cursor-pointer':
                                    category.keyProcessXAssets?.length > 0
                            }"
              [ngStyle]="{
                                color:
                                    category.keyProcessXAssets?.length > 0
                                        ? '#2d343d'
                                        : '#c0c2c4'
                            }"
              (click)="
                                category.keyProcessXAssets?.length > 0
                                    ? (category.ShowKeyProcessXAssetsArea =
                                          !category.ShowKeyProcessXAssetsArea)
                                    : null
                            "></i>

            <span
              class="tree-title cursor-pointer"
              (click)="setSelectedCategory(category)">
              {{ category.name }}
            </span>

            <span class="tree-count ml-auto">
              {{ category.assets?.length }}
            </span>
          </div>

          <div
            [@inOutAnimation]
            *ngIf="category.ShowKeyProcessXAssetsArea"
            class="key-process-area d-flex flex-column">
            <div
              *ngFor="let kpa of category.keyProcessXAssets"
              (click)="setSelectedKeyProcess(category, kpa)">
              <div
                *ngIf="
                                    kpa.keyProcessName != 'AllCategoryBIAssets'
                                "
                class="
                                    cursor-pointer
                                    d-flex
                                    align-items-center
                                    pt-3
                                ">
                <div
                  class="show-selected-kp mr-4"
                  [ngClass]="{
                                        'selected-key-process':
                                            selectedKeyProcessXAssets === kpa
                                    }"></div>

                <span class="key-process-title pl-3 pr-1">
                  {{ kpa.keyProcessName }}
                </span>

                <span class="tree-count ml-auto">
                  {{ kpa.assets?.length }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span class="filter-title pt-3">TAGS</span>

      <span
        *ngIf="tags?.length == 0"
        class="no-related-items d-block p-3">
        No related tags</span>

      <div
        *ngFor="let tag of tags"
        class="tags d-flex align-items-center px-3">
        <label class="check-box-container d-flex py-2 px-3">{{ tag.name }}
          <input
            type="checkbox"
            [checked]="tag.SelectedOnFilter"
            (change)="setSeletedTag($event, tag)" />
          <span class="checkmark"></span>
        </label>

        <span class="tag-count ml-auto"> {{ tag.usage }} </span>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-7 col-lg-9 col-xl-10 px-2 tabs-area">
    <mat-tab-group
      *ngIf="!isLoadingRequest.assets &&
            !isLoadingRequest.recommendedAssets;
        else loadingAnimation"
      [@.disabled]="true" disableRipple
      [selectedIndex]="defaultTab"
      class="storefront-page">

      <mat-tab
        label="Favourites ({{ likedAssets?.length }})">
        <ng-container>
          <app-board-assets
            (clickLike)="onClickLikeButton()"
            [assetCardSize]="'col-sm-12 col-md-12 col-lg-6 col-xl-4'"
            [Assets]="likedAssets"
            [logoAreaColor]="storefront.color"
            [showTitle]="false">
          </app-board-assets>
        </ng-container>
      </mat-tab>

      <mat-tab
        label="Recommended ({{ recommendedAssets?.length }})">
        <ng-container>
          <app-board-assets
            (clickLike)="onClickLikeButton()"
            [assetCardSize]="'col-sm-12 col-md-12 col-lg-6 col-xl-4'"
            [Assets]="recommendedAssets"
            [logoAreaColor]="storefront.color"
            [showTitle]="false">
          </app-board-assets>
        </ng-container>
      </mat-tab>

      <mat-tab label="All ({{ assets?.length }})">
        <ng-container>
          <app-board-assets
            (clickLike)="onClickLikeButton()"
            [assetCardSize]="'col-sm-12 col-md-12 col-lg-6 col-xl-4'"
            [Assets]="assets"
            [logoAreaColor]="storefront.color"
            [showTitle]="false">
          </app-board-assets>
        </ng-container>
      </mat-tab>

      <mat-tab disabled>
        <ng-template mat-tab-label>
          <app-bar-sort-by (sortByChanged)="toggleSort($event)">
          </app-bar-sort-by>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #loadingAnimation>
  <div class="d-flex justify-content-center align-items-center w-100 pt-5">
    <app-loader [height]="32"></app-loader>
  </div>
</ng-template>

<ng-template #loadingCategorries>
  <div *ngIf="!categoriesLoaded" class="d-flex justify-content-center align-items-center w-100 pt-3">
    <app-loader [height]="16"></app-loader>
  </div>
</ng-template>