import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '~/shared/shared.module';
import { BoardAppearsOnComponent } from './components/board-appears-on/board-appears-on.component';
import { BoardRelatedAssetsComponent } from './components/board-related-assets/board-related-assets.component';
import { BoardReviewsComponent } from './components/board-reviews/board-reviews.component';
import { CardReviewComponent } from './components/card-review/card-review.component';
import { FixAssetMaintenanceModalComponent } from './components/fix-asset-maintenance-modal/fix-asset-maintenance-modal.component';
import { ModalCimApprovalComponent } from './components/modal-cim-approval/modal-cim-approval.component';
import { ModalCreateBulkAssetsComponent } from './components/modal-create-bulk-assets/modal-create-bulk-assets.component';
import { NewReviewComponent } from './components/new-review/new-review.component';
import { AssetDetailsComponent } from './pages/asset-details/asset-details.component';
import { AssetsMaintenanceComponent } from './pages/assets-maintenance/assets-maintenance.component';
import { NewAssetComponent } from './pages/new-asset/new-asset.component';

const MODULE_COMPONENTS = [
  AssetDetailsComponent,
  BoardAppearsOnComponent,
  BoardReviewsComponent,
  CardReviewComponent,
  BoardRelatedAssetsComponent,
  NewAssetComponent,
  NewReviewComponent,
  ModalCreateBulkAssetsComponent,
  AssetsMaintenanceComponent,
  FixAssetMaintenanceModalComponent,
  ModalCimApprovalComponent
];

const MODULE_MODULES = [CommonModule, SharedModule, ClipboardModule];

@NgModule({
  declarations: [...MODULE_COMPONENTS],
  imports: [...MODULE_MODULES, MatFormFieldModule, MatInputModule]
})
export class AssetModule {}
