<app-board-header
  [Title]="'Tags'"
  [marginTop]="'mt-0'">
</app-board-header>

<div class="row no-gutters p-2 mb-3" *ngIf="tags.length > 0; else loading">

  <div *ngFor="let tag of tags" class="col-sm-6 col-md-4 col-lg-3 col-xl-2 d-flex py-1">

    <div [ngClass]="{'full-size': fullSize}" class="tag cursor-pointer d-flex align-items-center" (click)="setSeletedTag(tag)"
      [attr.title]="tag.storefront ? tag.storefront.name : 'Global Tag'">

      <div class="tag-color" [ngStyle]="{'background-color': tag.color}">

      </div>

      <span class="title pl-2">{{tag.name}}</span>

      <i *ngIf="tag.SelectedOnFilter" class="fas fa-check ml-1 tag-selected"></i>

    </div>

  </div>

</div>

<ng-template #loading>

  <div class="row no-gutters justify-content-center w-100 p-2">

    <span>No Tags to show</span>

  </div>

</ng-template>