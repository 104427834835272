<div [@inOutAnimation] *ngIf="storefront?.customMessage && showCustomMessage"
  class="custom-message-area w-100" [ngStyle]="{'background-color': storefront.color}">

  <div class="container p-0 px-1 py-2 ">

    <div class="row no-gutters d-flex align-items-center">

      <span class="custom-message pr-1"> {{storefront.customMessage}} </span>

      <i (click)="showCustomMessage = false" class="fas fa-times ml-auto pl-2 py-1 cursor-pointer"></i>

    </div>

  </div>

</div>

<div class="container pb-4">

  <div class="row" *ngIf="storefront; else loading">

    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">

      <div class="col-md-12 py-4 d-flex justify-content-between no-gutters px-0 pr-1">
        <div class="d-flex flex-column pr-3 w-70">
          <span class="title ">{{storefront.pageTitle ? storefront.pageTitle : 'BI Assets and Categories'}}</span>
          <span class="description ">{{storefront.description}}</span>
        </div>

        <div class="select-view p-0 d-flex justify-content-end">

          <i class="fas fa-th-large change-view-btn-detailed py-1 px-2 cursor-pointer d-flex align-items-center"
            [ngClass]="{'change-view-btn-selected': defaultView === StorefrontPageViewType.Detailed}"
            (click)="setViewType(StorefrontPageViewType.Detailed)"
            [attr.title]="'Detailed View'"></i>

          <i class="fas fa-columns change-view-btn-simple py-1 px-2 cursor-pointer d-flex align-items-center"
            [ngClass]="{'change-view-btn-selected': defaultView === StorefrontPageViewType.Subcategory}"
            (click)="setViewType(StorefrontPageViewType.Subcategory)"
            [attr.title]="'Subcategory View'"></i>

          <i class="fas fa-table change-view-btn-category py-1 px-2 cursor-pointer d-flex align-items-center"
            [ngClass]="{'change-view-btn-selected': defaultView === StorefrontPageViewType.Category}"
            (click)="setViewType(StorefrontPageViewType.Category)"
            [attr.title]="'Category View'"></i>

        </div>

      </div>

      <app-filter-board
        *ngIf="storefront && defaultView === StorefrontPageViewType.Detailed"
        [storefront]="storefront">
      </app-filter-board>

      <app-board-category-assets
        *ngIf="storefront && defaultView != StorefrontPageViewType.Detailed"
        [pageViewType]="defaultView"
        [storefront]="storefront">
      </app-board-category-assets>

    </div>

  </div>

</div>

<app-feedback-window *ngIf="storefront" [email]="storefront?.owner?.email" [storeName]="this.storefront.name"></app-feedback-window>

<ng-template #loading>
  <div class="d-flex justify-content-center w-100 p-5 mt-5">
    <app-loader [height]="50"></app-loader>
  </div>
</ng-template>

<app-card-help-links
  [helpLinks]="storefront?.helpLinks">
</app-card-help-links>