<div class="card-category card">

    <div class="header p-2" [ngStyle]="{'background-color': color}">

        <div class="row no-gutters d-flex align-items-center">

            <span class="name"> All Other BI Assets </span>

            <span class="assets-count ml-2">({{assetsToShow.length}})</span>

        </div>

    </div>

    <div class="body p-2 row no-gutters">

        <span class="no-info px-1" *ngIf="assetsToShow.length === 0" style="margin:auto; display:table;">No Assets related</span>

        <app-asset-category-label class="w-100"
            *ngFor="let asset of assetsToShow | slice:0:(showAll ? assetsToShow.length : MaxAssetsToShow)"
            [asset]="asset">
        </app-asset-category-label>

    </div>

    <div class="show-more-div d-flex justify-content-center">

        <span class="cursor-pointer show-more p-1 px-3 mb-2"
            *ngIf="!showAll && MaxAssetsToShow < assetsToShow?.length" (click)="showAll = true">
            + {{assetsToShow?.length - MaxAssetsToShow}} More
        </span>
    </div>

</div>
