import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { KeyProcess } from '~/shared/models/keyProcess.model';
import { ProductLine } from '~/shared/models/productLine';
import { Tag } from '~/shared/models/tag.model';
import { KeyProcessService } from '~/shared/services/key-process.service';
import { NotificationService } from '~/shared/services/notification.service';
import { ProductLineService } from '~/shared/services/product-line.service';
import { TagService } from '~/shared/services/tag.service';

@Component({
  selector: 'app-simple-data-manager',
  templateUrl: './simple-data-manager.component.html',
  styleUrls: ['./simple-data-manager.component.scss']
})
export class SimpleDataManagerComponent implements OnInit, AfterViewInit {
  @ViewChild('tagsPaginator') public tagsPaginator: MatPaginator;
  @ViewChild('tagSort') public tagsSort: MatSort;

  @ViewChild('keyProcessesPaginator') public keyProcessePaginator: MatPaginator;
  @ViewChild('kpSort') public keyProcesseSort: MatSort;

  @ViewChild('plBlPaginator') public plBlPaginator: MatPaginator;
  @ViewChild('plBlSort') public plBlSort: MatSort;

  public tagsDataSource = new MatTableDataSource<Tag>([]);
  public keyProcessesDataSource = new MatTableDataSource<KeyProcess>([]);
  public plBlDataSource = new MatTableDataSource<ProductLine>([]);

  public selectedTag = new Tag();
  public selectedKeyProcess = new KeyProcess();
  public selectedPlBl = new ProductLine();

  public showTagModal = false;
  public showKeyProcessModal = false;
  public showPlBlModal = false;

  public displayedTagsColumns: string[] = ['name', 'color', 'edit'];
  public displayedKpColumns: string[] = ['name', 'edit'];
  public displayedPlBlColumns: string[] = ['name', 'edit'];

  public tags: Array<Tag> = [];
  public keyProcesses: Array<KeyProcess> = [];
  public PlBls: Array<ProductLine> = [];

  constructor(
    private tagService: TagService,
    private keyProcessService: KeyProcessService,
    private plBlService: ProductLineService,
    private toastr: NotificationService
  ) {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this.fetchTags();
    this.fetchKeyProcess();
    this.fetchPlBl();
  }

  private fetchTags(): void {
    this.tagService
      .getTags()
      .pipe(take(1))
      .subscribe((tags) => {
        this.tags = tags;
        this.populateTagsList();
      });
  }

  private fetchKeyProcess(): void {
    this.keyProcessService
      .getAll()
      .pipe(take(1))
      .subscribe((kp) => {
        this.keyProcesses = kp;
        this.populatekeyProcessesList();
      });
  }

  private fetchPlBl(): void {
    this.plBlService
      .getAll()
      .pipe(take(1))
      .subscribe((plbl) => {
        this.PlBls = plbl;
        this.populatePlBlProcessesList();
      });
  }

  private populateTagsList(): void {
    this.tagsDataSource.data = this.tags;
    this.tagsDataSource.sort = this.tagsSort;
    this.tagsDataSource.paginator = this.tagsPaginator;
  }

  private populatekeyProcessesList(): void {
    this.keyProcessesDataSource.data = this.keyProcesses;
    this.keyProcessesDataSource.sort = this.keyProcesseSort;
    this.keyProcessesDataSource.paginator = this.keyProcessePaginator;
  }

  private populatePlBlProcessesList(): void {
    this.plBlDataSource.data = this.PlBls;
    this.plBlDataSource.sort = this.plBlSort;
    this.plBlDataSource.paginator = this.plBlPaginator;
  }

  public selectTagRow(row): void {
    this.selectedTag = row;
  }

  public selectKpRow(row): void {
    this.selectedKeyProcess = row;
  }

  public selectPlBlRow(row): void {
    this.selectedPlBl = row;
  }

  private showToastConfirmation(): void {
    this.toastr.success('', 'Saved', 2500);
  }

  private showToastDeleteConfirmation(): void {
    this.toastr.success('', 'Deleted', 2500);
  }

  public createNewTag(): void {
    this.selectedTag = new Tag();
    this.showTagModal = true;
  }

  public editTag(): void {
    this.showTagModal = true;
  }

  public createNewKeyProcess(): void {
    this.selectedKeyProcess = new KeyProcess();
    this.showKeyProcessModal = true;
  }

  public editKeyProcess(): void {
    this.showKeyProcessModal = true;
  }

  public createNewPlBl(): void {
    this.selectedPlBl = new ProductLine();
    this.showPlBlModal = true;
  }

  public editPlBl(): void {
    this.showPlBlModal = true;
  }

  public modalClosed(): void {
    this.showTagModal = false;
    this.showKeyProcessModal = false;
    this.showPlBlModal = false;
  }

  public tagSaved(tag: Tag): void {
    const t = this.tags.filter((t) => t.id == tag.id)[0];
    const index = this.tags.indexOf(t);

    if (t) {
      this.tags[index] = tag;
    } else {
      this.tags.unshift(tag);
    }

    this.populateTagsList();
    this.showToastConfirmation();
  }

  public tagDeleted(tag: any): void {
    const t = this.tags.filter((t) => t.id == tag.id)[0];
    const index = this.tags.indexOf(t);

    this.tags.splice(index, 1);

    this.populateTagsList();
    this.showToastDeleteConfirmation();
  }

  public keyProcessSaved(kp: KeyProcess): void {
    const t = this.keyProcesses.filter((t) => t.id == kp.id)[0];
    const index = this.keyProcesses.indexOf(t);

    if (t) {
      this.keyProcesses[index] = kp;
    } else {
      this.keyProcesses.unshift(kp);
    }

    this.populatekeyProcessesList();
    this.showToastConfirmation();
  }

  public plBlSaved(plBl: ProductLine): void {
    const t = this.PlBls.filter((t) => t.id == plBl.id)[0];
    const index = this.PlBls.indexOf(t);

    if (t) {
      this.PlBls[index] = plBl;
    } else {
      this.PlBls.unshift(plBl);
    }

    this.populatePlBlProcessesList();
    this.showToastConfirmation();
  }

  public applyTagFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tagsDataSource.filter = filterValue.trim().toLowerCase();
  }

  public applyKPFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.keyProcessesDataSource.filter = filterValue.trim().toLowerCase();
  }

  public applyPLBLfilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.plBlDataSource.filter = filterValue.trim().toLowerCase();
  }
}
