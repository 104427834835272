import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiServiceBase } from './api-service-base.service';
import { User } from '../models/user.model';
import { Observable, of } from 'rxjs';
import { Email } from '../models/email.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmailService extends ApiServiceBase<void> {
    constructor(protected http: HttpClient) {
        super(http, 'email');
    }

    public sendEmail(email: Email): Observable<void> {
        return this.post(email).pipe(map(() => undefined));
    }
}
