import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { RoleTypes } from '~/shared/enums/role-type';
import { Storefront } from '~/shared/models/storefront.model';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-card-storefront-simple',
    templateUrl: './card-storefront-simple.component.html',
    styleUrls: ['./card-storefront-simple.component.scss']
})
export class CardStorefrontSimpleComponent implements OnInit {
    @Input()
    public Store: Storefront;

    @Input()
    public iconToShow: string;

    constructor(
        private router: Router,
        private usersService: UserService) {}

    public ngOnInit(): void {
        this.usersService.getCurrentUser().then((obj) => {
            let user = obj.currentUser;

            this.Store['canCurrentUserEdit'] =
                user.roles.some((role) => role.name == RoleTypes.Owner || role.name == RoleTypes.Member);
        });

        switch (this.iconToShow) {
            case 'edit':
                this.iconToShow = 'edit';
                break;
            default:
                this.iconToShow = 'verified';
        }
    }

    public getInitialLetters(): string {
        return UtilsService.getInitialLetters(this.Store.name);
    }

    public navigateToCardDetails() {
        this.router.navigateByUrl(`/store/${this.Store.id}`);
    }

    public onEdit() {
        if (this.Store['canCurrentUserEdit'])
            this.router.navigate(['/store', this.Store.id, 'edit']);
    }
}
