import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { SimpleCategoryCardComponent } from './components/simple-category-card/simple-category-card.component';
import { UserManagementComponent } from './pages/user-management-component/user-management/user-management.component';
import { UserProfileComponent } from './pages/user-profile-component/user-profile/user-profile.component';

const MODULE_COMPONENTS = [
    UserProfileComponent,
    ProfileCardComponent,
    SimpleCategoryCardComponent,
    UserManagementComponent
];

const MODULE_MODULES = [CommonModule, SharedModule];

@NgModule({
    declarations: [...MODULE_COMPONENTS],
    imports: [...MODULE_MODULES],
    exports: [...MODULE_COMPONENTS]
})
export class UserModule { }
