import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs/internal/Observable';
import { Asset } from '../models/asset.model';
import { Tag } from '../models/tag.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
  providedIn: 'root'
})
export class TagService extends ApiServiceBase<Tag> {
  constructor(protected http: HttpClient) {
    super(http, 'tags');
  }

  public getTags(limit: number = 0): Observable<Array<Tag>> {
    return this.get(limit > 0 ? limit.toString() : '');
  }

  public getTagsFromCurrentUser(): Observable<Tag[]> {
    return this.get('get-all-from-user');
  }

  public getAllTagsFromStore(storeId: number): Observable<Array<Tag>> {
    return this.get('store-front/' + storeId.toString());
  }

  public getTagsByFilter(filter: string): Observable<Tag[]> {
    return filter
      ? this.http.post<Tag[]>(
          `${environment.apiUrl}/tags/filter`,
          { filter },
          this.HEADERS
        )
      : this.getTags();
  }

  public getAllByUser(filter: string): Observable<Array<Tag>> {
    return this.get(`all-by-user/${filter}`);
  }

  public add(tag): Observable<number> {
    return this.post(tag);
  }

  public update(tag): Observable<number> {
    return this.put(tag);
  }

  public deleteTag(tag): Observable<boolean> {
    return this.delete<boolean>(`${tag.id}/`);
  }

  public countTagsReferenceFromAssetsArray(assets: Array<Asset>): Array<Tag> {
    const tagsBeinUsed = [];
    assets.forEach((asset) => {
      asset.tags.forEach((tag) => {
        tagsBeinUsed.push(tag);
      });
    });

    const uniqueTags = [];
    tagsBeinUsed.forEach((tag) => {
      const i = uniqueTags.findIndex((x) => x.id === tag.id);
      if (i <= -1) {
        tag.usage = 0;
        uniqueTags.push(tag);
      }
    });

    uniqueTags.forEach((tag) => {
      return assets.forEach((asset) => {
        if (asset.tags.filter((t) => t.id === tag.id).length > 0) {
          tag.usage += 1;
        }
      });
    });

    return this.sortArrayByName(uniqueTags);
  }

  public sortArrayByName(tags: Array<Tag>): Array<Tag> {
    return tags.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public filterTagsByStore(tags: Array<Tag>, storefrontId: number): Array<Tag> {
    return tags.filter(
      (tag) => !tag.storefront || tag.storefront.id === storefrontId
    );
  }
}
