import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';
import { AssetMaintenanceEnum } from '~/core/enums/test.enum';
import { Asset } from '~/shared/models/asset.model';
import { BIReport } from '~/shared/models/bi-report.model';
import { ProductLine } from '~/shared/models/productLine';
import { AssetService } from '~/shared/services/asset.service';
import { BIReportService } from '~/shared/services/bi-report.service';
import { NotificationService } from '~/shared/services/notification.service';

@Component({
  selector: 'app-fix-asset-maintenance-modal',
  templateUrl: './fix-asset-maintenance-modal.component.html',
  styleUrls: ['./fix-asset-maintenance-modal.component.scss']
})
export class FixAssetMaintenanceModalComponent implements OnInit {
  @ViewChild('template', { static: true }) public elementView: ElementRef;

  @Output()
  public close = new EventEmitter();

  @Output()
  public assetSaved = new EventEmitter<ProductLine>();

  @Input()
  public asset = new Asset();

  public biReport: BIReport;
  public duplicatedAssets: Array<Asset>;

  @Output() public saved = new EventEmitter<boolean>();

  private config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'center-modal modal-dialog modal-dialog-centered modal-lg'
  };

  @Input() public settings = [];

  public modalRef: BsModalRef;

  public modalTitle: string;

  constructor(
    private modalService: BsModalService,
    private biReportService: BIReportService,
    private assetService: AssetService,
    private toastr: NotificationService
  ) {}

  public ngOnInit(): void {
    this.modalRef = this.modalService.show(this.elementView, this.config);
    this.asset = JSON.parse(JSON.stringify(this.asset));

    if (this.asset.biReport?.id) {
      this.biReportService
        .getAssetById(this.asset.biReport.id)
        .pipe(take(1))
        .subscribe((biReport) => {
          this.biReport = biReport;
        });
    } else {
    }

    this.assetService
      .getByBIReport(this.asset.biReport?.id)
      .pipe(take(1))
      .subscribe((assets) => {
        const fromIndex = assets.findIndex((a) => a.id === this.asset.id);
        const element = assets[fromIndex];
        assets.splice(fromIndex, 1);
        assets.splice(0, 0, element);

        this.duplicatedAssets = assets;
      });
  }

  public get showAssetDiff() {
    if (
      !this.biReport ||
      this.asset.status === AssetMaintenanceEnum.duplicated
    ) {
      return false;
    } else if (
      (this.asset.status === AssetMaintenanceEnum.assign && this.biReport) ||
      this.asset.status === AssetMaintenanceEnum.update
    ) {
      return true;
    } else {
      return false;
    }
  }

  public biReportSelected(biReport: BIReport) {
    this.biReport = biReport;
  }

  public save() {
    if (this.asset.status === AssetMaintenanceEnum.duplicated) {
      // const subscribes = this.duplicatedAssets.map((asset) => {
      //   if (asset.id !== this.duplicatedAssetSelected.id) {
      //     asset.biReport = null;

      //     return this.assetService.simpleUpdate(asset);
      //   }
      // });

      // forkJoin(subscribes).subscribe(
      //   () => {
      //     this.saved.emit(true);
      //     this.closeModal();
      //   },
      //   (e) => {}
      // );

      this.saved.emit(true);
      this.closeModal();
    } else {
      this.asset.biReport = this.biReport;
      this.assetService
        .simpleUpdate(this.asset)
        .pipe(take(1))
        .subscribe(() => {
          this.saved.emit(true);
          this.closeModal();
        });
    }
  }

  public noCLick() {
    this.closeModal();
  }

  private closeModal() {
    this.close.emit();
    this.modalRef.hide();
  }

  public get ckeckDisabled(): boolean {
    return false;
  }

  public fixName() {
    this.asset.name = this.biReport.name;
  }

  public fixUrl() {
    this.asset.url = this.biReport.url;
  }

  public getStatusClass(): string {
    if (this.asset.url !== this.biReport.url) {
      return 'red';
    } else if (this.asset.name !== this.biReport.name) {
      return 'yellow';
    } else {
      return 'green';
    }
  }

  public openEmail(asset: Asset) {
    let ownersEmail = this.duplicatedAssets.map((a) => {
      return a.productOwner.email;
    });

    ownersEmail = ownersEmail.filter((el, i, a) => i === a.indexOf(el));

    let coOwnersEmail = this.duplicatedAssets.map((a) => {
      return a.coOwners.map((u) => {
        return u.email;
      });
    });

    coOwnersEmail = coOwnersEmail.filter((el, i, a) => i === a.indexOf(el));

    const body = encodeURIComponent(
      `Power BI Report: ${this.biReport.name}
Id: ${this.biReport.code}
Workspace: ${this.biReport.biWorkspace.name}
${this.duplicatedAssets
  .map((a) => {
    return (
      '\n\nBI Asset Name: ' +
      a.name +
      '\nProduct Owner: ' +
      a.productOwner.name +
      '\nStorefront: ' +
      a.storefront?.name +
      '\nMore Details: https://biportfolio.data.slb.com/asset/' +
      a.id +
      '/details'
    );
  })
  .join('')}


  `
    );

    const email = `mailto:${ownersEmail}?cc=${coOwnersEmail}&subject=${encodeURIComponent(
      'BI Asset: ' + asset.name
    )}&body=${body}`;

    window.location.href = email;
  }
}
