import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { HelpLink } from '~/shared/models/help-link.model';
import { ScrollService } from '~/shared/services/scroll.service';

@Component({
  selector: 'app-card-help-links',
  templateUrl: './card-help-links.component.html',
  styleUrls: ['./card-help-links.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('200ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class CardHelpLinksComponent implements OnInit {
  @Input()
  public helpLinks: Array<HelpLink> = [];

  public showHelp = false;

  public footerMargin = 0;

  constructor(scrollService: ScrollService) {
    scrollService.footerMargin$.subscribe((value) => {
      this.footerMargin = value + 1;
    });
  }

  public ngOnInit(): void {}
}
