import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-brand',
    templateUrl: './brand.component.html',
    styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
    @Input()
    public logoRightWidth = 90;

    @Input()
    public logoRightHeight = 40;

    @Input()
    public logoLeftWidth = 120;

    @Input()
    public logoLeftHeight = 50;

    constructor() {}

    public ngOnInit() {}
}
