import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { FilterType } from '../enums/filter-type';
import { FilterOptions } from '../models/filter-options.model';
import { Storefront } from '../models/storefront.model';
import { Tag } from '../models/tag.model';
import { AssetService } from './asset.service';
import { CategoryService } from './category.service';
import { HeaderService } from './header.service';
import { StorefrontService } from './storefront.service';
import { TagService } from './tag.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class FilterService {
  private filterOptions = new FilterOptions();
  public filterOptions$ = new BehaviorSubject<FilterOptions>(
    this.filterOptions
  );

  constructor(
    private assetService: AssetService,
    private storeService: StorefrontService,
    private usersService: UserService,
    private tagService: TagService,
    private router: Router,
    private headerService: HeaderService,
    private categoryService: CategoryService
  ) {}

  public ShowAllGlobal(textToSearch: string) {
    this.filterOptions$.next(null);

    const filterOptions = new FilterOptions();
    filterOptions.filterType = FilterType.GLOBAL;
    filterOptions.textToSearch = textToSearch;
    filterOptions.title = 'Search results for ' + `"${textToSearch}"`;
    filterOptions.boardStoresTitle = 'Stores';
    filterOptions.boardAssetsTitle = 'BI Assets';
    filterOptions.boardTagsTitle = 'Tags';

    this.getItemsToGlobalSearch(filterOptions);

    this.headerService.SetDefaultHome();
    this.redirect();
  }

  private getItemsToGlobalSearch(filterOptions: FilterOptions): void {
    forkJoin([
      this.storeService.getStoreByFilter(filterOptions.textToSearch),
      this.assetService.getAssetsByFilter(filterOptions.textToSearch),
      this.tagService.getTagsByFilter(filterOptions.textToSearch)
    ])
      .pipe(take(1))
      .subscribe(([stores, assets, tags]) => {
        filterOptions.setStores(stores);

        assets.forEach((asst) => {
          asst.tags = this.tagService.filterTagsByStore(asst.tags, null);
        });

        filterOptions.setAssets(assets);
        filterOptions.setTags(tags);

        this.filterOptions$.next(filterOptions);
      });
  }

  public ShowAllRelatedToFrontStore(store: Storefront, textToSearch: string) {
    this.filterOptions$.next(null);

    const filterOptions = new FilterOptions();
    filterOptions.filterType = FilterType.ALL_FROM_STORE;
    filterOptions.textToSearch = textToSearch;

    filterOptions.title =
      'Search results for ' + `"${textToSearch}"` + ' in ' + `"${store.name}"`;

    filterOptions.boardStoresTitle = 'Stores';
    filterOptions.boardAssetsTitle = 'BI Assets';
    filterOptions.boardTagsTitle = 'Tags';

    this.getItemsRelatedFrontStore(store, filterOptions);
    this.redirect();
  }

  private getItemsRelatedFrontStore(
    store: Storefront,
    filterOptions: FilterOptions
  ): void {
    forkJoin([
      this.assetService.getAllAssetsFromStore(store.id),
      this.tagService.getAllTagsFromStore(store.id),
      this.categoryService.getAllByStorefront(store.id)
    ])
      .pipe(take(1))
      .subscribe(([assets, tags, categories]) => {
        let assetsToShow = assets.filter(
          (a) =>
            a.name
              .toLocaleLowerCase()
              .includes(filterOptions.textToSearch.toLocaleLowerCase()) ||
            a.searchTags
              ?.toLocaleLowerCase()
              .includes(filterOptions.textToSearch.toLocaleLowerCase())
        );

        categories.forEach((cat) => {
          const assetsToShowFromCategory = cat.assets.filter(
            (a) =>
              a.name
                .toLocaleLowerCase()
                .includes(filterOptions.textToSearch.toLocaleLowerCase()) ||
              a.searchTags
                ?.toLocaleLowerCase()
                .includes(filterOptions.textToSearch.toLocaleLowerCase())
          );

          if (assetsToShowFromCategory.length > 0)
            assetsToShow = assetsToShow.concat(assetsToShowFromCategory);
        });

        // remove duplicated itens from the list. The same asset can be used in more than one category + storefront.
        assetsToShow = assetsToShow.filter(
          (v, i) => assetsToShow.findIndex((item) => item.id === v.id) === i
        );

        assetsToShow.forEach((asst) => {
          asst.tags = this.tagService.filterTagsByStore(asst.tags, store.id);
        });

        filterOptions.setAssets(assetsToShow);

        filterOptions.setTags(
          tags.filter((t) =>
            t.name
              .toLocaleLowerCase()
              .includes(filterOptions.textToSearch.toLocaleLowerCase())
          )
        );

        categories.forEach((category) => {
          let found = false;
          category.assets.forEach((asst) => {
            if (
              filterOptions.assetsToShow.filter((a) => a.id === asst.id)
                .length > 0
            ) {
              found = true;
            }
          });

          if (found) filterOptions.assetsCategoryToShow.push(category);
        });

        this.filterOptions$.next(filterOptions);
      });
  }

  public ShowAllStores() {
    this.filterOptions$.next(null);

    const filterOptions = new FilterOptions();
    filterOptions.boardStoresTitle = 'ALL STOREFRONTS';
    filterOptions.boardStoresTitleSmall = false;
    filterOptions.filterType = FilterType.ALL_STOREFRONTS;

    this.getAllStores(filterOptions);
    this.headerService.SetDefaultHome();
    this.redirect();
  }

  private getAllStores(filterOptions: FilterOptions) {
    this.storeService
      .getStoresByOrderIndex()
      .pipe(take(1))
      .subscribe((stores) => {
        stores.forEach((store) => {
          if (store.owner?.email) {
            this.usersService
              .getUserPhoto(store.owner.email)
              .subscribe((photo) => {
                if (photo)
                  UtilsService.isValidImage((validImage) => {
                    store.owner.image = validImage
                      ? photo
                      : UtilsService.USER_PHOTO_PLACEHOLDER;

                    filterOptions.setStores(stores);
                    this.filterOptions$.next(filterOptions);
                  }, photo);
              });
          }
        });

        filterOptions.setStores(stores);

        this.filterOptions$.next(filterOptions);
      });
  }

  public ShowAllVerifiedStores() {
    this.filterOptions$.next(null);

    const filterOptions = new FilterOptions();
    filterOptions.filterType = FilterType.VERIFIED_STOREFRONTS;

    filterOptions.boardStoresTitle = 'All Verified Storefronts';
    filterOptions.boardStoresTitleSmall = false;

    this.getAllVerifiedStores(filterOptions);
    this.headerService.SetDefaultHome();
    this.redirect();
  }

  private getAllVerifiedStores(filterOptions: FilterOptions) {
    this.storeService
      .getVerified()
      .pipe(take(1))
      .subscribe((stores) => {
        stores.forEach((store) => {
          if (store.owner?.email) {
            this.usersService
              .getUserPhoto(store.owner.email)
              .subscribe((photo) => {
                if (photo)
                  UtilsService.isValidImage((validImage) => {
                    store.owner.image = validImage
                      ? photo
                      : UtilsService.USER_PHOTO_PLACEHOLDER;

                    filterOptions.setStores(stores);
                    this.filterOptions$.next(filterOptions);
                  }, photo);
              });
          }
        });

        filterOptions.setStores(stores);

        this.filterOptions$.next(filterOptions);
      });
  }

  public ShowAllTags() {
    this.filterOptions$.next(null);

    const filterOptions = new FilterOptions();

    filterOptions.filterType = FilterType.ALL_TAGS;
    filterOptions.boardTagsTitle = 'ALL TAGS';
    filterOptions.boardTagsTitleSmall = false;

    this.getAllTags(filterOptions);
    this.headerService.SetDefaultHome();
    this.redirect();
  }

  private getAllTags(filterOptions: FilterOptions) {
    this.tagService
      .getTags()
      .pipe(take(1))
      .subscribe((tags) => {
        filterOptions.setTags(tags);
        this.filterOptions$.next(filterOptions);
      });
  }

  public ShowAllAssets() {
    this.filterOptions$.next(null);

    const filterOptions = new FilterOptions();

    filterOptions.boardAssetsTitle = 'ALL BI ASSETS';
    filterOptions.boardAssetsTitleSmall = false;

    this.getAllDigitalAssets(filterOptions);
    this.headerService.SetDefaultHome();
    this.redirect();
  }

  private getAllDigitalAssets(filterOptions: FilterOptions) {
    this.assetService
      .getAll()
      .pipe(take(1))
      .subscribe((dr) => {
        dr.forEach((asset) => {
          if (asset.productOwner?.email) {
            this.usersService
              .getUserPhoto(asset.productOwner.email)
              .subscribe((photo) => {
                if (photo)
                  UtilsService.isValidImage((validImage) => {
                    asset.productOwner.image = validImage
                      ? photo
                      : UtilsService.USER_PHOTO_PLACEHOLDER;
                  }, photo);
              });
          }
        });

        filterOptions.setAssets(dr);
        this.filterOptions$.next(filterOptions);
      });
  }

  public ShowAssetsRelatedToTag(tag: Tag) {
    this.filterOptions$.next(null);

    const filterOptions = new FilterOptions();
    filterOptions.title = tag.name;
    filterOptions.boardAssetsTitle = 'Related BI Assets';
    filterOptions.boardTagsTitleSmall = false;

    this.getAssetsByTag(tag.id, filterOptions);
    this.headerService.SetDefaultHome();
    this.redirect();
  }

  private getAssetsByTag(tagId: number, filterOptions: FilterOptions) {
    this.assetService
      .getAssetsByTagId(tagId)
      .pipe(take(1))
      .subscribe((dr) => {
        dr.forEach((asset) => {
          if (asset.productOwner?.email) {
            this.usersService
              .getUserPhoto(asset.productOwner.email)
              .subscribe((photo) => {
                if (photo)
                  UtilsService.isValidImage((validImage) => {
                    asset.productOwner.image = validImage
                      ? photo
                      : UtilsService.USER_PHOTO_PLACEHOLDER;
                  }, photo);
              });
          }
        });

        filterOptions.setAssets(dr);
        this.filterOptions$.next(filterOptions);
      });
  }

  private redirect() {
    this.router.navigate(['/search']);
  }
}
