import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { FitForRoleItem } from '~/shared/models/fit-for-role-item.model';
import { Group } from '~/shared/models/group.model';
import { KeyProcess } from '~/shared/models/keyProcess.model';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { FitForRoleService } from '~/shared/services/fit-for-role.service';
import { UserService } from '~/shared/services/user.service';

const USER_PROFILE_PHOTO_BASE_URL =
  '/_layouts/15/userphoto.aspx?size=L&username=';

@Component({
  selector: 'app-create-category-modal',
  templateUrl: './create-category-modal.component.html',
  styleUrls: ['./create-category-modal.component.scss']
})
export class CreateCategoryModalComponent implements OnInit, OnDestroy {
  public listStyle = {
    width: '100%',
    height: 'auto',
    dropZoneHeight: '35px'
  };

  @ViewChild('createCategory', { static: true }) private modal: ElementRef;

  public modalRef: BsModalRef;

  @Output()
  private saveCategory = new EventEmitter<Category>();

  @Output()
  private saveEditCategory = new EventEmitter<Category>();

  @Input()
  public storefront: Storefront;

  @Output()
  public close = new EventEmitter<boolean>();

  @Input()
  public categoryToEdit: Category;

  public assetsList: Array<Asset> = [];
  public tagsList: Array<Tag> = [];
  public users: User[] = [];
  public coResponsibles: User[] = [];

  public showAddResponsible = false;
  public showAssetsTagsModal = false;
  public AssetsTagsModalType = '';

  public color: string;

  public categoryResponsiblePhotoPath = '';

  public categoryIcon: string;

  public categoryName = new FormControl('');

  public showCreateAssetModal = false;

  public categoryResponsibleControl = new FormControl();
  public categoryCoResponsibleControl = new FormControl([]);

  public user: User = new User();

  public slDivisions$: Observable<FitForRoleItem[]>;
  public jobs$: Observable<FitForRoleItem[]>;
  public slBusinessLines$: Observable<FitForRoleItem[]>;
  public bfs$: Observable<FitForRoleItem[]>;

  public keyProcesses: Array<KeyProcess> = [];

  // public priorityKeyProcesses: Array<KeyProcess> = [];

  public showAllJobTags = false;

  public isCurrentUserOwner = false;

  public userGroups: Group[];
  public userGroups$: Observable<Group[]>;
  public loadingUserGroups = false;

  private modalConfig: ModalOptions = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'lateral-menu col-md-9 p-0'
  };

  public categoryForm = new FormGroup({
    slDivisions: new FormControl(null),
    jobs: new FormControl(null),
    slBusinessLines: new FormControl(null),
    bfs: new FormControl(null)
  });

  constructor(
    private modalService: BsModalService,
    private assetService: AssetService,
    private usersService: UserService,
    private fitForRoleService: FitForRoleService
  ) {}

  public ngOnInit(): void {
    this.modalRef = this.modalService.show(this.modal, this.modalConfig);

    this.usersService.getCurrentUser().then((obj) => {
      this.user = obj.currentUser;
      this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(
        obj.currentUser
      );
    });

    this.listenToPropertiesChanges();

    this.slDivisions$ = this.fitForRoleService.getSlDivisions();
    this.jobs$ = this.getAllJobs();
    this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines();
    this.bfs$ = this.fitForRoleService.getBFs();

    if (this.categoryToEdit) {
      this.populateEditFields();

      this.setSlDivisions();
      this.setJobs();
      this.setSlBusinessLines();
      this.setBfs();
    }
  }

  private listenToPropertiesChanges() {
    this.categoryResponsibleControl.valueChanges.subscribe((responsible) => {
      this.users = [responsible];

      if (responsible) {
        this.usersService.getUserPhoto(responsible.email).subscribe((photo) => {
          if (photo)
            UtilsService.isValidImage((validImage) => {
              this.categoryResponsiblePhotoPath = validImage
                ? photo
                : UtilsService.USER_PHOTO_PLACEHOLDER;
            }, photo);
        });
      }
    });

    this.categoryCoResponsibleControl.valueChanges.subscribe((v) => {
      this.coResponsibles.push(v);
    });
  }

  private populateEditFields(): void {
    this.categoryName.setValue(this.categoryToEdit.name);
    this.categoryCoResponsibleControl.setValue(
      this.categoryToEdit.coResponsible
    );
    this.categoryResponsibleControl.setValue(this.categoryToEdit.responsible);
    this.color = this.categoryToEdit.color;

    this.showAddResponsible = true;
    this.assetsList = this.categoryToEdit.assets;

    this.categoryIcon = this.categoryToEdit.iconName;

    this.userGroups = this.categoryToEdit.userGroups;

    this.generateKeyProcessPriorityArray();
  }

  public ngOnDestroy(): void {
    this.closeModal();
  }

  public onClickButtonAddAssets(assets: Array<Asset>) {
    this.assetsList = this.assetsList.concat(assets);

    this.generateKeyProcessPriorityArray();
  }

  public onClickButtonAddTags(tags: Array<Tag>) {
    this.tagsList = this.tagsList.concat(tags);

    if (this.tagsList.length > 0) {
      this.assetService
        .getAssetsByTags(this.tagsList.map((t) => t.id))
        .subscribe((assets) => {
          this.assetsList.push(...assets);

          // remove duplicated from list
          this.assetsList = this.assetsList.filter(
            (v, i) =>
              this.assetsList.findIndex((item) => item.name === v.name) === i
          );
        });
    }
  }

  public onClickSaveCategory(calledFrom: string) {
    const category = this.categoryToEdit ? this.categoryToEdit : new Category();

    category.iconName = this.categoryIcon;
    category.name = this.categoryName.value;
    category.color = this.color;

    category.responsible = this.users[0];
    category.coResponsible = this.categoryCoResponsibleControl.value;

    category.assets = this.assetsList;

    category.keyProcesses = this.keyProcesses;

    category.userGroups = this.userGroups;

    const slDivisionsFormValue = this.categoryForm.controls.slDivisions.value;
    const jobsFormValue = this.categoryForm.controls.jobs.value;
    const slBusinessLinesFormValue =
      this.categoryForm.controls.slBusinessLines.value;
    const bfsFormValue = this.categoryForm.controls.bfs.value;

    category.slDivisionIds = UtilsService.arrayIsNullOrEmpty(
      slDivisionsFormValue
    )
      ? []
      : slDivisionsFormValue.map((element) => element.id);

    category.jobIds = UtilsService.arrayIsNullOrEmpty(jobsFormValue)
      ? []
      : jobsFormValue.map((element) => element.id);

    category.slBusinessLineIds = UtilsService.arrayIsNullOrEmpty(
      slBusinessLinesFormValue
    )
      ? []
      : slBusinessLinesFormValue.map((element) => element.id);

    category.bfIds = UtilsService.arrayIsNullOrEmpty(bfsFormValue)
      ? []
      : bfsFormValue.map((element) => element.id);

    this.emitSaveOrEdit(category);
  }

  private emitSaveOrEdit(category: Category): void {
    if (this.categoryToEdit && this.categoryToEdit.id)
      this.saveEditCategory.emit(category);
    else this.saveCategory.emit(category);

    this.sorted(this.assetsList);
    this.closeModal();
  }

  public setSelectedUser(users: User[]) {
    if (users && users.length > 0) {
      this.users = [users[0]];
      users[0] && users[0].email
        ? (this.categoryResponsiblePhotoPath = `${USER_PROFILE_PHOTO_BASE_URL}${users[0].email}`)
        : (this.categoryResponsiblePhotoPath = '');
    } else {
      this.users = null;
      this.categoryResponsiblePhotoPath = '';
      this.categoryResponsibleControl.setValue(null);
    }
  }

  public isAllFiledsChecked() {
    return this.categoryName.value && this.users && this.users[0];
  }

  public showCreateCategotyModal(): void {
    this.modalService.show(this.modal, this.modalConfig);
  }

  public closeModal(): void {
    this.close.emit(true);
    this.modalRef.hide();
  }

  public onClickAddDigitalAssetsOrTags(type: string) {
    this.AssetsTagsModalType = type;
    this.showAssetsTagsModal = true;
  }

  public removeAssetFromList(asset) {
    this.assetsList = this.assetsList.filter((a) => a.id !== asset.id);

    this.generateKeyProcessPriorityArray();
  }

  public removeTagFromList(tag) {
    this.tagsList = this.tagsList.filter((t) => t.id !== tag.id);
  }

  public sorted(listSorted) {
    this.assetsList = listSorted;

    for (let index = 0; index < this.assetsList.length; index++) {
      this.assetsList[index].orderIndex = index;
    }
  }

  public async updatedSlDivisions() {
    this.jobs$ = this.getAllJobs(
      this.categoryForm.controls.slDivisions.value.map((element) => element.id)
    );
    await this.jobs$.toPromise();
    this.updatedJobs();
  }

  public updatedJobs() {
    const slDivisionsFormValue = this.categoryForm.controls.slDivisions.value;
    const jobsFormValue = this.categoryForm.controls.jobs.value;

    if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue)
    ) {
      this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines();
      this.updatedSlBusinessLines();
    } else if (UtilsService.arrayIsNullOrEmpty(jobsFormValue)) {
      this.jobs$.subscribe(async (result) => {
        if (UtilsService.arrayIsNullOrEmpty(result)) {
          this.slBusinessLines$ = null;
          this.updatedSlBusinessLines();
        } else {
          this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines(
            result.map((element) => element.id)
          );
          await this.slBusinessLines$.toPromise();
          this.updatedSlBusinessLines();
        }
      });
    } else {
      this.slBusinessLines$ = this.fitForRoleService.getSlBusinessLines(
        jobsFormValue.map((element) => element.id)
      );
      this.updatedSlBusinessLines();
    }
  }

  public updatedSlBusinessLines() {
    const slDivisionsFormValue = this.categoryForm.controls.slDivisions.value;
    const jobsFormValue = this.categoryForm.controls.jobs.value;
    const slBusinessLinesFormValue =
      this.categoryForm.controls.slBusinessLines.value;

    if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue)
    ) {
      this.bfs$ = this.fitForRoleService.getBFs();
    } else if (UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue)) {
      if (this.slBusinessLines$ == null) {
        this.bfs$ = null;
      } else {
        this.slBusinessLines$.subscribe(async (result) => {
          if (UtilsService.arrayIsNullOrEmpty(result)) {
            this.bfs$ = null;
          } else {
            this.bfs$ = this.fitForRoleService.getBFs(
              result.map((element) => element.id)
            );
          }
        });
      }
    } else {
      this.bfs$ = this.fitForRoleService.getBFs(
        slBusinessLinesFormValue.map((element) => element.id)
      );
    }

    this.updatedBfs();
  }

  public updatedBfs() {
    this.controlFitForRoleAppearence();
  }

  private controlFitForRoleAppearence() {
    const slDivisionsFormValue = this.categoryForm.controls.slDivisions.value;
    const jobsFormValue = this.categoryForm.controls.jobs.value;
    const slBusinessLinesFormValue =
      this.categoryForm.controls.slBusinessLines.value;
    const bfsFormValue = this.categoryForm.controls.bfs.value;

    if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue) &&
      !UtilsService.arrayIsNullOrEmpty(bfsFormValue)
    ) {
      this.categoryForm.get('slDivisions').disable();
      this.categoryForm.get('jobs').disable();
      this.categoryForm.get('slBusinessLines').disable();
    } else if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      UtilsService.arrayIsNullOrEmpty(jobsFormValue) &&
      !UtilsService.arrayIsNullOrEmpty(slBusinessLinesFormValue)
    ) {
      this.categoryForm.get('slDivisions').disable();
      this.categoryForm.get('jobs').disable();
    } else if (
      UtilsService.arrayIsNullOrEmpty(slDivisionsFormValue) &&
      !UtilsService.arrayIsNullOrEmpty(jobsFormValue)
    ) {
      this.categoryForm.get('slDivisions').disable();
    } else {
      this.categoryForm.get('slDivisions').enable();
      this.categoryForm.get('jobs').enable();
      this.categoryForm.get('slBusinessLines').enable();
    }
  }

  private setSlDivisions() {
    this.slDivisions$.subscribe((result) => {
      this.categoryForm.controls.slDivisions.setValue(
        result.filter((element) =>
          this.categoryToEdit.slDivisionIds.includes(element.id)
        )
      );
    });
  }

  private setJobs() {
    this.jobs$.subscribe((result) => {
      this.categoryForm.controls.jobs.setValue(
        result.filter((element) =>
          this.categoryToEdit.jobIds.includes(element.id)
        )
      );
    });
  }

  private setSlBusinessLines() {
    this.slBusinessLines$.subscribe((result) => {
      this.categoryForm.controls.slBusinessLines.setValue(
        result.filter((element) =>
          this.categoryToEdit.slBusinessLineIds.includes(element.id)
        )
      );
    });
  }

  private setBfs() {
    this.bfs$.subscribe((result) => {
      this.categoryForm.controls.bfs.setValue(
        result.filter((element) =>
          this.categoryToEdit.bfIds.includes(element.id)
        )
      );
    });
  }

  public searchFitForRoleField(term: string, item: FitForRoleItem) {
    const bindLabel = `${item.code} - ${item.description}`;

    return (
      item.code.toUpperCase().includes(term.toUpperCase()) ||
      item.description.toUpperCase().includes(term.toUpperCase()) ||
      bindLabel.toUpperCase().includes(term.toUpperCase())
    );
  }

  public setAssetsList(assets: Array<Asset>) {
    this.assetsList = assets;

    this.generateKeyProcessPriorityArray();
  }

  public onSelectAllSlDivisions(): void {
    this.slDivisions$.subscribe((divisions) => {
      this.categoryForm.get('slDivisions').setValue(divisions);
    });
  }

  public onSelectAllJobs(): void {
    this.jobs$.subscribe((jobs) => {
      this.categoryForm.get('jobs').setValue(jobs);
    });
  }

  public onSelectAllSlBusinessLines(): void {
    this.slBusinessLines$.subscribe((lines) => {
      this.categoryForm.get('slBusinessLines').setValue(lines);
    });
  }

  public onSelectAllBfs(): void {
    this.bfs$.subscribe((bfs) => {
      this.categoryForm.get('bfs').setValue(bfs);
    });
  }

  public compareFn(a: any, b: any) {
    return a.id === b.id;
  }

  public showAllJobTagsChanged(event) {
    this.showAllJobTags = event;
    this.jobs$ = this.getAllJobs();
  }

  private getAllJobs(slDivisionIds: Array<number> = []) {
    return this.fitForRoleService.getJobs(slDivisionIds).pipe(
      map((items) => {
        return this.showAllJobTags
          ? items
          : items.filter(
              (i) =>
                i.bucketId === null ||
                i.bucketId >= 3 || // Bucket 3 and 4 must be displayed on the list
                this.categoryToEdit?.jobIds?.some((r) => r === i.id)
            );
      })
    );
  }

  private generateKeyProcessPriorityArray() {
    const keyProcesses = [];

    this.assetsList.forEach((asset) => {
      asset.keyProcesses?.forEach((keyProcess) => {
        if (!keyProcesses.find((sc) => sc.id === keyProcess.id)) {
          const kopItem = this.categoryToEdit.keyProcesses.find((kp) => {
            return kp.id === keyProcess.id;
          });

          keyProcess.orderIndex = kopItem ? kopItem.orderIndex : 999;

          keyProcesses.push(keyProcess);
        }
      });
    });

    this.keyProcesses = keyProcesses.sort(
      (a, b) => a.orderIndex - b.orderIndex
    );
  }

  public listSorted(listSorted) {
    this.keyProcesses = listSorted;

    for (let index = 0; index < this.keyProcesses.length; index++) {
      this.keyProcesses[index].orderIndex = index;
    }
  }

  public searchUserGroups(name: string) {
    if (!name) return;
    this.loadingUserGroups = true;
    this.userGroups$ = this.usersService
      .searchLDAPGroups(name)
      .pipe(tap(() => (this.loadingUserGroups = false)));
  }
}
