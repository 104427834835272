import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { SOLID_ICONS } from './FONT_AWESOME_CONSTANTS';

@Component({
    selector: 'app-font-awesome-picker',
    templateUrl: './font-awesome-picker.component.html',
    styleUrls: ['./font-awesome-picker.component.scss']
})
export class FontAwesomePickerComponent implements OnInit {

    @Output()
    public selectIcon = new EventEmitter<string>();

    @Input()
    public selectedIcon: string;

    public iconsToShow: Array<string> = SOLID_ICONS;

    public showIconsList = false;

    constructor() { }

    public ngOnInit(): void { }

    public setSelectedIcon(icon: string) {
        this.selectedIcon = icon;
        this.closeIconsList();
    }

    public openIconsList(): void {
        this.showIconsList = true;
    }

    public closeIconsList(): void {
        this.showIconsList = false;
    }

    public toggleIconListView(): void {
        this.showIconsList = !this.showIconsList;
    }

    public setIconValue(icon): void {
        this.selectedIcon = icon;
        this.closeIconsList()
        this.selectIcon.emit(icon);
    }

    @HostListener('window:keyup', ['$event'])
    public keyEvent(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.closeIconsList()
        }
    }

}
