import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { StorefrontOrder } from '~/shared/models/storefront-order.model';
import { NotificationService } from '~/shared/services/notification.service';
import { StorefrontOrderService } from '~/shared/services/storefront-order.service';

@Component({
    selector: 'app-storefronts-order',
    templateUrl: './storefronts-order.component.html',
    styleUrls: ['./storefronts-order.component.scss']
})
export class StorefrontsOrderComponent implements OnInit, AfterViewInit {

    public storefronts = new Array<StorefrontOrder>();

    @ViewChild('storefrontsSort', { static: false }) storefrontsSort: MatSort;
    @ViewChild('storefrontsPaginator', { static: false }) storefrontsPaginator: MatPaginator;

    public displayColumns: string[] = ['name', 'orderIndex'];

    public storefrontsDataSource = new MatTableDataSource<StorefrontOrder>([]);

    constructor(
        private storefrontOrderService: StorefrontOrderService,
        private toastr: NotificationService) { }

    ngOnInit() {
        this.fetchStores();
    }

    public ngAfterViewInit(): void {
        this.populateStoreLists();
    }

    private populateStoreLists(): void {
        this.storefrontsDataSource.data = this.storefronts;
        this.storefrontsDataSource.sort = this.storefrontsSort;
        this.storefrontsDataSource.paginator = this.storefrontsPaginator;
    }

    private fetchStores(): void {
        this.storefrontOrderService.getOrderIndex().pipe(take(1)).subscribe((stores) => {
            this.storefronts = stores;
            this.populateStoreLists()
        })
    }

    public save(): void {
        this.storefrontOrderService.updateStorefrontsOrder(this.storefronts).pipe(take(1)).subscribe(() => {
            this.fetchStores();

            this.toastr.success('', 'Saved', 2500);

        })

    }

}
