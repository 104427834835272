import { Notification } from '@/models/notification.model';
import { NotificationService } from '@/services/notification.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { Asset } from '~/shared/models/asset.model';
import { Review } from '~/shared/models/review.model';
import { ReviewService } from '~/shared/services/review.service';

@Component({
  selector: 'app-new-review',
  templateUrl: './new-review.component.html',
  styleUrls: ['./new-review.component.scss']
})
export class NewReviewComponent implements OnInit {
  private config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'center-modal modal-lg modal-dialog-centered'
  };

  @Input()
  public selectedAsset: Asset;

  @Output()
  public modalClosed = new EventEmitter();

  public rateHoverText = '';

  public reviewText = '';

  @ViewChild('template', { static: true }) public elementView: ElementRef;

  public modalRef: BsModalRef;

  public rating = -1;

  private review: Review;

  public labelText = 'Write Review';

  public newNotification: Notification;

  public reviewStars = '';

  constructor(
    private modalService: BsModalService,
    private reviewService: ReviewService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.reviewService
      .getReviewByUserAndId(this.selectedAsset.id)
      .pipe(take(1))
      .subscribe((review) => {
        if (review) {
          this.rating = review.rating;
          this.reviewText = review.review;
          this.review = review;
          this.labelText = 'Edit Review';
        }
      });

    this.modalRef = this.modalService.show(this.elementView, this.config);
  }

  public ratingMouseHoverChange(value) {
    switch (value) {
      case 1:
        this.rateHoverText = 'Hate it';
        break;
      case 2:
        this.rateHoverText = 'Didn’t like it';
        break;
      case 3:
        this.rateHoverText = 'OK';
        break;
      case 4:
        this.rateHoverText = 'Liked it';
        break;
      case 5:
        this.rateHoverText = 'Loved it';
        break;
      default:
        this.rateHoverText = '';
        break;
    }
  }

  public rateSelecte(value) {
    this.rating = value;
  }

  public cancel() {
    this.closeModal();
  }

  public save() {
    if (!this.review) {
      const review = new Review();

      review.rating = this.rating;
      review.review = this.reviewText;
      review.asset = this.selectedAsset;

      this.reviewService
        .saveReview(review)
        .pipe(take(1))
        .subscribe(() => {
          this.reviewService.reviewClosed();
        });
    } else {
      this.review.rating = this.rating;
      this.review.review = this.reviewText;
      this.review.asset = this.selectedAsset;

      this.reviewService
        .editReview(this.review)
        .pipe(take(1))
        .subscribe(() => {
          this.reviewService.reviewClosed();
        });
    }

    this.closeModal();
  }

  public closeModal() {
    this.modalClosed.emit(true);

    this.modalRef.hide();
  }
}
