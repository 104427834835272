<div class="container p-0">
    <div class="container mt-2">
        <div class="vertical-bar d-flex align-items-center justify-content-between pb-2 h-15 mt-4">
            <div class="d-flex flex-column">
                <span class="title mr-2 mr-auto">BI Studio Internal</span>
            </div>
            <button
                type="button"
                class="button-blue"
                (click)="add()">
                CREATE NEW
            </button>
        </div>
        <div class="row mt-3 px-3">
            <div class="w-100">
                <div class="overflow-x">
                    <table mat-table [dataSource]="dataSource" matSort #sort="matSort">
                        <!-- Headers -->
                        <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">

                            <!-- Sortable header -->
                            <ng-container *ngIf="column.sort == true; else noSort">
                                <th class="pl-3" mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.display }}</th>
                            </ng-container>

                            <!-- Not sortable header -->
                            <ng-template #noSort>
                                <th class="pl-3" mat-header-cell *matHeaderCellDef>{{ column.display }}</th>
                            </ng-template>

                            <td class="pl-3 pr-3" mat-cell *matCellDef="let element"
                                [title]="element[column.title] ? element[column.title] : element[column.field] ? element[column.field] : ''">

                                <!--Status column -->
                                <ng-container *ngIf="column.field == 'status'; else notProcessedField">
                                    <i [class]="'fas fa-circle status ' + getStatusClass(element['validated'],element['processedAssetId'])"
                                        [title]="(element['validated'] ? '' : 'Not ') + 'Validated / ' + (element['processedAssetId'] ? '' : 'Not ') + 'Processed'"></i>
                                </ng-container>

                                <!--Any other column -->
                                <ng-template #notProcessedField>
                                    <div [ngClass]="{ 'pointer-cursor text-blue': column.clickable == true }" (click)="column.clickable ? openLink(element[column.title]): null">
                                        {{ element[column.field] }}
                                    </div>
                                </ng-template>

                                <!-- Menu options column -->
                                <ng-container *ngIf="column.field == 'menu'">
                                    <div style="min-width: 25px;" [title]="'Options'">
                                        <i *ngIf="element.id == selectedInternal?.id" class="fas fa-ellipsis-h cursor-pointer p-1" [matMenuTriggerFor]="menu"></i>
                                    </div>
                                </ng-container>

                            </td>
                        </ng-container>

                        <!-- Selected Row -->
                        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                        <tr
                            mat-row *matRowDef="let row; columns: displayColumns;"
                            (click)="selectedInternal = row" (dblclick)="setRowAndOpenModal(row)"
                            [ngClass]="{ 'selected': row === selectedInternal }">
                            <button>View Details</button>
                        </tr>
                    </table>
                </div>
                <mat-paginator
                    #paginator
                    [pageSizeOptions]="[25, 50]"
                    showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>

    <div class="legend ml-2 mt-2">
        <i [class]="'fas fa-circle status icon-red pl-2 pr-1'"></i><span>Not validated</span>

        <i [class]="'fas fa-circle status icon-yellow pl-3 pr-1'"></i><span>Not Processed</span>

        <i [class]="'fas fa-circle status icon-green pl-3 pr-1'"></i><span>Validated and processed</span>

    </div>
</div>

<mat-menu #menu="matMenu">
    <button *ngIf="selectedInternal.processedAsset?.id"
        style="outline: none" mat-menu-item
        (click)="activateRoute(selectedInternal.processedAsset.id)">
        <i class="fas fa-external-link-square-alt mr-2"></i>
        <span>Open</span>
    </button>

    <button *ngIf="!selectedInternal.processedAsset?.id"
        style="outline: none" mat-menu-item (click)="newEntryModal = true">
        <i class="fas fa-pen mr-2"></i>
        <span>Edit</span>
    </button>

    <button *ngIf="!selectedInternal.validated"
        style="outline: none" mat-menu-item
        (click)="validateData()">
        <i class="fas fa-check mr-2"></i>
        <span>Validate</span>
    </button>

    <button *ngIf="selectedInternal.validated && !selectedInternal.processedAsset?.id"
        style=" outline: none" mat-menu-item
        (click)="processData()">
        <i class="fas fa-check-double mr-2"></i>
        <span>Process</span>
    </button>

    <button *ngIf="!selectedInternal.processedAsset?.id"
        style="outline: none" mat-menu-item (click)="deleteEntry()">
        <i class="fas fa-trash-alt mr-2"></i>
        <span>Delete</span>
    </button>

</mat-menu>

<app-modal-lateral-internal
    *ngIf="newEntryModal"
    [internal]="selectedInternal"
    (newInternal)="receiveNewEntry($event)"
    (updateData)="updateDataEvent($event)"
    (close)="newEntryModal = false">
</app-modal-lateral-internal>

<app-confirmation-box
    *ngIf="showDeleteModal"
    [settings]="deleteModalSettings"
    (close)="closeModalDelete($event)">
</app-confirmation-box>
