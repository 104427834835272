import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent {

  @Input()
  public BackgroundColor = '#ffffff';

  public openUrl(){
    window.open('http://data.slb.com/', '_blank');
  }

}
