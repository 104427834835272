import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { Group } from '~/shared/models/group.model';
import { HelpLink } from '~/shared/models/help-link.model';
import { IImage } from '~/shared/models/image.model';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { CategoryService } from '~/shared/services/category.service';
import { FitForRoleService } from '~/shared/services/fit-for-role.service';
import { HeaderService } from '~/shared/services/header.service';
import { NotificationService } from '~/shared/services/notification.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { TagService } from '~/shared/services/tag.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-new-store',
  templateUrl: './new-store.component.html',
  styleUrls: ['./new-store.component.scss']
})
export class NewStoreComponent implements OnInit, OnDestroy {
  public listStyle = {
    width: '100%',
    height: 'auto',
    dropZoneHeight: '50px'
  };

  public formLoaded = false;

  public store: Storefront;
  public categoryToEdit: Category;
  public StorefrontTagToEdit: Tag;

  public showCreateAssetModal: boolean;

  public selectedColor: string;

  public imageLogo: IImage;
  public imageHeader: IImage;

  public submitted = false;
  public saving = false;

  public loadingCategories = true;

  public isCurrentUserOwner = false;

  public storeForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    pageTitle: new FormControl(''),
    description: new FormControl('', [Validators.required]),
    owner: new FormControl(null, [Validators.required]),
    coOwners: new FormControl([]),
    editAssetsUsers: new FormControl([]),
    customMessage: new FormControl(''),
    userGroups: new FormControl(''),
    active: new FormControl(true),
    retired: new FormControl(false)
  });

  public assetsList: Array<Asset> = [];
  public tagsList: Array<Tag> = [];
  public categoriesList: Array<Category> = [];
  public storefrontTagsList: Array<Tag> = [];

  public showCreateEditCategoryModal = false;
  public showCreateCategoryModal = false;
  public showAssetsTagsModal = false;
  public showAddStorefrontTag = false;
  public AssetsTagsModalType = '';
  public allTagsFromAssets: Array<Tag> = [];
  public userGroups$: Observable<Group[]>;

  public loadingUserGroups = false;

  public user: User;

  constructor(
    private headerService: HeaderService,
    private tagService: TagService,
    private assetService: AssetService,
    private storeService: StorefrontService,
    private router: Router,
    private categoryService: CategoryService,
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private toastr: NotificationService,
    private usersService: UserService,
    private fitForRoleService: FitForRoleService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      if (params.get('id')) {
        this.saving = true;

        this.storeService.getStoreById(Number(params.get('id'))).subscribe(
          (store) => {
            this.store = store;

            if (!store.currentUserCanManage) {
              this.router.navigate(['home']);
            }

            this.categoryService
              .getAllByStorefront(this.store.id)
              .pipe(take(1))
              .subscribe((categories) => {
                this.categoriesList = categories;
                this.loadingCategories = false;

                this.saving = false;
                this.populateAllAssetTags(true);
              });

            this.populateEditFields();

            this.formLoaded = true;
          },
          () => {
            this.router.navigate(['/']);
          }
        );
      } else {
        this.formLoaded = true;
        this.loadingCategories = false;

        this.store = new Storefront();

        this.selectedColor = '#0014DC';
        this.headerService.setColor(this.selectedColor);
      }

      // this.userGroups$ = this.usersService
      //   .searchLDAPGroups('%20')
      //   .pipe(tap(() => (this.loadingUserGroups = false)));

      this.usersService.getCurrentUser().then((obj) => {
        this.user = obj.currentUser;
        this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(
          obj.currentUser
        );
      });
    });
  }

  private populateAllAssetTags(setOrderIndex = false) {
    let allTags: Array<Tag> = [];

    this.assetsList.forEach((asst) => {
      allTags = allTags.concat(asst.tags);
    });

    this.categoriesList.forEach((c) => {
      c.assets.forEach((asst) => {
        allTags = allTags.concat(asst.tags);
      });
    });

    // add not existing tag
    allTags.forEach((tag) => {
      const tagAlreadyExist = this.allTagsFromAssets.find(
        (t) => t.id === tag.id
      );

      if (!tagAlreadyExist) {
        this.allTagsFromAssets.push(tag);
      }
    });

    // check removed Tags
    const toRemove: Array<Tag> = [];
    this.allTagsFromAssets.forEach((tag) => {
      if (!allTags.some((t) => t.id === tag.id)) {
        toRemove.push(tag);
      }
    });

    toRemove.forEach((tag) => {
      const t = this.allTagsFromAssets.filter((t) => t.id === tag.id)[0];
      const index = this.allTagsFromAssets.indexOf(t);

      this.allTagsFromAssets.splice(index, 1);
    });

    if (setOrderIndex) {
      this.allTagsFromAssets.forEach((t) => {
        t.orderIndex = this.store.tagsFromAssetsOrder.find(
          (tfa) => tfa.id === t.id
        ).orderIndex;
      });
    }

    this.allTagsFromAssets = UtilsService.sortArrayByOrderIndex(
      this.allTagsFromAssets
    );
  }

  public editCategoryFromList(index: number) {
    this.categoryToEdit = this.categoriesList[index];
    this.showCreateEditCategoryModal = true;
  }

  public categoryEdited(category: Category) {
    category.wasEdited = true;

    this.categoriesList.forEach((cat, index) => {
      if (cat.id === category.id) {
        this.categoriesList[index] = category;
      }
    });

    this.categoryToEdit = null;
    this.populateAllAssetTags();
  }

  private populateEditFields() {
    this.storeForm.setValue({
      title: this.store.name,
      pageTitle: this.store.pageTitle ?? '',
      description: this.store.description ?? '',
      customMessage: this.store.customMessage ?? '',
      owner: this.store.owner,
      coOwners: this.store.coOwners ?? [],
      editAssetsUsers: this.store.editAssetsUsers ?? [],
      userGroups: this.store.userGroups ?? [],
      active: this.store.active,
      retired: this.store.retired
    });

    this.storefrontTagsList = this.store.storefrontTags;

    this.assetsList = this.store.assets;

    this.setColor(this.store.color);

    if (this.store.logo)
      this.imageLogo = {
        localPath: this.store.logo,
        image: new File([], this.store.logo),
        isNew: false
      };

    if (this.store.logoHeader)
      this.imageHeader = {
        localPath: this.store.logoHeader,
        image: new File([], this.store.logoHeader),
        isNew: false
      };
  }

  public ngOnDestroy(): void {
    this.headerService.resetColor();
  }

  public setColor(color: string) {
    this.selectedColor = color;
    this.headerService.setColor(this.selectedColor);
  }

  public onClickAddDigitalAssetsOrTags(type: string) {
    this.AssetsTagsModalType = type;
    this.showAssetsTagsModal = true;
  }

  public onClickButtonAddAssets(assets: Array<Asset>) {
    this.assetsList = this.assetsList.concat(assets);

    this.populateAllAssetTags();
  }

  public onClickButtonAddTags(tags: Array<Tag>) {
    this.tagsList = this.tagsList.concat(tags);
  }

  public showHideAddStorefrontTag(
    showModal: boolean,
    storefrontTagToEdit = null
  ) {
    this.showAddStorefrontTag = showModal;
    this.StorefrontTagToEdit = storefrontTagToEdit;
  }

  public storefrontTagSaved(storefrontTag: Tag): void {
    if (storefrontTag.id) {
      let tagToUpdate = new Tag();

      tagToUpdate = Object.assign({}, storefrontTag);
      tagToUpdate.storefront = this.store;

      this.tagService
        .update(tagToUpdate)
        .pipe(take(1))
        .subscribe((tag) => {
          this.toastr.success(
            'You successfully updated the tag: ' + storefrontTag.name,
            'Updated'
          );
        });
    } else {
      if (
        this.storefrontTagsList.find(
          (t) => t.name.toLowerCase() === storefrontTag.name.toLowerCase()
        )
      ) {
        alert('This Tag already exists');
      } else {
        storefrontTag.storefront = this.store;
        this.tagService
          .add(storefrontTag)
          .pipe(take(1))
          .subscribe((id) => {
            storefrontTag.id = id;
            this.storefrontTagsList =
              this.storefrontTagsList.concat(storefrontTag);

            this.toastr.success(
              'You successfully created the tag: ' + storefrontTag.name,
              'Created'
            );
          });
      }
    }
  }

  public removeStorefrontTagFromList(storefrontTag: Tag) {
    const ok = window.confirm(
      'Are you sure you want to delete the tag ' + storefrontTag.name + '?'
    );
    if (ok) {
      this.tagService
        .deleteTag(storefrontTag)
        .pipe(take(1))
        .subscribe((t) => {
          this.storefrontTagsList = this.storefrontTagsList.filter(
            (t) => t.name !== storefrontTag.name
          );

          this.toastr.success(
            'You successfully deleted the tag: ' + storefrontTag.name,
            'Deleted'
          );
        });

      event.stopPropagation();
    } else {
      event.stopPropagation();
    }
  }

  public removeTagFromList(tag) {
    this.tagsList = this.tagsList.filter((t) => t.id !== tag.id);
  }

  public categoryAdded(category: Category) {
    const existingCategoryIndex = this.categoriesList.findIndex(
      (c) => c.name == category.name
    );

    if (existingCategoryIndex > -1)
      this.categoriesList[existingCategoryIndex] = category;
    else this.categoriesList.push(category);

    this.populateAllAssetTags();
  }

  public helpLinksChanged(helpLinks: Array<HelpLink>) {
    this.store.helpLinks = helpLinks;
  }

  public onSelectLogo(event) {
    this.imageLogo = event[0];
  }

  public onSelectHeaderImg(event) {
    this.imageHeader = event[0];
  }

  public removeHeaderImage() {
    this.imageHeader = null;
  }

  public searchUserGroups(name: string) {
    if (!name) return;
    this.loadingUserGroups = true;
    this.userGroups$ = this.usersService
      .searchLDAPGroups(name)
      .pipe(tap(() => (this.loadingUserGroups = false)));
  }

  public submit() {
    this.submitted = true;

    const helpLinksIsValid = this.store.helpLinks
      ? this.store.helpLinks?.filter(
          (helpLink) => helpLink.title === '' || helpLink.linkAddress === ''
        ).length === 0
      : true;

    if (this.storeForm.status === 'VALID' && helpLinksIsValid) {
      this.saving = true;
      this.saveStore();
    } else {
      this.toastr.warning(
        'Please fill in all required fields.',
        'Required field'
      );
    }
  }

  private saveStore() {
    this.store.name = this.storeForm.controls.title.value;
    this.store.pageTitle = this.storeForm.controls.pageTitle.value;
    this.store.active = this.storeForm.controls.active.value;
    this.store.retired = this.storeForm.controls.retired.value;
    this.store.description = this.storeForm.controls.description.value;
    this.store.color = this.selectedColor;
    this.store.assets = this.assetsList;
    this.store.owner = this.storeForm.controls.owner.value;
    this.store.coOwners = this.storeForm.controls.coOwners.value?.map(
      (users: User) => users
    );
    this.store.editAssetsUsers =
      this.storeForm.controls.editAssetsUsers.value?.map(
        (users: User) => users
      );
    this.store.customMessage = this.storeForm.controls.customMessage.value;
    this.store.userGroups = this.storeForm.controls.userGroups.value;

    this.store.logo = this.imageLogo ? this.imageLogo.image.name : '';
    this.store.logoHeader = this.imageHeader ? this.imageHeader.image.name : '';

    const tagsIds = this.tagsList.map((v) => v.id);

    if (!this.store.id) {
      this.storeService
        .add(
          this.store,
          this.categoriesList,
          tagsIds,
          this.imageLogo,
          this.imageHeader,
          this.allTagsFromAssets
        )
        .pipe(take(1))
        .subscribe(
          (id) => {
            if (this.store.retired) {
              this.router.navigate(['home']);

              return;
            }
            this.router.navigate(['/store/' + id]);
          },
          () => (this.saving = false)
        );
    } else {
      this.storeService
        .update(
          this.store,
          this.categoriesList,
          tagsIds,
          this.imageLogo,
          this.imageHeader,
          this.allTagsFromAssets
        )
        .pipe(take(1))
        .subscribe(
          (id) => {
            if (this.store.retired) {
              this.router.navigate(['home']);
            }

            this.router.navigate(['/store/' + this.store.id]);
          },
          () => (this.saving = false)
        );
    }
  }

  public removeCategoryFromList(indexToRemove: number) {
    this.categoriesList = this.categoriesList.filter(
      (category, index) => index !== indexToRemove
    );

    this.populateAllAssetTags();
  }

  public listSorted(listSorted) {
    this.categoriesList = listSorted;

    for (let index = 0; index < this.categoriesList.length; index++) {
      this.categoriesList[index].orderIndex = index;
      this.categoriesList[index].wasEdited = true;
    }
  }

  public assetsListChanged(assets: Array<Asset>): void {
    this.assetsList = assets;

    this.populateAllAssetTags();
  }
}
