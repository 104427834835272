<div class="container pb-4" *ngIf="store">
    <div class="row">
        <div class="col-12 px-0">
            <app-board-header [Title]="'About Us'"></app-board-header>

            <img
                class="image-area pt-3"
                *ngIf="store.logoHeader"
                src="{{ store.logoHeader }}" />

            <span class="description d-block mt-3">{{ store.description }}</span>

            <div
                class="responsibles mt-3 p-3"
                [ngClass]="{ minimized: !expanded }">
                <span class="title">Storefront Responsible</span>
                <app-responsible-card
                    [people]="store.owner"
                    [description]="'Storefront Owner'"
                    [sendEmail]="true">
                </app-responsible-card>

                <div class="row no-gutters pt-3" *ngIf="categories?.length > 0">
                    <span class="title col-md-12">Categories Responsibles</span>
                    <app-responsible-card
                        *ngFor="let category of categories"
                        class="col-md-4 py-2"
                        [people]="category.responsible"
                        [description]="category.name"
                        [sendEmail]="true">
                    </app-responsible-card>
                </div>

                <button
                    *ngIf="categories?.length > 3 && expanded == false"
                    class="view-all"
                    (click)="expanded = true">
                    VIEW ALL Responsibles
                </button>
            </div>
        </div>

        <!-- <div
            class="col-sm-12 col-md-4 col-lg-2 col-xl-2 d-flex flex-column mt-5 pr-0">

            <a *ngIf="isCurrentUserOwnerOrMember || storeService.canUserEdit(store)"
                class="help mb-2"
                href="https://wiki.slb.com/display/BIStudio/Storefront+Management"
                target="_blank"
                rel="noopener noreferrer">
                Help<i class="fas fa-question-circle pl-1" style="color: #1683FB;"></i>
            </a>

            <app-button-edit *ngIf="store && storeService.canUserEdit(store)" [storefrontId]="store?.id"></app-button-edit>

            <app-button-add-big
                *ngIf="isCurrentUserOwnerOrMember"
                [Title]="'Create a New BI Asset'"
                [type]="'asset'"
                class="mt-2"></app-button-add-big>

            <app-card-help-links
                *ngIf="store?.id"
                [helpLinks]="store?.helpLinks"
                class="mt-2">
            </app-card-help-links>
        </div> -->
    </div>

    <app-feedback-window
        *ngIf="store"
        [email]="store.owner.email"
        [storeName]="this.store.name">
    </app-feedback-window>
</div>
