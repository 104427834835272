import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { ProductLine } from '~/shared/models/productLine';
import { ProductLineService } from '~/shared/services/product-line.service';

@Component({
    selector: 'app-create-edit-pl-bl',
    templateUrl: './create-edit-pl-bl.component.html',
    styleUrls: ['./create-edit-pl-bl.component.scss']
})
export class CreateEditPlBlComponent implements OnInit {
    @ViewChild('template', { static: true }) public elementView: ElementRef;

    @Output() public close = new EventEmitter();
    @Output() public plBlSaved = new EventEmitter<ProductLine>();

    @Input() public plBl = new ProductLine();

    public name: string;

    private config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'center-modal modal-dialog-centered'
    };

    @Input() public settings = [];

    public modalRef: BsModalRef;

    public modalTitle: string;

    constructor(private modalService: BsModalService,
        private productLineService: ProductLineService) { }

    public ngOnInit(): void {
        this.modalRef = this.modalService.show(this.elementView, this.config);
        this.modalTitle = this.plBl?.id ? "Edit PL/BL" : 'Create PL/BL';

        if (this.plBl.id) {
            this.name = this.plBl.name
        }
    }

    public save() {
        this.plBl.name = this.name;

        if (this.plBl.id) {
            this.productLineService.update(this.plBl).pipe(take(1)).subscribe((plBlId) => {
                this.plBlSaved.emit(this.plBl);
                this.closeModal();
            })

        } else {
            this.productLineService.add(this.plBl).pipe(take(1)).subscribe((plBlId) => {
                this.plBl.id = plBlId;
                this.plBlSaved.emit(this.plBl);
                this.closeModal();
            })
        }
    }

    public noCLick() {
        this.closeModal();
    }

    private closeModal() {
        this.close.emit();
        this.modalRef.hide();
    }

    public get ckeckDisabled(): boolean {
        return !this.name;
    }
}
