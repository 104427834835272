<div class="cursor-pointer asset-title my-1 pl-1 d-flex align-items-center w-100 py-1"

  (click)="openAssetAddress(asset.url, $event)">

  <!-- <i class="fas fa-circle" *ngIf="asset.isNew"></i> -->

  <div class="h-100 tag-indicator-area d-flex align-content-center flex-wrap">
    <div class="tag-indicator " [ngStyle]="{'background-color': item}" *ngFor="let item of assetTagsColors(asset)"></div>
  </div>

  <span class="px-1 title-text" [attr.title]="asset.name">{{asset.name.substr(0,40)}}</span>

  <i *ngIf="asset.isPriority" class="fas fa-thumbtack ml-auto pin-indicator mr-2 "></i>

  <div class="links d-flex justify-content-end ml-auto px-1">

    <div class="extenal-link d-flex align-items-center justify-content-end"
      (click)='openEmail(asset)' [attr.title]="'Feedback'">
      <i class="fas fa-comments px-1"></i>
    </div>

    <div *ngIf="asset.usageStats" class="extenal-link d-flex align-items-center justify-content-end"
      (click)='openAssetAddress(asset.usageStats, $event)' [attr.title]="'Usage Stats'">
      <i class="fas fa-chart-bar px-1"></i>
    </div>

    <div *ngIf="asset.videoUrl" class="extenal-link  d-flex align-items-center justify-content-end"
      (click)='openAssetAddress(asset.videoUrl, $event)' [attr.title]="'Video URL'">
      <i class="fas fa-film px-1"></i>
    </div>

    <div *ngIf="asset.askAndLearn" class="extenal-link  d-flex align-items-center justify-content-end"
      (click)='openAssetAddress(asset.askAndLearn, $event)' [attr.title]="'Ask & Learn'">
      <i class="fas fa-chalkboard-teacher px-1"></i>
    </div>

    <div *ngIf="asset.documentationLink" class="extenal-link  d-flex align-items-center justify-content-end"
      (click)='openAssetAddress(asset.documentationLink, $event)' [attr.title]="'Documentation'">
      <i class="fas fa-file-alt px-1"></i>
    </div>

    <div *ngIf="asset.requestAccessLink" class="extenal-link d-flex align-items-center justify-content-end"
      (click)='openRequestAccessDetails($event)' [attr.title]="'Request Access'">
      <i class="fas fa-unlock  px-1"></i>
    </div>

    <div *ngIf="asset.url" class="extenal-link d-flex align-items-center justify-content-end"
      (click)='assetClicked(asset)' [attr.title]="'More Details'">
      <i class="fas fa-info-circle px-1"></i>
    </div>

  </div>

</div>