import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgImageSliderModule } from 'ng-image-slider';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSortableModule } from 'ngx-sortable';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { LogoutComponent } from '~/modules/logout/logout/logout.component';
import { SmallItemListComponent } from '~/modules/store/components/small-item-list/small-item-list.component';
import { ColorPickerComponent } from '../modules/store/components/color-picker/color-picker.component';
import { AssetCarouselComponent } from './components/asset-carousel/asset-carousel.component';
import { AssetsDragDropSortComponent } from './components/assets-drag-drop-sort/assets-drag-drop-sort.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BarSortByComponent } from './components/bar-sort-by/bar-sort-by.component';
import { BiReportsSelectorComponent } from './components/bi-reports-selector/bi-reports-selector.component';
import { BoardAssetsComponent } from './components/board-assets/board-assets.component';
import { BoardHeaderComponent } from './components/board-header/board-header.component';
import { BoardStorefrontsComponent } from './components/board-storefronts/board-storefronts.component';
import { BoardTagsComponent } from './components/board-tags/board-tags.component';
import { BrandComponent } from './components/brand/brand.component';
import { ButtonEditComponent } from './components/button-edit/button-edit.component';
import { CardAssetHorizontalComponent } from './components/card-asset-horizontal/card-asset-horizontal.component';
import { CardAssetComponent } from './components/card-asset/card-asset.component';
import { CardStorefrontDetailedComponent } from './components/card-storefront-detailed/card-storefront-detailed.component';
import { CardStorefrontSimpleComponent } from './components/card-storefront-simple/card-storefront-simple.component';
import { CardTagComponent } from './components/card-tag/card-tag.component';
import { CategoriesHeaderComponent } from './components/categories-header/categories-header.component';
import { CategoryKeyProcessComponent } from './components/category-key-process/category-key-process.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { CreateAssetModalComponent } from './components/create-asset-modal/create-asset-modal.component';
import { EditableGridComponent } from './components/editable-grid/editable-grid.component';
import { FontAwesomePickerComponent } from './components/font-awesome-picker/font-awesome-picker.component';
import { HeaderLinksComponent } from './components/header-links/header-links.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { LateralMenuComponent } from './components/lateral-menu/lateral-menu.component';
import { LateralModalListComponent } from './components/lateral-modal-list/lateral-modal-list.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LikeAssetComponent } from './components/like-asset/like-asset.component';
import { LikeStorefrontComponent } from './components/like-storefront/like-storefront.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { NotificationComponent } from './components/notification/notification.component';
import { OurSectionsComponent } from './components/our-sections/our-sections.component';
import { OutdatedBrowserComponent } from './components/outdated-browser/outdated-browser.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { RatingComponent } from './components/rating/rating.component';
import { RequestAccessDetailsComponent } from './components/request-access-details/request-access-details.component';
import { SandboxWarningComponent } from './components/sandbox-warning/sandbox-warning.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SendEmailComponent } from './components/send-email/send-email.component';
import { StorefrontCarouselComponent } from './components/storefront-carousel/storefront-carousel.component';
import { StorefrontDropdownSelectorComponent } from './components/storefront-dropdown-selector/storefront-dropdown-selector.component';
import { TagsDragDropSortComponent } from './components/tags-drag-drop-sort/tags-drag-drop-sort.component';
import { ToggleSwitchButtonComponent } from './components/toggle-switch-button/toggle-switch-button.component';
import { UserPickerComponent } from './components/user-picker/user-picker.component';
import { ViewAllAnchorComponent } from './components/view-all-anchor/view-all-anchor.component';
import { VirtualAgentComponent } from './components/virtual-agent/virtual-agent.component';

const MODULE_COMPONENTS = [
  LayoutComponent,
  PageHeaderComponent,
  PageFooterComponent,
  LoaderComponent,
  BrandComponent,
  OutdatedBrowserComponent,
  AvatarComponent,
  SandboxWarningComponent,
  SearchBarComponent,
  HeaderLinksComponent,
  OurSectionsComponent,
  BoardHeaderComponent,
  CardStorefrontDetailedComponent,
  CardStorefrontSimpleComponent,
  CardTagComponent,
  CardAssetComponent,
  LateralMenuComponent,
  RatingComponent,
  LikeAssetComponent,
  LikeStorefrontComponent,
  CategoriesHeaderComponent,
  ColorPickerComponent,
  BoardAssetsComponent,
  BoardTagsComponent,
  BoardStorefrontsComponent,
  ModalHeaderComponent,
  ImageUploadComponent,
  FontAwesomePickerComponent,
  LoadingScreenComponent,
  SendEmailComponent,
  ConfirmationBoxComponent,
  LateralModalListComponent,
  SmallItemListComponent,
  StorefrontDropdownSelectorComponent,
  UserPickerComponent,
  ButtonEditComponent,
  EditableGridComponent,
  BarSortByComponent,
  CreateAssetModalComponent,
  LogoutComponent,
  ToggleSwitchButtonComponent,
  RequestAccessDetailsComponent,
  AssetsDragDropSortComponent,
  ViewAllAnchorComponent,
  StorefrontCarouselComponent,
  AssetCarouselComponent,
  CardAssetHorizontalComponent,
  NotificationComponent,
  CategoryKeyProcessComponent,
  VirtualAgentComponent,
  TagsDragDropSortComponent,
  BiReportsSelectorComponent
];

const MODULE_MODULES = [
  CommonModule,
  HttpClientModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,
  NgImageSliderModule,
  SwiperModule,
  NgxSortableModule,
  MatTableModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  CKEditorModule,
  MatButtonModule,
  MatRadioModule,
  ClickOutsideModule,
  TooltipModule,
  DragDropModule
];

const MODULE_PROVIDERS = [];

@NgModule({
  declarations: [...MODULE_COMPONENTS],
  providers: [...MODULE_PROVIDERS],
  imports: [
    ...MODULE_MODULES,
    TooltipModule.forRoot(),
    ToastrModule.forRoot({ preventDuplicates: true }),
    MatIconModule,
    MatBadgeModule,
    MatExpansionModule
  ],
  exports: [...MODULE_MODULES, ...MODULE_COMPONENTS]
})
export class SharedModule {}
