<div class="header" [ngStyle]="{ backgroundColor: StoreColor | async }">
  <header class="navbar py-3">
    <div
      class="container pl-0 d-flex flex-column flex-md-row align-items-center">
      <div *ngIf="(selectedStore | async) == null" class="logo-area d-flex align-items-center col-lg-2 col-md-2 col-sm-12 col-12">
        <div
          class="home"
          routerLink="home">
          <app-brand
            class="cursor-pointer float-left brand"></app-brand>
        </div>

      </div>
      <div *ngIf="(selectedStore | async) != null" class="logo-area d-flex align-items-center col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="home">
          <app-storefront-dropdown-selector
            [storefront]="selectedStore | async"
            class="float-left brand"></app-storefront-dropdown-selector>
        </div>
      </div>

      <div class="d-flex flex-md-row flex-sm-row-reverse align-items-center ml-auto">
        <app-header-links></app-header-links>
        <app-avatar [showName]="false" style="margin-left: 15px;"></app-avatar>
      </div>

    </div>

  </header>

  <app-our-sections></app-our-sections>

</div>