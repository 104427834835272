import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from '~/core/services/utils.service';
import { Storefront } from '~/shared/models/storefront.model';
import { FilterService } from '~/shared/services/filter.service';
import { HeaderService } from '~/shared/services/header.service';
import { NotificationService } from '~/shared/services/notification.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @ViewChild('searchInput') public searchInput: ElementRef;

  @Input()
  public isListSearch = false;

  @Output()
  public onSearch = new EventEmitter<string>();

  public searchText = '';
  public placeholder = 'Search';
  public currentStorefront: Storefront;

  public selectedFrontStore: Storefront;

  public StoreColor: BehaviorSubject<string>;

  public showHomeMenuTabs = false;

  public isCurrentUserOwnerOrMember = false;
  public isCurrentUserOwner = false;

  constructor(
    private heraderService: HeaderService,
    private filterService: FilterService,
    private usersService: UserService,
    public storefrontService: StorefrontService,
    private toastr: NotificationService
  ) {}

  public doSearch(): void {
    if (this.searchText) {
      this.isListSearch
        ? this.onSearch.emit(this.searchText)
        : this.defaultSearch();
    }
  }

  public ngOnInit() {
    this.StoreColor = this.heraderService.storeColor$;

    this.heraderService.selectedStore$.subscribe((selectedStore) => {
      if (selectedStore) this.currentStorefront = selectedStore;
      else this.currentStorefront = null;

      if (selectedStore && !this.isListSearch)
        this.placeholder = 'Search in ' + selectedStore?.name;
      else if (this.isListSearch) this.placeholder = 'Search';
      else this.placeholder = 'Search in BI Portfolio';

      this.selectedFrontStore = selectedStore;
    });

    this.heraderService.showHomeMenuTabs$.subscribe((show) => {
      this.showHomeMenuTabs = show;
    });

    this.usersService.getCurrentUser().then((obj) => {
      const user = obj.currentUser;
      this.isCurrentUserOwnerOrMember =
        UtilsService.isCurrentUserOwnerOrMember(user);
      this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(user);
    });
  }

  private defaultSearch() {
    if (this.selectedFrontStore)
      this.filterService.ShowAllRelatedToFrontStore(
        this.selectedFrontStore,
        this.searchText
      );
    else this.filterService.ShowAllGlobal(this.searchText);

    this.searchText = '';
  }

  public doSearchEmpty() {
    if (this.isListSearch && !this.searchText)
      this.onSearch.emit(this.searchText);
  }

  public get getTitle(): string {
    return this.currentStorefront ? this.currentStorefront.name : '';
  }

  public searchIconClicked() {
    this.doSearch();
  }

  public tabChanged(tab: any): void {
    let tabName = '';

    switch (tab.index) {
      case 0:
        tabName = 'storefronts';
        break;

      case 1:
        tabName = 'assets';
        break;
    }

    this.heraderService.changeHomeMenuSelectedTab(tabName);
  }

  public changeStorefrontVerifiedStatus(verified) {
    this.storefrontService
      .changeVerifiedStatus(this.selectedFrontStore.id, verified)
      .subscribe((r) => {
        this.toastr.success('Saved', 'Successfully Saved');
        this.selectedFrontStore.verified = verified;
      });
  }
}
