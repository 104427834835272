import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { FAQComponent } from './pages/faq/faq.component';

const MODULE_COMPONENTS = [FAQComponent];

const MODULE_MODULES = [CommonModule, SharedModule];

@NgModule({
  declarations: [...MODULE_COMPONENTS],
  imports: [...MODULE_MODULES],
  exports: [...MODULE_COMPONENTS]
})
export class FAQModule {}
