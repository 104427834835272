<div class="board d-block p-3" *ngIf="RelatedAssets?.length > 0">
    <span class="title">Related BI Assets</span>

    <div class="row no-gutters">
        <app-card-asset
            class="d-block col-md-3"
            *ngFor="let asset of RelatedAssets"
            [Asset]="asset"
        ></app-card-asset>
    </div>
</div>
