<div class="feedback-window" [ngStyle]="{'right':  !visible ? '-300px' : '0px' }">

    <div class="column1 cursor-pointer d-flex flex-column justify-content-center align-items-center" (click)="visible = !visible" [attr.title]="'Feedback'">

      <span class="feedback-text">Feedback</span>
      <i class="far fa-comments pt-1"></i>

    </div>

    <div class="column2 d-flex flex-column p-3">

      <label>How would you rate your experience?</label>
      <div class="d-flex justify-content-center mb-3 mt-1">
        <i class="fas fa-angry p-1" style="color: #CC3232;" [ngClass]="{'selected': rating == 1}" (click)="rating = 1"></i>
        <i class="fas fa-frown p-1" style="color: #DB7B2B;" [ngClass]="{'selected': rating == 2}" (click)="rating = 2"></i>
        <i class="fas fa-meh   p-1" style="color: #E7B416;" [ngClass]="{'selected': rating == 3}" (click)="rating = 3"></i>
        <i class="fas fa-smile p-1" style="color: #99C140;" [ngClass]="{'selected': rating == 4}" (click)="rating = 4"></i>
        <i class="fas fa-laugh p-1" style="color: #2DC937;" [ngClass]="{'selected': rating == 5}" (click)="rating = 5"></i>
      </div>

      <label for="message">Please tell us about your experience</label>
      <textarea type="text" id="message" autocomplete="off" rows="4" class="form-control" [(ngModel)]="message">  </textarea>

      <button type="button" class="mt-auto btn btn-primary" [disabled]="disableSendBtn" (click)="sendMessage()">Send feedback</button>

    </div>

  </div>
