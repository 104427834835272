import { Role } from './role.model';

export class User {
  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  public id: number;
  public name: string;
  public email: string;
  public userName: string;
  public image: string;
  public administrator: boolean;
  public ginNumber: string;

  public roles: Role[];
}
