import { Component, OnInit } from '@angular/core';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Faq } from '~/shared/models/faq.model';
import { IImage } from '~/shared/models/image.model';
import { FaqService } from '~/shared/services/faq.service';
import { NotificationService } from '~/shared/services/notification.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {
  public editor = InlineEditor;
  public config = {
    placeholder: 'Description',
    removePlugins: [
      // 'Image',
      // 'ImageCaption',
      // 'ImageStyle',
      // 'ImageToolbar',
      // 'ImageUpload',
      'MediaEmbed'
      // 'CKFinder',
      // 'EasyImage'
    ],
    link: {
      addTargetToExternalLinks: true
    },
    image: {
      toolbar: [
        // 'imageTextAlternative',
        // '|',
        'imageStyle:alignLeft',
        'imageStyle:full',
        'imageStyle:alignRight'
      ],

      styles: ['full', 'alignLeft', 'alignRight']
    }
  };

  public faqs: Faq[];

  public selectedItemIdxToShow = -1;

  public editMode = false;

  public faq: Faq;

  public isCurrentUserOwner = false;

  constructor(
    protected faqService: FaqService,
    private toastr: NotificationService,
    private usersService: UserService
  ) {}

  public ngOnInit(): void {
    this.faqService
      .getAllFaqs()
      .pipe(take(1))
      .subscribe((faqs) => {
        this.faqs = faqs;
      });

    this.usersService.getCurrentUser().then((obj) => {
      this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(
        obj.currentUser
      );
    });
  }

  public openCloseDetails(clickedIdx: number) {
    if (this.editMode) return;

    this.selectedItemIdxToShow =
      this.selectedItemIdxToShow === clickedIdx ? -1 : clickedIdx;
  }

  public editFaq() {
    event.stopPropagation();
    this.editMode = true;

    this.faq = this.faqs[this.selectedItemIdxToShow];
  }

  public saveFaq() {
    event.stopPropagation();

    if (!this.faq.name || !this.faq.description) {
      this.toastr.warning(
        'Title and Description are required',
        'Required Fields'
      );
      return;
    }

    if (this.faq.id) {
      this.faqService
        .update(this.faq)
        .pipe(take(1))
        .subscribe((id) => {
          this.toastr.success('', 'FAQ Updated');
        });
    } else {
      this.faqService
        .add(this.faq)
        .pipe(take(1))
        .subscribe((id) => {
          this.faqs[this.selectedItemIdxToShow].id = id;
          this.toastr.success('', 'FAQ Created');
        });
    }

    this.faq = null;
    this.editMode = false;
  }

  public newFaq() {
    event.stopPropagation();

    this.faq = new Faq();
    this.faq.name = '';
    this.faq.description = '';
    this.faq.created = new Date();
    this.faq.orderId = 99;
    this.faqs.push(this.faq);

    this.selectedItemIdxToShow = this.faqs.length - 1;

    this.editMode = true;
  }

  public deleteFaq() {
    event.stopPropagation();

    const ok = window.confirm(
      'Are you sure you want to delete the selected FAQ?'
    );

    if (ok) {
      this.faqService
        .deleteFaq(this.faqs[this.selectedItemIdxToShow])
        .pipe(take(1))
        .subscribe((f) => {
          this.faqs.splice(this.selectedItemIdxToShow, 1);
          this.editMode = false;
          this.selectedItemIdxToShow = -1;
        });
    }
  }

  public getCreatedDate() {
    return this.faq.created;
  }

  public onReady(eventData) {
    const transport = {
      faqService: this.faqService,
      callbackDate: () => this.getCreatedDate()
    };

    eventData.plugins.get('FileRepository').createUploadAdapter = function (
      loader
    ) {
      return new UploadService(loader, transport);
    };
  }
}

class UploadService {
  public loader: any;
  private faqService: FaqService;
  private date: Date;

  constructor(loader, transport: any) {
    this.loader = loader;
    this.faqService = transport.faqService;
    this.date = transport.callbackDate();
  }

  public upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => {
            const selectImg: IImage = {
              image: file,
              localPath: reader.result as string,
              isNew: true
            };

            this.faqService
              .uploadImage(selectImg, this.date)
              .pipe(take(1))
              .subscribe((response) => {
                resolve({
                  default: response.url
                });
              });
          };
        })
    );
  }
}
