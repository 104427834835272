import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '~/shared/models/asset.model';

@Component({
    selector: 'app-assets-drag-drop-sort',
    templateUrl: './assets-drag-drop-sort.component.html',
    styleUrls: ['./assets-drag-drop-sort.component.scss']
})
export class AssetsDragDropSortComponent implements OnInit {
    public dragOverComponentIdx = -1;
    public dragStartComponentIdx = -1;
    public dropComponentIdx = -1;

    @Input() public assets = Array<Asset>();

    @Input() public showBigCard = false;
    public assetCardSize = 'col-3';
    public assetCardDoubleSize = 'col-6';

    @Output() public clickAddDigitalAssets = new EventEmitter();
    @Output() public clickCreateAsset = new EventEmitter();
    @Output() public assetsListChanged = new EventEmitter<Array<Asset>>();
    @Output() public listSorted = new EventEmitter<Array<any>>();

    constructor() {}

    public ngOnInit(): void {
        if (this.showBigCard) {
            this.assetCardSize = 'col-6';
            this.assetCardDoubleSize = 'col-12';
        }
    }

    public drop(ev, index) {
        ev.preventDefault();
        this.arraymove(this.dragStartComponentIdx, index);
        this.dragOverComponentIdx = -1;
        this.dragStartComponentIdx = -1;
    }

    public dragOver(e, index) {
        e.preventDefault();
        this.dragOverComponentIdx = index;
    }

    public dragStart(e, index) {
        this.dragStartComponentIdx = index;
    }

    public dragLeave(e) {
        this.dragOverComponentIdx = -1;
        this.dragStartComponentIdx = -1;
    }

    public emitCreateAssetClick() {
        this.clickCreateAsset.emit();
    }

    public emitSelectAssetClick() {
        this.clickAddDigitalAssets.emit();
    }

    public arraymove(fromIndex, toIndex) {
        const element = this.assets[fromIndex];
        this.assets.splice(fromIndex, 1);
        this.assets.splice(toIndex, 0, element);

        var asstPriorityIdx = 0;
        this.assets.forEach((asset) => {
            asset.orderIndex = asset.isPriority ? asstPriorityIdx++ : 0;
        });

        this.emitAssetListChanged();
    }

    public removeAssetFromList(asset) {
        this.assets = this.assets.filter((a) => a.id !== asset.id);
        this.emitAssetListChanged();
    }

    public emitAssetListChanged() {
        this.assetsListChanged.emit(this.assets);
    }

    public sorted(){
        this.listSorted.emit(this.assets)
    }
}
