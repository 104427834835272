<div class="card card-category">

  <div class="header p-2" [ngStyle]="{'background-color': category?.color ? category.color : color}">

    <div class="row no-gutters d-flex align-items-center">

      <i class="icon fas fa-{{category.iconName}} d-flex align-items-center  mr-1 "></i>

      <span [attr.title]="category.name" class="name ">{{category.name}}</span>

      <span class="assets-count ml-2">({{assetsToShow.length}})</span>

    </div>

    <div class="row no-gutters pt-2">

      <div class="responsible col-md-12 new d-flex flex-column pr-2">

        <div class="user d-flex">

          <img alt="Category Responsible"
            [src]="category.responsible?.image || '/assets/default_user.jpg'"
            class="avatar img-thumbnail rounded-circle shadow-sm p-0 mr-1"
            [attr.title]="'Category Responsible'">

          <a [attr.title]="'Send Message'"
            class="title cursor-pointer d-flex align-items-center"
            href="mailto:{{category.responsible.email}}?subject=BI Portfolio">
            {{ category.responsible.name }}
          </a>

        </div>

      </div>

    </div>

  </div>

  <div class="body row no-gutters">

    <div *ngIf="loading" class="d-flex justify-content-center w-100">

      <app-loader [height]="32"></app-loader>

    </div>

    <span class="no-info p-3 mt-1" *ngIf="totalAssetsBeingDisplayed === 0 && loading == false"
      style="margin:auto; display:table;">No Assets related</span>

    <div *ngFor="let kp of keyProcessesAndAssetsToShow; let i = index" class="w-100">

      <div *ngIf="kp.assets.length > 0" class="kp-name d-flex w-100">
        <div>{{kp.keyProcessName}} ({{kp.assets.length}})</div>
      </div>

      <div *ngIf="kp.assets.length > 0" class="w-100 p-1">

        <app-asset-category-label class="w-100"
          *ngFor="let asset of kp.assets | slice:0:(showAll ? kp.assets.length : maxAssetsToShowPerKeyProcess[i])"
          [asset]="asset">
        </app-asset-category-label>

      </div>

    </div>

  </div>

  <div class="show-more-div d-flex justify-content-center">

    <span class="cursor-pointer show-more p-1 px-3 m-2"
      *ngIf="!showAll && totalAssetsBeingDisplayed < category.assets?.length" (click)="showAll = true">
      + {{category.assets?.length - totalAssetsBeingDisplayed}} More
    </span>
  </div>

</div>