import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { RoleTypes } from '../enums/role-type';
import { Group } from '../models/group.model';
import { User } from '../models/user.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiServiceBase<User> {
  constructor(protected http: HttpClient) {
    super(http, 'users');
  }

  public async getCurrentUser(): Promise<any> {
    const localStorageUser = localStorage.getItem(
      UtilsService.SESSION_USER_FIELD_NAME
    );

    const localStorageToken = localStorage.getItem(
      UtilsService.SESSION_TOKEN_FIELD_NAME
    );

    if (localStorageUser && localStorageToken) {
      const user = JSON.parse(localStorageUser);

      if (user) {
        return { currentUser: user, sessionToken: localStorageToken };
      }
    }

    return await this.http
      .get<any>(`${environment.apiUrl}/users/CurrentUser`)
      .pipe(
        tap((obj) => {
          localStorage.setItem(
            UtilsService.SESSION_TOKEN_FIELD_NAME,
            obj.sessionToken
          );

          localStorage.setItem(
            UtilsService.SESSION_USER_FIELD_NAME,
            JSON.stringify(obj.currentUser)
          );
        })
      )
      .toPromise();
  }

  public getUserPhoto(email: string): Observable<string> {
    return this.http.get<string>(
      `${environment.apiUrl}/users/UserPhoto/${email}`,
      this.BASE64_HEADERS
    );
  }

  public searchUsers(term: string): Observable<User[]> {
    return this.getAs<User>(`UsersFromGraph/${term}`);
  }

  public searchGroups(term: string): Observable<string[]> {
    return this.getAs<string>(`GroupsFromLDAP/${term}`);
  }

  public getUsersByPermission(permission: string): Observable<User[]> {
    return this.get('UsersByRole/' + permission);
  }

  public addUserPermission(user: User, permission: string): Observable<number> {
    return this.post({
      UserId: user.id,
      RoleName: permission
    });
  }

  public removeUserPermission(
    user: User,
    permission: string
  ): Observable<number> {
    return this.delete(user.id + '/' + permission);
  }

  public searchLDAPGroups(term: string): Observable<Group[]> {
    return this.getAs<Group>(`ldap-groups/${term}`);
  }

  public async canActivate() {
    let user: User;

    await this.getCurrentUser().then((obj) => {
      user = obj.currentUser;
    });

    if (user.roles.some((r) => r.name == RoleTypes.Owner)) return true;

    return false;
  }
}
