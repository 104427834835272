export class Email {
    constructor(init?: Partial<Email>) {
        Object.assign(this, init);
    }

    public to: string[];
    public cc: string[];
    public bcc: string[];
    public subject: string;
    public body: string;
    public support: boolean;
}
