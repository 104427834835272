import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Asset } from '../models/asset.model';
import { Category } from '../models/category.model';
import { Tag } from '../models/tag.model';
import { User } from '../models/user.model';
import { ApiServiceBase } from './api-service-base.service';

export interface ICrateNewCategory {
    allSelectedAssets: Array<Asset>;
    allSelectedTags: Array<Tag>;
    categoryName: string;
    selectedIcon: string;
    user: User;
}
@Injectable({
    providedIn: 'root'
})
export class CategoryService extends ApiServiceBase<Category> {
    constructor(protected http: HttpClient) {
        super(http, 'categories');
    }

    public getAll(): Observable<Array<Category>> {
        return this.get();
    }

    public getAllByStorefront(storeId: number): Observable<Array<Category>> {
        return this.get('store-front/' + storeId.toString());
    }

    public getRecommendedByStorefront(storeId: number): Observable<Array<Category>> {
        return this.get('store-front/recommended/' + storeId.toString());
    }

    public update(category: Category): Observable<number> {
        return this.put(category);
    }

    public add(category: Category): Observable<number> {
        return this.post(category);
    }

    public getCategoryById(storeId: number): Observable<Category> {
        return this.getSingle(storeId.toString());
    }

    public getCategoriesByResponsible(
        userId: number
    ): Observable<Array<Category>> {
        return this.get('responsible/' + userId.toString());
    }

    public getByUserId(): Observable<Array<Category>>{
      return this.get('user-responsible-categories')
    }

    public storeHasCategories(storeId: number) {
        return new Observable((observer) => {
            return () => {
                [];
            };
        });
    }
}
