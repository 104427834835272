import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { IImage } from '~/shared/models/image.model';
import { Internal } from '~/shared/models/internal.model';
import { KeyProcess } from '~/shared/models/keyProcess.model';
import { ProductLine } from '~/shared/models/productLine';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { User } from '~/shared/models/user.model';
import { CategoryService } from '~/shared/services/category.service';
import { InternalService } from '~/shared/services/internal.service';
import { KeyProcessService } from '~/shared/services/key-process.service';
import { NotificationService } from '~/shared/services/notification.service';
import { ProductLineService } from '~/shared/services/product-line.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { TagService } from '~/shared/services/tag.service';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-modal-lateral-internal',
    templateUrl: './modal-lateral-internal.component.html',
    styleUrls: ['./modal-lateral-internal.component.scss']
})
export class ModalLateralInternalComponent implements OnInit {

    loading: boolean = true;

    @ViewChild('createInternalModal', { static: true })
    public modal: ElementRef;

    @Output()
    public close = new EventEmitter<boolean>();

    @Output()
    public updateData = new EventEmitter<boolean>();

    public modalRef: BsModalRef;

    @Output()
    public newInternal = new EventEmitter<Internal>();

    @Input()
    public internal: Internal = new Internal();

    public tags$: Observable<Tag[]>;
    public categories$: Observable<Category[]>;
    public keyProcesses$: Observable<KeyProcess[]>;
    public productLines$: Observable<ProductLine[]>;

    public storefronts: Storefront[];

    public invalidImages: number = 0;

    public images: IImage[] = [];

    public originalInternal: Internal = new Internal();

    public currentUser: User = new User();

    public title: string = 'New Internal Item';

    public isSaved: boolean = true;
    public isDisabled: boolean = false;

    public hasStorefront: boolean = false;

    constructor(
        private router: Router,
        private toastr: NotificationService,
        private tagService: TagService,
        private userService: UserService,
        private modalService: BsModalService,
        private categoryService: CategoryService,
        private internalService: InternalService,
        private storefrontService: StorefrontService,
        private keyProcessService: KeyProcessService,
        private productLineService: ProductLineService) { }

    ngOnInit(): void {
        this.loadFormOptions();

        this.userService.getCurrentUser().then((obj) => {
            this.currentUser = obj.currentUser;

            if (this.internal.id != 0) {
                this.loading = true;

                this.internalService.getById(this.internal.id).pipe(take(1)).subscribe((i) => {
                    this.internal = i;
                    this.originalInternal = i;

                    this.populateEditFields();

                    if (this.internal.storefront?.id) {
                        this.onStorefrontChanges(this.internal.storefront);
                    }

                    this.subscribeControls();

                    this.title = 'Edit Internal Item';

                    if (this.internal.validated)
                        this.disableForm();

                    this.openModal();
                }, (error) => { }, () => this.loading = false);
            } else {
                this.formGroup.get('created').setValue(new Date());

                this.formGroup.get('createdBy').setValue(this.currentUser);

                this.loading = false;

                this.openModal();
            }
        });
    }

    public onStorefrontChanges(storefront: Storefront) {
        this.formGroup.controls["category"].setValue(null);

        this.categories$ = null;

        if (storefront && storefront.id) {
            this.categories$ = this.categoryService.getAllByStorefront(storefront.id);

            this.hasStorefront = true;
        }
    }

    set uploadedImages(uploaded) {
        this.isSaved = false;

        this.images = uploaded;
    }

    get uploaded() {
        return this.images;
    }

    public trimText(event) {
        event.target.value = event.target.value.trim();
    }

    public processData() {
        this.validateEntityState();

        if (!this.areAllFieldsChecked()) {
            this.toastr.error("Please, fill all required fields.");

            return;
        }

        if (!this.internal.validated) {
            this.toastr.error("Please, validate this entry before start processing it.");

            return;
        }

        if (!this.isSaved) {
            this.toastr.error("Please, save your data before validating it.");

            return;
        }

        this.internalService.process(this.internal.id).subscribe((assetId) => {
            const toast = this.toastr.success('The data was successfully processed. Click here to access your asset.', 'Success');

            toast.onTap.subscribe(() => {
                this.closeModal();

                this.router.navigate(['/asset', assetId, 'details']);
            });

            this.internal.processedAsset = new Asset();

            this.internal.processedAsset.id = assetId;

            this.updateData.emit(true);

            this.closeModal();
        });
    }

    public validateData() {
        this.validateEntityState();

        if (this.internal.processedAsset?.id) {
            this.toastr.error("This entry was already processed.");

            return;
        }

        if (!this.currentUserHasRole("Owner")) {
            this.toastr.error("Only owners can change the validation status.");

            return;
        }

        this.internalService.changeValidation(this.internal.id).subscribe(() => {
            this.isDisabled = !this.isDisabled;

            this.toastr.success(`The data was successfully ${this.internal.validated ? 'unlocked' : 'validated'}.`, 'Success');

            this.internal.validated = !this.internal.validated;
        });
    }

    private validateEntityState() {
        if (!this.areAllFieldsChecked()) {
            this.toastr.error("Please, fill all required fields.");

            return;
        }

        if (!this.isSaved) {
            this.toastr.error("Please, save your data before validating it.");

            return;
        }
    }

    private disableForm() {
        this.isDisabled = true;

        this.isSaved = true;
    }

    public disableSaveButton() {
        return (this.isSaved || !this.areAllFieldsChecked() || this.internal.validated ||
            (!this.currentUserHasRole('Owner') && !this.currentUserHasRole('Internal')) ||
            (this.internal.processedAsset != null && this.internal.processedAsset.id != 0));
    }

    public formGroup = new FormGroup({
        brd: new FormControl('', [
            Validators.pattern(/^[0-9]*$/)
        ]),

        storefront: new FormControl(null, Validators.required),

        created: new FormControl(null, Validators.required),
        createdBy: new FormControl(null, Validators.required),

        assetName: new FormControl(null, Validators.required),
        assetDescription: new FormControl(null, Validators.required),
        assetAddress: new FormControl(null, [
            Validators.required,
            Validators.pattern(UtilsService.REGEX_URL_WITH_MAILTO)
        ]),
        assetUsageStatsLink: new FormControl('', [
            Validators.pattern(UtilsService.REGEX_URL_WITH_MAILTO)
        ]),
        assetVideoLink: new FormControl('', [
            Validators.pattern(UtilsService.REGEX_URL_WITH_MAILTO)
        ]),
        assetAskAndLearnLink: new FormControl('', [
            Validators.pattern(UtilsService.REGEX_URL_WITH_MAILTO)
        ]),
        assetDocumentationLink: new FormControl('', [
            Validators.pattern(UtilsService.REGEX_URL_WITH_MAILTO)
        ]),
        assetRequestAccessLink: new FormControl('', [
            Validators.pattern(UtilsService.REGEX_URL_WITH_MAILTO)
        ]),
        assetProductLine: new FormControl(null),

        tags: new FormControl(null),
        category: new FormControl(null),
        keyProcesses: new FormControl(null),

        scrumMaster: new FormControl(null, Validators.required),
        assetProductOwner: new FormControl(null, Validators.required)
    });

    private subscribeControls() {
        Object.keys(this.formGroup.controls).forEach(key => {
            this.formGroup.controls[key].valueChanges.subscribe((val) => {
                if (val !== this.originalInternal[key]) {
                    this.isSaved = false;
                }
            });
        });
    }

    private populateEditFields() {
        this.formGroup.setValue({
            assetName: this.internal.assetName,
            assetDescription: this.internal.assetDescription,
            assetAddress: this.internal.assetAddress,
            assetRequestAccessLink: this.internal.assetRequestAccessLink,
            assetDocumentationLink: this.internal.assetDocumentationLink,
            assetAskAndLearnLink: this.internal.assetAskAndLearnLink,
            assetVideoLink: this.internal.assetVideoLink,
            assetUsageStatsLink: this.internal.assetUsageStatsLink,

            brd: this.internal.brd,

            tags: this.internal.tags || [],
            keyProcesses: this.internal.keyProcesses || [],

            scrumMaster: this.internal.scrumMaster,
            assetProductOwner: this.internal.assetProductOwner,

            category: this.internal.category,
            storefront: this.internal.storefront,
            assetProductLine: this.internal.assetProductLine,

            created: this.internal.created,
            createdBy: this.internal.createdBy
        });

        if (this.internal.images) {
            this.internal.images.forEach((img) => {
                UtilsService.isValidImage((validImage) => {
                    if (validImage) {
                        const image: IImage = {
                            localPath: img,
                            image: null,
                            isNew: false
                        };

                        this.images.push(image);
                    } else {
                        this.invalidImages++;
                    }
                }, img);
            });
        }
    }

    private loadFormOptions(): void {
        this.tags$ = this.tagService.getTags();
        this.keyProcesses$ = this.keyProcessService.getAll();
        this.productLines$ = this.productLineService.getAll();

        this.storefrontService.getAll().subscribe((storefronts) => {
            this.storefronts = storefronts;
        });
    }

    private openModal(): void {
        this.modalRef = this.modalService.show(this.modal, {
            animated: true,
            keyboard: false,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'lateral-menu col-md-9 p-0 modal-dialog'
        });
    }

    public add(): void {
        if (!this.areAllFieldsChecked()) {
            this.toastr.error("Please, fill all required fields.");

            return;
        }

        ["assetUsageStatsLink", "assetVideoLink", "assetAskAndLearnLink", "assetDocumentationLink", "assetRequestAccessLink", "assetAddress"].forEach((url) => {
            if (this.formGroup.controls[url].value && !this.formGroup.controls[url].valid) {
                this.toastr.warning("The BI Asset URL Address format must be like: \"http://www.example.com\".", 'Invalid URL address');

                return;
            }
        });

        let internal = new Internal();

        internal = this.formGroup.value;
        internal.id = this.internal.id;

        internal.modified = new Date();
        internal.modifiedBy = this.currentUser;

        if (internal?.id != 0) {
            this.internalService.update(internal, this.images).subscribe(() => {
                this.originalInternal = internal;

                this.isSaved = true;

                this.newInternal.emit(internal);

                this.toastr.success("Internal entry updated successfully.");
            });
        } else {
            internal.created = new Date();
            internal.createdBy = this.currentUser;

            this.internalService.add(internal, this.images).subscribe((result) => {
                internal.id = result;

                internal.created = new Date();
                internal.createdBy = this.currentUser;

                this.internal = internal;
                this.originalInternal = internal;

                this.isSaved = true;
                this.closeModal();

                this.newInternal.emit(internal);

                this.toastr.success("New Internal entry created successfully.");
            });
        }
    }

    public areAllFieldsChecked() {
        return this.formGroup.valid;
    }

    public currentUserHasRole(role: string) {
        return this.currentUser != null &&
            this.currentUser.roles != null &&
            this.currentUser.roles.some((r) => r.name.toLowerCase() == role.toLowerCase());
    }

    public closeModal() {
        this.close.emit(true);

        this.modalRef.hide();
    }

    public removeScreenshotImage(idx) {
        this.images.splice(idx, 1);
    }

    public onSelectImages(event) {
        this.uploadedImages = this.images.concat(event);
    }
}