import { UtilsService } from '~/core/services/utils.service';
import { Asset, IKeyProcessAssets } from './asset.model';
import { Group } from './group.model';
import { KeyProcess } from './keyProcess.model';
import { ModelBase } from './mode-base.model';
import { Storefront } from './storefront.model';
import { User } from './user.model';

export class Category extends ModelBase {
  constructor() {
    super();
  }

  public storefront: Storefront;

  public created: Date;

  public responsible: User;

  public assets: Asset[];

  public keyProcessXAssets: IKeyProcessAssets[];

  public coResponsible: User[];

  public color: string;

  public isPriority: boolean;

  public orderIndex = 99;

  public wasEdited: boolean;

  public ShowKeyProcessXAssetsArea: boolean;

  public iconName: string;

  public slDivisionIds: number[];

  public jobIds: number[];

  public slBusinessLineIds: number[];

  public bfIds: number[];

  public userGroups: Group[];

  // utilized to show the priority sub-categories
  public keyProcesses: KeyProcess[];

  public get isNew(): boolean {
    return UtilsService.isNew(this.created);
  }
}
