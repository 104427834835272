import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterType } from '~/shared/enums/filter-type';
import { FilterOptions } from '~/shared/models/filter-options.model';
import { Storefront } from '~/shared/models/storefront.model';
import { FilterService } from '~/shared/services/filter.service';
import { HeaderService } from '~/shared/services/header.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    public filter = new FilterOptions();
    public selectedStore: Storefront;
    public sortBy = 'default';

    constructor(
        private filterService: FilterService,
        private router: Router,
        private headerService: HeaderService
    ) {}

    public ngOnInit(): void {
        this.filterService.filterOptions$.subscribe((filterOptions) => {
            // if the page was reloaded it will reditect to home instead of show a infinity loading
            if (typeof filterOptions === 'undefined')
                this.router.navigate(['home']);

            this.filter = filterOptions;
        });

        this.headerService.selectedStore$.subscribe((store) => {
            this.selectedStore = store;
        });
    }

    public get showNoResultsMessage() {
        return (
            !this.filter ||
            (this.filter.storesRawCount === 0 &&
                this.filter.assetsRawCount === 0 &&
                this.filter.tagsRawCount === 0)
        );
    }

    public get showNothingMessage() {
        switch (this.filter?.filterType) {
            case FilterType.GLOBAL:
                return (
                    ((((!this.filter.showStoresVerified &&
                        !this.filter.showStoresUnVerified) ||
                        !this.filter.showStoresList) &&
                        this.filter.storesRawCount > 0) ||
                        this.filter.storesRawCount === 0) &&
                    !this.filter.showAssetsList &&
                    this.filter.assetsRawCount > 0 &&
                    !this.filter.showTagsList &&
                    this.filter.tagsRawCount > 0
                );

            case FilterType.ALL_FROM_STORE:
                return !this.filter.showAssetsList && !this.filter.showTagsList;
        }
    }

    public goToStorefrontPage(): void {
        this.router.navigate(['/store', this.selectedStore.id]);
    }

    public toggleSort($event) {
        switch ($event) {
            case 'most-recent':
                this.filter.sortByDate();
                break;
            case 'name':
                this.filter.sortByName();
                break;
        }

        this.sortBy = $event;
    }
}
