<ng-template #template class="lateral-menu">
  <div class="modal-header d-flex align-items-center">
    <h5 class="modal-title pull-left">Details</h5>

    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="container p-0" *ngIf="selectedAsset; else loading">
      <div class="row">
        <div
          class="image col-md-4 d-flex justify-content-center align-items-center">
          <img
            class="logo"
            *ngIf="assetImage"
            src="{{ assetImage }}" />
        </div>

        <div class="col-md-8 pl-0 d-flex flex-column">
          <div
            class="d-flex align-items-center justify-content-between">
            <app-rating
              [Rating]="ReviewStats?.RatingAverage"></app-rating>

            <app-like-asset
              [Asset]="selectedAsset"
              class="pull-right"></app-like-asset>
          </div>

          <span class="title py-2">{{ selectedAsset.name }}
            <svg *ngIf="!selectedAsset.ignoreBiReport  " width="17" height="22" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" tooltip="Valid BI Report Assigned" placement="center" [delay]="250"
              class="position-absolute m-1">
              <path d="M6.39364 0.388081C6.73452 0.154229 7.18416 0.154229 7.52504 0.388081L7.8263 0.59475C8.04468 0.744564 8.31357 0.801634 8.57398 0.75344L8.93361 0.686883C9.34032 0.611612 9.7514 0.794501 9.96781 1.14699L10.1578 1.45643C10.2965 1.68239 10.5193 1.844 10.7771 1.90575L11.1312 1.99053C11.5339 2.08698 11.8356 2.42174 11.8897 2.83235L11.9368 3.18978C11.9715 3.45305 12.1095 3.69171 12.3204 3.85313L12.6073 4.0728C12.9366 4.32487 13.0761 4.75399 12.9581 5.15151L12.8558 5.49593C12.7801 5.75073 12.809 6.02517 12.9361 6.25863L13.1079 6.57439C13.3062 6.93871 13.259 7.38763 12.9892 7.70273L12.755 7.97639C12.5822 8.17824 12.4968 8.44055 12.5177 8.70544L12.5461 9.06397C12.5787 9.47713 12.3531 9.86761 11.9788 10.0457L11.6513 10.2015C11.4117 10.3156 11.2272 10.5201 11.1385 10.7702L11.0176 11.1111C10.8792 11.5012 10.5149 11.7657 10.1011 11.7765L9.73614 11.786C9.47128 11.7929 9.21998 11.9046 9.03742 12.0966L8.78635 12.3607C8.50148 12.6603 8.06158 12.7538 7.67951 12.5958L7.34145 12.456C7.09676 12.3548 6.82193 12.3548 6.57723 12.456L6.23918 12.5958C5.85711 12.7538 5.41721 12.6603 5.13233 12.3607L4.88127 12.0966C4.69871 11.9046 4.4474 11.7929 4.18254 11.786L3.81755 11.7765C3.40382 11.7657 3.03947 11.5012 2.9011 11.1111L2.78017 10.7702C2.69145 10.5201 2.50699 10.3156 2.26738 10.2015L1.93985 10.0457C1.56561 9.86761 1.34 9.47713 1.37263 9.06397L1.40095 8.70544C1.42187 8.44055 1.33651 8.17824 1.16372 7.97639L0.929452 7.70273C0.659708 7.38763 0.61249 6.93871 0.810771 6.57439L0.982619 6.25863C1.10968 6.02517 1.13856 5.75073 1.0629 5.49593L0.960621 5.15151C0.842577 4.75399 0.982112 4.32487 1.31137 4.0728L1.59832 3.85313C1.80917 3.69171 1.94717 3.45305 1.98187 3.18978L2.02898 2.83235C2.08311 2.42174 2.38475 2.08698 2.78753 1.99053L3.14157 1.90575C3.39942 1.844 3.62219 1.68239 3.76091 1.45643L3.95088 1.14699C4.16728 0.794501 4.57836 0.611612 4.98508 0.686883L5.34471 0.75344C5.60511 0.801634 5.874 0.744564 6.09239 0.59475L6.39364 0.388081Z"
                fill="#136FD5" />
              <path d="M6.30531 9.53093C6.19705 9.65274 6.01135 9.66584 5.88705 9.56044L3.13689 7.22832C3.0031 7.11487 2.99477 6.91133 3.11884 6.78732L3.81435 6.09217C3.92476 5.98181 4.10135 5.97457 4.22043 6.07552L5.71761 7.3448C5.8419 7.45017 6.02758 7.43706 6.13583 7.31527L9.5655 3.45675C9.66932 3.33995 9.84541 3.32241 9.97022 3.41643L10.7705 4.01922C10.9105 4.12467 10.9306 4.32716 10.8142 4.45815L6.30531 9.53093Z"
                fill="#F9F9F9" />
            </svg>
          </span>

          <span class="views-count" [attr.title]="selectedAsset?.numberOfViews + ' views'">
            {{getCountViewNumber()}} views</span>

          <span class="product-line">{{
                        selectedAsset.productLine?.name
                        }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 py-3">
          <span class="description">{{
                        selectedAsset.description
                        }}</span>
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-6 pr-1">
          <a
            href="{{ selectedAsset.url }}"
            class="launch btn btn-block"
            target="_blank">Visit BI Asset</a>
        </div>

        <div class="col-6 pl-1">
          <button
            *ngIf="selectedAsset.requestAccessLink"
            (click)="openRequestAccessDetails($event)"
            class="launch btn btn-block">Request Access</button>
        </div>
      </div>

      <div class="row">
        <div class="border-bottom d-flex flex-column" *ngIf="selectedAsset.usingCIM && selectedAsset.cimVerified">

          <label class="d-block field-title">
            <img class=" mr-1" [src]="'../../assets/cube-icon.svg'" />
            Powered by CIM
          </label>

          <span *ngIf="selectedAsset.cimName" class="field" [attr.title]="'CIM'"> {{ selectedAsset.cimName }}</span>

          <div class="d-flex">
            <span *ngFor="let vs of selectedAsset.valueStreams; let i = index"
              class="field ml-1" [attr.title]="'Value Stream'"> {{ vs.name + ((i < selectedAsset.valueStreams.length - 1) ? ', ': '' ) }}</span>
          </div>

        </div>

        <div class="border-bottom">
          <label class="field-title">Product Owner</label>

          <span class="d-block field">
            {{ selectedAsset.productOwner?.name }}
          </span>
        </div>

        <div class="border-bottom">
          <label class="field-title">Storefront</label>

          <span class="d-block field">{{
                        selectedAsset.storefront?.name
                        }}</span>
        </div>

        <div class="border-bottom" *ngIf="selectedAsset.keyProcesses && selectedAsset.keyProcesses.length > 0">
          <label class="field-title">Key Processes/Sub Category</label>

          <span class="d-block fie ld" *ngFor="let kp of selectedAsset.keyProcesses">{{ kp.name }}</span>
        </div>

        <div
          class="border-bottom"
          *ngIf="selectedAsset.tags.length > 0">
          <label class="field-title d-block">Tags</label>

          <div
            class="d-flex p-1 d-inline-flex"
            *ngFor="let tag of selectedAsset.tags">
            <span class="tag"> {{ tag.name }} </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="border-bottom pr-3 w-100"
          *ngIf="selectedAsset.documentationLink">
          <label class="field-title d-block">Documentation Link</label>
          <a
            class="link-address d-block field"
            href="{{ selectedAsset?.documentationLink }}"
            [attr.title]="selectedAsset?.documentationLink"
            target="_blank">
            {{ selectedAsset?.documentationLink }}</a>

          <!-- <span class="d-block field">{{selectedAsset?.DocumentationLink}}</span> -->
        </div>
      </div>
      <div class="border-bottom ml-0 mt-0" *ngIf="(imageObject && imageObject.length > 0) || invalidImages > 0">
        <label class="field-title d-block">Images</label>
        <br>
        <label *ngIf="invalidImages > 0">
          <small>
            <span style="color: red">*</span>
            {{ invalidImages }} image(s) did not load correctly or no longer exist in the database.
          </small>
        </label>
        <ng-image-slider
          *ngIf="imageObject.length > 0"
          [manageImageRatio]="true"
          [imagePopup]="true"
          [imageSize]="{ width: '100px', height: '65px', space: 3 }"
          [animationSpeed]="0.2"
          [arrowKeyMove]="false"
          [images]="imageObject"
          [imagePopup]="true"
          (lightboxClose)="lightboxOppened = false"
          (imageClick)="lightboxOppened = true"
          #nav>
        </ng-image-slider>
      </div>

      <div class="pt-3" *ngIf="videoUrl">

        <label class="field-title">Video</label>

        <iframe
          width="420"
          height="236"
          class="border-0"
          [src]="videoUrl"
          allowfullscreen>
        </iframe>

      </div>

    </div>

    <br />

  </div>

  <div class="modal-footer px-4" *ngIf="selectedAsset">
    <a (click)="redirect()" class="details btn btn-block">More details</a>
  </div>
</ng-template>

<ng-template #loading>
  <div class="d-flex justify-content-center w-100 pt-5">
    <app-loader [height]="60"></app-loader>
  </div>
</ng-template>