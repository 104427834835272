<div class="help-links w-100">

    <div class="row no-gutters row-item mb-2" *ngFor="let link of helpLinks; index as i">

        <div class="col-md-11">
            <input type="text"
                required
                maxlength="100"
                class="custom-input"
                placeholder="Title"
                (focus)="enterFocus(link.title)"
                (focusout)="leaveFocus(link.title)"
                [(ngModel)]="link.title"
                [ngClass]="{  required: formValidated && link.title == ''}" />

            <input type="text"
                required
                maxlength="300"
                class="custom-input"
                placeholder="Link"
                pattern="{{ regex }}"
                (focus)="enterFocus(link.linkAddress)"
                (focusout)="leaveFocus(link.linkAddress)"
                [(ngModel)]="link.linkAddress"
                [ngClass]="{ required: formValidated && ( link.linkAddress == '' || !link.linkAddress.match(regex)) }" />

        </div>

        <div class="col-md-1">
            <button type="button"
                class="btn-remove"
                (click)="removeItem(i)"
                [attr.title]="'Remove useful link'">
                <i class="fas fa-trash-alt"></i>
            </button>
        </div>

    </div>

    <div class="btn-add-new px-2 cursor-pointer" (click)="addNew()"
        [attr.title]="'Add useful link'">
        <i class="fas fa-plus"></i>
    </div>

</div>
