import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-toggle-switch-button',
    templateUrl: './toggle-switch-button.component.html',
    styleUrls: ['./toggle-switch-button.component.scss']
})
export class ToggleSwitchButtonComponent implements OnInit {

    @Input() checked = false;

    @Input() confirmationMessage: string;

    @Output()
    public changed: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit(): void {
    }

    public changeValue($event) {

        if (this.confirmationMessage) {
            var ok = window.confirm(this.confirmationMessage);
            if (ok) {
                this.checked = !this.checked;
                this.changed.emit(this.checked);
            } else {
                $event.preventDefault()
            }
        }
        else {
            this.checked = !this.checked;
            this.changed.emit(this.checked);
        }
    }

}
