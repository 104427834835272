import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Tag } from '~/shared/models/tag.model';

@Component({
  selector: 'app-create-edit-storefront-tag',
  templateUrl: './create-edit-storefront-tag.component.html',
  styleUrls: ['./create-edit-storefront-tag.component.scss']
})
export class CreateEditStorefrontTagComponent implements OnInit {
  @ViewChild('template', { static: true }) public elementView: ElementRef;

  @Output() public close = new EventEmitter();

  @Output() public storefrontTagSaved = new EventEmitter<Tag>();

  @Input() public storefrontTag = new Tag();

  @Input() public createdTags: Array<Tag>;

  public name: string;
  public color: string;

  private config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'center-modal modal-dialog-centered'
  };

  @Input() public settings = [];

  public modalRef: BsModalRef;

  public modalTitle: string;

  constructor(private modalService: BsModalService) {}

  public ngOnInit(): void {
    this.modalRef = this.modalService.show(this.elementView, this.config);
    this.modalTitle = this.storefrontTag
      ? 'Edit Storefront Tag'
      : 'Create Storefront Tag';

    if (this.storefrontTag.id) {
      this.color = this.storefrontTag.color;
      this.name = this.storefrontTag.name;
    }
  }

  public save() {
    let duplicatedName = false;

    this.createdTags.forEach((tag) => {
      if (
        tag.name.toLocaleLowerCase() === this.name.toLocaleLowerCase() &&
        (!this.storefrontTag || tag.id !== this.storefrontTag.id)
      ) {
        duplicatedName = true;

        return;
      }
    });

    if (duplicatedName) {
      window.alert(`you already have a Tag called ${this.name}`);

      return;
    }

    if (!this.storefrontTag) this.storefrontTag = new Tag();

    this.storefrontTag.color = this.color;
    this.storefrontTag.name = this.name;
    this.storefrontTag.active = true;

    this.storefrontTagSaved.emit(this.storefrontTag);

    this.closeModal();
  }

  public noCLick() {
    this.closeModal();
  }

  private closeModal() {
    this.close.emit();
    this.modalRef.hide();
  }

  public get ckeckDisabled(): boolean {
    return !this.name || !this.color ? true : false;
  }
}
