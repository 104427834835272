<ng-container *ngIf="RelatedStores?.length">
    <app-board-header [Title]="'Appears on'"> </app-board-header>

    <div class="container">
        <div class="row pt-2">
            <app-card-storefront-simple
                class="d-block col-md-3 p-1"
                *ngFor="let store of RelatedStores"
                [Store]="store"
            ></app-card-storefront-simple>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <div class="d-flex justify-content-center w-100">
        <app-loader [height]="32"></app-loader>
    </div>
</ng-template>
