import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';
import { Observable } from 'rxjs/internal/Observable';
import { RoleTypes } from '../enums/role-type';
import { IImage } from '../models/image.model';
import { Internal } from '../models/internal.model';
import { User } from '../models/user.model';
import { ApiServiceBase } from './api-service-base.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class InternalService extends ApiServiceBase<Internal> {
    constructor(
        protected http: HttpClient,
        private router: Router,
        private usersService: UserService) {
        super(http, 'internal');
    }

    public getAll(): Observable<Internal[]> {
        return this.get();
    }

    public getById(id: number): Observable<Internal> {
        return this.getSingle(id.toString());
    }

    public update(internal, images?: IImage[]): Observable<number> {
        return this.put(this.setFieldsToRequest(internal, images));
    }

    public add(internal, images?: IImage[]): Observable<number> {
        return this.post(this.setFieldsToRequest(internal, images));
    }

    public getOptions(): Observable<any> {
        return this.get('options');
    }

    public changeValidation(id: number): Observable<void> {
        return this.http.put<void>(`${environment.apiUrl}/internal/validate/${id}`, null, this.HEADERS);
    }

    public process(id: number): Observable<number> {
        return this.http.put<number>(`${environment.apiUrl}/internal/process/${id}`, null, this.HEADERS);
    }

    public async canActivate() {
        let user: User;

        await this.usersService.getCurrentUser().then((obj) => {
            user = obj.currentUser;
        });

        if (!user.roles.some((r) => [RoleTypes.Internal, RoleTypes.Owner].some((role) => role === r.name)))
        {
            this.router.navigate(['home']);

            return false;
        }

        return true;
    }

    private setFieldsToRequest(internal: Internal, images?: IImage[]) {
        return {
            Id: internal.id,
            ScrumMaster: internal.scrumMaster,
            Brd: internal.brd,

            Storefront: internal.storefront,

            AssetName: internal.assetName,
            AssetDescription: internal.assetDescription,
            AssetAddress: internal.assetAddress,
            AssetVideoLink: internal.assetVideoLink,
            AssetAskAndLearnLink: internal.assetAskAndLearnLink,
            AssetUsageStatsLink: internal.assetUsageStatsLink,
            AssetDocumentationLink: internal.assetDocumentationLink,
            AssetRequestAccessLink: internal.assetRequestAccessLink,
            AssetProductOwner: internal.assetProductOwner,
            AssetProductLine: internal.assetProductLine,

            Tags: internal.tags,
            KeyProcesses: internal.keyProcesses,

            Category: internal.category,
            CategoryResponsible: internal.categoryResponsible,

            UploadedImages:
                images && images.length > 0
                    ? images.map((i) => {
                        return {
                            Base64: i.localPath,
                            Name: i.image?.name ? i.image.name : null,
                            ImageType: i.image?.type ? i.image.type : null,
                            IsNew: i.isNew
                        };
                    }) : null
        }
    }
}