import { FilterService } from "@/services/filter.service";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Storefront } from '~/shared/models/storefront.model';

@Component({
    selector: 'app-storefront-carousel',
    templateUrl: './storefront-carousel.component.html',
    styleUrls: ['./storefront-carousel.component.scss']
})
export class StorefrontCarouselComponent implements OnInit {

    @ViewChild('scrollableDiv', { static: true })
    public scrollableDiv: ElementRef;

    @Input()
    public Title: string;

    @Input()
    public isLoading = false;

    @Input()
    public showFullCards = false;

    @Input() set Stores(stores: Storefront[]) {
        this._stores = stores;
        this._checkedOverflow = false;
    }

    get Stores(): Storefront[] {
        if (this.scrollableDiv.nativeElement.scrollWidth != this._previousScrollValue)
            this.checkOverflow();

        return this._stores;
    }

    @Output()
    public clickLike = new EventEmitter();

    private _stores: Storefront[];

    private _previousScrollValue = 0;

    private _checkedOverflow = false;

    public showLeftButton = false;

    public showRightButton = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private filterService: FilterService,
    ) { }

    public ngOnInit() { }

    public ngAfterContentChecked() {
        this.changeDetector.detectChanges();
    }

    public checkOverflow() {
        this._previousScrollValue = this.scrollableDiv.nativeElement.scrollWidth;
        if (
            this.scrollableDiv.nativeElement.offsetWidth <
            this.scrollableDiv.nativeElement.scrollWidth
        ) {
            this.showRightButton = true;
            this._checkedOverflow = true;
        } else {
            this.showRightButton = false;
            this.showLeftButton = false;
        }
    }

    public scrollRight() {
        const scrollLeftResult =
            this.scrollableDiv.nativeElement.scrollLeft + 500;

        this.scrollableDiv.nativeElement.scrollTo({
            left: scrollLeftResult,
            behavior: 'smooth'
        });

        this.showLeftButton = true;

        const maxScrollLeft =
            this.scrollableDiv.nativeElement.scrollWidth -
            this.scrollableDiv.nativeElement.clientWidth;
        if (scrollLeftResult >= maxScrollLeft) this.showRightButton = false;
    }

    public scrollLeft() {
        const scrollLeftResult =
            this.scrollableDiv.nativeElement.scrollLeft - 500;

        this.scrollableDiv.nativeElement.scrollTo({
            left: scrollLeftResult,
            behavior: 'smooth'
        });

        this.showRightButton = true;

        if (scrollLeftResult <= 0) this.showLeftButton = false;
    }

    public emitLike(storefront: Storefront) {
        this.clickLike.emit(storefront);
    }

    public OpenAllStores() {
        this.filterService.ShowAllStores();
    }
}
