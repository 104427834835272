<div class="container-fluid p-0 home-page" style="margin-top: 50px">

  <div class="container">

    <div class="row">

      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">

        <app-asset-carousel
          [Assets]="favouriteAssets"
          [isLoading]="isLoadingRequest.favouriteAssets"
          [Title]="'Favourites (' +  favouriteAssets?.length + ')'">
        </app-asset-carousel>

      </div>

      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0" style="margin-top: 40px;">

        <app-asset-carousel
          [Assets]="recommendedAssets"
          [isLoading]="isLoadingRequest.recommendedAssets"
          [Title]="'Recommended (' +  recommendedAssets?.length + ')'">
        </app-asset-carousel>

      </div>

    </div>

  </div>

</div>

<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center w-100" style="height: 156px">
    <app-loader [height]="30"></app-loader>
  </div>
</ng-template>