import { Component, OnInit } from '@angular/core';
import { Asset } from '~/shared/models/asset.model';
import { AssetService } from '~/shared/services/asset.service';
import { LikedAssetService } from '~/shared/services/liked-asset.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-my-bi-dashboard',
  templateUrl: './my-bi-dashboard.component.html',
  styleUrls: ['./my-bi-dashboard.component.scss']
})
export class MyBiDashboardComponent implements OnInit {
  constructor(
    private usersService: UserService,
    private assetsService: AssetService,
    private likedAssetsService: LikedAssetService
  ) {}

  public isLoadingRequest = {
    favouriteAssets: true,
    recommendedAssets: true
  };

  public favouriteAssets: Array<Asset> = [];
  public recommendedAssets: Array<Asset> = [];

  public ngOnInit(): void {
    this.getFavouriteAssets();
    this.getRecommendedAssets();
  }

  public getFavouriteAssets() {
    this.likedAssetsService.getLikedAssetsByUser().subscribe((assets) => {
      this.favouriteAssets = assets;
      this.isLoadingRequest.favouriteAssets = false;
    });
  }

  public getRecommendedAssets() {
    this.assetsService.getRecommended().subscribe((assets) => {
      this.recommendedAssets = assets;
      this.isLoadingRequest.recommendedAssets = false;
    });
  }
}
