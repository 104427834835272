<ng-container *ngIf="isSandBox && !hideNotification">
    <div
        class="alert alert-warning rounded-0 m-0 d-flex justify-content-between"
        role="alert"
    >
        <div class="flex-fill">
            <p class="m-0">
                <strong>Sandbox environment.</strong>
                This is a testing environment. The datum on this site may not be
                accurate.
            </p>
        </div>

        <button
            type="button"
            class="close p-1"
            aria-label="Close"
            (click)="dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</ng-container>
