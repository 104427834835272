import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { UtilsService } from '~/core/services/utils.service';
import { Asset, IKeyProcessAssets } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { Tag } from '~/shared/models/tag.model';

@Component({
  selector: 'app-card-category-and-subcategory-detailed',
  templateUrl: './card-category-and-subcategory-detailed.component.html',
  styleUrls: ['./card-category-and-subcategory-detailed.component.scss']
})
export class CardCategoryAndSubcategoryDetailedComponent
  implements OnInit, OnChanges
{
  @Input()
  public category: Category;

  @Input()
  public tagsToFilter: Array<Tag> = [];

  private previusTagsToFilter: Array<Tag> = null;

  public loading = true;

  @Input()
  public color = '#1683FB';

  @Input()
  public sortBy = 'default';

  // the original list of items
  private keyProcessesAndAssets: IKeyProcessAssets[] = [];

  // list that will be shown on the ui
  public keyProcessesAndAssetsToShow: IKeyProcessAssets[] = [];

  public maxAssetsToShowPerKeyProcess: Array<number> = [];

  public showAll = false;

  public totalAssetsBeingDisplayed = 0;

  constructor() {}

  public ngOnInit() {
    this.keyProcessesAndAssets = this.category.keyProcessXAssets.slice(0);

    // add all assets that does not have any key process.
    const assetsWithoutSubCategory = this.category.assets.filter(
      (a) => a.keyProcesses.length === 0
    );
    if (assetsWithoutSubCategory.length > 0)
      this.keyProcessesAndAssets.push({
        keyProcessName: 'All Other BI Assets',
        assets: assetsWithoutSubCategory,
        orderIndex: 1000
      });

    this.keyProcessesAndAssets.forEach((kpa) => {
      const defaulNumberToShow = 2;

      const numberOfPriorityAssets = kpa.assets.filter(
        (a) => a.isPriority
      ).length;

      if (kpa.assets.length < defaulNumberToShow) {
        this.maxAssetsToShowPerKeyProcess.push(kpa.assets.length);
      } else if (numberOfPriorityAssets > 2) {
        this.maxAssetsToShowPerKeyProcess.push(numberOfPriorityAssets);
      } else {
        this.maxAssetsToShowPerKeyProcess.push(defaulNumberToShow);
      }
    });

    this.keyProcessesAndAssets = this.keyProcessesAndAssets.sort((a, b) => {
      // all other bi asset to the end
      if (a.keyProcessName === 'All Other BI Assets') return 1;

      if (a.orderIndex !== b.orderIndex) {
        return a.orderIndex - b.orderIndex;
      } else {
        return a.keyProcessName
          .toLowerCase()
          .localeCompare(b.keyProcessName.toLowerCase());
      }
    });

    this.keyProcessesAndAssetsToShow = JSON.parse(
      JSON.stringify(this.keyProcessesAndAssets)
    );

    this.calculateTotalAssetsBeingDisplayed();

    this.loading = false;
  }

  public ngDoCheck(): void {
    if (
      !this.previusTagsToFilter ||
      this.tagsToFilter.length !== this.previusTagsToFilter.length
    ) {
      this.filterAssetsByTags();
    }

    this.previusTagsToFilter = JSON.parse(JSON.stringify(this.tagsToFilter));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.sortBy) {
      this.keyProcessesAndAssetsToShow.forEach((kpa) => {
        kpa.assets = this.sortArray(kpa.assets);
      });
    }
  }

  public filterAssetsByTags(): void {
    this.keyProcessesAndAssetsToShow = JSON.parse(
      JSON.stringify(this.keyProcessesAndAssets)
    );

    if (this.tagsToFilter?.length > 0) {
      this.keyProcessesAndAssets.forEach((kpa, idx) => {
        const filteredAssets = [];

        kpa.assets.forEach((asset) => {
          this.tagsToFilter.forEach((selectedTag) => {
            if (asset.tags.some((tag) => tag.id === selectedTag.id)) {
              const i = filteredAssets.findIndex((x) => x.id === asset.id);
              if (i <= -1) {
                filteredAssets.push(asset);
              }
            }
          });
        });

        this.keyProcessesAndAssetsToShow[idx].assets = JSON.parse(
          JSON.stringify(filteredAssets)
        );
      });

      this.showAll = true;
    } else {
      this.keyProcessesAndAssetsToShow = JSON.parse(
        JSON.stringify(this.keyProcessesAndAssets)
      );
    }

    this.calculateTotalAssetsBeingDisplayed();
  }

  public calculateTotalAssetsBeingDisplayed(): void {
    let total = 0;

    if (this.showAll || this.tagsToFilter?.length > 0) {
      this.keyProcessesAndAssetsToShow?.forEach((kpa) => {
        total += kpa.assets.length;
      });
    } else {
      this.maxAssetsToShowPerKeyProcess.forEach((max) => {
        total += max;
      });
    }

    this.totalAssetsBeingDisplayed = total;
  }

  private sortArray(assets: Array<Asset>): Array<Asset> {
    if (assets) {
      if (this.sortBy === 'most-recent') {
        return UtilsService.sortArrayByMostRecentAndPriority(assets);
      } else if (this.sortBy === 'name') {
        return UtilsService.sortArrayByTitleAndPriority(assets);
      } else {
        return UtilsService.sortArrayByOrderIndexAndPriority(assets);
      }
    } else {
      return assets;
    }
  }

  public get assetsToShow(): Array<Asset> {
    if (this.tagsToFilter?.length > 0) {
      const filteredAssets = [];
      this.category.assets.forEach((asset) => {
        this.tagsToFilter.forEach((selectedTag) => {
          if (asset.tags.some((tag) => tag.id === selectedTag.id)) {
            const i = filteredAssets.findIndex((x) => x.id === asset.id);
            if (i <= -1) {
              filteredAssets.push(asset);
            }
          }
        });
      });

      return filteredAssets;
    } else {
      return this.category.assets;
    }
  }
}
