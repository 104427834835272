import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Asset } from '~/shared/models/asset.model';
import { AssetService } from '~/shared/services/asset.service';
import { LateralMenuService } from '~/shared/services/lateral-menu.service';
import { RequestAccessDetailsService } from '~/shared/services/request-access-description.service';
import { TagService } from '~/shared/services/tag.service';

@Component({
  selector: 'app-asset-category-label',
  templateUrl: './asset-category-label.component.html',
  styleUrls: ['./asset-category-label.component.scss']
})
export class AssetCategoryLabelComponent implements OnInit {
  @Input()
  public asset: Asset;

  constructor(
    private lateralMenuService: LateralMenuService,
    private tagService: TagService,
    private requestAccessDetailsService: RequestAccessDetailsService,
    private assetService: AssetService
  ) {}

  public ngOnInit(): void {}

  public openAssetAddress(url: any, event) {
    event.stopPropagation();
    window.open(url, '_blank');
  }

  public openRequestAccessDetails(event) {
    event.stopPropagation();
    this.requestAccessDetailsService.setRequestAccess(
      this.asset.requestAccessLink,
      true
    );
  }

  public assetClicked(asset: Asset) {
    event.stopPropagation();
    this.lateralMenuService.setAsset(asset);
  }

  public assetTagsColors(asset: Asset) {
    return asset.tags.map((tag) => tag.color);

    // return this.tagService.getTagsColors(asset.Tags);
  }

  public openEmail(asset: Asset) {
    event.stopPropagation();

    this.assetService
      .getAssetById(asset.id)
      .pipe(take(1))
      .subscribe((a) => {
        const mailText = `mailto:${a.coOwners.map((u) => {
          return u.email + ';';
        })}${a.productOwner.email}?subject=${a.name}&body=`;
        window.location.href = mailText;
      });
  }
}
