import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from '~/core/services/utils.service';

@Component({
    selector: 'app-created-category-list',
    templateUrl: './created-category-list.component.html',
    styleUrls: ['./created-category-list.component.scss']
})
export class CreatedCategoryListComponent implements OnInit {
    @Input()
    public categoryId: number;
    @Input()
    public categoryIcon: string;
    @Input()
    public categoryName: string;
    @Input()
    public categoryResponsible: string;
    @Input()
    public index: number;

    @Output()
    private removeCategory = new EventEmitter();

    @Output()
    private editCategory = new EventEmitter();

    public showDeleteModal = false;
    public deleteModalSettings = {
        isDelete: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'DELETE CATEGORY',
        title: 'Delete Category',
        description: 'Are you sure you want to delete?'
    };

    constructor() { }

    public ngOnInit(): void { }

    public onClickEdit(): void {
        this.editCategory.emit(this.index);
    }

    public getTextFirstLetters(): string {
        return UtilsService.getInitialLetters(this.categoryName);
    }

    public closeModalDelete(event) {
        if (event) {
            this.removeCategory.emit(this.index);
        }
        this.showDeleteModal = false;
    }
}
