<ng-container *ngIf="isSupported && isOutdated && !hideNotification">
    <div
        class="alert alert-warning rounded-0 d-flex justify-content-between"
        role="alert"
    >
        <div class="flex-fill text-center">
            <p>
                <strong>Outdated browser.</strong> To improve your security and
                for a better experience, keep your browser up to date.
                <a href="https://browsehappy.com/" target="_blank"
                    >Check here</a
                >
                for latest versions.
                <span class="current-version"
                    >(Current: {{ browser.name }} {{ browser.version }})</span
                >
            </p>
        </div>

        <button
            type="button"
            class="close p-3"
            aria-label="Close"
            (click)="dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="!isSupported">
    <div
        class="alert alert-warning rounded-0 d-flex justify-content-between"
        role="alert"
    >
        <div class="flex-fill text-center">
            <p>
                Your current browser
                <span class="current-version"
                    >({{ browser.name }} {{ browser.version }})</span
                >
                is not supported. Prefer to use the Google Chrome, Mozilla
                Firefox or Microsoft Edge.
            </p>
        </div>

        <button
            type="button"
            class="close p-3"
            aria-label="Close"
            (click)="dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</ng-container>
