<div class="modal body d-flex justify-content-center align-items-center" [@fadeInOut]>
  <div class="container form-area p-5">
    <form [formGroup]="assetForm">

      <div class="row mb-3">
        <div class="col-md-3 pr-0">
          <span class="pb-2 title f-16">BI Asset Logo
            <app-image-upload [fileType]="'.png'" [multiple]="false" (filesSelected)="onSelectLogo($event)">
            </app-image-upload>
          </span>

          <div class="picture d-flex flex-column justify-content-center align-items-center">
            <img class="logo" [src]="
                            logo?.localPath || '/assets/placeholder-camera.png'
                        " />
          </div>
        </div>

        <div class="col-md-9 pt-3">
          <div class="row pl-0 pb-3">
            <div class="col-md-12 pl-0">

              <app-bi-reports-selector
                [showRequired]="(!this.assetForm.controls.ignoreBiReport.value && !this.assetForm.controls.biReport.value && submitted) || (this.assetForm.controls.ignoreBiReport.value === true && !assetForm.controls['url'].valid && submitted)"
                [asset]="asset"
                [ignoreBiReport]="asset.ignoreBiReport"
                (selectBiReport)="biReportSelected($event)"
                (setURLAddress)="setURLAddress($event)"
                (setIgnoreBIReport)="setIgnoreBIReport($event)">
              </app-bi-reports-selector>

            </div>
          </div>
          <div class="row pl-0 py-3">
            <div class="col-md-12 pl-0">
              <label class="custom-label">BI Asset Name*</label>
              <input type="text" class="custom-input" formControlName="title" required
                maxlength="100" [ngClass]="{
                                required:
                                    submitted &&
                                    !assetForm.controls['title'].valid
                            }" />
            </div>
          </div>

          <div class="row pl-0 py-3">
            <div class="col-md-6 pl-0">
              <label class="custom-label">PL/BL</label>
              <ng-select class="m-0" [items]="productLines$ | async"
                formControlName="productLine" [bindLabel]="'name'"></ng-select>
            </div>

            <div class="col-md-6">
              <label class="custom-label">Product Owner*</label>
              <div [ngClass]="{
                                required:
                                    submitted &&
                                    !assetForm.controls['productOwner'].valid
                            }">
                <app-user-picker [multiple]="false"
                  formControlName="productOwner">
                </app-user-picker>
              </div>
            </div>
          </div>

          <div class="row pl-0 py-3">
            <div class="col-md-12 pl-0">
              <label class="custom-label">Co-Product Owners</label>
              <app-user-picker appendTo="modal" class="co-owners w-100"
                [multiple]="true" formControlName="coOwners">
              </app-user-picker>
            </div>
          </div>
          <div class="row pl-0 pb-0 pt-3">
            <div class="col-md-6 pl-0">
              <label class="custom-label">Storefront*</label>
              <div [ngClass]="{required: submitted && !assetForm.controls['storefront'].valid }">

                <ng-select
                  class="m-0"
                  [multiple]="false"
                  [items]="storefronts$ | async"
                  [bindLabel]="'name'"
                  formControlName="storefront">
                </ng-select>

              </div>
            </div>

            <div class="col-md-6">
              <label class="custom-label">Key Process/Sub Category</label>
              <div>
                <ng-select [multiple]="true" class="m-0" [items]="keyProcesses$ | async"
                  [bindLabel]="'name'" formControlName="keyProcesses">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row py-3">
        <div class="col-md">
          <label class="custom-label">Description*</label>
          <textarea type="text" maxlength="2000" class="custom-input" formControlName="name" rows="4"
            formControlName="description" [ngClass]="{
                        required:
                            submitted &&
                            !assetForm.controls['description'].valid
                    }">
                </textarea>
        </div>
      </div>

      <div class="row py-3">
        <div class="col-md">
          <label class="custom-label">More Info</label>
          <textarea
            type="text"
            maxlength="2000"
            class="custom-input"
            formControlName="moreInfo"
            rows="2"
            formControlName="moreInfo"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 py-3">
          <label class="custom-label">Search Tags (Only Used For Search)</label>
          <input
            type="text"
            class="custom-input"
            maxlength="2000"
            formControlName="searchTags" />
        </div>

        <div class="col-md-6 py-3">
          <label class="custom-label">Global Level Tags</label>
          <div>
            <ng-select class="m-0" [multiple]="true" [items]="globalTags$ | async" bindLabel="name"
              formControlName="globalTags">
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 py-3">
          <label class="custom-label">Storefront Level Tags</label>
          <div>
            <ng-select class="m-0"
              [multiple]="true"
              [items]="storefrontTags$ | async" bindLabel="name"
              formControlName="storefrontTags">

              <div *ng-option-tmp="let tag = item" title="Storefront: {{ tag?.storefront?.name }}">
                <span>{{ tag.name }}</span>
              </div>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row py-3 pl-3">
        <div class="col-md-4 d-flex align-items-center ">
          <label class="check-box-container">Using CIM
            <input
              type="checkbox"
              [checked]="false"
              formControlName="usingCIM" />
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="col-md-4">
          <label class="custom-label">CIM Name</label>
          <input
            type="text"
            class="custom-input mt-1"
            maxlength="2000"
            formControlName="cimName" />
        </div>

        <div class="col-md-4">
          <label class="custom-label">Value Stream</label>
          <div>
            <ng-select
              class="m-0"
              [multiple]="true"
              [items]="valueStreams$ | async"
              bindLabel="name"
              formControlName="valueStreams">
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 py-3">
          <label class="custom-label">Usage Stats Address</label>
          <input type="text" maxlength="250" class="custom-input"
            formControlName="usageStats" [attr.title]="'Usage Stats Address'" autocomplete="off" [ngClass]="{
                        required: !assetForm.controls['usageStats'].valid
                    }" onkeyup="this.value=this.value.trim()" (focusout)="trimText($event)" />
        </div>

        <div class="col-md-12 py-3">
          <label class="custom-label">Video Address</label>
          <input type="text" maxlength="250" class="custom-input"
            formControlName="videoUrl" [attr.title]="'Video Address'" autocomplete="off" [ngClass]="{
                        required: !assetForm.controls['videoUrl'].valid
                    }" onkeyup="this.value=this.value.trim()" (focusout)="trimText($event)" />
        </div>

        <div class="col-md-12 py-3">
          <label class="custom-label">Ask And Learn Address</label>
          <input type="text" maxlength="300" class="custom-input"
            formControlName="askAndLearn" [attr.title]="'Ask And Learn Address'" autocomplete="off"
            [ngClass]="{
                        required: !assetForm.controls['askAndLearn'].valid
                    }" onkeyup="this.value=this.value.trim()" (focusout)="trimText($event)" />
        </div>

        <div class="col-md-12 py-3">
          <label class="custom-label">Documentation Address</label>
          <input type="text" maxlength="300" class="custom-input"
            formControlName="documentationLink" [attr.title]="'Documentation Address'" autocomplete="off"
            [ngClass]="{
                        required: !assetForm.controls['documentationLink'].valid
                    }" onkeyup="this.value=this.value.trim()" (focusout)="trimText($event)" />
        </div>

        <div class="col-md-12 py-3">
          <label class="custom-label">Request Access</label>
          <ckeditor
            [attr.title]="'Request Access'"
            [editor]="editor"
            [config]="config"
            [ngClass]="{
                            required: assetForm.controls['requestAccessLink'].value.length > 6000
                        }"
            formControlName="requestAccessLink">
          </ckeditor>

          <div class="d-flex justify-content-end w-100 pt-2">
            <span style="font-size: 12px;">{{ assetForm.controls['requestAccessLink'].value.length + '/6000' }}</span>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-6 py-3">
          <label class="custom-label">SL Divisions</label>
          <div>
            <ng-select
              class="m-0"
              [multiple]="true"
              [items]="slDivisions$ | async"
              formControlName="slDivisions"
              [searchable]="true"
              [searchFn]="searchFitForRoleField"
              (change)="updatedSlDivisions()"
              [compareWith]="compareFn">
              <ng-template ng-header-tmp>
                <div class="ng-value-label select-all">
                  <span class="cursor-pointer" (click)="onSelectAllSlDivisions()">Select All</span>
                </div>
              </ng-template>

              <ng-template
                ng-label-tmp
                let-item="item"
                let-clear="clear">
                <span
                  class="ng-value-icon left"
                  (click)="clear(item)">×</span>
                <span class="ng-value-label">{{ item.code }} - {{ item.description }}</span>
              </ng-template>

              <div
                *ng-option-tmp="let slDivision = item"
                title="{{ slDivision.code }} - {{
                                    slDivision.description
                                }}">
                <span>{{ slDivision.code }} -
                  {{ slDivision.description }}</span>
              </div>
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 py-3">
          <div class="d-flex">
            <label class="custom-label">Jobs</label>

            <div class="d-flex ml-auto" *ngIf="this.isCurrentUserOwner">
              <span style="margin: 0 9px; font-size: 14px; color: #272c39; opacity: 1">{{ showAllJobTags ? 'Show Whitelist' : 'Hide Whitelist' }} </span>
              <app-toggle-switch-button
                [checked]="false"
                (changed)="showAllJobTagsChanged($event)">
              </app-toggle-switch-button>
            </div>

          </div>

          <div>
            <ng-select
              class="m-0"
              [multiple]="true"
              [items]="jobs$ | async"
              [clearOnBackspace]="false"
              formControlName="jobs"
              [hideSelected]="true"
              [searchable]="true"
              [searchFn]="searchFitForRoleField"
              (change)="updatedJobs()"
              [compareWith]="compareFn"
              [clearable]="false">
              <ng-template ng-header-tmp>
                <div class="ng-value-label select-all">
                  <span class="cursor-pointer" (click)="onSelectAllJobs()">Select All</span>
                </div>
              </ng-template>

              <ng-template
                ng-label-tmp
                let-item="item"
                let-clear="clear">
                <span
                  *ngIf="!item.bucketId || this.isCurrentUserOwner"
                  class="ng-value-icon left"
                  (click)="clear(item)">×</span>

                <span class="ng-value-label" [attr.title]="item.bucketId ? 'Read Only' : ''">
                  <i *ngIf="item.bucketId" class="fas fa-eye mr-1"></i>
                  {{ item.code }} - {{ item.description }}
                </span>
              </ng-template>

              <div
                *ng-option-tmp="let job = item"
                title="{{ job.code }} - {{ job.description }}">
                <span>
                  <i *ngIf="job.bucketId && job.bucketId < 3" class="fas fa-eye mr-1"></i>
                  {{ job.code }} - {{ job.description }}
                </span>
              </div>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 py-3">
          <label class="custom-label">SL Business Lines</label>
          <div>
            <ng-select
              class="m-0"
              [multiple]="true"
              [items]="slBusinessLines$ | async"
              formControlName="slBusinessLines"
              [searchable]="true"
              [searchFn]="searchFitForRoleField"
              (change)="updatedSlBusinessLines()"
              [compareWith]="compareFn">
              <ng-template ng-header-tmp>
                <div class="ng-value-label select-all">
                  <span class="cursor-pointer" (click)="onSelectAllSlBusinessLines()">Select All</span>
                </div>
              </ng-template>

              <ng-template
                ng-label-tmp
                let-item="item"
                let-clear="clear">
                <span
                  class="ng-value-icon left"
                  (click)="clear(item)">×</span>
                <span class="ng-value-label">{{ item.code }} - {{ item.description }}</span>
              </ng-template>

              <div
                *ng-option-tmp="let slBusinessLine = item"
                title="{{ slBusinessLine.code }} - {{
                                    slBusinessLine.description
                                }}">
                <span>{{ slBusinessLine.code }} -
                  {{ slBusinessLine.description }}</span>
              </div>
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 py-3">
          <label class="custom-label">Business Functions</label>
          <div>
            <ng-select
              class="m-0"
              [multiple]="true"
              [items]="bfs$ | async"
              formControlName="bfs"
              [searchable]="true"
              [searchFn]="searchFitForRoleField"
              (change)="updatedBfs()"
              [compareWith]="compareFn">
              <ng-template ng-header-tmp>
                <div class="ng-value-label select-all">
                  <span class="cursor-pointer" (click)="onSelectAllBfs()">Select All</span>
                </div>
              </ng-template>

              <ng-template
                ng-label-tmp
                let-item="item"
                let-clear="clear">
                <span
                  class="ng-value-icon left"
                  (click)="clear(item)">×</span>

                <span class="ng-value-label">{{ item.code }} - {{ item.description }}</span>
              </ng-template>

              <div
                *ng-option-tmp="let bf = item"
                title="{{ bf.code }} - {{ bf.description }}">
                <span>{{ bf.code }} - {{ bf.description }}</span>
              </div>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-md-12 pt-3">
          <label class="check-box-container">Active
            <input type="checkbox" [checked]="asset.active" formControlName="active" />
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="col-md-12 pt-3">
          <label class="check-box-container">Retired
            <input type="checkbox" [checked]="asset.retired" formControlName="retired" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="row py-3">
        <div class="select-image d-flex flex-column py-5 px-3 w-100">
          <span class="pb-2 title f-16">Images
            <app-image-upload [fileType]="'.png'" [multiple]="true"
              (filesSelected)="onSelectImages($event)">
            </app-image-upload>
          </span>

          <span *ngIf="images.length == 0" class="no-selected pl-3 pt-3">No images selected</span>

          <div class="row no-gutters w-100">
            <div *ngFor="let img of images; let idx = index" class="position-relative col-md-2 p-1">
              <img class="screenshot" [src]="img.localPath" />

              <div class="remove-img d-flex align-items-center justify-content-center">
                <span class="cursor-pointer" (click)="removeScreenshotImage(idx)">
                  <svg width="10" height="10" viewBox="0 0 13 13" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668
              5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
                      fill="white" />
                  </svg>
                  Remove Image
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button type="button" class="button-bordered-blue col-md-2" (click)="cancel()">
          Cancel
        </button>
        <button type="button" class="button-blue col-md-2" (click)="submit()">
          {{ asset?.id ? 'UPDATE BI ASSET' : 'PUBLISH BI ASSET' }}
        </button>
      </div>
    </form>
  </div>

  <ng-template #loading>
    <div class="d-flex justify-content-center w-100 pt-5">
      <app-loader [height]="50"></app-loader>
    </div>
  </ng-template>

  <app-loading-screen *ngIf="saving"></app-loading-screen>

</div>