<div class="home-page">
    <div class="container-fluid p-0 home-page">

        <div *ngIf="homeSelectedTopTab === 'storefronts'" class="container" [@inAnimation]>

            <div class="row">

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 ">

                    <app-storefront-carousel
                        [Title]="'Favourites (' +  favouriteStorefronts.length + ')'"
                        [Stores]="favouriteStorefronts"
                        [showFullCards]="true"
                        [isLoading]="isLoadingRequest.favouriteStorefronts"
                        (clickLike)="onClickStorefrontLikeButton($event)">
                    </app-storefront-carousel>

                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-40px">

                    <div class="section-title d-flex align-items-center">

                        <span class="title capitalize"> All Storefronts </span>

                        <div class="toggle d-flex ml-auto align-items-center">

                            <span class="toggle-text">All</span>

                            <app-toggle-switch-button
                                [checked]="false"
                                (changed)="filterChanged($event)">
                            </app-toggle-switch-button>

                            <span class="toggle-text">Verified</span>

                        </div>

                    </div>

                    <app-board-storefronts
                        [Stores]="filteredStorefronts"
                        [showFullCards]="true"
                        [isLoading]="isLoadingRequest.stores">
                    </app-board-storefronts>

                </div>

            </div>

        </div>

        <div *ngIf="homeSelectedTopTab === 'assets'" class="container" [@inAnimation]>

            <div class="row">

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">

                    <app-asset-carousel
                        [Assets]="favouriteAssets"
                        [isLoading]="isLoadingRequest.favouriteAssets"
                        [Title]="'Favourites (' +  favouriteAssets?.length + ')'">
                    </app-asset-carousel>

                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-40px">

                    <app-asset-carousel
                        [Assets]="recommendedAssets"
                        [isLoading]="isLoadingRequest.recommendedAssets"
                        [Title]="'Recommended (' +  recommendedAssets?.length + ')'">
                    </app-asset-carousel>

                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-40px">

                    <app-asset-carousel
                        [Assets]="lastVisitedAssets"
                        [Title]="'Last Visited By You (' +  lastVisitedAssets?.length + ')'"
                        [isLoading]="isLoadingRequest.lastVisitedAssets">
                    </app-asset-carousel>

                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-40px">

                    <app-asset-carousel
                        [Assets]="mostVisitedAssets"
                        [Title]="'Most Visited (' +  mostVisitedAssets?.length + ')'"
                        [isLoading]="isLoadingRequest.mostVisitedAssets">
                    </app-asset-carousel>

                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-40px">

                    <app-asset-carousel
                        [Assets]="assets"
                        [Title]="'All'"
                        [isLoading]="isLoadingRequest.assets"
                        [ShowSeeAllButton]="true">
                    </app-asset-carousel>

                </div>

            </div>

        </div>

    </div>

    <ng-template #loading>
        <div class="d-flex justify-content-center align-items-center w-100" style="height: 156px">
            <app-loader [height]="30"></app-loader>
        </div>
    </ng-template>
</div>
