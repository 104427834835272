import { ModelBase } from './mode-base.model';
import { Storefront } from './storefront.model';

export class Tag extends ModelBase {
  constructor() {
    super();
  }

  public color: string;

  public created: Date;

  public usage = 0;

  public SelectedOnFilter: boolean;

  public active = true;

  public storefront: Storefront;

  public orderIndex = 99;
}
