import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { FitForRoleItem } from '../models/fit-for-role-item.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
  providedIn: 'root'
})
export class FitForRoleService extends ApiServiceBase<FitForRoleItem> {
  constructor(protected http: HttpClient) {
    super(http, 'fit-for-role');
  }

  public getSlDivisions(): Observable<FitForRoleItem[]> {
    return this.get(`slDivisions`);
  }

  public getJobs(
    slDivisionIds: Array<number> = []
  ): Observable<Array<FitForRoleItem>> {
    return this.http.post<Array<FitForRoleItem>>(
      `${environment.apiUrl}/fit-for-role/jobs`,
      slDivisionIds,
      this.HEADERS
    );

    // return this.http.post<Array<FitForRoleItem>>(`${environment.apiUrl}/fit-for-role/jobs-without-bucket`, slDivisionIds, this.HEADERS);
  }

  public getSlBusinessLines(
    jobIds: Array<number> = []
  ): Observable<FitForRoleItem[]> {
    return this.http.post<Array<FitForRoleItem>>(
      `${environment.apiUrl}/fit-for-role/slBusinessLines`,
      jobIds,
      this.HEADERS
    );
  }

  public getBFs(
    slBusinessLineIds: Array<number> = []
  ): Observable<FitForRoleItem[]> {
    return this.http.post<Array<FitForRoleItem>>(
      `${environment.apiUrl}/fit-for-role/bfs`,
      slBusinessLineIds,
      this.HEADERS
    );
  }
}
