<div class="d-flex align-items-start justify-content-between">
    <i
        class="star fas fa-star pr-1"
        [ngClass]="enabled(1)"
        (mouseenter)="mouseHover(1)"
        (mouseleave)="mouseHover(-1)"
        (click)="OnClick(1)"
        [style.fontSize.px]="fontSize"
    ></i>

    <i
        class="star fas fa-star pr-1"
        [ngClass]="enabled(2)"
        (mouseenter)="mouseHover(2)"
        (mouseleave)="mouseHover(-1)"
        (click)="OnClick(2)"
        [style.fontSize.px]="fontSize"
    ></i>

    <i
        class="star fas fa-star pr-1"
        [ngClass]="enabled(3)"
        (mouseenter)="mouseHover(3)"
        (mouseleave)="mouseHover(-1)"
        (click)="OnClick(3)"
        [style.fontSize.px]="fontSize"
    ></i>

    <i
        class="star fas fa-star pr-1"
        [ngClass]="enabled(4)"
        (mouseenter)="mouseHover(4)"
        (mouseleave)="mouseHover(-1)"
        (click)="OnClick(4)"
        [style.fontSize.px]="fontSize"
    ></i>

    <i
        class="star fas fa-star"
        [ngClass]="enabled(5)"
        (mouseenter)="mouseHover(5)"
        (mouseleave)="mouseHover(-1)"
        (click)="OnClick(5)"
        [style.fontSize.px]="fontSize"
    ></i>
</div>
