import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { StorefrontDefaultPageViewType } from '~/shared/enums/storefront-page-view-type';
import { Asset, IKeyProcessAssets } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { AssetService } from '~/shared/services/asset.service';
import { CategoryService } from '~/shared/services/category.service';
import { TagService } from '~/shared/services/tag.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-board-category-assets',
  templateUrl: './board-category-assets.component.html',
  styleUrls: ['./board-category-assets.component.scss']
})
export class BoardCategoryAssetsComponent implements OnInit {
  @Input()
  public storefront: Storefront;

  public categories: Array<Category>;

  @Input()
  public tagsToFilter: Array<Tag> = [];

  public tagsToShow: Array<Tag> = [];

  @Input()
  public Title = 'Categories';

  @Input()
  public assetCardSize = 'col-sm-12 col-md-6 col-lg-4 col-xl-3';

  @Input()
  public pageViewType: StorefrontDefaultPageViewType;

  private sortBy = 'default';

  public storefrontPageViewType: typeof StorefrontDefaultPageViewType =
    StorefrontDefaultPageViewType;

  public loading = true;

  public favouriteAssetsCategories: Array<Category>;

  public recommendedAssetsCategories: Array<Category> = [];

  public loadingRecommended = true;

  public categoryAssetsCount = 0;

  public favouriteAssetsCount = 0;

  public recommendedAssetsCount = 0;

  public isLoadingRequest = {
    all: false,
    recommended: false
  };

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService,
    private usersService: UserService,
    private assetService: AssetService
  ) {}

  public ngOnInit(): void {
    this.isLoadingRequest.all = true;

    this.tagService
      .getAllTagsFromStore(this.storefront.id)
      .pipe(take(1))
      .subscribe((tags) => {
        this.tagsToShow = UtilsService.sortArrayByOrderIndex(tags);
      });

    this.categoryService
      .getAllByStorefront(this.storefront.id)
      .pipe(take(1))
      .subscribe((categories) => {
        this.categories = categories;

        this.getRecommendedAssets();

        categories.forEach((cat) => {
          this.categoryAssetsCount += cat.assets.length;

          cat.assets.forEach((asset) => {
            asset.tags = this.tagService.filterTagsByStore(
              asset.tags,
              this.storefront.id
            );
          });

          if (cat.responsible && cat.responsible.email)
            this.usersService
              .getUserPhoto(cat.responsible.email)
              .subscribe((photo) => {
                if (photo)
                  UtilsService.isValidImage((validImage) => {
                    cat.responsible.image = validImage
                      ? photo
                      : UtilsService.USER_PHOTO_PLACEHOLDER;
                  }, photo);
              });
        });

        this.setFavouriteCategories();
        this.isLoadingRequest.all = false;
      });
  }

  // chenge the sort type
  public toggleSort(type: string): void {
    this.sortBy = type;
    this.sortAssetsArrayAndSetAsMain();
  }

  private sortAssetsArrayAndSetAsMain(): void {
    this.categories.forEach((cat) => {
      if (cat.assets) {
        cat.assets = this.sortArray(cat.assets);
      }
    });

    if (this.storefront.assets) {
      this.storefront.assets = this.sortArray(this.storefront.assets);
    }

    this.favouriteAssetsCategories.forEach((cat) => {
      if (cat.assets) {
        cat.assets = this.sortArray(cat.assets);
      }
    });

    this.recommendedAssetsCategories.forEach((cat) => {
      if (cat.assets) {
        cat.assets = this.sortArray(cat.assets);
      }
    });

    this.loading = false;
  }

  private sortArray(assets: Array<Asset>): Array<Asset> {
    if (assets) {
      if (this.sortBy === 'most-recent') {
        return UtilsService.sortArrayByMostRecentAndPriority(assets);
      } else if (this.sortBy === 'name') {
        return UtilsService.sortArrayByTitleAndPriority(assets);
      } else {
        return UtilsService.sortArrayByOrderIndexAndPriority(assets);
      }
    } else {
      return assets;
    }
  }

  public setFavouriteCategories() {
    this.favouriteAssetsCategories = [];

    this.categories.forEach((category) => {
      const categoryCopy = JSON.parse(JSON.stringify(category));
      categoryCopy.assets = category.assets.filter(
        (asset) => asset.isLikedByCurrentUser
      );

      const tempKeyProcessXAssets: IKeyProcessAssets[] = [];

      categoryCopy.keyProcessXAssets.forEach((kpa) => {
        const assts = kpa.assets.filter((a1) =>
          categoryCopy.assets.some((a2) => a1.id === a2.id)
        );

        if (assts.length > 0) {
          const tempKPA = {
            assets: assts,
            keyProcessName: kpa.keyProcessName,
            orderIndex: kpa.orderIndex
          };

          tempKeyProcessXAssets.push(tempKPA);
        }
      });

      categoryCopy.keyProcessXAssets = tempKeyProcessXAssets;

      if (categoryCopy.assets.length > 0) {
        this.favouriteAssetsCount += categoryCopy.assets.length;
        this.favouriteAssetsCategories.push(categoryCopy);
      }
    });
  }

  public getRecommendedAssets() {
    this.isLoadingRequest.recommended = true;

    this.assetService
      .getRecommendedByStorefront(this.storefront.id)
      .pipe(take(1))
      .subscribe((assets) => {
        this.recommendedAssetsCount = assets.length;

        this.categories.forEach((category) => {
          const suggestedAssetsInCategory = category.assets.filter((a1) =>
            assets.some((a2) => a1.id === a2.id)
          );

          if (suggestedAssetsInCategory.length > 0) {
            const categoryCopy = JSON.parse(JSON.stringify(category));
            categoryCopy.assets = suggestedAssetsInCategory;

            const tempKeyProcessXAssets: IKeyProcessAssets[] = [];

            categoryCopy.keyProcessXAssets.forEach((kpa) => {
              const assts = kpa.assets.filter((a1) =>
                assets.some((a2) => a1.id === a2.id)
              );

              if (assts.length > 0) {
                const tempKPA = {
                  assets: assts,
                  keyProcessName: kpa.keyProcessName,
                  orderIndex: kpa.orderIndex
                };

                tempKeyProcessXAssets.push(tempKPA);
              }
            });

            categoryCopy.keyProcessXAssets = tempKeyProcessXAssets;

            this.recommendedAssetsCategories.push(categoryCopy);
          }
        });

        this.isLoadingRequest.recommended = false;
      });
  }

  public checkTabToSetAsMain() {
    if (this.storefront.defaultTab == 0) {
      if (this.favouriteAssetsCategories.length > 0) {
        return 0;
      } else if (this.recommendedAssetsCount > 0) {
        return 1;
      } else {
        return 2;
      }
    } else {
      return this.storefront.defaultTab - 1;
    }
  }
}
