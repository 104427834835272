import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { environment } from '@env';
import { AppModule } from '~/app.module';

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();

    persistState({
        storage: sessionStorage,
        key: `CWI.SPAkita`,
        include: ['GeoMarket', 'Sub-GeoMarket']
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
