import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule } from '@azure/msal-angular';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '@env';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  BotDirective,
  BotHelperDirective,
  BotService,
  ComService,
  NgxMicrosoftBotFrameworkModule,
  StyleSetDirective
} from 'ngx-microsoft-bot-framework';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { interceptorProviders } from './core/interceptors/interceptors';
import { ModulesModule } from './modules/modules.module';
import { SharedModule } from './shared/shared.module';

const protectedResourceMap = new Map<string, string[]>();

protectedResourceMap.set('https://graph.microsoft.com/', ['User.Read']);
protectedResourceMap.set(environment.apiUrl, [
  'https://graph.microsoft.com/User.Read'
]);

@NgModule({
  declarations: [AppComponent],
  imports: [
    ModalModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ModulesModule,
    SharedModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    NgxMicrosoftBotFrameworkModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.azureClientId,
        authority: `https://login.microsoftonline.com/${environment.azureTenantId}`,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      },
      framework: {
        protectedResourceMap,
        isAngular: true
      }
    })
  ],
  providers: [
    interceptorProviders,
    BotService,
    ComService,
    BotDirective,
    BotHelperDirective,
    StyleSetDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
