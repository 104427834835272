<div
  class="digital-asset-title d-flex align-items-end justify-content-between pb-2 h-15 mt-4                    ">
  <div class="d-flex flex-column">
    <span class="title-header mr-2 mr-auto reviews-title">Asset Tags</span>
    <span
      class="small-light mr-2 mr-auto reviews-title pt-2">All tags related to the selected BI Assets. To change the sorting order, you can click and drag.</span>
  </div>

  <div class="d-flex align-items-center justify-content-end ">
    <i class="fas fa-sort-alpha-down cursor-pointer " [attr.title]="'Sort by name'" (click)="sortByName()"></i>
  </div>

</div>
<div class=" mt-2">

  <div #dropListContainer class="tag-container no-gutters w-100" cdkDropListGroup>

    <div
      *ngFor="let tag of tags; let i = index"
      cdkDropList
      [cdkDropListData]="i">

      <div class="tag-box"
        cdkDrag
        [cdkDragData]="i"
        (cdkDragEntered)="dragEntered($event)"
        (cdkDragMoved)="dragMoved($event)"
        (cdkDragDropped)="dragDropped($event)"
        [attr.title]="tag.name">

        <div class="tag d-flex align-items-center" [attr.title]="tag.storefront ? 'Storefront Tag' : 'Global Tag'">
          <div class="tag-color" [ngStyle]="{'background-color': tag.color}"> </div>
          <span class="title pl-2">{{tag.name}}</span>
        </div>

      </div>

    </div>
  </div>
</div>