import { Component, Input } from '@angular/core';
import { ShowAllType } from '~/shared/enums/show-all-type';
import { FilterService } from '~/shared/services/filter.service';

@Component({
    selector: 'app-board-header',
    templateUrl: './board-header.component.html',
    styleUrls: ['./board-header.component.scss']
})
export class BoardHeaderComponent {
    @Input()
    public Title: string;

    @Input()
    public Description = '';

    @Input()
    public FontSmall = false;

    @Input()
    public showAllType: ShowAllType = null;

    @Input()
    public marginTop = 'mt-4';

    @Input()
    public showBorderBottom = true;

    @Input()
    public isVisible = true;

    constructor(private filterService: FilterService) { }

    protected redirect() {
        switch (this.showAllType) {
            case ShowAllType.SHOW_STORES:
                this.filterService.ShowAllStores();
                break;

            case ShowAllType.SHOW_ASSETS:
                this.filterService.ShowAllAssets();
                break;

            case ShowAllType.SHOW_ALL_TAG:
                this.filterService.ShowAllTags();
                break;
        }
    }
}
