import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Storefront } from '~/shared/models/storefront.model';
import { User } from '~/shared/models/user.model';
import { HeaderService } from '~/shared/services/header.service';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-header-links',
    templateUrl: './header-links.component.html',
    styleUrls: ['./header-links.component.scss']
})
export class HeaderLinksComponent implements OnInit {
    public ourSectionsVisibility: boolean;
    public categoriesVisibility: boolean;
    public SelectedStore: Storefront;

    public currentUser: User = null;

    public showContactUs = false;

    public showBackButton = false;

    constructor(
        private headerService: HeaderService,
        private usersService: UserService,
        private location: Location
    ) { }

    public openOurSections(): void {
        this.headerService.ToggleOurSectionsVisibility();
    }

    public openCategories() {
        this.headerService.ToggleCategoriesVisibility();
    }

    public ngOnInit(): void {
        this.usersService.getCurrentUser().then((obj) => {
            this.currentUser = obj.currentUser;
        });

      this.headerService.ourSectionsVisibility$.subscribe((visibility) => {
        this.ourSectionsVisibility = visibility;
      });

      this.headerService.categoriesVisibility$.subscribe((visibility) => {
        this.categoriesVisibility = visibility;
      });

      this.headerService.selectedStore$.subscribe((store) => {
        this.SelectedStore = store;
      });

      this.headerService.showBackButton$.subscribe((val) => {
        this.showBackButton = val;
      });
    }

    public showContactUsModal() {
        this.showContactUs = true;
    }

    public sendEmailClosed(event) {
        this.showContactUs = false;
    }

    public goToPreviousUrl() {
        this.location.back();
    }
}
