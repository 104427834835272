import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { InternalComponent } from './pages/internal/internal.component';
import { ModalLateralInternalComponent } from './components/modal-lateral-internal/modal-lateral-internal.component';

const MODULE_MODULES = [CommonModule, SharedModule];

const MODULE_COMPONENTS = [
    InternalComponent,
    ModalLateralInternalComponent
]

@NgModule({
    imports: [...MODULE_MODULES],
    declarations: [...MODULE_COMPONENTS]
})
export class InternalModule {}
