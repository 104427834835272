import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs/internal/Observable';
import { Faq } from '../models/faq.model';
import { IImage } from '../models/image.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends ApiServiceBase<Faq> {
  constructor(protected http: HttpClient) {
    super(http, 'faqs');
  }

  public getAllFaqs(limit: number = 0): Observable<Array<Faq>> {
    return this.get(limit > 0 ? limit.toString() : '');
  }

  public add(faq): Observable<number> {
    return this.post(faq);
  }

  public update(faq): Observable<number> {
    return this.put(faq);
  }

  public deleteFaq(faq): Observable<boolean> {
    return this.delete<boolean>(`${faq.id}/`);
  }

  public uploadImage(image: IImage, date: Date): Observable<any> {
    const file: any = {
      Base64: image.localPath,
      Name: image.image?.name ? image.image.name : null,
      ImageType: image.image?.type ? image.image.type : null,
      IsNew: image.isNew,
      Created: date
    };

    return this.http.post<any>(
      `${environment.apiUrl}/faqs/upload-image`,
      file,
      this.TEXT_HEADERS
    );
  }
}
