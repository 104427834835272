<div class="color-picker">
  <div class="summary-info" (click)="toggleColors()">
    <div class="circle main-circle d-flex justify-content-center align-items-center" [ngStyle]="{'background': color}">

      <i *ngIf="!color" class="fas fa-plus" style="color: #1683FB;"></i>

    </div>
  </div>

  <div *ngIf="show" class="opened">
    <div class="colors">
      <div
        *ngFor="let paint of defaultColors"

        (click)="changeColor(paint)"
        class="circle"
        [ngClass]="{'selected': color == paint}"
        [ngStyle]="{'background': paint}"></div>
    </div>

  </div>
</div>