import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { User } from '~/shared/models/user.model';
import { TagService } from '~/shared/services/tag.service';

@Component({
    selector: 'app-search-user',
    templateUrl: './search-user.component.html',
    styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit {
    @ViewChild('template', { static: true }) public elementView: ElementRef;

    @Output() public close = new EventEmitter();
    @Output() public userAdded = new EventEmitter<object>();

    public userSelected: User;

    private config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'center-modal modal-dialog-centered'
    };

    @Input() public settings = [];

    public modalRef: BsModalRef;

    public modalTitle: string;

    constructor(private modalService: BsModalService,
        private tagService: TagService) { }

    public ngOnInit(): void {
        this.modalRef = this.modalService.show(this.elementView, this.config);
    }

    public save() {
        this.userAdded.emit(this.userSelected);
        this.closeModal()
    }

    public noCLick() {
        this.closeModal();
    }

    private closeModal() {
        this.close.emit();
        this.modalRef.hide();
    }

    public get ckeckDisabled(): boolean {
        return !this.userSelected;
    }
}
