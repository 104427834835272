import { Notification } from '@/models/notification.model';
import { NotificationService } from '@/services/notification.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { NotificationCategory } from '~/shared/models/notification-category';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('150ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('150ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class NotificationComponent implements OnInit {
  @ViewChild('template', { static: true })
  public elementView: ElementRef;

  public notificationsCategories: Array<NotificationCategory> = [];

  public notification: Notification;
  public modalRef: BsModalRef;
  public panelOpenState = false;
  public notRead = 0;

  public selectedNotification: Notification;

  private config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'center-modal modal-lg modal-dialog-centered'
  };

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private modalService: BsModalService,
    public sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    this.getAll();
  }

  public getAll() {
    this.notificationService
      .getAll()
      .pipe(take(1))
      .subscribe((notifications) => {
        this.notificationsCategories = notifications;
        this.countUnreadNotifications();
      });
  }

  public redirect(notification: Notification) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/asset', 1, 'details']);
    });

    this.closeModal();
  }

  public showNotificationModel() {
    this.modalRef = this.modalService.show(this.elementView, this.config);
  }

  public closeModal() {
    this.modalRef.hide();
  }

  public setNotificationSelected(notification: Notification) {
    if (this.selectedNotification?.id === notification.id) {
      this.selectedNotification = null;
    } else {
      this.selectedNotification = notification;

      if (!notification.viewed) {
        notification.viewed = true;
        this.setViewed(notification);
        this.countUnreadNotifications();
      }
    }
  }

  public setViewed(notification: Notification) {
    this.notificationService.update(notification).subscribe(() => {});
  }

  public countUnreadNotifications() {
    let notReadCount = 0;

    this.notificationsCategories.forEach((cat) => {
      notReadCount += cat.notifications.filter(
        (n) => n.viewed === false
      ).length;
    });

    this.notRead = notReadCount;
  }

  public delete(idToDelete: number) {
    this.notificationService.remove(idToDelete).subscribe(() => {
      this.notificationsCategories.forEach((cat) => {
        cat.notifications = cat.notifications.filter(
          (n) => n.id !== idToDelete
        );
      });

      if (this.selectedNotification.id === idToDelete) {
        this.selectedNotification = null;
      }
    });
  }

  public setSelectedCategory(selectedCategory: NotificationCategory): void {
    selectedCategory.expand = !selectedCategory.expand;
  }
}
