import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Email } from '~/shared/models/email.model';
import { EmailService } from '~/shared/services/email.service';
import { NotificationService } from '~/shared/services/notification.service';

@Component({
    selector: 'app-send-email',
    templateUrl: './send-email.component.html',
    styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {
    @ViewChild('template', { static: true })
    public elementView: ElementRef;

    @Output() public close = new EventEmitter<boolean>();

    private config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'center-modal modal-lg modal-dialog-centered'
    };

    public modalRef: BsModalRef;

    public modalTitle: string;

    public model = 'support';

    public subject = '';

    public message = '';

    public contactMail = '';

    public name = '';

    public rating = 0;

    constructor(
        private modalService: BsModalService,
        private emailService: EmailService,
        private toastr: NotificationService
    ) { }

    public ngOnInit(): void {
        this.modalTitle = 'Contact us';

        this.modalRef = this.modalService.show(this.elementView, this.config);
    }

    public sendEmail() {
        let message = this.message;

        if (this.model === 'feedback') {
            this.subject = `${this.rating} Stars`;

            let stars = '';

            for (let index = 0; index < this.rating; index++) {
                stars = stars + '&#9733 ';
            }

            message = stars + '<br /><br />' + message;
        }

        this.emailService.sendEmail(new Email({
            subject: this.subject,
            body: message,
            support: this.model == 'support'
        })).subscribe(() => {
            this.toastr.success("Contact message sent successfully.")
        },
            () => {
                this.toastr.error("Our e-mail service isn't available now. Please, try again later or contact an administrator.")
            });

        this.closeModal();
    }

    public closeModal() {
        this.close.emit(true);
        this.modalRef.hide();
    }

    public get disableSendButtom(): boolean {
        if (this.model !== 'support') {
            if (this.message && this.rating > 0) return false;
        } else {
            if (this.message) return false;
        }

        return true;
    }

    public ratingMouseHoverChange(value) { }

    public rateSelecte(value) {
        this.rating = value;
    }
}
