import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Storefront } from '~/shared/models/storefront.model';
import { StorefrontService } from '~/shared/services/storefront.service';

@Component({
    selector: 'app-board-appears-on',
    templateUrl: './board-appears-on.component.html',
    styleUrls: ['./board-appears-on.component.scss']
})
export class BoardAppearsOnComponent implements OnInit {
    @Input()
    public AssetId: number;

    public RelatedStores: Storefront[];

    constructor(private storeService: StorefrontService) {}

    public ngOnInit(): void {
        this.storeService
            .getStoresByAsset(this.AssetId)
            .pipe(take(1))
            .subscribe((stores) => {
                this.RelatedStores = stores;
            });
    }
}
