import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Storefront } from '../models/storefront.model';
import { LikedStorefront } from '../models/liked-storefront'
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
    providedIn: 'root'
})
export class LikedStorefrontService extends ApiServiceBase<Storefront> {
    constructor(protected http: HttpClient) {
        super(http, 'liked-storefronts');
    }

    public getLikedStorefrontsByUser(): Observable<Storefront[]> {
        return this.get('liked-by-user/');
    }

    public addLiked(storefront: Storefront): Observable<number> {
        const likedStorefront = new LikedStorefront();
        likedStorefront.storefront = storefront;

        return this.post(likedStorefront);
    }

    public removeLiked(storefront: Storefront): Observable<boolean> {
        return this.delete<boolean>(`${storefront.id}/`);
    }
}
