<div class="board container mt-2" *ngIf="isVisible">
    <div class="row h-100" *ngIf="categories && categories.length > 0">
        <div
            class="d-block col-sm-12 col-md-6 col-lg-4 col-xl-3 p-1"
            *ngFor="let category of categories">
            <div class="cursor-pointer card">

                <div class="row no-gutters h-100">

                    <div class="col-md-2 d-flex align-items-center justify-content-md-center">

                        <i *ngIf="category.iconName" class="p-1 fas fa-{{category.iconName}}"></i>

                        <span class="fist-letters" *ngIf="!category.iconName">{{ getInitialLetters(category) | uppercase }}</span>
                    </div>

                    <div class="pl-2 col-md-9 d-flex flex-column justify-content-center align-items-start">
                        <span class="category-title">{{ category.name }}</span>
                        <span class="storefront-title">{{ category.storefront.name }}</span>
                    </div>

                    <div
                        class="col-md-1 d-flex justify-content-end align-items-start p-1"
                        *ngIf="isEditable">
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            xmlns="http://www.w3.org/2000/svg"
                            [attr.fill]="category['canCurrentUserEdit'] ? '#1683FB' : 'grey'"
                            [class]="category['canCurrentUserEdit'] ? 'cursor-pointer' : 'cursor-block'"
                            (click)="onEdit(category)">
                            <path
                                d="M9.99997 1.63574C9.99997 1.85059 9.96099 2.05892 9.88303 2.26074C9.80508 2.46256 9.69126 2.6416 9.54158 2.79785L3.38136 9.22852L0.420532 10L1.15957 6.90918L7.31979 0.478516C7.46947 0.322266 7.64097 0.203451 7.83431 0.12207C8.02764 0.0406901 8.22721 0 8.43302 0C8.64819 0 8.85088 0.0439453 9.04109 0.131836C9.23131 0.216471 9.39658 0.333659 9.5369 0.483398C9.68035 0.629883 9.7926 0.802409 9.87368 1.00098C9.95787 1.19954 9.99997 1.41113 9.99997 1.63574ZM1.94538 6.97266C2.27592 7.08659 2.55969 7.26888 2.79668 7.51953C3.03679 7.76693 3.21142 8.06315 3.32056 8.4082L8.37689 3.125L7.0064 1.69434L1.94538 6.97266ZM1.24377 9.14062L2.78265 8.74023C2.75147 8.59049 2.70001 8.44889 2.62829 8.31543C2.55969 8.18197 2.4755 8.06315 2.37571 7.95898C2.27592 7.85482 2.16211 7.76693 2.03426 7.69531C1.90641 7.62044 1.77076 7.56673 1.62732 7.53418L1.24377 9.14062ZM8.80254 2.68066C8.8805 2.59928 8.95534 2.52279 9.02706 2.45117C9.09878 2.37956 9.16271 2.30469 9.21884 2.22656C9.27497 2.14518 9.31862 2.05892 9.3498 1.96777C9.38411 1.87337 9.40126 1.76432 9.40126 1.64062C9.40126 1.50065 9.37475 1.37044 9.32174 1.25C9.27185 1.1263 9.20168 1.01888 9.11125 0.927734C9.02394 0.833333 8.92104 0.760091 8.80254 0.708008C8.68716 0.652669 8.56243 0.625 8.42834 0.625C8.30985 0.625 8.20539 0.642904 8.11495 0.678711C8.02764 0.711263 7.94501 0.756836 7.86705 0.81543C7.79221 0.874023 7.72049 0.940755 7.65189 1.01562C7.58328 1.09049 7.51 1.16862 7.43205 1.25L8.80254 2.68066Z" />
                        </svg>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>
