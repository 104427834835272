<div class="header-links d-flex align-items-center">

  <div *ngIf="showBackButton" class="cursor-pointer d-flex align-items-center">
    <a (click)="goToPreviousUrl()"> Back </a>
  </div>

  <div *ngIf="showBackButton" class="cursor-pointer d-flex align-items-center">
    <a [routerLink]="['/']">MY VIEW</a>
  </div>

  <div *ngIf="SelectedStore != null" class="cursor-pointer d-flex align-items-center">
    <a [routerLink]="['/store', SelectedStore.id, 'aboutus']" class="about-us">About us</a>
  </div>

  <div class="cursor-pointer d-flex align-items-center">
    <a href="https://slb001.sharepoint.com/sites/CIM/SitePages/Home.aspx/#/home" target="_blank" rel="noopener noreferrer" class="text-white btn">CIM Catalog</a>
  </div>

  <div class="cursor-pointer d-flex align-items-center">
    <a href="https://schlumberger.alationcloud.com/" target="_blank" rel="noopener noreferrer" class="text-white btn">Alation</a>
  </div>

  <div class="cursor-pointer d-flex align-items-center">
    <button type="button" class="text-white btn" (click)="showContactUsModal()">
      Contact Us
    </button>
  </div>

  <app-notification style=" margin-top: 8px;"></app-notification>

  <div class="cursor-pointer d-flex align-items-center">
    <a [routerLink]="['/faq']">FAQ</a>
  </div>

</div>

<app-send-email *ngIf="showContactUs" (close)="sendEmailClosed($event)"></app-send-email>