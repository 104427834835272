import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bar-sort-by',
  templateUrl: './bar-sort-by.component.html',
  styleUrls: ['./bar-sort-by.component.scss']
})
export class BarSortByComponent implements OnInit {

    public sortBy = 'default';

  @Output()
  public sortByChanged: EventEmitter<string> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void { }

  public toggleSort(type: string): void {
    this.sortBy = type;
    this.sortByChanged.emit(type);
  }

}
