<div class="card-asset cursor-pointer d-flex flex-column" (click)="openAddress(Asset.url, $event)">

  <div class="card-body d-flex flex-column">

    <span class="views-count" [attr.title]="Asset.numberOfViews + ' views'">{{getCountViewNumber()}} views</span>

    <div class="icons-area d-flex flex-column">

      <app-like-asset
        [Asset]="Asset"
        [fontSize]="16"
        (clickLike)="emitLike()"
        *ngIf="!isRemove && !isEdit">
      </app-like-asset>

      <i *ngIf="isPriority"
        class="fas fa-thumbtack p-1 priority-icon"
        [ngClass]="{ 'priority-icon-active': this.Asset.isPriority }"
        (click)="$event.stopPropagation(); this.Asset.isPriority = !this.Asset.isPriority"
        [attr.title]="this.Asset.isPriority ? 'Remove Priority' : 'Add Priority' "></i>

      <i *ngIf="Asset.isPriority && !isPriority && !isRemove && !isEdit"
        class="fas fa-thumbtack p-1 priority-icon"
        [ngClass]="{ 'priority-icon-active': Asset.isPriority }"
        style="color: #7a7a7a; font-size: 14px"
        [attr.title]="'This asset has priority'"></i>

    </div>

    <div class="image-container d-flex align-items-center justify-content-md-center w-100">

      <div *ngIf="!isRemove"
        class="logo d-flex align-items-center justify-content-md-center "
        [ngStyle]="{ 'background-color': logoAreaColor }">

        <img class="logo" *ngIf="Asset.logo; else loading" [src]="Asset.logo" />

        <ng-template #loading>
          <span class="fist-letters"> {{ getInitialLetters() }} </span>
        </ng-template>

      </div>

    </div>

    <span class="title" [tooltip]="Asset.description" [delay]="500">

      <svg *ngIf="Asset.isNew"
        width="29" height="10" viewBox="0 0 29 10" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 2px">
        <rect x="29" width="10" height="29" rx="5" transform="rotate(90 29 0)" fill="#FF9800" />
        <path d="M11.7031 8H10.5312L8.25 4.25781V8H7.07812V2.3125H8.25L10.5352 6.0625V2.3125H11.7031V8ZM14.5977 8.07812C13.9779 8.07812 13.4727 7.88802 13.082 7.50781C12.694 7.1276 12.5 6.62109 12.5 5.98828V5.87891C12.5 5.45443 12.582 5.07552 12.7461 4.74219C12.9102 4.40625 13.1419 4.14844 13.4414 3.96875C13.7435 3.78646 14.0872 3.69531 14.4727 3.69531C15.0508 3.69531 15.5052 3.8776 15.8359 4.24219C16.1693 4.60677 16.3359 5.1237 16.3359 5.79297V6.25391H13.6445C13.681 6.52995 13.7904 6.7513 13.9727 6.91797C14.1576 7.08464 14.3906 7.16797 14.6719 7.16797C15.1068 7.16797 15.4466 7.01042 15.6914 6.69531L16.2461 7.31641C16.0768 7.55599 15.8477 7.74349 15.5586 7.87891C15.2695 8.01172 14.9492 8.07812 14.5977 8.07812ZM14.4688 4.60938C14.2448 4.60938 14.0625 4.6849 13.9219 4.83594C13.7839 4.98698 13.6953 5.20312 13.6562 5.48438H15.2266V5.39453C15.2214 5.14453 15.1536 4.95182 15.0234 4.81641C14.8932 4.67839 14.7083 4.60938 14.4688 4.60938ZM20.6562 6.46484L21.2109 3.77344H22.3008L21.2227 8H20.2773L19.4766 5.33984L18.6758 8H17.7344L16.6562 3.77344H17.7461L18.2969 6.46094L19.0703 3.77344H19.8867L20.6562 6.46484Z"
          fill="white" />
      </svg>

      {{ Asset.name }}

      <svg *ngIf="!Asset?.ignoreBiReport" width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" tooltip="Valid BI Report Assigned" placement="center" [delay]="250"
        class=" m-1">
        <path d="M6.39364 0.388081C6.73452 0.154229 7.18416 0.154229 7.52504 0.388081L7.8263 0.59475C8.04468 0.744564 8.31357 0.801634 8.57398 0.75344L8.93361 0.686883C9.34032 0.611612 9.7514 0.794501 9.96781 1.14699L10.1578 1.45643C10.2965 1.68239 10.5193 1.844 10.7771 1.90575L11.1312 1.99053C11.5339 2.08698 11.8356 2.42174 11.8897 2.83235L11.9368 3.18978C11.9715 3.45305 12.1095 3.69171 12.3204 3.85313L12.6073 4.0728C12.9366 4.32487 13.0761 4.75399 12.9581 5.15151L12.8558 5.49593C12.7801 5.75073 12.809 6.02517 12.9361 6.25863L13.1079 6.57439C13.3062 6.93871 13.259 7.38763 12.9892 7.70273L12.755 7.97639C12.5822 8.17824 12.4968 8.44055 12.5177 8.70544L12.5461 9.06397C12.5787 9.47713 12.3531 9.86761 11.9788 10.0457L11.6513 10.2015C11.4117 10.3156 11.2272 10.5201 11.1385 10.7702L11.0176 11.1111C10.8792 11.5012 10.5149 11.7657 10.1011 11.7765L9.73614 11.786C9.47128 11.7929 9.21998 11.9046 9.03742 12.0966L8.78635 12.3607C8.50148 12.6603 8.06158 12.7538 7.67951 12.5958L7.34145 12.456C7.09676 12.3548 6.82193 12.3548 6.57723 12.456L6.23918 12.5958C5.85711 12.7538 5.41721 12.6603 5.13233 12.3607L4.88127 12.0966C4.69871 11.9046 4.4474 11.7929 4.18254 11.786L3.81755 11.7765C3.40382 11.7657 3.03947 11.5012 2.9011 11.1111L2.78017 10.7702C2.69145 10.5201 2.50699 10.3156 2.26738 10.2015L1.93985 10.0457C1.56561 9.86761 1.34 9.47713 1.37263 9.06397L1.40095 8.70544C1.42187 8.44055 1.33651 8.17824 1.16372 7.97639L0.929452 7.70273C0.659708 7.38763 0.61249 6.93871 0.810771 6.57439L0.982619 6.25863C1.10968 6.02517 1.13856 5.75073 1.0629 5.49593L0.960621 5.15151C0.842577 4.75399 0.982112 4.32487 1.31137 4.0728L1.59832 3.85313C1.80917 3.69171 1.94717 3.45305 1.98187 3.18978L2.02898 2.83235C2.08311 2.42174 2.38475 2.08698 2.78753 1.99053L3.14157 1.90575C3.39942 1.844 3.62219 1.68239 3.76091 1.45643L3.95088 1.14699C4.16728 0.794501 4.57836 0.611612 4.98508 0.686883L5.34471 0.75344C5.60511 0.801634 5.874 0.744564 6.09239 0.59475L6.39364 0.388081Z"
          fill="#136FD5" />
        <path d="M6.30531 9.53093C6.19705 9.65274 6.01135 9.66584 5.88705 9.56044L3.13689 7.22832C3.0031 7.11487 2.99477 6.91133 3.11884 6.78732L3.81435 6.09217C3.92476 5.98181 4.10135 5.97457 4.22043 6.07552L5.71761 7.3448C5.8419 7.45017 6.02758 7.43706 6.13583 7.31527L9.5655 3.45675C9.66932 3.33995 9.84541 3.32241 9.97022 3.41643L10.7705 4.01922C10.9105 4.12467 10.9306 4.32716 10.8142 4.45815L6.30531 9.53093Z"
          fill="#F9F9F9" />
      </svg>

    </span>

    <div class="d-flex align-items-center">

      <div class=" w-50">
        <span class="product-line text-truncate mt-auto">{{ Asset.productLine?.name }}</span>
      </div>

      <div *ngIf="Asset.usingCIM && Asset.cimVerified" class="w-50 d-flex justify-content-end">

        <img class=" mr-1" [src]="'../../assets/cube-icon.svg'" [tooltip]="'Powered by CIM: ' + Asset.cimName" />

      </div>
    </div>

    <div class="w-50 mt-2">
      <app-rating [fontSize]="10" [Rating]="Asset.averageRating"> </app-rating>
    </div>

  </div>

  <div class="footer d-flex justify-content-end">

    <div class="icons-area d-flex align-items-end justify-content-end w-100 h-100 px-2 py-1">

      <a class="icon-block"
        (click)='openEmail()'>
        <i class="fas fa-comments" [attr.title]="'Feedback'"></i>
      </a>

      <a href="{{ Asset.usageStats }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.usageStats"
        (click)="$event.stopPropagation()">
        <i class="fas fa-chart-bar ml-2" [attr.title]="'Usage Stats'"></i>
      </a>

      <a href="{{ Asset.videoUrl }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.videoUrl"
        (click)="$event.stopPropagation()">
        <i class="fas fa-film ml-2" [attr.title]="'Video URL'"></i>
      </a>

      <a href="{{ Asset.askAndLearn }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.askAndLearn"
        (click)="$event.stopPropagation()">
        <i class="fas fa-chalkboard-teacher ml-2" [attr.title]="'Ask & Learn'"></i>
      </a>

      <a href="{{ Asset.documentationLink }}"
        target="_blank"
        class="icon-block"
        *ngIf="Asset.documentationLink"
        (click)="$event.stopPropagation()">
        <i class="fas fa-file-alt ml-2" [attr.title]="'Documentation'"></i>
      </a>

      <a class="icon-block"
        *ngIf="Asset.requestAccessLink"
        (click)="openRequestAccessDetails($event)">
        <i class="fas fa-unlock ml-2" [attr.title]="'Request Access'"></i>
      </a>

      <a
        target="_blank"
        class="icon-block"
        *ngIf="Asset.url"
        (click)="OpenLateralModal(Asset)">
        <i class="fas fa-info-circle ml-2" [attr.title]="'More Details'"></i>
      </a>

    </div>

  </div>

</div>