import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ShowAllType } from '~/shared/enums/show-all-type';
import { Asset } from '~/shared/models/asset.model';
import { FilterService } from '~/shared/services/filter.service';

@Component({
    selector: 'app-asset-carousel',
    templateUrl: './asset-carousel.component.html',
    styleUrls: ['./asset-carousel.component.scss']
})
export class AssetCarouselComponent implements OnInit {

    @ViewChild('scrollableDiv', { static: true })
    public scrollableDiv: ElementRef;

    @Input()
    public Title: string;

    @Input()
    public isLoading = true;

    @Input() set Assets(assets: Asset[]) {
        this._assets = assets;
        this._checkedOverflow = false;
    }

    get Assets(): Asset[] {
        if (this.scrollableDiv.nativeElement.scrollWidth != this._previousScrollValue)
            this.checkOverflow();

        return this._assets;
    }

    @Output()
    public clickLike = new EventEmitter();

    @Input()
    public ShowSeeAllButton = false;

    public showAllType: ShowAllType = null;

    private _assets: Asset[];

    private _previousScrollValue = 0;

    private _checkedOverflow = false;

    public showLeftButton = false;

    public showRightButton = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private filterService: FilterService,
    ) { }

    public ngOnInit() {
        if (this.ShowSeeAllButton) {
            this.showAllType = ShowAllType.SHOW_ASSETS;
        }
    }

    public ngAfterContentChecked() {
        this.changeDetector.detectChanges();
    }

    public checkOverflow() {
        this._previousScrollValue = this.scrollableDiv.nativeElement.scrollWidth;
        if (
            this.scrollableDiv.nativeElement.offsetWidth <
            this.scrollableDiv.nativeElement.scrollWidth
        ) {
            this.showRightButton = true;
            this._checkedOverflow = true;
        } else {
            this.showRightButton = false;
            this.showLeftButton = false;
        }
    }

    public scrollRight() {
        const scrollLeftResult =
            this.scrollableDiv.nativeElement.scrollLeft + 500;

        this.scrollableDiv.nativeElement.scrollTo({
            left: scrollLeftResult,
            behavior: 'smooth'
        });

        this.showLeftButton = true;

        const maxScrollLeft =
            this.scrollableDiv.nativeElement.scrollWidth -
            this.scrollableDiv.nativeElement.clientWidth;
        if (scrollLeftResult >= maxScrollLeft) this.showRightButton = false;
    }

    public scrollLeft() {
        const scrollLeftResult =
            this.scrollableDiv.nativeElement.scrollLeft - 500;

        this.scrollableDiv.nativeElement.scrollTo({
            left: scrollLeftResult,
            behavior: 'smooth'
        });

        this.showRightButton = true;

        if (scrollLeftResult <= 0) this.showLeftButton = false;
    }

    public emitLike(asset: Asset) {
        this.clickLike.emit(asset);
    }

    public OpenAllAssets() {
        this.filterService.ShowAllAssets();
    }

}
