import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '~/shared/models/user.model';

@Component({
    selector: 'app-profile-card',
    templateUrl: './profile-card.component.html',
    styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {
    @Input()
    public user: User;

    @Output()
    public labeledButtonClicked = new EventEmitter<string>();

    constructor() {}

    public ngOnInit(): void {}

    public onLabeledButtonClick(event: string) {
        this.labeledButtonClicked.emit(event);
    }
}
