import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const DEFAULT_HEIGHT_PX = 70;

@Component({
    selector: 'app-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {
    @Input()
    public heightpx: number = DEFAULT_HEIGHT_PX;
    @Input()
    public title: string;

    @Output()
    public onClickClose = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    public onClose() {
        this.onClickClose.emit();
    }
}
