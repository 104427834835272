<ng-template #template>
  <div class="modal-header d-flex align-items-center">
    <h5 class="modal-title pull-left">{{ labelText }}</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="d-flex flex-column px-3">
      <span class="rating-title">BI Asset Rating</span>

      <div class="d-flex align-items-center pt-2 pb-4">
        <div class="col-md-3 p-0">
          <app-rating
            [Rating]="rating"
            [fontSize]="26"
            [isInput]="true"
            (hoverChange)="ratingMouseHoverChange($event)"
            (rateChange)="rateSelecte($event)"></app-rating>
        </div>

        <span class="rating-hover pl-2">{{ rateHoverText }}</span>
      </div>

      <span class="rating-title">Review</span>
      <textarea
        maxlength="255"
        class="custom-input pl-0"
        rows="4"
        placeholder="Write your review (optional)"
        [(ngModel)]="reviewText"></textarea>

      <span class="description pt-3">Tell people what you think about this BI Asset, give feedback
        to the owner and write down out the positive or negative points
        of the BI Asset. The review will be available for everyone to
        see.</span>
    </div>

    <div class="d-flex justify-content-end pt-4">
      <button
        type="reset"
        (click)="cancel()"
        class="button-bordered-blue col-md-3">
        CANCEL
      </button>

      <button
        type="submit"
        [disabled]="rating == -1"
        (click)="save()"
        class="button-blue col-md-3">
        SUBMIT REVIEW
      </button>
    </div>
  </div>
</ng-template>