import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Asset } from '../models/asset.model';
import { LikedAsset } from '../models/liked-asset.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
    providedIn: 'root'
})
export class LikedAssetService extends ApiServiceBase<Asset> {
    constructor(protected http: HttpClient) {
        super(http, 'liked-assets');
    }

    private toggleAssetModal = new Subject<void>();

    public getLikedAssetsByUser(limit: number = 0): Observable<Asset[]> {
        return this.get(`liked-by-user/${limit}`);
    }

    public addLiked(asset: Asset): Observable<number> {
        const likedAsset = new LikedAsset();
        likedAsset.asset = asset;

        return this.post(likedAsset);
    }

    public removeLiked(asset: Asset): Observable<boolean> {
        return this.delete<boolean>(`${asset.id}/`);
    }

    public getToggleAssetModalObservable(): Observable<void> {
        return this.toggleAssetModal.asObservable();
    }

    public onToggleAssetModal() {
        this.toggleAssetModal.next();
    }
}
