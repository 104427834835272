import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tag } from '~/shared/models/tag.model';

@Component({
  selector: 'app-tags-filter-horizontal',
  templateUrl: './tags-filter-horizontal.component.html',
  styleUrls: ['./tags-filter-horizontal.component.scss']
})
export class TagsFilterHorizontalComponent implements OnInit {
  @Input()
  public tags: Array<Tag>;

  @Input()
  public fullSize = false;

  @Output()
  public tagsToFilter = new EventEmitter<Array<Tag>>();

  private selectedTags: Array<Tag> = [];

  constructor() {}

  public ngOnInit(): void {}

  public setSeletedTag(tag: Tag): void {
    if (!tag.SelectedOnFilter) {
      this.selectedTags.push(tag);
    } else {
      const i = this.selectedTags.findIndex((x) => x.id === tag.id);
      this.selectedTags.splice(i, 1);
    }

    tag.SelectedOnFilter = !tag.SelectedOnFilter;

    this.tagsToFilter.emit(this.selectedTags);
  }
}
