import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { Internal } from '~/shared/models/internal.model';
import { User } from '~/shared/models/user.model';
import { InternalService } from '~/shared/services/internal.service';
import { NotificationService } from '~/shared/services/notification.service';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-internal',
    templateUrl: './internal.component.html',
    styleUrls: ['./internal.component.scss']
})
export class InternalComponent implements OnInit {

    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('sort') sort: MatSort;

    public dataSource = new MatTableDataSource<Internal>([]);

    loading: boolean = true;
    newEntryModal: boolean = false;
    showDeleteModal: boolean = false;
    isCurrentUserOwner: boolean = false;

    data: Internal[] = [];

    selectedInternal: Internal = new Internal();

    public deleteModalSettings = {
        isDelete: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'DELETE',
        title: 'Delete BI Studio Internal Entry',
        description: 'Are you sure you want to delete?'
    };

    public pagination: number = 1;

    public columns: any[] = [
        { field: 'status' },
        { field: 'menu' },
        { field: 'scrumMasterName', display: 'Scrum Master', sort: true },
        { field: 'assetName', display: 'Asset Name', sort: true },
        { field: 'editedDescription', display: 'Description', title: 'assetDescription', sort: true },
        { field: 'editedAddress', display: 'Asset URL', title: 'assetAddress', clickable: true, sort: true },
        { field: 'storefrontName', display: 'Storefront', sort: true },
        { field: 'brd', display: 'BRD', sort: true },
        { field: 'editedUsageStatsLink', display: 'Usage Stats URL', title: 'assetUsageStatsLink', clickable: true, sort: true },
        { field: 'editedVideoLink', display: 'Video URL', title: 'assetVideoLink', clickable: true, sort: true },
        { field: 'editedAskAndLearnLink', display: 'Ask & Learn', title: 'assetAskAndLearnLink', clickable: true, sort: true },
        { field: 'editedDocumentationLink', display: 'Documentation URL', title: 'assetDocumentationLink', clickable: true, sort: true },
        { field: 'editedRequestAccessLink', display: 'Request Access URL', title: 'assetRequestAccessLink', clickable: true, sort: true },
        { field: 'assetProductOwnerName', display: 'Product Owner', sort: true },
        { field: 'assetProductLineName', display: 'Product Line', sort: true },
        { field: 'tagsNames', display: 'Tags' },
        { field: 'keyProcessesNames', display: 'Key Processes', sort: true },
        { field: 'categoryName', display: 'Category', sort: true },
        { field: 'editedCreated', display: 'Created', sort: true },
        { field: 'createdByName', display: 'Created By', sort: true },
        { field: 'editedModified', display: 'Modified', sort: true },
        { field: 'modifiedByName', display: 'Modified By', sort: true }
    ];

    public displayColumns: string[] = this.columns.map(col => col.field);

    public currentUser: User = new User();

    constructor(
        private internalService: InternalService,
        private router: Router,
        private toastr: NotificationService,
        private usersService: UserService) {
        this.getData();

        this.usersService.getCurrentUser().then((obj) => {
            this.currentUser = obj.currentUser;

            this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(obj.currentUser);
        });
    }

    ngOnInit(): void { }

    public add() {
        this.newEntryModal = true;

        this.selectedInternal = new Internal();

        this.selectedInternal.id = 0;
    }

    public activateRoute(assetId: number) {
        if (assetId) // TODO: if asset is active
            this.router.navigate(['/asset', assetId, 'details']);
    }

    public receiveNewEntry(internal: Internal) {
        this.getData();
    }

    public deleteEntry() {
        if (this.selectedInternal.id && !this.selectedInternal.processedAsset?.id) {
            this.showDeleteModal = true;
        } else {
            this.toastr.error("You cannot delete this entry because it was already processed.");
        }
    }

    public remove(internalId: number) {
        this.loading = true;

        this.internalService.delete(internalId.toString()).subscribe(() => {
            this.toastr.success("BI Studio Internal entry deleted successfully.");

            this.getData();
        },
            () => { },
            () => this.loading = false);
    }

    public openLink(url: string) {
        if (url)
            window.open(url, '_blank');
    }

    public updateDataEvent(event) {
        if (event) {
            this.getData();
        }
    }

    public closeModalDelete(event) {
        if (event && this.selectedInternal.id != 0) {
            this.remove(this.selectedInternal.id);
        }

        this.selectedInternal = new Internal();

        this.selectedInternal.id = 0;

        this.showDeleteModal = false;
    }

    public getHeaderValue(column: any) {
        return typeof (column) === "string" ? column : column.name;
    }

    private getData() {
        this.internalService.getAll().subscribe((data) => {
            data.forEach((internal) => {
                internal = this.createCustomProperties(internal);
            });

            this.data = data;

            this.setDataSoruceValues();
        },
            () => { },
            () => this.loading = false);
    }

    public processData() {
        if (!this.selectedInternal.validated) {
            this.toastr.error("Please, validate this entry before start processing it.");

            return;
        }

        this.internalService.process(this.selectedInternal.id).subscribe((assetId) => {
            const toast = this.toastr.success(
                `The data was successfully processed. Click here to access your asset.`);

            toast.onTap.subscribe(() => {
                this.router.navigate(['/asset', assetId, 'details']);
            });

            this.selectedInternal.processedAsset = new Asset();

            this.selectedInternal.processedAsset.id = assetId;

            this.getData();
        });
    }

    public validateData() {
        if (this.selectedInternal.processedAsset?.id) {
            this.toastr.error("This entry was already processed.");

            return;
        }

        if (!this.currentUserHasRole("Owner")) {
            this.toastr.error("Only owners can change the validation status.");

            return;
        }

        this.internalService.changeValidation(this.selectedInternal.id).subscribe(() => {
            this.toastr.success(`The data was successfully ${this.selectedInternal.validated ? 'unlocked' : 'validated'}.`);

            this.selectedInternal.validated = !this.selectedInternal.validated;
        });
    }

    public currentUserHasRole(role: string) {
        return this.currentUser != null &&
            this.currentUser.roles != null &&
            this.currentUser.roles.some((r) => r.name.toLowerCase() == role.toLowerCase());
    }

    private setDataSoruceValues() {
        this.dataSource.data = this.data;

        this.dataSource.sort = this.sort;

        this.dataSource.paginator = this.paginator;
    }

    private sliceText(text: string) {
        if (!text) return '';

        text = text.trim();

        return !text ? '' : (text.length < 60 ? text : `${text.slice(0, 60)}...`);
    }

    private joinDataByProperty(data: any[], property: string) {
        return data.map((d) => d[property]).join(",\n");
    }

    private createCustomProperties(internal: Internal) {
        // processed
        internal['processedAssetId'] = internal.processedAsset?.id;

        // dates
        internal['editedCreated'] = internal.created.toLocaleDateString('en-US');
        internal['editedModified'] = internal.created.toLocaleDateString('en-US');

        // objects
        internal['storefrontName'] = internal.storefront?.name;
        internal['scrumMasterName'] = internal.scrumMaster?.name;
        internal['assetProductOwnerName'] = internal.assetProductOwner?.name;
        internal['assetProductLineName'] = internal.assetProductLine?.name;
        internal['categoryName'] = internal.category?.name;
        internal['createdByName'] = internal.createdBy?.name;
        internal['modifiedByName'] = internal.modifiedBy?.name;

        // huge strings
        internal['editedVideoLink'] = this.sliceText(internal.assetVideoLink);
        internal['editedAddress'] = this.sliceText(internal.assetAddress);
        internal['editedUsageStatsLink'] = this.sliceText(internal.assetUsageStatsLink);
        internal['editedAskAndLearnLink'] = this.sliceText(internal.assetAskAndLearnLink);
        internal['editedDocumentationLink'] = this.sliceText(internal.assetDocumentationLink);
        internal['editedRequestAccessLink'] = this.sliceText(internal.assetRequestAccessLink);
        internal['editedDescription'] = this.sliceText(internal.assetDescription);

        // lists
        internal['tagsNames'] = this.joinDataByProperty(internal.tags, "name");
        internal['keyProcessesNames'] = this.joinDataByProperty(internal.keyProcesses, "name");

        return internal;
    }

    public setRowAndOpenModal(row): void {
        if (!this.selectedInternal.processedAsset?.id) {
            this.selectedInternal = row;
            this.newEntryModal = true
        } else if (this.selectedInternal.processedAsset?.id) {
            this.activateRoute(this.selectedInternal.processedAsset.id)
        }
    }

    public getStatusClass(validated: boolean, processed: number): string {
        if (validated && processed) {
            return 'icon-green';
        } else if (!validated && !processed) {
            return 'icon-red';
        } else {
            return 'icon-yellow';
        }
    }
}