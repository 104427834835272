import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';
import { Asset } from '~/shared/models/asset.model';
import { AssetService } from '~/shared/services/asset.service';
import { NotificationService } from '~/shared/services/notification.service';

@Component({
  selector: 'app-modal-cim-approval',
  templateUrl: './modal-cim-approval.component.html',
  styleUrls: ['./modal-cim-approval.component.scss']
})
export class ModalCimApprovalComponent implements OnInit {
  private config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'center-modal modal-lg modal-dialog-centered'
  };

  @Input()
  public selectedAsset: Asset;

  @Output()
  public modalClosed = new EventEmitter();

  public approvalText = '';

  @ViewChild('template', { static: true }) public elementView: ElementRef;

  public modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private assetService: AssetService,
    private toastr: NotificationService
  ) {}

  public ngOnInit(): void {
    this.modalRef = this.modalService.show(this.elementView, this.config);
  }

  public cancel() {
    this.closeModal();
  }

  public save() {
    this.assetService
      .approveCIMRelation(this.selectedAsset)
      .pipe(take(1))
      .subscribe((id) => {
        this.closeModal();
        this.selectedAsset.cimVerified = true;
        this.toastr.success('CIM Successfully Approved.', 'Approved');
      });
  }

  public closeModal() {
    this.modalClosed.emit(true);
    this.modalRef.hide();
  }

  public getValueStreams(): string {
    let vs = '';

    for (let i = 0; i < this.selectedAsset.valueStreams.length; i++) {
      vs += this.selectedAsset.valueStreams[i].name;

      if (i < this.selectedAsset.valueStreams.length - 1) {
        vs += ', ';
      }
    }

    return vs;
  }
}
