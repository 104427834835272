<div class="container ">

  <div class="digital-asset-title d-flex align-items-center justify-content-between pb-2 h-15 mt-4">

    <span class="title mr-2 mr-auto">Owners</span>

    <mat-form-field appearance="standard" class="mr-3">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyOwnerFilter($event)" placeholder="Name" #input>
    </mat-form-field>

    <button type="button" class="button-blue px-3 button-add-user" (click)="openSearchUser('Owner')">
      ADD User
    </button>
  </div>

  <div class="row mt-3 px-3">

    <div class=" d-flex flex-column w-100">

      <table mat-table [dataSource]="ownersDataSource" matSort #ownersSort="matSort">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef width="5%" class="align-right"></th>
          <td mat-cell *matCellDef="let element" class="align-right">
            <i class="cursor-pointer fas fa-trash" *ngIf="element.id == selectedOwner?.id" (click)="removePermission('Owner')"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"
          (click)="selectOwnersRow(row)" [ngClass]="{ 'selected': row === selectedOwner }"></tr>
      </table>

      <mat-paginator #ownersPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>
  </div>

</div>

<div class="container mt-2">

  <div class="digital-asset-title d-flex align-items-center justify-content-between pb-2 h-15 mt-4">
    <span class="title mr-2 mr-auto">Members</span>

    <mat-form-field appearance="standard" class="mr-3">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyMemberFilter($event)" placeholder="Name" #input>
    </mat-form-field>

    <button type="button" class="button-blue px-3" (click)="openSearchUser('Member')">
      ADD User
    </button>
  </div>

  <div class="row mt-3 px-3">

    <div class=" d-flex flex-column w-100">

      <table mat-table [dataSource]="membersDataSource" matSort #membersSort="matSort">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef width="5%" class="align-right"> </th>
          <td mat-cell *matCellDef="let element" class="align-right">
            <i class="cursor-pointer fas fa-trash" *ngIf="element.id == selectedMember?.id" (click)="removePermission('Member')"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"
          (click)="selectMembersRow(row)" [ngClass]="{ 'selected': row === selectedMember }"></tr>
      </table>

      <mat-paginator #membersPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>
  </div>

</div>

<div class="container mt-2">

  <div class="digital-asset-title d-flex align-items-center justify-content-between pb-2 h-15 mt-4">

    <span class="title mr-2 mr-auto">BI Studio Internal</span>

    <mat-form-field appearance="standard" class="mr-3">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyInternalFilter($event)" placeholder="Name" #input>
    </mat-form-field>

    <button type="button" class="button-blue px-3 button-add-user" (click)="openSearchUser('Internal')">
      ADD User
    </button>
  </div>

  <div class="row mt-3 px-3">

    <div class=" d-flex flex-column w-100">

      <table mat-table [dataSource]="internalDataSource" matSort #internalsSort="matSort">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef width="5%" class="align-right"> </th>
          <td mat-cell *matCellDef="let element" class="align-right">
            <i class="cursor-pointer fas fa-trash" *ngIf="element.id == selectedInternal?.id" (click)="removePermission('Internal')"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"
          (click)="selectInternalRow(row)" [ngClass]="{ 'selected': row === selectedInternal }"></tr>
      </table>

      <mat-paginator #internalsPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>

  </div>

</div>

<app-search-user
  *ngIf="showSelectUser"
  (userAdded)="userAdded($event)"
  (close)="showSelectUser = false">
</app-search-user>