<div class="review-card p-3 mx-5 h-100">
    <div class="d-flex d-flex flex-row align-items-center">
        <div class="">
            <img
                src="{{ Review.user.image || '/assets/default_user.jpg' }}"
                class="avatar rounded-circle p-0 ml-0" />
        </div>

        <div class="flex-fill w-100 ml-3">
            <div class="d-flex justify-content-between pb-2">
                <span class="user-name">{{ Review.user.name }}</span>

                <span class="date">{{
                    Review.modified.toLocaleDateString()
                    }}</span>
            </div>
            <hr class="m-0" />

            <div class="pt-2" style="width: 100px">
                <app-rating
                    [Rating]="Review.rating"
                    [fontSize]="12"></app-rating>
            </div>
        </div>
    </div>

    <div
        class="pt-4 px-3"
        style="
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;        ">
        {{ Review.review }}
    </div>
</div>
