import { Component, Input, OnInit } from '@angular/core';
import { ShowAllType } from '~/shared/enums/show-all-type';
import { Asset } from '~/shared/models/asset.model';

@Component({
    selector: 'app-board-tags',
    templateUrl: './board-tags.component.html',
    styleUrls: ['./board-tags.component.scss']
})
export class BoardTagsComponent implements OnInit {
    @Input()
    public Tags: Array<Asset>;

    @Input()
    public Title: string;

    @Input()
    public Description: string;

    @Input()
    public showAllType: ShowAllType = null;

    @Input()
    public FontSmall = false;

    @Input()
    public showHeader = true;

    constructor() {}

    public ngOnInit(): void {}
}
