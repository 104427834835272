import { Component, Input, OnInit } from '@angular/core';
import { ShowAllType } from '~/shared/enums/show-all-type';

@Component({
    selector: 'app-board-storefronts',
    templateUrl: './board-storefronts.component.html',
    styleUrls: ['./board-storefronts.component.scss']
})
export class BoardStorefrontsComponent implements OnInit {
    @Input()
    public Stores;

    @Input()
    public Title: string;

    @Input()
    public Description: string;

    @Input()
    public showAllType: ShowAllType = null;

    @Input()
    public FontSmall = false;

    @Input()
    public iconType: string;

    @Input()
    public isLoading = false;

    @Input()
    public showFullCards = false;

    @Input()
    public = true;

    @Input()
    public isVisible = true;

    constructor() { }

    public ngOnInit(): void { }
}
