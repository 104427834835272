<app-board-header
  *ngIf="showTitle"
  [Title]="Title"
  [marginTop]="marginTop"
  [FontSmall]="FontSmall">
</app-board-header>

<div class="board">

  <div *ngIf="Assets && Assets.length > 0">

    <div class="card-container" *ngIf="!isEdit">
      <app-card-asset
        class="card-item"
        (clickLike)="emitLike($event)"
        *ngFor="let asset of Assets"
        [Asset]="asset"
        [logoAreaColor]="logoAreaColor"
        [isRemove]="isRemove">
      </app-card-asset>
    </div>

    <div class="row no-gutters" *ngIf="isEdit">
      <app-card-asset-horizontal
        class="{{ assetCardSize }}"
        (clickLike)="emitLike($event)"
        *ngFor="let asset of Assets"
        [isEdit]="isEdit"
        [Asset]="asset">
      </app-card-asset-horizontal>
    </div>

  </div>

  <div class="row no-gutters">
    <ng-container *ngIf="isLoading; else noData">
      <div class="d-flex justify-content-center w-100 my-5 py-5">
        <app-loader [height]="32"> </app-loader>
      </div>
    </ng-container>

    <ng-template #noData>
      <div *ngIf="assetsByStorefront.length == 0"
        class="d-flex justify-content-center w-100 pt-5 mt-5">
        There is no related data.
      </div>
    </ng-template>
  </div>
</div>