import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { KeyProcess } from '~/shared/models/keyProcess.model';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { CategoryService } from '~/shared/services/category.service';
import { NotificationService } from '~/shared/services/notification.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  public listStyle = {
    width: '100%',
    height: 'auto',
    dropZoneHeight: '35px'
  };

  public category: Category;
  public store: Storefront;
  public user: User;

  public selectedColor = '#3366CC';

  public responsible: User = {
    email: '',
    id: 0,
    userName: '',
    name: '',
    image: '',
    ginNumber: '',
    administrator: false,
    roles: []
  };

  public AssetsTagsModalType = '';
  public responsiblePhotoPath = '/assets/default_user.jpg';

  public tagsList: Array<Tag> = [];

  public keyProcesses: Array<KeyProcess> = [];

  public isLoading = true;
  public showCreateAssetModal: boolean;
  public showAssetsTagsModal = false;

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    responsible: new FormControl(null, [Validators.required]),
    coResponsible: new FormControl([]),
    color: new FormControl('', [Validators.required]),
    iconName: new FormControl('')
  });

  constructor(
    private router: Router,
    private toastr: NotificationService,
    private categoryService: CategoryService,
    private storeService: StorefrontService,
    private assetService: AssetService,
    private usersService: UserService
  ) {}

  public ngOnInit(): void {
    const urlParams: Array<string> = this.router.url.split('/');
    const categoryId: number = +urlParams[urlParams.length - 1];

    this.getCategory(categoryId);
  }

  public setColor(color: string) {
    this.selectedColor = color;
  }

  private getCategory(categoryId: number) {
    this.isLoading = true;
    this.categoryService
      .getCategoryById(categoryId)
      .pipe(take(1))
      .subscribe(
        (category) => {
          this.category = category;

          this.getStoreById(category.storefront.id);

          this.selectedColor = category.color || this.selectedColor;

          this.form.setValue({
            name: category.name,
            responsible: category.responsible,
            coResponsible: category.coResponsible,
            color: this.selectedColor,
            iconName: category.iconName
          });

          this.generateKeyProcessPriorityArray();

          this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
  }

  private getStoreById(storeId: number) {
    this.storeService
      .getStoreById(storeId)
      .pipe(take(1))
      .subscribe((store) => {
        this.store = store;

        if (store.owner) {
          this.usersService
            .getUserPhoto(store.owner.email)
            .subscribe((photo) => {
              if (photo)
                UtilsService.isValidImage((validImage) => {
                  this.store.owner.image = validImage
                    ? photo
                    : UtilsService.USER_PHOTO_PLACEHOLDER;
                }, photo);
            });
        }
      });
  }

  public onClickButtonAddAsset(assets: Array<Asset>) {
    if (!this.category.assets) this.category.assets = [];

    const allAssets = this.category.assets.concat(assets);

    const assetsIds = allAssets.map((tag) => tag.id);

    const distinct = assetsIds.filter(this.onlyUnique);

    this.category.assets = allAssets.filter((tag) => distinct.includes(tag.id));

    this.generateKeyProcessPriorityArray();
  }

  public onClickButtonAddTag(tags: Array<Tag>) {
    const allTags = this.tagsList.concat(tags);

    const tagsIds = allTags.map((tag) => tag.id);

    const distinct = tagsIds.filter(this.onlyUnique);

    this.tagsList = allTags.filter((tag) => distinct.includes(tag.id));

    this.assetService
      .getAssetsByTags(distinct)
      .pipe(take(1))
      .subscribe((asset) => {
        this.onClickButtonAddAsset(asset);
      });
  }

  public setAssetsList(assets: Array<Asset>) {
    this.category.assets = assets;

    this.generateKeyProcessPriorityArray();
  }

  public removeAssetFromList(assetToDelete: Asset) {
    this.category.assets = this.category.assets.filter(
      (asset) => asset.id !== assetToDelete.id
    );

    this.generateKeyProcessPriorityArray();
  }

  public removeTagFromList(tagToDelete: Tag) {
    this.tagsList = this.tagsList.filter((tag) => tag.id !== tagToDelete.id);
  }

  public onClickCancel() {
    this.router.navigate(['user-profile']);
  }

  public onClickSaveCategory() {
    this.category.name = this.form.controls.name.value;
    this.category.responsible = this.form.controls.responsible.value;
    this.category.coResponsible = this.form.controls.coResponsible.value;
    this.category.color = this.selectedColor;
    this.category.iconName = this.form.controls.iconName.value;
    this.category.keyProcesses = this.keyProcesses;

    if (!this.category.name || !this.category.responsible) {
      this.toastr.error('Check if your category has name and responsible;');

      return;
    }

    this.categoryService.update(this.category).subscribe(() => {
      this.router.navigate(['user-profile']);
      this.toastr.success('Your category was updated successfully.');
    });
  }

  public checkDisabled(): boolean {
    return !this.form.valid;
  }

  public onClickAddDigitalAssetsOrTags(type: string) {
    this.AssetsTagsModalType = type;
    this.showAssetsTagsModal = true;
  }

  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  private generateKeyProcessPriorityArray() {
    const keyProcesses = [];

    this.category.assets.forEach((asset) => {
      asset.keyProcesses?.forEach((keyProcess) => {
        if (!keyProcesses.find((sc) => sc.id === keyProcess.id)) {
          const kopItem = this.category.keyProcesses.find((kp) => {
            return kp.id === keyProcess.id;
          });

          keyProcess.orderIndex = kopItem ? kopItem.orderIndex : 999;

          keyProcesses.push(keyProcess);
        }
      });
    });

    this.keyProcesses = keyProcesses.sort(
      (a, b) => a.orderIndex - b.orderIndex
    );
  }

  public listSorted(listSorted) {
    this.keyProcesses = listSorted;

    for (let index = 0; index < this.keyProcesses.length; index++) {
      this.keyProcesses[index].orderIndex = index;
    }
  }
}
