import { Asset } from './asset.model';
import { ModelBase } from './mode-base.model';
import { User } from './user.model';

export class LikedAsset extends ModelBase {
    constructor(init?: Partial<LikedAsset>) {
        super();
        Object.assign(this, init);
    }

    public user: User;

    public asset: Asset;
}
