import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { RatingStats } from '~/shared/models/ratingStats';
import { Review } from '~/shared/models/review.model';
import { ReviewService } from '~/shared/services/review.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-board-reviews',
  templateUrl: './board-reviews.component.html',
  styleUrls: ['./board-reviews.component.scss']
})
export class BoardReviewsComponent implements OnInit, OnDestroy {
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  @Input()
  public AssetId: number;

  @Output()
  public showModal = new EventEmitter();

  @Output()
  public averageRating = new EventEmitter<number>();

  public ReviewStats: RatingStats = null;

  public Reviews: Array<Review> = [];

  private subscription = new Subscription();

  public userName = this.msalService.getAccount().userName;

  constructor(
    private reviewService: ReviewService,
    private msalService: MsalService,
    private usersService: UserService
  ) {
    this.subscription.add(
      this.reviewService.getReviewClosedObservable().subscribe(() => {
        this.getReviews();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.getReviews();
  }

  private getReviews() {
    this.reviewService
      .getReviewStats(this.AssetId)
      .pipe(take(1))
      .subscribe((rt) => {
        this.ReviewStats = rt;
        this.Reviews = rt.ReviewsWithText;

        this.emitAverageRating();

        if (this.Reviews) {
          this.Reviews.forEach((review) => {
            if (review.user) {
              this.usersService
                .getUserPhoto(review.user.email)
                .subscribe((photo) => {
                  if (photo)
                    UtilsService.isValidImage((validImage) => {
                      review.user.image = validImage
                        ? photo
                        : UtilsService.USER_PHOTO_PLACEHOLDER;
                    }, photo);
                });
            }
          });
        }
      });
  }

  public showReviewModal() {
    this.showModal.emit(true);
  }

  public emitAverageRating() {
    this.averageRating.emit(this.ReviewStats.RatingAverage);
  }

  public get checkReview(): boolean {
    const username = this.userName.substring(0, this.userName.indexOf('@'));
    if (this.Reviews !== undefined) {
      if (this.Reviews.some((review) => review.user.userName === username)) {
        return true;
      }

      return false;
    } else {
      return false;
    }
  }
}
