import { BIReport } from './bi-report.model';
import { Group } from './group.model';
import { KeyProcess } from './keyProcess.model';
import { ModelBase } from './mode-base.model';
import { ProductLine } from './productLine';
import { Storefront } from './storefront.model';
import { Tag } from './tag.model';
import { User } from './user.model';
import { ValueStream } from './value-stream.model';

export interface IKeyProcessAssets {
  keyProcessName: string;
  assets: Array<Asset>;
  orderIndex: number;
}

export class Asset extends ModelBase {
  public description: string;

  public moreInfo: string;

  public productOwner: User;

  public created: Date;

  public url: { Url: string };

  public usageStats: string;

  public videoUrl: string;

  public askAndLearn: string;

  public documentationLink: string;

  public requestAccessLink: string;

  public searchTags: string;

  public active: boolean;

  public retired: boolean;

  public tags: Tag[];

  public productLine: ProductLine;

  public images: Array<string>;

  public logo: string;

  // Sub-categories
  public keyProcesses: KeyProcess[];

  public coOwners: User[];

  public isNew: boolean;

  public isLikedByCurrentUser = false;

  public currentUserCanManage = false;

  public isPriority: boolean;

  public orderIndex = 9999;

  public averageRating: number;

  public slDivisionIds: number[];

  public jobIds: number[];

  public slBusinessLineIds: number[];

  public bfIds: number[];

  public numberOfViews: number;

  public biReport: BIReport;

  public status: string;

  public storefront: Storefront;

  public usingCIM: boolean;

  public cimName: string;

  public valueStreams: ValueStream[];

  public cimVerified: boolean;

  public ignoreBiReport = true;

  public userGroups: Group[];

  constructor() {
    super();
  }
}
