import { Asset } from './asset.model';
import { Group } from './group.model';
import { HelpLink } from './help-link.model';
import { ModelBase } from './mode-base.model';
import { Tag } from './tag.model';
import { User } from './user.model';

export class Storefront extends ModelBase {
  constructor() {
    super();
  }

  public description: string;

  public pageTitle: string;

  public verified: boolean;

  public color: string;

  public created: Date;

  public modified: Date;

  public owner: User;

  public coOwners: User[];

  public editAssetsUsers: User[];

  public logo: string;

  public logoHeader: string;

  public assets: Asset[];

  public storefrontTags: Tag[];

  public defaultPageView = 1;

  public defaultTab = -1;

  public customMessage: string;

  public currentUserCanManage: boolean;

  public helpLinks: HelpLink[];

  public active: boolean;

  public retired: boolean;

  public isNew: boolean;

  public isLikedByCurrentUser = false;

  public tagsFromAssetsOrder: Tag[];

  public userGroups: Group[];

  // public slDivisionIds: number[];

  // public jobIds: number[];

  // public slBusinessLineIds: number[];

  // public bfIds: number[];
}
