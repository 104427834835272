<div [@inOutAnimation] *ngIf="ourSectionsIsVisible | async" class="our-sections border-bottom">
    <div class="container">
        <div class="row justify-content-center px-3">
            <div class="border-left col-md-4">
                <a class="pl-2 text-uppercase font-weight-bold text-muted">Storefronts</a>

                <ng-container *ngIf="Stores?.loaded; else loadingStores">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column">
                            <a [routerLink]="['/store', store.id]" class="p-2 text-white"
                                *ngFor="let store of Stores | slice: 0:5">{{ store.name }}</a>
                        </div>

                        <div class="col-md-6 d-flex flex-column">
                            <a [routerLink]="['/store', store.id]" class="p-2 text-white"
                                *ngFor="let store of Stores | slice: 5:9">{{ store.name }}</a>

                            <div class="view-all d-flex justify-content-center align-items-center p-2">
                                <a (click)="openAllVerifiedStores()" class="cursor-pointer pr-2">View All</a>

                                <svg width="5" height="9" viewBox="0 0 5 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0.402985L0.410959 0L5 4.5L0.410959 9L0 8.59701L4.17808 4.5L0 0.402985Z"
                                        fill="#919499" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-template #loadingStores>
                    <div class="d-flex justify-content-center align-items-center w-100 h-100">
                        <app-loader [fill]="'#ffffff'" [height]="32"></app-loader>
                    </div>
                </ng-template>
            </div>

            <div class="border-left col-md-4">
                <a class="pl-2 text-uppercase font-weight-bold text-muted">Tags</a>

                <ng-container *ngIf="Tags?.length; else loadingTags">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-column">
                            <a class="cursor-pointer p-2 text-white" *ngFor="let tag of Tags | slice: 0:5"
                                (click)="onTagClick(tag)">{{ tag.name }}
                            </a>
                        </div>

                        <div class="col-md-6 d-flex flex-column">
                            <a class="cursor-pointer p-2 text-white" *ngFor="let tag of Tags | slice: 5:9"
                                (click)="onTagClick(tag)">{{ tag.name }}</a>

                            <div class="view-all d-flex justify-content-center align-items-center p-2">
                                <a (click)="openAllTags()" class="cursor-pointer pr-2">View All</a>

                                <svg width="5" height="9" viewBox="0 0 5 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0.402985L0.410959 0L5 4.5L0.410959 9L0 8.59701L4.17808 4.5L0 0.402985Z"
                                        fill="#919499" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-template #loadingTags>
                    <div class="d-flex justify-content-center align-items-center w-100 h-100">
                        <app-loader [fill]="'#ffffff'" [height]="32"></app-loader>
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="row justify-content-center p-3 pt-5 text-uppercase">
            <a (click)="OpenAllAssets()" class="cursor-pointer view-all-digital-assets">VIEW ALL BI ASSETS</a>
        </div>
    </div>
</div>