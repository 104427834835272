<div style="margin-top: -10px;">
  <span class="text-white btn btn-notification" (click)="showNotificationModel()">Notifications</span>
  <span [matBadge]="notRead" [matBadgeHidden]="notRead == 0" matBadgeColor="warn"></span>
</div>

<ng-template #template>

  <div
    class="modal-header d-flex align-items-center">
    <h5 class="modal-title pull-left">Notifications</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body p-0">

    <div class="notifications-area d-flex">

      <div class="container p-0">

        <div *ngIf="notificationsCategories.length == 0"
          class="no-notifications d-flex flex-column w-100 h-100 align-items-center justify-content-center">
          <i class="fas fa-bell mb-3" style="font-size: 28px;"></i>
          <span>No notifications right now</span>
        </div>

        <div *ngIf="notificationsCategories.length > 0" class="row no-gutters h-100">

          <div class="col-md-4">

            <div class="notifications-menu d-flex flex-column">

              <div class="categories" *ngFor="let category of notificationsCategories;">

                <div
                  class="category-header cursor-pointer d-flex align-items-center"
                  [ngClass]="{'category-selected': category.expand}"
                  (click)="setSelectedCategory(category)">

                  <i class="fas fa-chevron-right py-1 px-2"></i>

                  <span class="category-title ">
                    {{ category.name }}
                  </span>

                  <span *ngIf="category.notReadCount" class="not-read-count ml-auto">
                    {{ category.notReadCount }}
                  </span>

                </div>

                <div
                  [@inOutAnimation]
                  *ngIf="category.expand"
                  class="notifications d-flex flex-column">

                  <div *ngFor="let notification of category.notifications;"
                    class="notification-title d-flex align-items-center"
                    [ngClass]="{'notification-selected': selectedNotification?.id == notification.id}"
                    (click)="setNotificationSelected(notification)">

                    <div class="not-read-indicator" [ngStyle]=" {'background-color': !notification.viewed ? 'white' : null } "></div>

                    <span [ngClass]="{'not-read': !notification.viewed}">{{notification.title}}</span>

                  </div>

                </div>

              </div>

            </div>

          </div>

          <div class="col-md-8">

            <div *ngIf="!selectedNotification"
              class="no-notifications d-flex flex-column w-100 h-100 align-items-center justify-content-center">
              <span>No notification selected</span>
              <span style="font-size: 13px;">(Please select a message in the left panel)</span>
            </div>

            <div *ngIf="selectedNotification" class="notification-details">

              <div class="menu-bar d-flex pt-2">
                <span class="date">{{selectedNotification.created | date }}</span>
                <span class="delete ml-auto" (click)="delete(selectedNotification.id)">Delete</span>
              </div>

              <div class="notification-details-title d-flex align-items-center">

                <span class="pr-4">{{selectedNotification.title}}</span>

              </div>

              <div class="notification-details-description d-flex flex-column">

                <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(selectedNotification.description)"></span>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</ng-template>