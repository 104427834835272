import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetMaintenanceEnum } from '~/core/enums/test.enum';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-assets-maintenance',
  templateUrl: './assets-maintenance.component.html',
  styleUrls: ['./assets-maintenance.component.scss']
})
export class AssetsMaintenanceComponent implements OnInit {
  public assets: Array<Asset> = [];

  public user: User;
  public selectedAsset = null;
  public showModal = false;

  @ViewChild('assetsSort', { static: false })
  public assetsSort: MatSort;

  @ViewChild('assetsPaginator', { static: false })
  public assetsPaginator: MatPaginator;

  public assetsDataSource = new MatTableDataSource<Asset>([]);

  public displayColumns: string[] = ['status', 'name', 'edit'];

  public currentFilter = '';

  public isLoading = true;

  constructor(
    private assetsService: AssetService,
    private usersService: UserService
  ) {}

  public ngOnInit(): void {
    this.usersService.getCurrentUser().then((obj) => {
      this.user = obj.currentUser;

      if (UtilsService.isCurrentUserOwnerOrMember(this.user)) {
        this.getAssets();
      }
    });
  }

  private getAssets(): void {
    this.assetsService.getAsstesByProductOwnerWithStatus().subscribe(
      (assets) => {
        this.selectedAsset = null;

        this.assets = [];
        this.assets.push(...assets);

        this.assetsDataSource.sort = this.assetsSort;
        this.assetsDataSource.paginator = this.assetsPaginator;

        this.filterAssets();

        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }

  public takeAction(asset) {
    this.selectedAsset = asset;
    this.showModal = true;
  }

  public selecAssetRow(asset): void {
    this.selectedAsset = asset;
  }

  public getStatusClass(asset): string {
    switch (asset.status) {
      case 'assign':
        return 'icon-orange';

      case 'duplicated':
        return 'icon-red';

      case 'update':
        return 'icon-yellow';

      case 'ok':
        return 'icon-green';
    }
  }

  public getStatusName(asset): string {
    switch (asset.status) {
      case AssetMaintenanceEnum.assign:
        return 'BI Report link not validated';

      case AssetMaintenanceEnum.duplicated:
        return 'Duplicated';

      case AssetMaintenanceEnum.update:
        return 'Update needed';

      case AssetMaintenanceEnum.ok:
        return 'No action is required';
    }
  }

  public getbtnTitle(asset): string {
    switch (asset.status) {
      case AssetMaintenanceEnum.assign:
        return 'Validate link';

      case AssetMaintenanceEnum.duplicated:
        return 'Fix duplication';

      case AssetMaintenanceEnum.update:
        return 'Fix Name or URL';
    }
  }

  public filterAssets() {
    if (this.currentFilter !== '') {
      this.assetsDataSource.data = this.assets.filter(
        (a) => a.status === this.currentFilter
      );
    } else {
      this.assetsDataSource.data = this.assets;
    }
  }

  public applyFilter(status) {
    if (status === this.currentFilter) {
      this.currentFilter = '';
    } else {
      this.currentFilter = status;
    }

    this.filterAssets();
  }

  public applySearchFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assetsDataSource.filter = filterValue.trim().toLowerCase();
  }
}
