import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from '~/core/services/utils.service';
import { Tag } from '~/shared/models/tag.model';

@Component({
  selector: 'app-tags-drag-drop-sort',
  templateUrl: './tags-drag-drop-sort.component.html',
  styleUrls: ['./tags-drag-drop-sort.component.scss']
})
export class TagsDragDropSortComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
  @ViewChild('dropListContainer') public dropListContainer?: ElementRef;

  @Input()
  public tags: Array<Tag> = [];

  public dropListReceiverElement?: HTMLElement;
  public dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  public dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.tags, dragIndex, dropIndex);
    }
  }

  public dragMoved(event: CdkDragMove<number>) {
    if (!this.dropListContainer || !this.dragDropInfo) return;

    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  public dragDropped(event: CdkDragDrop<number>) {
    if (!this.dropListReceiverElement) {
      return;
    }

    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  public sortByName() {
    this.tags = UtilsService.sortArrayByTitle(this.tags);
  }
}
