import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { StorefrontDefaultPageViewType } from '~/shared/enums/storefront-page-view-type';
import { Asset, IKeyProcessAssets } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { Storefront } from '~/shared/models/storefront.model';
import { Tag } from '~/shared/models/tag.model';
import { AssetService } from '~/shared/services/asset.service';
import { CategoryService } from '~/shared/services/category.service';
import { TagService } from '~/shared/services/tag.service';

@Component({
    selector: 'app-filter-board',
    templateUrl: './filter-board.component.html',
    styleUrls: ['./filter-board.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('250ms ease-out', style({ height: '*', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                animate('250ms ease-in', style({ height: 0, opacity: 0 }))
            ])
        ])
    ]
})
export class FilterBoardComponent implements OnInit {
    @Input()
    public storefront: Storefront;

    @Input()

    public pageViewType: StorefrontDefaultPageViewType = StorefrontDefaultPageViewType.Detailed;

    public storefrontPageViewType: typeof StorefrontDefaultPageViewType = StorefrontDefaultPageViewType;

    public tags: Array<Tag> = [];
    public assets: Array<Asset> = [];

    public assetsWithoutCategory: Array<Asset> = [];

    public assetsWithNoTagFIlter: Array<Asset> = [];

    public likedAssets: Array<Asset> = [];

    public categories: Array<Category> = [];

    public headerTitle = 'BI Assets';

    public selectedKeyProcessXAssets: IKeyProcessAssets;

    public selectedCategory: Category;

    public sortBy = 'default';

    public selectedFilterTags: Array<Tag> = [];

    public recommendedAssets: Array<Asset> = [];

    public categoriesLoaded = false;

    public defaultTab = 0;

    public isLoadingRequest = {
        assets: false,
        recommendedAssets: false
    };

    constructor(
        private assetService: AssetService,
        private categoryService: CategoryService,
        private tagService: TagService
    ) { }

    public ngOnInit(): void {
        this.showAllAssets();

        // Assets without category
        if (this.storefront.assets.length > 0) {
            this.assetsWithoutCategory = this.storefront.assets;
            this.sortAssetsArrayAndSetAsMain(this.storefront.assets);
        }

        // tags from all assets, including categories
        this.tagService
            .getAllTagsFromStore(this.storefront.id)
            .subscribe((tags) => {
                this.tags = tags;
            });

        // get all categories
        this.categoryService
            .getAllByStorefront(this.storefront.id)
            .subscribe((categories) => {
                this.categories = categories;
                this.categoriesLoaded = true;
            });

        this.getRecommendedAssets();
        this.checkTabToSetAsMain();
    }

    // set the selected key procecss by the user
    public setSelectedKeyProcess(
        category: Category,
        kp: IKeyProcessAssets
    ): void {
        this.selectedCategory = null;
        this.selectedKeyProcessXAssets = kp;
        this.sortAssetsArrayAndSetAsMain(kp.assets);
        this.headerTitle = category.name + ' - ' + kp.keyProcessName;

        this.clearSeletedTags();
    }

    // Clear the selected Key Process and show the Assets without category
    public clearFilter(): void {
        this.selectedKeyProcessXAssets = null;
        this.sortAssetsArrayAndSetAsMain(this.assetsWithoutCategory);
        this.headerTitle = 'BI Assets';

        this.clearSeletedTags();
    }

    private clearSeletedTags(): void {
        this.tags.forEach((tag) => {
            tag.SelectedOnFilter = false;
        });

        this.selectedFilterTags = [];
    }

    // chenge the sort type
    public toggleSort(type: string): void {
        this.sortBy = type;
        this.sortAssetsArrayAndSetAsMain(this.assets);
        this.sortRecommendedAssetsArrayAndSetAsMain(this.recommendedAssets);
    }

    // creates the array used to create the Tags menu
    private createOrChangeTagArray() {
        this.tags = this.tagService.countTagsReferenceFromAssetsArray(
            this.assets
        );
    }

    private sortAssetsArrayAndSetAsMain(assets: Array<Asset>): void {
        assets.forEach(a => {
            a.tags = this.tagService.filterTagsByStore(a.tags, this.storefront.id)
        });

        if (assets) {
            if (this.sortBy === 'most-recent') {
                this.assets = UtilsService.sortArrayByMostRecentAndPriority(assets);
            } else if (this.sortBy === 'name') {
                this.assets = UtilsService.sortArrayByTitleAndPriority(assets);
            } else {
                this.assets = UtilsService.sortArrayByOrderIndexAndPriority(assets);
            }


            // make a backup of the assets array, if the user applies a tag filter, we can user the backuped array.
            this.assetsWithNoTagFIlter = this.assets;

            this.createOrChangeTagArray();
        }

        this.setLikedAssets();
        this.isLoadingRequest.assets = false;
    }

    private sortRecommendedAssetsArrayAndSetAsMain(assets: Array<Asset>): void {
        if (assets) {
            if (this.sortBy === 'most-recent') {
                this.recommendedAssets =
                    UtilsService.sortArrayByMostRecentAndPriority(assets);
            } else {
                this.recommendedAssets =
                    UtilsService.sortArrayByTitleAndPriority(assets);
            }

            this.setLikedAssets();
        }
    }

    private setLikedAssets(): void {
        this.likedAssets = this.assets.filter((a) => a.isLikedByCurrentUser);

        Array.prototype.push.apply(this.likedAssets);
        this.setLikedAssetsArrayAndSetAsMain();
    }

    private setLikedAssetsArrayAndSetAsMain(): void {
        if (this.likedAssets) {
            if (this.sortBy === 'most-recent') {
                this.likedAssets =
                    UtilsService.sortArrayByMostRecentAndPriority(this.likedAssets);
            } else {
                this.likedAssets =
                    UtilsService.sortArrayByTitleAndPriority(this.likedAssets);
            }
        }
    }

    public setSeletedTag(event, tag: Tag): void {
        tag.SelectedOnFilter = event.target.checked;
        if (event.target.checked) {
            // Add tag to the selected tags list
            const i = this.selectedFilterTags.findIndex((x) => x.id === tag.id);
            if (i <= -1) {
                this.selectedFilterTags.push(tag);
            }
        } else {
            // remove tag from the selected tags list
            const i = this.selectedFilterTags.findIndex((x) => x.id === tag.id);
            if (i <= -1) {
                this.selectedFilterTags.push(tag);
            }
            this.selectedFilterTags.splice(i, 1);
        }

        // clear all current assets being displayed
        this.assets = [];

        // If there is no selected Tag, the show the full Assets Array
        if (this.selectedFilterTags.length === 0) {
            this.assets = this.assetsWithNoTagFIlter;
        } else {
            // Filter all Assets using any of the selected tags.
            const filteredAssets = [];
            this.assetsWithNoTagFIlter.forEach((asset) => {
                this.selectedFilterTags.forEach((selectedTag) => {
                    if (asset.tags.some((t) => t.id === selectedTag.id)) {
                        filteredAssets.push(asset);
                    }
                });
            });

            filteredAssets.forEach((asset) => {
                const i = this.assets.findIndex((x) => x.id === asset.id);
                if (i <= -1) {
                    this.assets.push(asset);
                }
            });
        }
    }

    public showAllAssets(): void {
        this.isLoadingRequest.assets = true;
        this.assets = [];
        this.headerTitle = 'All BI Assets';
        this.selectedKeyProcessXAssets = null;
        this.selectedCategory = null;

        this.assetService
            .getAllAssetsFromStoreWithCategories(this.storefront.id)
            .subscribe((assets) => {
                this.sortAssetsArrayAndSetAsMain(assets);
            });
    }

    get totalAssets(): number {
        return this.assets.length;
    }

    public setSelectedCategory(category: Category): void {
        this.selectedKeyProcessXAssets = null;
        this.selectedCategory = category;

        if (category.assets) {
            const allAssets = category.assets;
            this.sortAssetsArrayAndSetAsMain(allAssets);
        } else {
            this.assets = [];
        }

        this.headerTitle = category.name;

        this.clearSeletedTags();
    }

    public getRecommendedAssets() {
        this.isLoadingRequest.recommendedAssets = true;

        this.assetService
            .getRecommendedByStorefront(this.storefront.id)
            .pipe(take(1))
            .subscribe((assets) => {
                this.recommendedAssets = assets;
                this.isLoadingRequest.recommendedAssets = false;
                this.sortRecommendedAssetsArrayAndSetAsMain(this.recommendedAssets);
                this.checkTabToSetAsMain();
            });
    }

    public onClickLikeButton() {
        this.assets = JSON.parse(JSON.stringify(this.assets));
        this.recommendedAssets = JSON.parse(JSON.stringify(this.recommendedAssets))

        this.setLikedAssets();
    }

    private checkTabToSetAsMain() {
        if (this.storefront.defaultTab == 0) {
            if (this.likedAssets?.length > 0) {
                this.defaultTab = 0;
            } else if (this.recommendedAssets?.length > 0) {
                this.defaultTab = 1;
            } else {
                this.defaultTab = 2;
            }
        } else {
            this.defaultTab = this.storefront.defaultTab - 1;
        }
    }
}
