import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Storefront } from '~/shared/models/storefront.model';
import { HeaderService } from '~/shared/services/header.service';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    public StoreColor: BehaviorSubject<string>;
    public selectedStore: BehaviorSubject<Storefront>;

    constructor(
        private heraderService: HeaderService,
        private router: Router
    ) {}

    public ngOnInit() {
        this.StoreColor = this.heraderService.storeColor$;
        this.selectedStore = this.heraderService.selectedStore$;
    }
}
