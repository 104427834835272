import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  @Input()
  public heading: string;

  @Input()
  public color: string;

  @Output()
  public event: EventEmitter<string> = new EventEmitter<string>();

  public show = false;

  public defaultColors: string[] = [
    '#0014DC', // SLB Blue
    '#00D2DC', // SLB Aqua Blue
    '#051464', // SLB Deep Blue
    '#6E8CC8', // SLB Frost Blue 1
    '#AFBEE1', // SLB Frost Blue 2
    '#C8CDCD', // SLB Light Grey 3
    '#AAAAAA', // SLB Light Grey 4
    '#32323C', // SLB Dark Grey
    '#000000', // Black
    '#198C19', // Ext Green 1
    '#056E5A', // Ext Teal 1
    '#292963', // Ext Purple 1
    '#871445', // Ext Pink 1
    '#B50A0A', // Ext Orange 1
    '#0014DC',
    '#00A0C1',
    '#999999',
    '#008C7F',
    '#8177B6',
    '#F4881C',
    '#FDC526'
  ];

  constructor() {}

  public changeColor(color: string): void {
    this.color = color;
    this.event.emit(this.color);
    this.show = false;
  }

  public changeColorManual(color: string): void {
    const isValid = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

    if (isValid) {
      this.color = color;
      this.event.emit(this.color);
    }
  }

  public toggleColors(): void {
    this.show = !this.show;
  }
}
