export const environment = {
  production: true,
  qualityAssurance: false,
  azureClientId: '28a28b9c-9c57-45dd-b8ee-599ae5dccaa1',
  azureTenantId: '41ff26dc-250f-4b13-8981-739be8610c21',
  apiUrl: 'https://bi-portfolio-api-prod.azurewebsites.net',
  virtualAgentSecretKey:
    'hv1A-bXy6pw.l7B9LbS_VCAUi307bHINT5qxSFNUQDYMLyRHUpOVyrI',
  settings: {
    title: 'Base Project',
    description: null,
    url: null
  }
};
