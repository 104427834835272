<div *ngIf="isVisible">
    <app-board-header *ngIf="Title"
        [Title]="Title"
        [Description]="Description"
        [showAllType]="showAllType"
        [FontSmall]="FontSmall">
    </app-board-header>

    <div class="container p-0">

        <div class="storefronts-container">

            <ng-container *ngIf="!isLoading && !showFullCards">
                <app-card-storefront-simple
                    [iconToShow]="iconType"
                    class="d-block col-sm-12 col-md-6 col-lg-4 col-xl-3 p-1"
                    *ngFor="let store of Stores"
                    [Store]="store">
                </app-card-storefront-simple>
            </ng-container>

            <ng-container *ngIf="!isLoading && showFullCards">
                <app-card-storefront-detailed
                    class="storefront-item"
                    *ngFor="let store of Stores"
                    [Store]="store">
                </app-card-storefront-detailed>
            </ng-container>

            <ng-container *ngIf="isLoading; else noData">
                <div class="d-flex justify-content-center w-100 my-5 py-5">
                    <app-loader [height]="32">
                    </app-loader>
                </div>
            </ng-container>

            <ng-template #noData>
                <div
                    *ngIf="Stores.length == 0"
                    class="d-flex justify-content-center w-100">
                    There is no related data.
                </div>
            </ng-template>
        </div>
    </div>
</div>
