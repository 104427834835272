import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs/internal/Observable';
import { BIReport } from '../models/bi-report.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
  providedIn: 'root'
})
export class BIReportService extends ApiServiceBase<BIReport> {
  constructor(protected http: HttpClient) {
    super(http, 'biReports');
  }

  public getAll(): Observable<Array<BIReport>> {
    return this.get();
  }

  public getByFilter(filter: string): Observable<Array<BIReport>> {
    return filter
      ? this.http.post<BIReport[]>(
          `${environment.apiUrl}/biReports/filter`,
          { filter },
          this.HEADERS
        )
      : this.getAll();
  }

  public getAssetById(Id: number): Observable<BIReport> {
    return this.getSingle(Id.toString());
  }
}
