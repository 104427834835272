import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { Storefront } from '~/shared/models/storefront.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { CategoryService } from '~/shared/services/category.service';
import { HeaderService } from '~/shared/services/header.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public storefronts: Array<Storefront> = [];
  public categories: Array<Category> = [];
  public assets: Array<Asset> = [];
  public user: User;

  public retiredStorefronts: Array<Storefront> = [];
  public retiredAssets: Array<Asset> = [];

  public isCurrentUserInternal = false;
  public isCurrentUserOwner = false;
  public isCurrentUserOwnerOrMember = false;

  public isLoadingRequest = {
    stores: false,
    categories: false,
    assets: false
  };

  constructor(
    private storeService: StorefrontService,
    private categoryService: CategoryService,
    private assetsService: AssetService,
    private headerService: HeaderService,
    private router: Router,
    private usersService: UserService
  ) {}

  public ngOnInit(): void {
    this.headerService.SetDefaultHome();

    this.usersService.getCurrentUser().then((obj) => {
      this.user = obj.currentUser;

      this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(this.user);
      this.isCurrentUserOwnerOrMember = UtilsService.isCurrentUserOwnerOrMember(
        this.user
      );
      this.isCurrentUserInternal = UtilsService.isCurrentUserInternal(
        this.user
      );

      this.getProfilePhoto();

      if (this.isCurrentUserOwnerOrMember) {
        this.getStores();
        this.getCategories();
        this.getAssets();
      }

      if (this.isCurrentUserOwner) {
        this.getRetiredAssetsAndStores();
      }
    });
  }

  private getStores(): void {
    this.isLoadingRequest.stores = true;
    this.storeService.getStoreByOwner(this.user.id).subscribe(
      (stores) => {
        // stores.forEach((store) => {
        //     store.owner.image = this.user.image;
        // });

        this.storefronts.push(...stores);
        this.isLoadingRequest.stores = false;
      },
      () => (this.isLoadingRequest.stores = false)
    );
  }

  private getCategories(): void {
    this.isLoadingRequest.categories = true;
    this.categoryService.getCategoriesByResponsible(this.user.id).subscribe(
      (categories) => {
        // categories.forEach((category) => {
        //     category.responsible.image = this.user.image;
        // });

        this.categories.push(...categories);
        this.isLoadingRequest.categories = false;
      },
      () => (this.isLoadingRequest.categories = false)
    );
  }

  private getAssets(): void {
    this.isLoadingRequest.assets = true;
    this.assetsService.getAsstesByProductOwner(this.user.id).subscribe(
      (assets) => {
        // assets.forEach((asset) => {
        //     asset.productOwner.image = this.user.image;
        // });

        this.assets.push(...assets);
        this.isLoadingRequest.assets = false;
      },
      () => (this.isLoadingRequest.assets = false)
    );
  }

  private getProfilePhoto(): void {
    this.usersService.getUserPhoto(this.user.email).subscribe((photo) => {
      if (photo)
        UtilsService.isValidImage((validImage) => {
          this.user.image = validImage
            ? photo
            : UtilsService.USER_PHOTO_PLACEHOLDER;
        }, photo);
    });
  }

  private getRetiredAssetsAndStores(): void {
    this.isLoadingRequest.stores = true;
    this.storeService.getAllRetired().subscribe(
      (retiredStores) => {
        this.retiredStorefronts.push(...retiredStores);
        this.isLoadingRequest.stores = false;
      },
      () => (this.isLoadingRequest.stores = false)
    );

    this.isLoadingRequest.assets = true;
    this.assetsService.getAllRetired().subscribe(
      (retiredAssets) => {
        this.retiredAssets.push(...retiredAssets);
        this.isLoadingRequest.assets = false;
      },
      () => (this.isLoadingRequest.assets = false)
    );
  }

  public onClick(event: string) {
    if (event === 'asset') {
      this.router.navigateByUrl('/asset/new');
    }

    if (event === 'store') {
      this.router.navigateByUrl('/store/new');
    }

    if (event === 'data-manager') {
      this.router.navigateByUrl('/simple-data-manager');
    }

    if (event === 'internal') {
      this.router.navigateByUrl('/internal');
    }

    // if (event === 'logout') {
    //     this.router.navigateByUrl('/logout');
    // }
  }
}
