<app-board-header
    [Title]="Title"
    [marginTop]="'mt-0'"
    [showAllType]="showAllType">
</app-board-header>

<div class="container px-0 pt-3 position-relative" style="padding-top: 0 !important;">
    <div #scrollableDiv class="d-flex overflow-hidden w-100">
        <ng-container>

            <button
                *ngIf="showLeftButton"
                class="position-absolute left  btn-arrow"
                (click)="scrollLeft()">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
            </button>

            <div class="asset-container" *ngFor="let asset of Assets">
                <app-card-asset
                    [Asset]="asset">
                </app-card-asset>
            </div>

            <button
                *ngIf="showRightButton"
                class="right  btn-arrow"
                (click)="scrollRight()">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>

        </ng-container>

        <ng-container *ngIf="isLoading; else noData">
            <div class="d-flex justify-content-center align-items-center w-100" style="height: 140px;">
                <app-loader [height]="32"> </app-loader>
            </div>
        </ng-container>

        <ng-template #noData>
            <div *ngIf="Assets.length == 0"
                class="d-flex justify-content-center align-items-center w-100"
                style="height: 140px">
                There is no related data.
            </div>
        </ng-template>
    </div>
</div>
