import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Storefront } from '~/shared/models/storefront.model';
import { StorefrontService } from '~/shared/services/storefront.service';

@Component({
    selector: 'app-storefront-dropdown-selector',
    templateUrl: './storefront-dropdown-selector.component.html',
    styleUrls: ['./storefront-dropdown-selector.component.scss']
})
export class StorefrontDropdownSelectorComponent implements OnInit {
    @Input()
    public storefront: Storefront;

    public storefrontDropDown: Storefront[];

    constructor(
        private storefrontService: StorefrontService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.storefrontService
            .getAll()
            .pipe(take(1))
            .subscribe((storefronte) => {
                this.storefrontDropDown = storefronte;
            });
    }

    public getInitialLetters(): string {
        return UtilsService.getInitialLetters(this.storefront.name);
    }

    public dropDownChanged() {
        // when change the selected Store, the page does not update, so we have to do whi trick to force page to reload.
        // Check a better way to do that we have more time. (maybe never)
        this.router
            .navigateByUrl('/store/' + this.storefront.id + '/aboutus', {
                skipLocationChange: true
            })
            .then(() =>
                this.router.navigate(['/store', this.storefront.id], {
                    skipLocationChange: true
                })
            );
    }
}
