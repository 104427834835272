import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { Asset } from '~/shared/models/asset.model';
import { BIReport } from '~/shared/models/bi-report.model';
import { AssetService } from '~/shared/services/asset.service';
import { BIReportService } from '~/shared/services/bi-report.service';

@Component({
  selector: 'app-bi-reports-selector',
  templateUrl: './bi-reports-selector.component.html',
  styleUrls: ['./bi-reports-selector.component.scss']
})
export class BiReportsSelectorComponent implements OnInit {
  public showBIReportsSelector = false;
  public biReports: Array<BIReport>;

  @Input()
  public selectedBiReport: BIReport;

  @Input()
  public showRequired = false;

  @Input()
  public asset: Asset;

  @Input()
  public showIgnoreBiReportOption = true;

  @Input()
  public ignoreBiReport = false;

  @Output()
  public selectBiReport = new EventEmitter<BIReport>();

  @Output()
  public setURLAddress = new EventEmitter<string>();

  @Output()
  public setIgnoreBIReport = new EventEmitter<boolean>();

  public textToSearch = '';

  public urlToSearch = '';

  public searchType = 'name';

  public assetUsingSelectedReport: Asset;

  public totalItemsByName = -1;
  public totalItemsByUrl = -1;

  constructor(
    private biReportService: BIReportService,
    private assetService: AssetService
  ) {}

  public ngOnInit(): void {
    if (this.selectedBiReport?.name) {
      this.textToSearch = this.selectedBiReport.name;
    } else if (this.asset.name) {
      this.textToSearch = this.asset.name;
    }

    this.urlToSearch = this.asset.url as any;

    if (this.asset?.name) {
      this.biReportService
        .getByFilter(this.asset.name)
        .pipe(take(1))
        .subscribe((items) => {
          this.totalItemsByName = items.length;
        });
    }
    if (this.asset?.url) {
      this.biReportService
        .getByFilter(this.asset.url as any)
        .pipe(take(1))
        .subscribe((items) => {
          this.totalItemsByUrl = items.length;
        });
    }
  }

  public searchBIReports(isUrl = false) {
    let search = '';

    if (isUrl) {
      if (this.urlToSearch.length < 3) return;
      search = this.urlToSearch;
    } else {
      if (this.textToSearch.length < 3) return;
      search = this.textToSearch;
    }

    this.biReports = null;

    this.showBIReportsSelector = true;

    this.biReportService
      .getByFilter(search)
      .pipe(take(1))
      .subscribe((items) => {
        this.biReports = items;
        this.showBIReportsSelector = true;

        if (isUrl) {
          this.totalItemsByUrl = items.length;
        } else {
          this.totalItemsByName = items.length;
        }
      });
  }

  public closeBiReports() {
    this.showBIReportsSelector = false;
    this.biReports = null;
  }

  public biReportSelected(biReport: BIReport) {
    this.showBIReportsSelector = false;
    this.assetUsingSelectedReport = null;
    this.totalItemsByUrl = this.totalItemsByName = -1;

    this.assetService
      .getByBIReport(biReport?.id)
      .pipe(take(1))
      .subscribe((assets) => {
        if (assets.length > 0 && assets[0].id !== this.asset.id) {
          this.assetUsingSelectedReport = assets[0];
        } else {
          this.selectedBiReport = biReport;
          this.selectBiReport.emit(this.selectedBiReport);
        }
      });
  }

  public clear() {
    this.selectedBiReport = null;
    this.textToSearch = '';
    this.urlToSearch = this.asset.url.Url;

    this.selectBiReport.emit(null);
  }

  public setSearchType(searchType: string) {
    this.searchType = searchType;
  }

  public setIgnoreBiReport() {
    this.selectedBiReport = null;
    this.selectBiReport.emit(null);
    this.setIgnoreBIReport.emit(this.ignoreBiReport);
    this.setURLAddress.emit(this.urlToSearch);
  }

  public urlChanged() {
    this.setURLAddress.emit(this.urlToSearch);
  }
}
