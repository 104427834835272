import { Component, Input, OnInit } from '@angular/core';
import { KeyProcess } from '~/shared/models/keyProcess.model';

@Component({
  selector: 'app-category-key-process',
  templateUrl: './category-key-process.component.html',
  styleUrls: ['./category-key-process.component.scss']
})
export class CategoryKeyProcessComponent implements OnInit {
  @Input()
  public keyProcess: KeyProcess;

  constructor() {}

  public ngOnInit(): void {}
}
