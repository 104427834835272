import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Storefront } from '../models/storefront.model';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  private defaultHeaderColor = '#0014DC';

  private ourSectionsVisibility = false;
  private categoriesVisibility = false;
  private store: Storefront;
  private storeColor = this.defaultHeaderColor;
  // private virtualAgentVisible = false;

  public ourSectionsVisibility$ = new BehaviorSubject<boolean>(
    this.ourSectionsVisibility
  );
  public categoriesVisibility$ = new BehaviorSubject<boolean>(
    this.categoriesVisibility
  );

  public selectedStore$ = new BehaviorSubject<Storefront>(null);
  public storeColor$ = new BehaviorSubject<string>(this.storeColor);
  public showBackButton$ = new BehaviorSubject<boolean>(true);

  public showHomeMenuTabs$ = new BehaviorSubject<boolean>(false);
  public homeMenuTabChanged$ = new BehaviorSubject<string>('storefronts');

  private resetOpenedHeaderMenus() {
    this.ourSectionsVisibility = false;
    this.ourSectionsVisibility$.next(this.ourSectionsVisibility);

    this.categoriesVisibility = false;
    this.categoriesVisibility$.next(this.categoriesVisibility);
  }

  public ToggleOurSectionsVisibility(): void {
    this.ourSectionsVisibility = !this.ourSectionsVisibility;
    this.ourSectionsVisibility$.next(this.ourSectionsVisibility);
  }

  public ToggleCategoriesVisibility(): void {
    this.categoriesVisibility = !this.categoriesVisibility;
    this.categoriesVisibility$.next(this.categoriesVisibility);
  }

  public SetStore(store: Storefront): void {
    this.store = store;
    this.selectedStore$.next(this.store);

    this.storeColor = store.color;
    this.storeColor$.next(this.storeColor);

    this.resetOpenedHeaderMenus();
  }

  public SetDefaultHome(): void {
    this.store = null;
    this.selectedStore$.next(null);

    this.storeColor = this.defaultHeaderColor;
    this.storeColor$.next(this.storeColor);

    this.resetOpenedHeaderMenus();
  }

  public setColor(color: string): void {
    this.storeColor = color;
    this.storeColor$.next(this.storeColor);
  }

  public resetColor(): void {
    this.storeColor = this.defaultHeaderColor;
    this.storeColor$.next(this.storeColor);
  }

  public getOpenedStorefrontId(): number {
    if (this.store) return this.store.id;

    return null;
  }

  public showOrHideBackButton(val: boolean) {
    this.showBackButton$.next(val);
  }

  public showHomeMenuTabs(show: boolean): void {
    this.showHomeMenuTabs$.next(show);
    this.changeHomeMenuSelectedTab('storefronts');
  }

  public changeHomeMenuSelectedTab(tab: string): void {
    this.homeMenuTabChanged$.next(tab);
  }
}
