import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '@env';
import { Observable } from 'rxjs/internal/Observable';
import { RoleTypes } from '../enums/role-type';
import { Asset } from '../models/asset.model';
import { IImage } from '../models/image.model';
import { Storefront } from '../models/storefront.model';
import { User } from '../models/user.model';
import { ApiServiceBase } from './api-service-base.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService extends ApiServiceBase<Asset> {
  constructor(
    protected http: HttpClient,
    private router: Router,
    private usersService: UserService
  ) {
    super(http, 'assets');
  }

  public getAll(): Observable<Array<Asset>> {
    return this.get();
  }

  public getAssets(limit: number = 0): Observable<Array<Asset>> {
    return this.get('most-visited/' + (limit > 0 ? limit : ''));
  }

  public getAssetById(Id: number): Observable<Asset> {
    return this.getSingle(Id.toString());
  }

  public getAllAssetsFromStore(storeId: number): Observable<Array<Asset>> {
    return this.get('store-front/' + storeId.toString());
  }

  public getByBIReport(BiReportId: number): Observable<Array<Asset>> {
    return this.get('bi-report/' + BiReportId.toString());
  }

  public getAllAssetsFromStoreWithCategories(
    storefrontId: number
  ): Observable<Array<Asset>> {
    return this.get(`storefront-with-categories/${storefrontId}`);
  }

  public getLastAddedByStore(store: Storefront): Observable<Array<Asset>> {
    return this.get('last-added-by-store/' + store.id.toString());
  }

  public getLastAddede(limit: number = 0): Observable<Array<Asset>> {
    return this.get('last-added/' + (limit > 0 ? limit : ''));
  }

  public getMostVisited(limit: number = 0): Observable<Array<Asset>> {
    return this.get('most-visited/' + (limit > 0 ? limit : ''));
  }

  public getAssetsByTagId(tagId: number): Observable<Array<Asset>> {
    return this.get('tag/' + tagId);
  }

  public getAssetsByTags(tagsIds: Array<number>): Observable<Array<Asset>> {
    return this.http.post<Array<Asset>>(
      `${environment.apiUrl}/assets/tag`,
      tagsIds,
      this.HEADERS
    );
  }

  public getAssetsByFilter(filter: string): Observable<Asset[]> {
    return filter
      ? this.http.post<Asset[]>(
          `${environment.apiUrl}/assets/filter`,
          { filter },
          this.HEADERS
        )
      : this.getAll();
  }

  public getSimpleData(filter: string): Observable<Asset[]> {
    return this.http.post<Asset[]>(
      `${environment.apiUrl}/assets/simple-data-filter`,
      { filter },
      this.HEADERS
    );
  }

  public getLastVisitedByCurentUser(
    limit: number = 0
  ): Observable<Array<Asset>> {
    return this.get('last-visited-by-current-user/' + (limit > 0 ? limit : ''));
  }

  public getAsstesByProductOwner(userId: number): Observable<Array<Asset>> {
    return this.get('product-owner/' + userId.toString());
  }

  public getAsstesByProductOwnerWithStatus(): Observable<Array<Asset>> {
    return this.get('product-owner-with-status');
  }

  public getAllByIds(ids: number[]): Observable<Asset[]> {
    return this.http.post<Array<Asset>>(
      `${environment.apiUrl}/assets/all-by-ids`,
      ids,
      this.HEADERS
    );
  }

  public getAllRetired(): Observable<Array<Asset>> {
    return this.get('retired');
  }

  public getRecommended(limit: number = 0): Observable<Array<Asset>> {
    return this.get(`recommended/${limit}`);
  }

  public getRecommendedByStorefront(
    storefrontId: number,
    limit: number = 0
  ): Observable<Array<Asset>> {
    return this.get(`recommended/${storefrontId}/${limit}`);
  }

  public approveCIMRelation(asset: Asset) {
    return this.http.put<number>(
      `${environment.apiUrl}/assets/approve-cim/${asset.id}`,
      null,
      this.HEADERS
    );
  }

  public update(
    item: Asset,
    logo?: IImage,
    images?: IImage[]
  ): Observable<number> {
    return this.put({
      Id: item.id,
      Name: item.name,
      Description: item.description,
      MoreInfo: item.moreInfo,
      Storefront: item.storefront,
      CoOwners: item.coOwners,
      Url: item.url,
      AskAndLearn: item.askAndLearn,
      DocumentationLink: item.documentationLink,
      RequestAccessLink: item.requestAccessLink,
      ProductOwnerId: item.productOwner,
      UsageStats: item.usageStats,
      VideoUrl: item.videoUrl,
      SearchTags: item.searchTags,
      Active: item.active,
      Retired: item.retired,

      IdProductLine: item.productLine?.id,
      IdsKeyProcesses: item.keyProcesses,
      Tags: item.tags,

      SlDivisionIds: item.slDivisionIds,
      JobIds: item.jobIds,
      SlBusinessLinesIds: item.slBusinessLineIds,
      BfIds: item.bfIds,

      BiReportId: item.biReport?.id,
      IgnoreBiReport: item.ignoreBiReport,
      UsingCIM: item.usingCIM,
      CIMName: item.cimName,
      ValueStreams: item.valueStreams,
      UserGroups: item.userGroups,

      LogoFile: logo
        ? {
            Base64: logo.localPath,
            Name: logo.image.name,
            ImageType: logo.image.type
          }
        : null,

      ImagesFiles:
        images && images.length > 0
          ? images.map((i) => {
              return {
                Base64: i.localPath,
                Name: i.image?.name ? i.image.name : null,
                ImageType: i.image?.type ? i.image.type : null,
                IsNew: i.isNew
              };
            })
          : null
    });
  }

  public simpleUpdate(item: Asset) {
    const assetDto = {
      Id: item.id,
      Name: item.name,
      Url: item.url,
      BiReportId: item.biReport?.id
    };

    return this.http.put<number>(
      `${environment.apiUrl}/assets/simple-update`,
      assetDto,
      this.HEADERS
    );
  }

  public sortArrayByTitle(assets: Array<Asset>): Array<Asset> {
    return assets.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public sortArrayByMostRecent(assets: Array<Asset>): Array<Asset> {
    return assets.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );
  }

  public add(
    item: Asset,
    logo?: IImage,
    images?: IImage[]
  ): Observable<number> {
    return this.post({
      Name: item.name,
      Description: item.description,
      MoreInfo: item.moreInfo,
      Storefront: item.storefront,
      CoOwners: item.coOwners,
      ProductOwnerId: item.productOwner,
      Url: item.url,
      AskAndLearn: item.askAndLearn,
      DocumentationLink: item.documentationLink,
      RequestAccessLink: item.requestAccessLink,
      UsageStats: item.usageStats,
      VideoUrl: item.videoUrl,
      SearchTags: item.searchTags,
      Active: item.active,
      Retired: item.retired,

      ProductLineId: item.productLine?.id,
      IdsKeyProcesses: item.keyProcesses,
      Tags: item.tags,

      SlDivisionIds: item.slDivisionIds,
      JobIds: item.jobIds,
      SlBusinessLinesIds: item.slBusinessLineIds,
      BfIds: item.bfIds,

      BiReportId: item.biReport?.id,
      IgnoreBiReport: item.ignoreBiReport,

      UsingCIM: item.usingCIM,
      CIMName: item.cimName,
      ValueStreams: item.valueStreams,
      UserGroups: item.userGroups,

      LogoFile: logo
        ? {
            Base64: logo.localPath,
            Name: logo.image.name,
            ImageType: logo.image.type
          }
        : null,
      ImagesFiles:
        images && images.length > 0
          ? images.map((i) => {
              return {
                Base64: i.localPath,
                Name: i.image.name,
                ImageType: i.image.type
              };
            })
          : null
    });
  }

  public addBulkAssets(csvFile: File): Observable<number[]> {
    const formData = new FormData();

    formData.append('file', csvFile, csvFile.name);

    return this.http.post<number[]>(
      `${environment.apiUrl}/assets/add-bulk-assets`,
      formData,
      this.TEXT_HEADERS
    );
  }

  public async canActivate(route: ActivatedRouteSnapshot) {
    const id: number = +route.params.id;

    let asset: Asset = new Asset();

    if (id) asset = await this.getAssetById(id).toPromise();

    let user: User;

    await this.usersService.getCurrentUser().then((obj) => {
      user = obj.currentUser;
    });

    if (user.roles.some((r) => r.name == RoleTypes.Owner)) return true;

    // Test if...
    if (
      (route.url.some((p) => p.path == 'new') &&
        !user.roles.some((r) => r.name == RoleTypes.Member)) || // The current user has permission to create new assets.
      (route.url.some((p) => p.path == 'edit') && !asset.currentUserCanManage)
    ) {
      // The current user has permission to edit it.
      this.router.navigate(['home']);

      return false;
    }

    return true;
  }

  private dataURLtoFile(dataurl, imageName): File {
    const imgExt = imageName.split('.').pop();
    let mimeType = 'image/png';
    if (imgExt.toLowerCase() !== 'png') {
      mimeType = 'image/jpeg';
    }
    const bstr = atob(dataurl);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], imageName, { type: mimeType });
  }

  public canUserEdit(asset: Asset): boolean {
    return asset?.currentUserCanManage;
  }

  public canUserApproveCIM(asset: Asset, user: User): boolean {
    return (
      !asset.cimVerified &&
      (asset.productOwner.id === user.id ||
        asset.coOwners.some((co) => co.id === user.id))
    );
  }
}
