import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { UtilsService } from '~/core/services/utils.service';
import { Asset } from '~/shared/models/asset.model';
import { RatingStats } from '~/shared/models/ratingStats';
import { AssetService } from '~/shared/services/asset.service';
import { HeaderService } from '~/shared/services/header.service';
import { LateralMenuService } from '~/shared/services/lateral-menu.service';
import { LikedAssetService } from '~/shared/services/liked-asset.service';
import { RequestAccessDetailsService } from '~/shared/services/request-access-description.service';
import { ReviewService } from '~/shared/services/review.service';
import { TagService } from '~/shared/services/tag.service';
import { UserService } from '@/services/user.service';

@Component({
  selector: 'app-lateral-menu',
  templateUrl: './lateral-menu.component.html',
  styleUrls: ['./lateral-menu.component.scss']
})
export class LateralMenuComponent implements OnInit {
  private config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'lateral-menu'
  };

  public get getTagsString(): Array<string> {
    const tags = [];

    this.selectedAsset.tags.map((tag) => {
      tags.push(tag.name);
    });

    return tags;
  }

  @ViewChild('template', { static: true }) public elementView: ElementRef;

  public modalRef: BsModalRef;

  public selectedAsset: Asset;

  public imageObject: Array<object> = [];

  public invalidImages = 0;

  public ReviewStats: RatingStats;

  public assetImage = '../../assets/placeholder.jpg';

  public lightboxOppened = false;

  public videoUrl: SafeResourceUrl;

  constructor(
    private modalService: BsModalService,
    private lateralMenuService: LateralMenuService,
    private router: Router,
    private reviewService: ReviewService,
    private likedAssetService: LikedAssetService,
    private assetService: AssetService,
    private requestAccessDetailsService: RequestAccessDetailsService,
    private headerService: HeaderService,
    public tagsService: TagService,
    private domSanitizer: DomSanitizer,
    private usersService: UserService
  ) {
  }

  private sanitizeVideoUrl(videoUrl: string) {
    if (!videoUrl || !videoUrl?.includes('microsoftstream')) return null;

    if (!videoUrl.includes('embed')) {
      videoUrl = videoUrl.replace('/video', '/embed/video');
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  public getImages(): Array<object> {
    const imgs: Array<object> = [];
    let idx = 0;

    if (this.selectedAsset.images)
      this.selectedAsset.images.forEach((element) => {
        imgs.push({
          image: element,
          index: idx,
          thumbImage: element
        });
        idx += 1;
      });

    return imgs;
  }

  public redirect() {
    const storefrontId = this.headerService.getOpenedStorefrontId();
    this.modalRef.hide();

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      if (storefrontId)
        this.router.navigate([
          '/asset',
          this.selectedAsset.id,
          'details',
          storefrontId
        ]);
      else this.router.navigate(['/asset', this.selectedAsset.id, 'details']);
    });
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  public ngOnInit() {
    this.modalService.onHidden.subscribe(() => {
      const modalsOpened = this.modalService.getModalsCount();
      if (modalsOpened === 0) this.clearAsset();
    });
    this.lateralMenuService.selectedAsset$.subscribe((asset) => {
      if (asset != null) {
        this.modalRef = this.modalService.show(this.elementView, this.config);

        this.assetService
          .getAssetById(asset.id)
          .pipe(take(1))
          .subscribe((fullAsset) => {
            this.selectedAsset = fullAsset;

            const storefrontId = this.headerService.getOpenedStorefrontId();
            this.selectedAsset.tags = this.tagsService.filterTagsByStore(
              this.selectedAsset.tags,
              storefrontId
            );

            if (this.selectedAsset.logo) {
              UtilsService.isValidImage((validImage) => {
                if (validImage) this.assetImage = fullAsset.logo;
              }, this.selectedAsset.logo);
            }

            this.registerCloseModalEvent();

            const images = this.getImages();

            images.forEach((v) => {
              UtilsService.isValidImage((validImage) => {
                if (validImage) {
                  this.imageObject.push(v);
                } else {
                  this.invalidImages++;
                }
              }, v['image']);
            });

            this.videoUrl = this.sanitizeVideoUrl(this.selectedAsset.videoUrl);
          });

        this.reviewService
          .getReviewStats(asset.id)
          .pipe(take(1))
          .subscribe((rt) => {
            this.ReviewStats = rt;
          });
      }
    });
  }

  private registerCloseModalEvent() {
    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.likedAssetService.onToggleAssetModal();
    });
  }

  private clearAsset() {
    this.selectedAsset = null;
    this.imageObject = [];
    this.invalidImages = 0;
    this.assetImage = '../../assets/placeholder.jpg';
  }

  public close() {
    this.modalRef.hide();
    this.clearAsset();
  }

  public openRequestAccessDetails(event) {
    event.stopPropagation();
    this.requestAccessDetailsService.setRequestAccess(
      this.selectedAsset.requestAccessLink
    );
  }

  public getCountViewNumber() {
    return UtilsService.getShortViewNumber(this.selectedAsset.numberOfViews);
  }

  @HostListener('document:keydown', ['$event'])
  public onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.lightboxOppened) {
      event.stopPropagation();
    }
  }
}
