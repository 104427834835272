import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ProductLine } from '../models/productLine';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({ providedIn: 'root' })
export class ProductLineService extends ApiServiceBase<ProductLine> {
    constructor(protected http: HttpClient) {
        super(http, 'product-lines');
    }

    public getAll(): Observable<Array<ProductLine>> {
        return this.get();
    }

    public add(plBl): Observable<number> {
        return this.post(plBl);
    }

    public update(plBl): Observable<number> {
        return this.put(plBl);
    }
}
