<div class="container ">

    <div class="digital-asset-title d-flex align-items-center justify-content-between pb-2 h-15 mt-4">

        <div class="d-flex flex-column">
            <span class="title mr-2 mr-auto">Storefronts Order</span>
            <span class="small-light mr-2 mr-auto description-title">Changes the order of the Storefronts on the home page</span>
        </div>

        <button type="button" class="button-blue px-3" (click)="save()">
            SAVE
        </button>

    </div>

    <div class="row mt-3 px-3">

        <div class=" d-flex flex-column w-100">

            <table mat-table [dataSource]="storefrontsDataSource" matSort #storefrontsSort="matSort">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.storefront.name}} </td>
                </ng-container>

                <ng-container matColumnDef="orderIndex">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="40%">Order</th>
                    <td mat-cell *matCellDef="let element">
                        <input matInput placeholder="Order value" type="number" min="0" class="custom-input w-25" [value]="element.orderIndex" [(ngModel)]="element.orderIndex">
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            </table>

            <mat-paginator #storefrontsPaginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

        </div>
    </div>

</div>
