<ng-template #requestAccess>
    <div class="modal-header d-flex align-items-center">
        <h5 class="modal-title pull-left">How to Request Access</h5>

        <button
            type="button"
            class="close-btn close pull-right"
            aria-label="Close"
            (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body px-4 ">

        <div class="container p-0">

            <div class="row no-gutters d-flex align-items-center">

                <div *ngIf="isHTML(); else showAsLink" [innerHTML]="requestAccessDetails">
                </div>

                <ng-template #showAsLink>
                    <a href="{{requestAccessDetails}}" class="link-area" target="_blank">Click here to request access</a>
                </ng-template>

            </div>

        </div>

    </div>

</ng-template>
