import { ApiServiceBase } from '@/services/api-service-base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { NotificationCategory } from '../models/notification-category';
import { Notification } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiServiceBase<Notification> {
  constructor(
    private toastr: ToastrService,
    protected http: HttpClient,
    private router: Router
  ) {
    super(http, 'notifications');
  }

  public success(message: string, title = 'Success', timeOut = 5000) {
    return this.toastr.success(message, title, {
      closeButton: true,
      timeOut
    });
  }

  public error(
    message: string,
    title = 'Error',
    timeOut = 5000,
    enableHtml = false
  ) {
    return this.toastr.error(message, title, {
      closeButton: true,
      timeOut,
      enableHtml
    });
  }

  public warning(message: string, title = 'Warning', timeOut = 5000) {
    return this.toastr.warning(message, title, {
      closeButton: true,
      timeOut
    });
  }

  public getAll(): Observable<Array<NotificationCategory>> {
    return this.get().pipe(
      map((items) => {
        const categories: Array<NotificationCategory> = [];

        // search if category exists.
        items.forEach((notification) => {
          let category = categories.find((cat) => {
            return cat.name === notification.category;
          });

          // if not exists, create a category.
          if (!category) {
            category = new NotificationCategory();
            category.name = notification.category;

            // add the category to the array of categories.
            categories.push(category);
          }
          // add the notification to the category.
          category.notifications.push(notification);
        });

        // sort all notification from each category by view.
        categories.forEach((category) => {
          category.notifications.sort((x) => (x.viewed ? 1 : -1));
        });

        return categories;
      })
    );
  }

  public update(notification: Notification): Observable<number> {
    return this.put(notification);
  }

  public add(notification: Notification): Observable<number> {
    return this.post(notification);
  }

  public remove(idToDelete: number): Observable<void> {
    return this.delete<void>(`${idToDelete}/`);
  }
}
