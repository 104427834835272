import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { Category } from '~/shared/models/category.model';
import { Storefront } from '~/shared/models/storefront.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { CategoryService } from '~/shared/services/category.service';
import { HeaderService } from '~/shared/services/header.service';
import { StorefrontService } from '~/shared/services/storefront.service';
import { UserService } from '~/shared/services/user.service';

@Component({
    selector: 'app-store-detail',
    templateUrl: './store-about-us.component.html',
    styleUrls: ['./store-about-us.component.scss']
})
export class StoreAboutUsComponent implements OnInit {
    @Input()
    public store: Storefront;

    public categories: Category[];

    public isActive = false;

    public expanded = false;

    public user: User = new User();

    public isCurrentUserOwner = false;
    public isCurrentUserOwnerOrMember = false;

    constructor(
        private headerService: HeaderService,
        private activatedRoute: ActivatedRoute,
        public storeService: StorefrontService,
        private categoryService: CategoryService,
        private usersService: UserService,
        public assetService: AssetService
    ) { }

    public ngOnInit() {
        this.usersService.getCurrentUser().then((obj) => {
            this.user = obj.currentUser;

            this.isCurrentUserOwner = UtilsService.isCurrentUserOwner(this.user);
            this.isCurrentUserOwnerOrMember = UtilsService.isCurrentUserOwnerOrMember(this.user);
        })

        this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
            this.storeService
                .getStoreById(Number(params.get('id')))
                .subscribe((store) => {
                    this.store = store;
                    this.headerService.SetStore(this.store);

                    if (store.owner) {
                        this.usersService.getUserPhoto(store.owner.email).subscribe((photo) => {
                            if (photo)
                                UtilsService.isValidImage((validImage) => {
                                    this.store.owner.image = validImage ? photo : UtilsService.USER_PHOTO_PLACEHOLDER;
                                }, photo);
                        });
                    }

                    this.categoryService
                        .getAllByStorefront(store.id)
                        .subscribe((categories) => {
                            this.categories = categories;

                            this.categories.forEach((category) => {
                                if (category.responsible) {
                                    this.usersService.getUserPhoto(category.responsible.email).subscribe((photo) => {
                                        if (photo)
                                            UtilsService.isValidImage((validImage) => {
                                                category.responsible.image = validImage ? photo : UtilsService.USER_PHOTO_PLACEHOLDER;
                                            }, photo);
                                    });
                                }
                            })
                        });
                });
        });
    }
}
