import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { AddEditHelpLinksComponent } from './components/add-edit-help-links/add-edit-help-links.component';
import { AssetCategoryLabelComponent } from './components/asset-category-label/asset-category-label.component';
import { AssetsWithoutCategoryComponent } from './components/assets-without-category/assets-without-category.component';
import { BoardCategoryAssetsComponent } from './components/board-category-assets/board-category-assets.component';
import { CardCategoryAndSubcategoryDetailedComponent } from './components/card-category-and-subcategory-detailed/card-category-and-subcategory-detailed.component';
import { CardCategoryDetailedComponent } from './components/card-category-detailed/card-category-detailed.component';
import { CardHelpLinksComponent } from './components/card-help-links/card-help-links.component';
import { CreateCategoryModalComponent } from './components/create-category-modal/create-category-modal.component';
import { CreateEditStorefrontTagComponent } from './components/create-edit-storefront-tag/create-edit-storefront-tag.component';
import { CreatedCategoryListComponent } from './components/created-category-list/created-category-list.component';
import { FeedbackWindowComponent } from './components/feedback-window/feedback-window.component';
import { FilterBoardComponent } from './components/filter-board/filter-board.component';
import { ResponsibleCardComponent } from './components/responsible-card/responsible-card.component';
import { TagsFilterHorizontalComponent } from './components/tags-filter-horizontal/tags-filter-horizontal.component';
import { NewStoreComponent } from './pages/new-store/new-store.component';
import { StoreAboutUsComponent } from './pages/store-about-us/store-about-us.component';
import { StoreHomeComponent } from './pages/store-home/store-home.component';

const MODULE_COMPONENTS = [
    StoreHomeComponent,
    StoreAboutUsComponent,
    NewStoreComponent,
    ResponsibleCardComponent,
    CreateCategoryModalComponent,
    CreatedCategoryListComponent,
    CardHelpLinksComponent,
    FilterBoardComponent,
    FeedbackWindowComponent,
    AssetsWithoutCategoryComponent,
    BoardCategoryAssetsComponent,
    CardCategoryDetailedComponent,
    AssetsWithoutCategoryComponent,
    TagsFilterHorizontalComponent,
    AssetCategoryLabelComponent,
    AddEditHelpLinksComponent,
    CreateEditStorefrontTagComponent,
    CardCategoryAndSubcategoryDetailedComponent
];

const MODULE_MODULES = [CommonModule, SharedModule];

@NgModule({
    declarations: [...MODULE_COMPONENTS],
    imports: [...MODULE_MODULES]
})
export class StoreModule { }
