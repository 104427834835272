import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Asset } from '~/shared/models/asset.model';
import { AssetService } from '~/shared/services/asset.service';

@Component({
    selector: 'app-board-related-assets',
    templateUrl: './board-related-assets.component.html',
    styleUrls: ['./board-related-assets.component.scss']
})
export class BoardRelatedAssetsComponent implements OnInit {
    @Input()
    public Asset: Asset;

    public RelatedAssets: Array<Asset>;

    constructor(private assetService: AssetService) {}

    public ngOnInit(): void {
        this.assetService
            .getAssetsByTags(
                this.Asset.tags.map((t) => {
                    return t.id;
                })
            )
            .pipe(take(1))
            .subscribe((assts) => {
                this.RelatedAssets = assts
                    .filter((item) => item.id !== this.Asset.id)
                    .slice(0, 8);
            });
    }
}
