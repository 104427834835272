import { NgModule } from '@angular/core';
import { AssetModule } from './asset/asset.module';
import { CategoryModule } from './category/category/category.module';
import { ErrorModule } from './error/error.module';
import { FAQModule } from './faq/faq.module';
import { HomeModule } from './home/home.module';
import { InternalModule } from './internal/internal.module';
import { SearchModule } from './search/search.module';
import { SimpleDataManagerModule } from './simple-data-manager/simple-data-manager.module';
import { StoreModule } from './store/store.module';
import { UserModule } from './user/user.module';

const MODULE_MODULES = [
  HomeModule,
  AssetModule,
  StoreModule,
  ErrorModule,
  SearchModule,
  InternalModule,
  UserModule,
  CategoryModule,
  SimpleDataManagerModule,
  FAQModule
];

@NgModule({
  imports: [...MODULE_MODULES],
  exports: [...MODULE_MODULES],
  declarations: []
})
export class ModulesModule {}
