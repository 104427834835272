<ng-template #template>
  <div
    class="modal-header d-flex align-items-center"
    [ngStyle]="{ 'background-color': '#0014DC' }">
    <h5 class="modal-title pull-left">{{ modalTitle }}</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="px-4 pt-2 d-flex flex-column">
    <span style="font-size: 14px; color: red; font-weight: 500;">
      This Support is for BI Portfolio application.
    </span>

    <span style="font-size: 14px; color: red; font-weight: 500;">
      If you have any issue related to BI report, please reach out to Product Owner, Category owner or Storefront Owner.
    </span>

    <span style="font-size: 14px; color: red; font-weight: 500;">
      For any dashboard related support please raise a ticket using <a target="_blank" href="https://slb.service-now.com/">ESM</a> or send an email to ITHelp@slb.com
    </span>

  </div>

  <div class="modal-body px-4">
    <div class="d-flex mb-3">
      <div class="radio-btn">
        <input
          id="support"
          type="radio"
          value="support"
          name="support"
          [(ngModel)]="model" />
        <label class="pl-1" for="support">Support</label>
      </div>

      <div class="radio-btn ml-3">
        <input
          id="feedback"
          type="radio"
          value="feedback"
          name="feedback"
          [(ngModel)]="model" />
        <label class="pl-1" for="feedback">Feedback</label>
      </div>
    </div>

    <div class="w-25 mb-4" *ngIf="model === 'feedback'">
      <app-rating
        [fontSize]="26"
        [isInput]="true"
        (hoverChange)="ratingMouseHoverChange($event)"
        (rateChange)="rateSelecte($event)">
      </app-rating>
    </div>

    <div class="subject mb-4" *ngIf="model === 'support'">
      <label class="m-0">Subject</label>

      <input
        type="text"
        name="main-search"
        [(ngModel)]="subject"
        class="custom-input"
        autocomplete="off"
        maxlength="30"
        placeholder="What's it about? (optional)" />
    </div>

    <div class="subject">
      <label class="m-0">Message</label>

      <textarea
        [(ngModel)]="message"
        placeholder="Write your message"
        class="custom-input"
        name="comments"
        maxlength="1000"
        rows="5"></textarea>
    </div>

    <div class="d-flex justify-content-end pt-4">
      <button
        type="reset"
        (click)="closeModal()"
        [ngClass]="'button-bordered-blue'"
        class="col-md-3">
        Cancel
      </button>

      <button
        type="submit"
        (click)="sendEmail()"
        [ngClass]="'button-blue'"
        [disabled]="disableSendButtom"
        class="button-blue col-md-3">
        Send email
      </button>
    </div>
  </div>
</ng-template>