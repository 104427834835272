import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirmation-box',
    templateUrl: './confirmation-box.component.html',
    styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {
    @ViewChild('template', { static: true }) public elementView: ElementRef;

    @Output() public close = new EventEmitter<boolean>();

    private config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'center-modal modal-dialog-centered'
    };

    @Input() public settings = [];

    public modalRef: BsModalRef;

    public modalTitle: string;

    constructor(private modalService: BsModalService) {}

    public ngOnInit(): void {
        this.modalRef = this.modalService.show(this.elementView, this.config);
    }

    public yesClick() {
        this.close.emit(true);
        this.closeModal();
    }

    public noCLick() {
        this.close.emit(false);
        this.closeModal();
    }

    private closeModal() {
        this.modalRef.hide();
    }
}
