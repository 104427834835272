import { ModelBase } from "./mode-base.model";
import { Storefront } from "./storefront.model";
import { User } from "./user.model";

export class LikedStorefront extends ModelBase {
    constructor(init?: Partial<LikedStorefront>) {
        super();
        Object.assign(this, init);
    }

    public user: User;

    public storefront: Storefront;
}