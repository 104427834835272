import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from '~/core/services/utils.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceBase<T> {
  private apiUrl: string = environment.apiUrl;

  constructor(protected http: HttpClient, protected route: string) {
    this.apiUrl = environment.apiUrl;
  }

  protected get HEADERS() {
    return new Object({
      headers: {
        'Content-Type': 'application/json',
        'ApiAuthorization': `${this.currentUserToken}`
      }
    });
  };

  protected get TEXT_HEADERS() {
    return new Object({
      headers: {
        responseType: 'text',
        'ApiAuthorization': `${this.currentUserToken}`
      }
    });
  };

  protected get BASE64_HEADERS() {
    return new Object({
      responseType: 'text' as 'text',
      headers: { 'Content-Type': 'text/html; charset=utf-8',
        'ApiAuthorization': `${this.currentUserToken}`, }
    });
  }

  protected get currentUserToken(): string {
    return localStorage.getItem(UtilsService.SESSION_TOKEN_FIELD_NAME);
  }
  public delete<T>(parameter: string): Observable<T> {
    return this.http.delete<T>(
      this.apiUrl + '/' + this.route + '/' + parameter, this.HEADERS
    );
  }

  protected put(item: any): Observable<number> {
    return this.http.put<number>(this.apiUrl + '/' + this.route, item, this.HEADERS);
  }

  protected post(item: any): Observable<number> {
    return this.http.post<number>(this.apiUrl + '/' + this.route, item, this.HEADERS);
  }

  protected get(parameter?: string): Observable<Array<T>> {
    return this.getAs<T>(parameter);
  }

  protected getSingle(parameter?: string): Observable<T> {
    return this.getSingleAs<T>(parameter);
  }

  protected getAs<T>(parameter?: string): Observable<Array<T>> {
    return this.http.get<Array<T>>(
      this.apiUrl + '/' + this.route + '/' + (parameter ? parameter : ''), this.HEADERS
    );
  }

  protected getSingleAs<T>(parameter?: string): Observable<T> {
    return this.http.get<T>(
      this.apiUrl + '/' + this.route + '/' + (parameter ? parameter : ''), this.HEADERS
    );
  }
}