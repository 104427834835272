import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { ScrollService } from '~/shared/services/scroll.service';
import { UtilsService } from '@core/services/utils.service';
import { User } from '@/models/user.model';
import { UserService } from '@/services/user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @ViewChild('mainarea', { read: ElementRef }) public mainArea: ElementRef<any>;

  constructor(private scrollService: ScrollService,
  private usersService: UserService) {}

  public ngOnInit(): void {
  }

  public ngDoCheck(): void {

    if (this.mainArea) {
      this.scrollService.setScrollChanged(
        this.mainArea.nativeElement.offsetHeight,
        this.mainArea.nativeElement.scrollTop,
        this.mainArea.nativeElement.scrollHeight
      );
    }
  }

  @HostListener('scroll', ['$event'])
  public onScroll(event: any) {
    if (this.mainArea) {
      this.scrollService.setScrollChanged(
        this.mainArea.nativeElement.offsetHeight,
        this.mainArea.nativeElement.scrollTop,
        this.mainArea.nativeElement.scrollHeight
      );
    }
  }
}
