<ng-template #template>
  <div class="modal-header d-flex align-items-center">
    <h5 class="modal-title pull-left">BI Asset/CIM relation Approval</h5>

    <button
      type="button"
      class="close-btn close pull-right"
      aria-label="Close"
      (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="d-flex flex-column px-3">

      <span class="description pb-3">
        Check if the BI Asset is correctly associated with the CIM.<br />
        <br />
        CIM Name: <strong>{{selectedAsset.cimName}}</strong><br />
        Value Streams: <strong>{{getValueStreams()}}</strong><br />

      </span>

      <!-- <span class="rating-title">TExt</span>
      <textarea
        maxlength="255"
        class="custom-input pl-0"
        rows="4"
        placeholder="Write your review (optional)"
        [(ngModel)]="approvalText"></textarea> -->
    </div>

    <div class="d-flex justify-content-end pt-4">
      <button
        type="reset"
        (click)="cancel()"
        class="button-bordered-blue col-md-3">
        CANCEL
      </button>

      <button
        type="submit"
        [disabled]="rating == -1"
        (click)="save()"
        class="button-blue col-md-3">
        APPROVE
      </button>
    </div>
  </div>
</ng-template>