import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '~/core/services/utils.service';
import { User } from '~/shared/models/user.model';
import { UserService } from '~/shared/services/user.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input()
  public showName = false;

  @Input()
  public showAlternativeStyle = false;

  public currentUser: User;

  public isCurrentUserOwnerOrMember = false;

  constructor(private usersService: UserService, private router: Router) {}

  public ngOnInit(): void {
    this.usersService.getCurrentUser().then((obj) => {
      this.currentUser = obj.currentUser;

      this.isCurrentUserOwnerOrMember = UtilsService.isCurrentUserOwnerOrMember(
        this.currentUser
      );
      this.usersService
        .getUserPhoto(this.currentUser.email)
        .subscribe((photo) => {
          if (photo)
            UtilsService.isValidImage((validImage) => {
              this.currentUser.image = validImage
                ? photo
                : UtilsService.USER_PHOTO_PLACEHOLDER;
            }, photo);
        });
    });
  }

  public goToUserProfile() {
    this.router.navigateByUrl('/user-profile');
  }

  public goToLogout() {
    this.router.navigateByUrl('/logout');
  }

  public goToUserManagement() {
    this.router.navigateByUrl('/user-management');
  }
}
