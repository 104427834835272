<div *ngIf="!selectedBiReport" class="bi-reports" [ngClass]="{ required: showRequired }">
  <label class="custom-label">BI Report* <i>(Search for Power BI Reports using the Name or the URL)</i></label>

  <div *ngIf="!ignoreBiReport" class="mt-2 mb-3">

    <div class="d-flex align-items-start ml-2">
      <label class=" custom-label mr-2">Search By:</label>

      <input type="radio" name="title" id="title" class="cursor-pointer"
        [ngModelOptions]="{standalone: true}"
        [checked]="searchType == 'name'"
        (change)="setSearchType('name')">
      <label for="title" class="cursor-pointer f-12 radio-btn mr-2 pl-1">Name</label><br>

      <input type="radio" name="url" id="url" class="cursor-pointer"
        [ngModelOptions]=" {standalone: true}"
        [checked]="searchType == 'url'"
        (change)="setSearchType('url')">
      <label for="url" class="cursor-pointer f-12 radio-btn pl-1">URL</label><br>
    </div>

    <div class="d-flex search-area" *ngIf="searchType == 'name'">

      <input
        autocomplete="chrome-off"
        type="text"
        [(ngModel)]="textToSearch"
        required
        maxlength="300"
        class="custom-input"
        placeholder="Name"
        (keyup.enter)="searchBIReports()" />

      <div class="total-items" tooltip="Items found">
        <span *ngIf="totalItemsByName >= 0">{{totalItemsByName}}</span>
      </div>

      <div
        class="search-bi-report cursor-pointer d-flex align-items-center justify-content-center"
        [ngClass]="{'disabled': textToSearch.length < 3}"
        (click)="searchBIReports()">
        <img src="/assets/icon-search.svg" />
      </div>
    </div>

    <div class="d-flex search-area" *ngIf="searchType == 'url'">

      <input
        autocomplete="chrome-off"
        type="text"
        [(ngModel)]="urlToSearch"
        required
        maxlength="500"
        class="custom-input"
        placeholder="URL"
        (keyup.enter)="searchBIReports(true)" />

      <div class="total-items" tooltip="Items found">
        <span *ngIf="totalItemsByUrl >= 0">{{totalItemsByUrl}}</span>
      </div>

      <div
        class="search-bi-report cursor-pointer d-flex align-items-center justify-content-center"
        [ngClass]="{'disabled': urlToSearch?.length < 3}"
        (click)="searchBIReports(true)">
        <img src="/assets/icon-search.svg" />
      </div>
    </div>

    <div
      *ngIf="showBIReportsSelector"
      class="results d-flex flex-column"
      (clickOutside)="closeBiReports()" [delayClickOutsideInit]="true" [exclude]="'.search-bi-report'">

      <div *ngIf="!biReports" class="d-flex justify-content-center align-items-center w-100">
        <app-loader class="loading d-flex justify-content-center align-items-center " [height]="20"></app-loader>
      </div>

      <span class="no-items" *ngIf="biReports?.length == 0">No items found</span>

      <span
        *ngFor="let bi of biReports"
        class="result"
        (click)="biReportSelected(bi)"
        [attr.title]="'BI Report Id:      ' + bi.code + '\nWorkspace Name:  '+ bi.biWorkspace.name + '\nWorkspace Id:   '+ bi.biWorkspace.code">
        {{ bi.name }}
      </span>

    </div>

    <span style="color: red; font-size: 13px;" *ngIf="assetUsingSelectedReport">
      The selected BI Report is already being used by BI Asset
      <a class="ml-auto mt-2" target="_blank" style="color: red; text-decoration:underline;" [routerLink]="['/asset', assetUsingSelectedReport.id, 'details']">{{assetUsingSelectedReport.name}}</a>
    </span>

  </div>

  <div class="d-flex flex-column" *ngIf="showIgnoreBiReportOption">
    <div>
      <label class="check-box-container ml-2">My Power BI Report is not on the list
        <input
          type="checkbox"
          [(ngModel)]="ignoreBiReport"
          [checked]=" ignoreBiReport"
          (change)="setIgnoreBiReport()" />
        <span class="checkmark"></span>
      </label>
    </div>

    <label *ngIf="ignoreBiReport" class="custom-label mt-2 pb-0 ml-2">BI Asset URL* </label>
    <input *ngIf="ignoreBiReport"
      autocomplete="chrome-off"
      type="text"
      [(ngModel)]="urlToSearch"
      (change)="urlChanged()"
      required
      maxlength="500"
      class="custom-input" />
  </div>

</div>

<div *ngIf="selectedBiReport"
  class="bi-report-selected d-flex justify-items-center w-100">

  <div class="d-flex flex-column ">
    <label class="custom-label">BI Report*</label>

    <span class="bi-name d-block" tooltip="BI Report name" delay="500">{{selectedBiReport.name}}</span>
    <span class="bi-workspace d-block" tooltip="Workspace name" delay="500">{{selectedBiReport?.biWorkspace?.name}}</span>
    <span class="bi-url d-block" tooltip="BI Report address" delay="500">{{selectedBiReport?.url}}</span>
  </div>

  <span class="clear ml-auto cursor-pointer" (click)="clear()"> ×</span>

</div>