<div class="d-flex flex-column align-items-stretch align-self-stretch w-100 h-100">

  <app-page-header class="d-block"></app-page-header>

  <main #mainarea class="d-flex flex-column h-100" role="main" (scroll)="onScroll($event)">

    <app-search-bar></app-search-bar>

    <router-outlet></router-outlet>

    <app-page-footer class="mt-auto"></app-page-footer>

    <app-lateral-menu></app-lateral-menu>

    <app-virtual-agent></app-virtual-agent>

    <app-request-access-details></app-request-access-details>

  </main>

</div>

<app-outdated-browser></app-outdated-browser>
<app-sandbox-warning></app-sandbox-warning>