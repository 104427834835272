import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { StorefrontOrder } from '../models/storefront-order.model';
import { ApiServiceBase } from './api-service-base.service';

@Injectable({
    providedIn: 'root'
})
export class StorefrontOrderService extends ApiServiceBase<StorefrontOrder> {
    constructor(protected http: HttpClient) {
        super(http, 'storefronts-order');
    }

    public getOrderIndex(): Observable<StorefrontOrder[]> {
        return this.http.get<StorefrontOrder[]>(`${environment.apiUrl}/storefronts-order`, this.HEADERS);
    }

    public updateStorefrontsOrder(order: StorefrontOrder[]): Observable<number> {
        return this.put(order);
    }

}
