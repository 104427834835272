<ng-select
    id="userpicker"
    [items]="people$ | async"
    bindLabel="name"
    [minTermLength]="2"
    [loading]="loading"
    typeToSearchText="Please enter 2 or more characters"
    [typeahead]="peopleInput$"
    [formControl]="form"
    [editableSearchTerm]="editableSearchTerm"
    [placeholder]="placeholder"
    [multiple]="multiple">

    <div
        *ng-option-tmp="let person = item"
        title="
            Email: {{ person.email }}
            Gin Number: {{ person.ginNumber }}
            Login Name: {{ person.loginName }}
            ">
        <span>{{ person.name }}</span>
    </div>
</ng-select>
