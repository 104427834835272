import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from '~/core/services/utils.service';
import { HelpLink } from '~/shared/models/help-link.model';

@Component({
    selector: 'app-add-edit-help-links',
    templateUrl: './add-edit-help-links.component.html',
    styleUrls: ['./add-edit-help-links.component.scss']
})
export class AddEditHelpLinksComponent implements OnInit {

    @Input()
    public helpLinks: HelpLink[];

    @Output()
    public linksChanged = new EventEmitter<Array<HelpLink>>();

    @Input()
    public formValidated = false;

    public regex = UtilsService.REGEX_URL_WITH_MAILTO;

    private textFieldBeingEdited = '';

    constructor() { }

    public ngOnInit(): void { }

    public removeItem(indexToRemove): void {
        this.helpLinks = this.helpLinks.filter(
            (helpLink, index) => index !== indexToRemove
        );

        this.linksListChanged();
    }

    public addNew(): void {
        if (!this.helpLinks)
            this.helpLinks = [];

        const newItem = new HelpLink();

        newItem.title = '';
        newItem.linkAddress = '';

        this.helpLinks.push(newItem);
    }

    public enterFocus(initialText: string): void {
        this.textFieldBeingEdited = initialText;
    }

    public leaveFocus(endlText: string): void {
        if (this.textFieldBeingEdited !== endlText) {
            this.linksListChanged();
        }
    }

    private linksListChanged(): void {
        this.linksChanged.emit(this.helpLinks);
    }

}
