import { CategoryService } from "@/services/category.service";
import { Component, Input, OnInit } from '@angular/core';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { Tag } from '~/shared/models/tag.model';

@Component({
    selector: 'app-card-category-detailed',
    templateUrl: './card-category-detailed.component.html',
    styleUrls: ['./card-category-detailed.component.scss']
})
export class CardCategoryDetailedComponent implements OnInit {

    @Input()
    public category: Category;

    @Input()
    public tagsToFilter: Array<Tag> = [];

    public loading = true;

    @Input()
    public color = '#1683FB';

    public MaxAssetsToShow = 3;
    public showAll = false;

    constructor(private categoryService: CategoryService) { }

    public ngOnInit() {
        this.loading = false;

        // this.category.assets = UtilsService.sortArrayByOrderIndexAndPriority(this.category.assets);

        const totalPriorityItens = this.category.assets.filter(a => a.isPriority === true).length;

        if (totalPriorityItens > 0) {
            this.MaxAssetsToShow = totalPriorityItens;
        }
    }

    public get assetsToShow(): Array<Asset> {
        if (this.tagsToFilter?.length > 0) {
            const filteredAssets = [];
            this.category.assets.forEach((asset) => {
                this.tagsToFilter.forEach((selectedTag) => {
                    if (asset.tags.some((tag) => tag.id === selectedTag.id)) {
                        const i = filteredAssets.findIndex((x) => x.id === asset.id);
                        if (i <= -1) {
                            filteredAssets.push(asset);
                        }
                    }
                });
            });

            return filteredAssets;
        }
        else {
            return this.category.assets;
        }
    }
}
