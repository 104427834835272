<ng-template #createInternalModal>
    <form [formGroup]="formGroup" (change)="isSaved = false">
        <div class="lateral-menu">
            <header>
                <app-modal-header
                    [title]="title"
                    [heightpx]="70"
                    (onClickClose)="closeModal()">
                </app-modal-header>
            </header>
            <fieldset [disabled]="isDisabled">
                <div class="modal-body">
                    <div class="pt-2">
                        <div class="header-container d-flex flex-column">
                            <span class="title mb-1">Asset Info</span>
                            <span class="sub-title pb-1">Fill the required asset information</span>
                        </div>
                        <div>
                            <div class="row pl-4 pb-0 pt-3 pr-4">
                                <label class="custom-label">Asset Name*</label>
                                <input maxlength="100" class="custom-input mb-2 w-100" type="text" formControlName="assetName"
                                    [ngClass]="{ required: !formGroup.controls['assetName'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">Asset Description*</label>
                                <input maxlength="300" class="custom-input mb-2 w-100" type="text" formControlName="assetDescription"
                                    [ngClass]="{ required: !formGroup.controls['assetDescription'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">Asset Address*</label>
                                <input maxlength="300" class="custom-input mb-2 w-100" type="text" formControlName="assetAddress"
                                    [ngClass]="{ required: !formGroup.controls['assetAddress'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">Ask & Learn Address</label>
                                <input maxlength="300" class="custom-input mb-2 w-100" type="text" formControlName="assetAskAndLearnLink"
                                    [ngClass]="{ required: !formGroup.controls['assetAskAndLearnLink'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">Usage Stats Address</label>
                                <input maxlength="300" class="custom-input mb-2 w-100" type="text" formControlName="assetUsageStatsLink"
                                    [ngClass]="{ required: !formGroup.controls['assetUsageStatsLink'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">Video Address</label>
                                <input maxlength="300" class="custom-input mb-2 w-100" type="text" formControlName="assetVideoLink"
                                    [ngClass]="{ required: !formGroup.controls['assetVideoLink'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">Documentation Address</label>
                                <input maxlength="300" class="custom-input mb-2 w-100" type="text" formControlName="assetDocumentationLink"
                                    [ngClass]="{ required: !formGroup.controls['assetDocumentationLink'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">Request Access Address</label>
                                <input maxlength="300" class="custom-input mb-2 w-100" type="text" formControlName="assetRequestAccessLink"
                                    [ngClass]="{ required: !formGroup.controls['assetRequestAccessLink'].valid }" />
                            </div>
                            <div class="row pl-4 pb-0 pt-2 pr-4">
                                <label class="custom-label">BRD</label>
                                <input maxlength="50" class="custom-input mb-2 w-100" type="text" formControlName="brd"
                                    onkeyup="this.value=this.value.replace(/[^\d+]/, '')"
                                    [ngClass]="{ required: !formGroup.controls['brd'].valid }" />
                            </div>
                        </div>
                        <div class="pl-2 pr-2">
                            <div class="pb-0 pt-2">
                                <label class="custom-label">Storefront*</label>
                                <ng-select
                                    class="m-0"
                                    [items]="storefronts"
                                    [bindLabel]="'name'"
                                    formControlName="storefront"
                                    (change)="onStorefrontChanges($event)">
                                </ng-select>
                                <div [ngClass]="{ required: !formGroup.controls['storefront'].valid }"></div>
                            </div>
                            <div class="pb-0 pt-2">
                                <label class="custom-label">PL/BL</label>
                                <ng-select
                                    class="m-0"
                                    [items]="productLines$ | async"
                                    formControlName="assetProductLine"
                                    [bindLabel]="'name'">
                                </ng-select>
                            </div>
                            <div class="pb-0 pt-2">
                                <label class="custom-label">Tags</label>
                                <ng-select
                                    class="m-0"
                                    [multiple]="true"
                                    [items]="tags$ | async"
                                    bindLabel="name"
                                    formControlName="tags">
                                </ng-select>
                            </div>
                            <div class="pb-0 pt-2">
                                <label class="custom-label">Key Processes/Sub Categories</label>
                                <ng-select
                                    [multiple]="true"
                                    class="m-0"
                                    [items]="keyProcesses$ | async"
                                    [bindLabel]="'name'"
                                    formControlName="keyProcesses">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="pt-4">
                        <div class="header-container d-flex flex-column">
                            <span class="title mb-1">Scrum Master</span>
                            <span class="sub-title pb-1">Select a user to be the Scrum Master</span>
                        </div>

                        <div class="m-0 mt-2">
                            <div class="w-100 pl-2 pt-2">
                                <app-user-picker [multiple]="false" formControlName="scrumMaster"></app-user-picker>
                                <div [ngClass]="{ required: !formGroup.controls['scrumMaster'].valid }"></div>
                            </div>
                        </div>

                    </div>

                    <div class="pt-4">
                        <div class="header-container d-flex flex-column">
                            <span class="title mb-1">Product Owner</span>
                            <span class="sub-title pb-1">Select a user to be the Product Owner</span>
                        </div>

                        <div class="m-0 mt-2">
                            <div class="w-100 pl-2 pt-2">
                                <app-user-picker [multiple]="false" formControlName="assetProductOwner"></app-user-picker>
                                <div [ngClass]="{ required: !formGroup.controls['assetProductOwner'].valid }"></div>
                            </div>
                        </div>

                    </div>

                    <div class="pt-4">
                        <div class="header-container d-flex flex-column">
                            <span class="title mb-1">Category</span>
                            <span class="sub-title pb-1">Select a category to add in this internal entry</span>
                            <div class="pb-0 pt-2">
                                <ng-select
                                    class="m-0"
                                    [items]="categories$ | async"
                                    formControlName="category"
                                    placeholder="{{ hasStorefront ? 'Categories' : 'First, choose a Storefront' }}"
                                    [disabled]="!hasStorefront || !categories$"
                                    [bindLabel]="'name'">
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="pt-4 header-container d-flex flex-column">
                        <span class="title mb-1">Images</span>
                        <span class="sub-title pb-1">Select images to add in this internal entry</span>
                    </div>

                    <div class="select-image pt-2">
                        <app-image-upload
                            class="pt-3"
                            [fileType]="'.png'"
                            [multiple]="true"
                            (filesSelected)="onSelectImages($event)">
                        </app-image-upload>

                        <div class="row no-gutters w-100">
                            <div *ngFor="let img of images; let idx = index" class="position-relative col-md-2 p-1">

                                <img class="screenshot" [src]="img.localPath" width="100%" />

                                <div class="remove-img d-flex align-items-center justify-content-center">
                                    <span class="cursor-pointer" (click)="removeScreenshotImage(idx)">
                                        <svg width="10" height="10" viewBox="0 0 13 13" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5332 5L9.57617 9.04883L9.04883 9.57617L5 5.5332L0.951172 9.57617L0.423828 9.04883L4.4668 5L0.423828 0.951172L0.951172 0.423828L5 4.4668L9.04883 0.423828L9.57617 0.951172L5.5332 5Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="modal-footer row mr-1 ml-0">
            <button
                *ngIf="internal.id != 0 && currentUserHasRole('Owner')"
                title="{{ internal.id != 0 && !internal.validated ?
                    'You have to validate this entry before process it.' : 'Create an asset with its entry information and set this entry to Finalized.' }}"
                type="submit"
                class="button-blue col-md-3"
                [disabled]="!internal.validated || internal.processedAsset?.id != 0"
                (click)="processData()">
                PROCESS
            </button>
            <button
                type="submit"
                *ngIf="internal.id != 0 && currentUserHasRole('Owner')"
                [disabled]="!areAllFieldsChecked() || !isSaved || internal.processedAsset?.id != 0"
                (click)="validateData()"
                [class]="'button-' + (internal.validated ? 'red' : 'blue') + ' col-md-3'">
                {{ internal.validated ? 'UNLOCK' : 'VALIDATE' }}
            </button>
            <button
                type="submit"
                class="button-blue col-md-3"
                (click)="add()"
                [disabled]="disableSaveButton()">
                SAVE
            </button>
        </div>
    </form>
    <div *ngIf="loading" class="d-flex justify-content-center w-100 pt-5">
        <app-loader [height]="60"></app-loader>
    </div>
</ng-template>
