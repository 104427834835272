import { FitForRoleItem } from "@/models/fit-for-role-item.model";
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Asset } from '~/shared/models/asset.model';
import { Category } from '~/shared/models/category.model';
import { KeyProcess } from '~/shared/models/keyProcess.model';
import { ProductLine } from '~/shared/models/productLine';
import { Tag } from '~/shared/models/tag.model';
import { User } from '~/shared/models/user.model';
import { AssetService } from '~/shared/services/asset.service';
import { NotificationService } from '~/shared/services/notification.service';

@Component({
    selector: 'app-modal-create-bulk-assets',
    templateUrl: './modal-create-bulk-assets.component.html',
    styleUrls: ['./modal-create-bulk-assets.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(500, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(500, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ModalCreateBulkAssetsComponent implements OnInit {

    loading: boolean = false;

    @ViewChild('createBulkAssets', { static: true }) public modal: ElementRef;

    @Output() public close = new EventEmitter<boolean>();

    @Input() public keyProcesses: KeyProcess[];
    @Input() public productLines: ProductLine[];
    @Input() public tags: Tag[];
    @Input() public jobs: FitForRoleItem[];
    @Input() public slBusinessLines: FitForRoleItem[];
    @Input() public businessFunctions: FitForRoleItem[];
    @Input() public slDivisions: FitForRoleItem[];
    @Input() public categories: Category[];
    @Input() public storefrontTags : Tag[];

    public modalRef: BsModalRef;

    public file: File = null;

    public createdAssets: Asset[] = null;

    public width: number = 0;

    public fileNameLabel: string = null;

    public fileNamePlaceholderLabel: string = 'Choose file';

    public progressBarBackgroundColor: string = '#1683FB';

    public showPredefinedValues: boolean = false;

    public selectedUser: User = null;

    public selectedOptions: string[] = [];

    public readonly predefinedValuesOptions: string[] = ['Key Processes', 'Product Lines', 'Global Tags', 'Storefront Tags', 'Users', 'SL Divisions', 'Jobs', 'SL Business Lines', 'Business Functions', 'Categories (Storefront - Category)'];

    private _selectedOption: string = '';
    public selectedStorefront: string = '';

    set selectedOption(selectedOption: string) {
        this._selectedOption = selectedOption;

        this.selectedOptions = [];
    }

    get selectedOption(): string { return this._selectedOption; }

    constructor(
        private assetService: AssetService,
        private toastr: NotificationService,
        private modalService: BsModalService) { }

    ngOnInit(): void {
        this.openModal();
    }

    public add() {
        let extension = this.file.name.substr(this.file.name.length - 4).toLowerCase();

        if (!this.file || extension !== ".csv") {
            this.toastr.error("Please, submit a .CSV file.");
            return;
        }

        this.loading = true;

        this.assetService.addBulkAssets(this.file).subscribe((ids) => {
            this.width = 70;

            this.assetService.getAllByIds(ids).subscribe((assets) => {
                this.width = 100;

                this.createdAssets = assets;

                this.toastr.success("All assets were created successfully.");
            });
        },
            (error) => {
                this.progressBarBackgroundColor = '#FB1631';

                let errorInterval = setInterval(() => {
                    if (this.width < 10) {
                        clearInterval(errorInterval);
                    } else {
                        this.width -= 10;
                    }

                    this.closeModal();
                }, 500);
            },
            () => {
                this.file = null;

                this.fileNameLabel = '';

                this.loading = false;
            });

        let interval = setInterval(() => {
            if (this.width < 90) {
                this.width += 5
            } else {
                clearInterval(interval);
            }
        }, 1500);
    }

    public onFileChange(files: FileList) {
        if (files && files.item(0)) {
            this.file = files.item(0);

            this.fileNameLabel = this.file.name;
        } else {
            this.file = null;

            this.fileNameLabel = null;
        }
    }

    public closeModal() {
        this.close.emit(true);

        this.modalRef.hide();
    }

    private openModal(): void {
        this.modalRef = this.modalService.show(this.modal, {
            animated: true,
            keyboard: false,
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'lateral-menu col-md-9 p-0 modal-dialog'
        });
    }

    public concatenateAllOptionsName(): string {
        return this.selectedOptions.join(';');
    }

    public onOptionChange(event: any) {
        this.selectedOption = event;
    }
    public storeFrontNameChanged(value: string){
      this.selectedStorefront = value;
    }
}
